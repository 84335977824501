import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';

import classNames from 'classnames';
import ProfileIdHandler from '@/common/ProfileIdHandler';
import useSelectedProfile from '@/hooks/router/useSelectedProfile';
import EnrichedRevealProfileModal from '@/revealComponents/EnrichedRevealProfileModal';
import {
  ContactSearch,
  SearchResultItem,
} from '@/routes/RevealView/SearchView/types';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import getAtsFiltersExplanation from './atsFiltersExplanation';
import NewProfileRow from '../../ProfileRow';
import ProfileActions from '../../JobsView/JobView/MoreContactsTab/ProfileActions';
import { CandidateSelection } from './useCandidateSelection';
import { ColumnIds } from '../../JobsView/JobView/ProjectProfilesTab/columns/useProfilesTableColumns';

import styles from './SearchResultsPage.module.less';

type SearchResultsPageProps = {
  clientId: string;
  searchPoolId: string;
  candidateSelection: CandidateSelection;
  searchResultItems: SearchResultItem[];
  searches: ContactSearch[];
  loadingResults: boolean;
  allCandidateAcrossAllPages?: SearchResultItem[];
  projectId?: string;
  shouldHideCheckboxes?: boolean;
  displayColumn?: (id: ColumnIds) => boolean;
  customFields?: CustomFieldDefinition[];
};

const SearchResultsPage = ({
  clientId,
  searchPoolId,
  candidateSelection,
  searchResultItems,
  searches,
  loadingResults,
  allCandidateAcrossAllPages,
  projectId,
  shouldHideCheckboxes,
  displayColumn = () => false,
  customFields,
}: SearchResultsPageProps): JSX.Element => {
  const { t } = useTranslation('translations');
  const [selectedProfileId, setSelectedProfileId] = useSelectedProfile();
  const [lockedRowHover, setLockedRowHover] = useState<string>();

  const loading = loadingResults;

  const atsFilters = getATSFilters(searches);

  const profilesWithScore = React.useMemo(
    () =>
      _.map(_.compact(searchResultItems), (profile) => {
        const scores = profile?.scores || [];
        const matchingDetails = profile?.matchingDetails || [];
        return {
          id: profile.searchPoolProfileId,
          ...profile,
          score: scores[0] || 0,
          ...(!!matchingDetails[0] && { matchingDetails: matchingDetails[0] }),
        };
      }),
    [searchResultItems],
  );
  const sortedVisibleProfiles = _.sortBy(
    profilesWithScore,
    ({ score }) => -score,
  );

  const handleRangeSelect = <T extends { id: string }>(profile: T) => {
    candidateSelection.handleRangeSelect({ profileId: profile.id });
  };

  const selectOneProfileHandler = <T extends { id: string }>(profile: T) => {
    candidateSelection.toggle(profile);
  };

  const profileIdHandler = ProfileIdHandler({
    profileIds: _.pluck(sortedVisibleProfiles, 'id'),
    selectedProfileId,
    onSelectProfileId: (profileId: string) => setSelectedProfileId(profileId),
  });

  useEffect(() => {
    const profilesWithId = _.map(
      allCandidateAcrossAllPages || [],
      (profile) => {
        return {
          id: profile.searchPoolProfileId,
        };
      },
    );
    candidateSelection.updateAllCandidates({ candidates: profilesWithId });
    // eslint-disable-next-line
  }, [allCandidateAcrossAllPages?.length]);

  return (
    <div className={styles.searchResultsPage}>
      {loadingResults ? (
        <Loading />
      ) : (
        _.map(sortedVisibleProfiles, (profile) => (
          <div
            className={classNames(styles.row, {
              [styles.lockedHover]: lockedRowHover === profile.id,
            })}
            key={profile.id}
          >
            <NewProfileRow
              clientId={clientId}
              searchPoolId={searchPoolId}
              profileId={profile.id}
              onClick={() => setSelectedProfileId(profile?.id)}
              isSelected={candidateSelection.isSelected({
                profileId: profile?.id,
              })}
              onToggleCheckbox={selectOneProfileHandler}
              onRangeSelect={handleRangeSelect}
              showMissions={displayColumn('project-profiles-view-missions')}
              showEmail={displayColumn('project-profiles-view-email')}
              showRelevance={displayColumn('project-profiles-view-relevance')}
              showSequence={displayColumn('project-profiles-view-sequence')}
              showCampaignState={displayColumn(
                'project-profiles-view-campaign-state',
              )}
              showActiveSequence
              showLastInteraction={displayColumn(
                'project-profiles-view-last-interaction',
              )}
              showNextInteraction={displayColumn(
                'project-profiles-view-next-interaction',
              )}
              showAtsApplications={false}
              atsExplanation={getAtsFiltersExplanation({ atsFilters, profile })}
              matchingDetails={profile.matchingDetails}
              shouldHideCheckbox={shouldHideCheckboxes}
              displayColumn={displayColumn}
              customFields={customFields}
              showCompanyDealState={displayColumn('company-custom-deal-state')}
            />
            {projectId !== undefined && !shouldHideCheckboxes && (
              <div className={styles.actions}>
                <ProfileActions
                  projectId={projectId}
                  profileId={profile.id}
                  setLockHover={(lock) => {
                    if (lock && lockedRowHover === undefined) {
                      setLockedRowHover(profile.id);
                    }
                    if (!lock && lockedRowHover === profile.id) {
                      setLockedRowHover(undefined);
                    }
                  }}
                />
              </div>
            )}
          </div>
        ))
      )}

      {!loading && _.isEmpty(sortedVisibleProfiles) && (
        <div className={styles.noResults}>
          {t('reveal.searchView.results.empty')}
        </div>
      )}

      {/* Enriched profile query must end after non-enriched profiles have
      loaded so we get the full data in the modal */}
      {!loading && (
        <EnrichedRevealProfileModal
          clientId={clientId}
          searches={searches}
          open={!!selectedProfileId}
          onClose={() => setSelectedProfileId(null)}
          profileIdHandler={profileIdHandler}
        />
      )}
    </div>
  );
};

const Loading = () => (
  <div className={styles.loadingPlaceholder}>
    <Loader active inline='centered' size='large' />
  </div>
);

function getATSFilters(searches: ContactSearch[]) {
  try {
    const searchParams = JSON.parse(searches[0]?.free || '{}') || {};
    if (!_.isEmpty(searchParams?.smartrecruitersFilters)) {
      return searchParams?.smartrecruitersFilters;
    }
    if (!_.isEmpty(searchParams?.greenhouseFilters)) {
      return searchParams?.greenhouseFilters;
    }
    if (!_.isEmpty(searchParams?.leverFilters)) {
      return searchParams?.leverFilters;
    }
    if (!_.isEmpty(searchParams?.teamtailorFilters)) {
      return searchParams?.teamtailorFilters;
    }
    if (!_.isEmpty(searchParams?.recruiteeFilters)) {
      return searchParams?.recruiteeFilters;
    }
    if (!_.isEmpty(searchParams?.boondmanagerFilters)) {
      return searchParams?.boondmanagerFilters;
    }
    if (!_.isEmpty(searchParams?.ashbyFilters)) {
      return searchParams?.ashbyFilters;
    }
    return null;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
}

export default SearchResultsPage;
