import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

export const UpdateProfileDataByEnrichmentMutation = gql`
  mutation UpdateProfileDataByEnrichment(
    $profileId: ID!
    $searchPoolId: ID!
    $input: UpdateProfileDataByEnrichmentInput
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateProfileDataByEnrichment(profileId: $profileId, input: $input) {
        profile {
          id
          resumeData {
            phoneNumber
            email
          }
        }
      }
    }
  }
`;

type UpdateProfileDataByEnrichmentVariables = {
  searchPoolId: string;
  profileId: string;
  input: { email?: string; phoneNumber?: string };
};

type UpdateProfileDataByEnrichmentResult = {
  data: {
    searchPoolProfile: {
      updateProfileDataByEnrichment: {
        profile: {
          id: string;
          resumeData: {
            email: string;
            phoneNumber: string;
          };
        };
      };
    };
  };
};

export default function useUpdateProfileDataByEnrichment() {
  return useMutation<
    UpdateProfileDataByEnrichmentResult,
    UpdateProfileDataByEnrichmentVariables
  >(UpdateProfileDataByEnrichmentMutation);
}
