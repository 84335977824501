import React from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Grid } from 'semantic-ui-react';
import { getTranslatedText } from '../../../../common';

import Pill from './Pill';


const RelevantTags = ({ relevantTags }) => {
  return (
    <Grid padded className='profile-relevant-tags'>
      {
        _.map(relevantTags, ({ label, content, popupContent, category }, index) => {
          return (
            <RelevantTag
              key={index}
              label={label}
              content={content}
              popupContent={popupContent}
              category={category}
            />
          );
        })
      }
    </Grid>
  );
};

const tagIcons = {
  GitHub: '/images/logos/logo-github.svg',
  StackOverflow: '/images/logos/logo-stackoverflow.svg',
  LinkedIn: '/images/logos/logo-linkedin.svg',
  Greenhouse: '/images/logos/greenhouse-square-logo.png',
  Lever: '/images/logos/lever-square-logo.png',
  Recruitee: '/images/logos/recruitee-square-logo.png',
  SmartRecruiters: '/images/logos/smartrecruiters-square-logo.png',
  WelcomeKit: '/images/logos/wttj-square-logo.png',
  Workable: '/images/logos/workable-square-logo.png',
  Teamtailor: '/images/logos/teamtailor-square-logo.png',
  Ashby: '/images/logos/ashby-square-logo.png',
};

const RelevantTag = ({ label, content, popupContent, category }) => {
  if (_.isEmpty(content) || _.isEmpty(label) || label.default === '') {
    return null;
  }

  const iconSrc = tagIcons[getTranslatedText(label)];
  const icon = iconSrc && <img src={iconSrc} alt='tag logo'/>;
  const className = classNames(category && category.split(':'));
  return (
    <Pill
      className={className}
      icon={icon}
      content={content}
      label={label}
      popupContent={popupContent}
    />
  );
};


export default RelevantTags;
