import gql from 'graphql-tag';

import { QueryResult, useQuery } from '@apollo/client';

import { UserEnrichmentIntegration as UserIntegrationFragment } from '@/graphql/fragments/EnrichmentIntegration';

export interface EnrichmentIntegrationQuotaPeriod {
  beginDate: string;
  endDate: string;
  totalCreditsCount: number;
  usedCreditsCount: number;
}

export interface EnrichmentIntegrationQuota {
  currentPeriod: EnrichmentIntegrationQuotaPeriod;
  periodPolicy: {
    type: 'monthly';
    totalCreditsCount: number;
    carryOver: boolean;
  };
  history: EnrichmentIntegrationQuotaPeriod[];
}

export interface UserEnrichmentIntegration {
  id: string;
  type: string;
  status: string;
  useClientIntegration?: boolean;
  integrationId?: string;
  // We can enable an integration with no quota defined, meaning it will
  // directly use client's quota
  quota?: EnrichmentIntegrationQuota;
  clientIntegration?: {
    id: string;
    quota?: {
      currentPeriod: EnrichmentIntegrationQuotaPeriod;
    };
  };
}

const GET_USER_ENRICHMENT_INTEGRATIONS = gql`
  query getUserEnrichmentIntegrations {
    user {
      id
      userCurrentClientSettings {
        id
        enrichmentIntegrations {
          ...UserEnrichmentIntegration
        }
      }
    }
  }
  ${UserIntegrationFragment}
`;

export default function useUserEnrichmentIntegrations(): QueryResult & {
  enrichmentIntegrations: UserEnrichmentIntegration[];
} {
  const query = useQuery(GET_USER_ENRICHMENT_INTEGRATIONS);

  return {
    ...query,
    enrichmentIntegrations:
      query.data?.user?.userCurrentClientSettings?.enrichmentIntegrations || [],
  };
}
