import gql from 'graphql-tag';

export default gql`
  fragment ProfileMissionInfo on ProfileMissionInfo {
    missionId
    insertionDate
    data {
      archived
      comment
      segmentationStates {
        segmentationId
        state
        interestedStepId
      }
    }
  }
`;
