import React, { useCallback, useState } from 'react';

import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import Cross from '@/components/Common/Icons/Cross';
import DropdownPanel from '@/components/Common/DropdownPanel';
import GenericSlider from '@/components/Common/GenericSlider';

import styles from './LocationPill.module.less';

type Props = {
  name: string;
  initialDistance: number;
  onClose: () => void;
  onDistance: (value: number) => void;
};

function LocationPill({ name, initialDistance, onClose, onDistance }: Props) {
  const [distance, setDistance] = useState(initialDistance);
  const handleDistance = useCallback(
    (value: number) => {
      setDistance(value);
      onDistance(value);
    },
    [onDistance],
  );
  return (
    <GenericDropdown
      trigger={
        <DropdownControlsContext.Consumer>
          {({ toggleDropdown }) => (
            <div className={styles.pill} onClick={toggleDropdown}>
              {name}
              {distance !== 0 && (
                <span className={styles.distance}>
                  <DistanceInKmDisplay value={distance} />
                </span>
              )}
              <button
                type='button'
                className={styles.close}
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                <Cross />
              </button>
            </div>
          )}
        </DropdownControlsContext.Consumer>
      }
      className={styles.container}
    >
      <DropdownPanel className={styles.dropdownPanel}>
        <div className={styles.distanceTitle}>Distance</div>
        <GenericSlider
          min={0}
          max={200}
          step={10}
          onValue={handleDistance}
          value={distance}
          ValueDisplay={DistanceInKmDisplay}
        />
      </DropdownPanel>
    </GenericDropdown>
  );
}

const DistanceInKmDisplay = ({ value }: { value: number }) => `${value}km`;

export default LocationPill;
