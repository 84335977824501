import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { withTranslation } from 'react-i18next';
import { Form, Divider, Dropdown, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import withClient from '@/hocs/clients/withClient';
import withUpdateJobPostingApplicationForm from '@/hocs/offers/withUpdateJobPostingApplicationForm';
import contextToProps from '@/hocs/contextToProps';
import { sanitizeTypename } from '@/common/utils/apollo';

import './JobPostingApplicationFormSelector.css';

const FormDisplay = ({
  clientId,
  editMode,
  setApplicationFormState,
  currentOption,
  questionsFormOptions,
  applicationFormFields,
  t,
}) => (
  <Form className='application-form-display'>
    <h5>
      <span>{t('offers.jobPostings.form.application.contactInfo')}</span>
      {editMode && (
        <span>&nbsp;{t('offers.jobPostings.form.application.fixed')}</span>
      )}
    </h5>
    <Form.Input
      className='default-input'
      type='text'
      name='firstname'
      label={t('offers.jobPostings.form.application.fields.firstname')}
      value=''
      inline
      required
      disabled
    />
    <Form.Input
      className='default-input'
      type='text'
      name='lastname'
      label={t('offers.jobPostings.form.application.fields.lastname')}
      value=''
      inline
      required
      disabled
    />
    <Form.Input
      className='default-input'
      type='text'
      name='email'
      label={t('offers.jobPostings.form.application.fields.email')}
      value=''
      inline
      required
      disabled
    />
    <Form.Input
      className='default-input'
      type='text'
      name='phoneNumber'
      label={t('offers.jobPostings.form.application.fields.phoneNumber')}
      value=''
      inline
      disabled
    />
    <Form.Input
      className='default-input file-input'
      type='file'
      name='resumeFile'
      label={t('offers.jobPostings.form.application.fields.resumeFile')}
      inline
      required
      disabled
    />
    <Form.Input
      className='default-input file-input'
      type='file'
      name='coverLetterFile'
      label={t('offers.jobPostings.form.application.fields.coverLetterFile')}
      inline
      disabled
    />

    {(!_.isEmpty(applicationFormFields) || editMode) && (
      <div className='application-form'>
        <div className='application-form-divider-header'>
          <Divider />
          <h5>
            <span>
              {t('offers.jobPostings.form.application.additionalQuestions')}
            </span>
            {editMode && (
              <span>
                &nbsp;{t('offers.jobPostings.form.application.selectATemplate')}
              </span>
            )}
          </h5>
        </div>

        {editMode && (
          <div>
            <ApplicationFormSelector
              clientId={clientId}
              setApplicationFormState={setApplicationFormState}
              currentOption={currentOption}
              questionsFormOptions={questionsFormOptions}
              t={t}
            />
            <br />
          </div>
        )}

        {_.map(applicationFormFields, ({ id, title }) => (
          <div key={id} className='application-form-field-container'>
            <div className='application-form-title-container'>
              <span>{title}</span>
            </div>
            <div className='application-form-input-container'>
              <Form.Input
                className='default-input'
                type='text'
                value=''
                inline
                required
                disabled
              />
            </div>
          </div>
        ))}
      </div>
    )}
  </Form>
);

const JobPostingApplicationFormSelector = ({
  jobPostingId,
  clientId,
  applicationForm,
  updateJobPostingApplicationForm,
  client,
  onShowNotification,
  t,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [applicationFormState, setApplicationFormState] = useState(
    applicationForm,
  );
  const [loading, setLoading] = useState(false);
  const questionsFormOptions = [
    {
      value: null,
      text: t('offers.jobPostings.form.application.noAdditionalQuestions'),
    },
    ..._.map(client.applicationFormTemplates, ({ id, title }) => ({
      value: id,
      text: title,
    })),
  ];

  const handleSave = async () => {
    setLoading(true);
    const input = getJobPostingApplicationFormInput({
      jobPostingId,
      applicationFormState,
    });
    try {
      await updateJobPostingApplicationForm({ input });
      onShowNotification({
        message: t('offers.jobPostings.form.application.updateSuccessful'),
        level: 'success',
      });
    } catch (e) {
      onShowNotification({
        message: t('offers.jobPostings.form.application.updateError'),
        level: 'error',
      });
    }
    setLoading(false);
    setEditMode(false);
  };

  const handleCancel = () => {
    setApplicationFormState(applicationForm);
    setEditMode(false);
  };

  const currentOption = _.findWhere(questionsFormOptions, {
    value: applicationFormState?.questionsForm?.templateId,
  })?.text;

  if (_.isEmpty(client?.applicationFormTemplates)) {
    return (
      <div className='job-posting-form-selector section'>
        <div className='input-container'>
          <div className='input-label'>
            {t('offers.jobPostings.form.application.applicationForm')}
          </div>
          <div className='input-element'>
            {t(
              'offers.jobPostings.form.application.noApplicationFormsDescription',
            )}
            <Link
              target='_blank'
              rel='noopener noreferrer'
              to={`/client/${clientId}/careerPage`}
            >
              {t('offers.jobPostings.form.application.createApplicationForm')}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const templateId = applicationFormState?.questionsForm?.templateId;

  const applicationFormFields = (
    templateId &&
    _.findWhere(client.applicationFormTemplates, { id: templateId })
  )?.form?.fields;

  if (!editMode) {
    return (
      <div className='job-posting-form-selector section'>
        <div className='header'>
          <h3>{t('offers.jobPostings.form.application.applicationForm')}</h3>
          <Button primary onClick={() => setEditMode(true)}>
            {t('common.edit')}
          </Button>
        </div>
        <FormDisplay
          editMode={false}
          applicationFormFields={applicationFormFields}
          t={t}
        />
      </div>
    );
  }

  return (
    <div className='job-posting-form-selector section'>
      <div className='input-container'>
        <div className='header'>
          <h3>{t('offers.jobPostings.form.application.applicationForm')}</h3>
        </div>
        <FormDisplay
          clientId={clientId}
          setApplicationFormState={setApplicationFormState}
          currentOption={currentOption}
          questionsFormOptions={questionsFormOptions}
          applicationFormFields={applicationFormFields}
          t={t}
          editMode
        />
      </div>
      <div className='buttons-container'>
        <Button secondary onClick={handleCancel}>
          {t('common.cancel')}
        </Button>
        <Button
          primary
          loading={loading}
          disabled={loading}
          onClick={handleSave}
        >
          {t('common.save')}
        </Button>
      </div>
    </div>
  );
};

const getJobPostingApplicationFormInput = ({
  jobPostingId,
  applicationFormState,
}) => ({
  id: jobPostingId,
  applicationForm: sanitizeTypename(
    formatApplicationFormInput(applicationFormState),
  ),
});

const formatApplicationFormInput = (arg) => {
  if (_.isArray(arg)) {
    return _.map(arg, formatApplicationFormInput);
  }
  if (_.isObject(arg)) {
    if (arg.__typename === 'ContactAndQuestionsJobPostingApplicationForm') {
      return {
        contactAndQuestionsJobPostingApplicationForm: formatApplicationFormInput(
          _.omit(arg, '__typename'),
        ),
      };
    }
    return _.mapObject(arg, formatApplicationFormInput);
  }
  return arg;
};

export default compose(
  withTranslation('translations'),
  withClient,
  withUpdateJobPostingApplicationForm,
  contextToProps,
)(JobPostingApplicationFormSelector);

export const ApplicationFormSelector = ({
  clientId,
  setApplicationFormState,
  currentOption,
  questionsFormOptions,
  t,
}) => {
  const handleChangeApplicationQuestionForm = ({ value }) => {
    setApplicationFormState((oldApplicationForm) => ({
      ...oldApplicationForm,
      questionsForm: value
        ? {
            ...oldApplicationForm?.questionsForm,
            type: 'template',
            templateId: value,
          }
        : null,
    }));
  };

  return (
    <Dropdown
      className='selection'
      fluid
      closeOnChange
      text={
        currentOption ||
        t('offers.jobPostings.form.application.chooseAdditionalQuestions')
      }
    >
      <Dropdown.Menu>
        {_.map(questionsFormOptions, ({ value, text }) => (
          <Dropdown.Item
            key={value}
            onClick={() => handleChangeApplicationQuestionForm({ value })}
          >
            {text}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item
          as={Link}
          to={`/client/${clientId}/careerPage`}
          target='_blank'
        >
          {t('offers.jobPostings.form.application.manageApplicationForms')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
