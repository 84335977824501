import React, { FC, useState, useCallback, ReactNode } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'underscore';

import GenericSelect from '@/components/Common/GenericSelect';

import styles from './HardFiltersForm.module.less';

type HardFiltersFormProps = {
  current: FormValue;
  handleChange: <T extends keyof FormValue>(args: {
    label: T;
    value: FormValue[T];
  }) => void;
};

const HardFiltersForm: FC<HardFiltersFormProps> = ({
  handleChange,
  current,
}) => {
  const { t } = useTranslation();

  const [filteringParameter, setFilteringParameter] = useState(
    current.filteringParameter,
  );

  const handleFilteringParameter = useCallback(
    (
      field: keyof Required<FormValue>['filteringParameter'],
      value: FilteringOption,
    ) => {
      const newFilteringParameter = {
        ...filteringParameter,
        [field]: value,
      };
      setFilteringParameter(newFilteringParameter);
      handleChange({
        label: 'filteringParameter',
        value: newFilteringParameter,
      });
    },
    [filteringParameter, handleChange],
  );

  return (
    <>
      <div className={styles.paragraph}>
        {t('criteria.hardFilters.paragraph')}
      </div>
      <div className='form-row'>
        <div className='form-box'>
          <div className='form-field-label'>
            {t('criteria.hardFilters.minExperience')}
          </div>
          <div className='form-field-input'>
            <GenericSelect
              options={options}
              defaultValue={optionMap[filteringParameter.minExperience]}
              onChange={(value) => {
                if (!value) {
                  return;
                }
                handleFilteringParameter('minExperience', value.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-box'>
          <div className='form-field-label'>
            {t('criteria.hardFilters.maxExperience')}
          </div>
          <div className='form-field-input'>
            <GenericSelect
              options={options}
              defaultValue={optionMap[filteringParameter.maxExperience]}
              onChange={(value) => {
                if (!value) {
                  return;
                }
                handleFilteringParameter('maxExperience', value.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-box'>
          <div className='form-field-label'>
            {t('criteria.hardFilters.locationAndRemote')}
          </div>
          <div className='form-field-input'>
            <GenericSelect
              options={options}
              defaultValue={optionMap[filteringParameter.locationAndRemote]}
              onChange={(value) => {
                if (!value) {
                  return;
                }
                handleFilteringParameter('locationAndRemote', value.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='form-box'>
          <div className='form-field-label'>
            {t('criteria.hardFilters.maxSalary')}
          </div>
          <div className='form-field-input'>
            <GenericSelect
              options={options}
              defaultValue={optionMap[filteringParameter.maxSalary]}
              onChange={(value) => {
                if (!value) {
                  return;
                }
                handleFilteringParameter('maxSalary', value.value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const filteringOptions = [
  'disabled',
  'only-active-for-main-recommendations',
  'active',
] as const;

type FilteringOption = typeof filteringOptions[number];

const optionMap = _.reduce(
  filteringOptions,
  (acc, value) => ({
    ...acc,
    [value]: {
      value,
      label: (
        <Trans
          className={styles.label}
          i18nKey={`criteria.hardFilters.options.${value}`}
          components={{ pill: <span className={styles.pill} /> }}
        />
      ),
    },
  }),
  {} as Record<FilteringOption, { value: FilteringOption; label: ReactNode }>,
);

const options = _.values(optionMap);

type FormValue = {
  filteringParameter: {
    minExperience: FilteringOption;
    maxExperience: FilteringOption;
    locationAndRemote: FilteringOption;
    maxSalary: FilteringOption;
  };
};

export default HardFiltersForm;
