import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';
import { useAddProfilesToMissionAndSequenceMutation } from '@/graphql/searchPoolProfiles';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import GenericDropdown from '@/components/Common/GenericDropdown';
import AddToSequencePanel from './AddToSequencePanel';

interface AddToProjectActionProps {
  projectId: string;
  profileId: string;
  setLockHover?: (lock: boolean) => void;
}

const AddToProjectAction: FC<AddToProjectActionProps> = ({
  projectId,
  profileId,
  setLockHover,
}) => {
  const { t } = useTranslation();
  const { success, error } = useNotificationSystem();

  const [
    addProfilesToMissionAndSequence,
  ] = useAddProfilesToMissionAndSequenceMutation();

  const handleSubmit = (sequenceId: string | null) => {
    addProfilesToMissionAndSequence({
      variables: {
        searchPoolId: 'reveal',
        input: {
          missionId: projectId,
          profileIds: [profileId],
          ...(sequenceId && { sequenceId }),
        },
      },
    }).then(({ data }) => {
      const result =
        data?.searchPool?.addProfilesToMissionAndSequence?.results?.[0];

      if (!result) {
        return;
      }

      if (result.success) {
        success(
          t('reveal.missions.mission.actions.addedToMissionSuccess', {
            count: 1,
          }),
        );
      } else {
        error(result.error);
      }
    });
  };

  return (
    <GenericDropdown
      position='right'
      Trigger={({ onClick }) => (
        <GenericButton primacy='secondary' onClick={onClick}>
          <i className='ri-thumb-up-line' />
          {t('reveal.recommendedProfiles.confirm.trigger')}
        </GenericButton>
      )}
      onDisplayStatus={setLockHover}
    >
      <AddToSequencePanel projectId={projectId} onSubmit={handleSubmit} />
    </GenericDropdown>
  );
};

export default AddToProjectAction;
