import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import _ from 'underscore';

import GenericSelect from '@/components/Common/GenericSelect';
import locationOptions from '@/common/options/revealLocationOptions.json';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useClientId from '@/hooks/router/useClientId';
import GenericAsyncSelect from '@/components/Common/GenericSelect/GenericAsyncSelect';
import { debouncePromise } from '@/common/utils/async';

import {
  LocationTarget,
  Location,
  getLocationSearchResults,
  getRadiusLocations,
} from './utils';
import LocationPill from './LocationPill';

import styles from './LocationFields.module.less';

type Props = {
  locations?: LocationTarget;
  setLocations: Dispatch<SetStateAction<LocationTarget>>;
};

const debouncedGetLocationSearchResults = debouncePromise(
  getLocationSearchResults,
  500,
);

const LocationFields = ({ locations, setLocations }: Props) => {
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);
  const { searchLocationRadius = false } = permissions ?? {};
  const debouncedSetLocations = useMemo(
    () => _.debounce(setLocations, 600),
    [setLocations],
  );

  const createLocation = useCallback(
    (newLocation: Location) => {
      debouncedSetLocations((current) => {
        const newLocations = [...getRadiusLocations(current), newLocation];
        return {
          target: newLocations.map((location) => ({
            location,
            remoteWish: 'full-time',
          })),
        };
      });
    },
    [debouncedSetLocations],
  );

  const removeLocation = useCallback(
    (index: number) => {
      debouncedSetLocations((current) => {
        const newLocations = getRadiusLocations(current).filter(
          (_location, i) => i !== index,
        );
        return {
          target: newLocations.map((location) => ({
            location,
            remoteWish: 'full-time',
          })),
        };
      });
    },
    [debouncedSetLocations],
  );

  const setDistanceForLocation = useCallback(
    (index: number, radiusInKm: number) => {
      debouncedSetLocations((current) => {
        const newLocations = getRadiusLocations(current).map((location, i) =>
          i !== index
            ? location
            : {
                ...location,
                radiusInKm,
              },
        );
        return {
          target: newLocations.map((location) => ({
            location,
            remoteWish: 'full-time',
          })),
        };
      });
    },
    [debouncedSetLocations],
  );

  const filteredLocations = getRadiusLocations(locations);

  return (
    <div className='location-fields section'>
      {searchLocationRadius ? (
        <GenericAsyncSelect
          loadOptions={debouncedGetLocationSearchResults}
          value={null}
          onChange={(newValue) => {
            if (!newValue) {
              return;
            }
            createLocation({
              type: 'radius-from-point',
              label: newValue.label,
              pointLatitude: newValue.latitude,
              pointLongitude: newValue.longitude,
              radiusInKm: 0,
            });
          }}
        />
      ) : (
        <GenericSelect
          isMulti
          options={formattedOptions}
          value={_.compact(
            filteredLocations.map((location) =>
              'id' in location
                ? _.findWhere(formattedOptions, { value: location.id })
                : undefined,
            ),
          )}
          onChange={(newValue) => {
            if (!newValue) {
              return;
            }
            debouncedSetLocations({
              target: newValue.map(({ value }) => ({
                location: {
                  type: 'exact-id' as const,
                  id: value,
                },
                remoteWish: 'full-time',
              })),
            });
          }}
        />
      )}
      {searchLocationRadius && (
        <div className={styles.pills}>
          {filteredLocations.map((location, index) => {
            const id =
              'id' in location
                ? location.id
                : `${location.pointLatitude}_${location.pointLongitude}`;
            const { radiusInKm } = location;
            return (
              <LocationPill
                key={id}
                onClose={() => removeLocation(index)}
                initialDistance={radiusInKm}
                onDistance={(value) => setDistanceForLocation(index, value)}
                name={
                  _.findWhere(formattedOptions, { value: id })?.label ??
                  location?.label ??
                  '???'
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const cleanOptions = _.map(locationOptions, (location) =>
  location.value === 'san-francisco-region'
    ? {
        ...location,
        value: 'bay-area-region',
      }
    : location,
);

const finalOptions = _.sortBy(
  _.uniq(cleanOptions, 'text'),
  (option) =>
    ({
      'United States': 'A',
      Canada: 'B',
      'United Kingdom': 'C',
      France: 'D',
      'San Francisco': 'E',
      'New York': 'F',
      London: 'G',
      Paris: 'H',
    }[option.text] || 'ZZ'),
);

const formattedOptions = _.map(finalOptions, ({ value, text }) => ({
  value,
  label: text,
}));

export default LocationFields;
