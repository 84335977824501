import _ from 'underscore';
// import classnames from 'classnames';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';

import { getTranslatedText } from '@/common';
import { TranslatableText } from '@/types/text';
import useClientId from '@/hooks/router/useClientId';
import SettingsLayout from '@/containers/SettingsLayout';
import {
  useClientConfigurationParams,
  useUserConfigurationParams,
  useUpdateClientConfigurationParam,
  useUpdateUserConfigurationParam,
} from '@/graphql/hooks/useMergedConfigurationParams';
import GenericSelect from '@/components/Common/GenericSelect';
import InfoTooltip from '@/components/Common/InfoTooltip';

import styles from '../CustomTasks/CustomTasksTable/CustomTasksTable.module.less';

interface EditableParameter {
  key: string;
  title: TranslatableText;
  tooltip?: TranslatableText;
  options: {
    value: string;
    title: TranslatableText;
  }[];
}

interface EditableParameterGroup {
  id: string;
  title: TranslatableText;
  editableParameters: EditableParameter[];
}

const shownOrHideOptions = [
  {
    value: 'true',
    title: {
      default: 'Hidden',
      fr: 'Caché',
    },
  },
  {
    value: 'false',
    title: {
      default: 'Displayed',
      fr: 'Affiché',
    },
  },
];

const yesOrNoOptions = [
  {
    value: 'true',
    title: {
      default: 'Yes',
      fr: 'Oui',
    },
  },
  {
    value: 'false',
    title: {
      default: 'No',
      fr: 'Non',
    },
  },
];

const EDITABLE_CONFIGURATION_PARAMETERS_GROUPS: EditableParameterGroup[] = [
  {
    id: 'global',
    title: {
      default: 'Global',
      fr: 'Global',
      en: 'Global',
    },
    editableParameters: [
      {
        key: 'shouldHideAllNewProjectButtons',
        title: {
          default: 'Hide all "New project" buttons',
          fr: 'Cacher tous les bouttons "Nouveau projet"',
          en: 'Hide all "New project" buttons',
        },
        tooltip: {
          default: 'shouldHideAllNewProjectButtons',
          fr: 'shouldHideAllNewProjectButtons',
          en: 'shouldHideAllNewProjectButtons',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProfilePreview',
        title: {
          default: 'Hide all profile previews',
          fr: 'Cacher tous le prévisualisation de profils',
          en: 'Hide all profile previews',
        },
        tooltip: {
          default: 'shouldHideProfilePreview',
          fr: 'shouldHideProfilePreview',
          en: 'shouldHideProfilePreview',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideArchivedMissionsInProfilesTables',
        title: {
          default: 'Hide archived missions in profile tables',
          fr: 'Cacher les projets archivés dans les tableaux de profils',
          en: 'Hide archived missions in profile tables',
        },
        tooltip: {
          default: 'shouldHideArchivedMissionsInProfilesTables',
          fr: 'shouldHideArchivedMissionsInProfilesTables',
          en: 'shouldHideArchivedMissionsInProfilesTables',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'displayedActionsLimit',
        title: {
          default: 'Maximum number of actions displayed',
          fr: "Nombre maximum d'actions à afficher",
          en: 'Maximum number of actions displayed',
        },
        tooltip: {
          default: 'displayedActionsLimit',
          fr: 'displayedActionsLimit',
          en: 'displayedActionsLimit',
        },
        options: [
          {
            value: '1',
            title: {
              default: '1',
            },
          },
          {
            value: '2',
            title: {
              default: '2',
            },
          },
          {
            value: '3',
            title: {
              default: '3',
            },
          },
          {
            value: '4',
            title: {
              default: '4',
            },
          },
          {
            value: '5',
            title: {
              default: '5',
            },
          },
        ],
      },
      {
        key: 'shouldHideCSVExport',
        title: {
          default: 'Hide the CSV export button',
          fr: "Cacher le bouton d'export CSV",
          en: 'Hide the CSV export button',
        },
        tooltip: {
          default: 'shouldHideCSVExport',
          fr: 'shouldHideCSVExport',
          en: 'shouldHideCSVExport',
        },
        options: shownOrHideOptions,
      },
    ],
  },
  {
    id: 'projects-view',
    title: {
      default: '"Projects" view',
      fr: 'Vue "Projets"',
      en: '"Projects" view',
    },
    editableParameters: [
      {
        key: 'shouldHideProjectsViewTopHeader',
        title: {
          default: 'Hide top header',
          fr: "Cacher l'entête de la page",
          en: 'Hide top header',
        },
        tooltip: {
          default: 'shouldHideProjectsViewTopHeader',
          fr: 'shouldHideProjectsViewTopHeader',
          en: 'shouldHideProjectsViewTopHeader',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProjectsViewNewProjectButton',
        title: {
          default: 'Hide "New project" button',
          fr: 'Cacher le bouton "Nouveau projet"',
          en: 'Hide "New project" button',
        },
        tooltip: {
          default: 'shouldHideProjectsViewNewProjectButton',
          fr: 'shouldHideProjectsViewNewProjectButton',
          en: 'shouldHideProjectsViewNewProjectButton',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProjectsViewStatsHeader',
        title: {
          default: "Hide project's stats header",
          fr: 'Cacher les statiques du projet',
          en: "Hide project's stats header",
        },
        tooltip: {
          default: 'shouldHideProjectsViewStatsHeader',
          fr: 'shouldHideProjectsViewStatsHeader',
          en: 'shouldHideProjectsViewStatsHeader',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProjectEditionButtons',
        title: {
          default: 'Hide project edition buttons',
          fr: 'Cacher les boutons "..." d\'édition de projets',
          en: 'Hide project edition  "..." buttons',
        },
        tooltip: {
          default: 'shouldHideProjectEditionButtons',
          fr: 'shouldHideProjectEditionButtons',
          en: 'shouldHideProjectEditionButtons',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shoulPreventFromEnteringProjectFromProjectsView',
        title: {
          default: 'Prevent from entering into a project',
          fr: 'Empécher de rentrer dans un projet',
          en: 'Prevent from entering into a project',
        },
        tooltip: {
          default: 'shoulPreventFromEnteringProjectFromProjectsView',
          fr: 'shoulPreventFromEnteringProjectFromProjectsView',
          en: 'shoulPreventFromEnteringProjectFromProjectsView',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldHideProjectsSequenceIcon',
        title: {
          default: 'Hide sequence icon of each project',
          fr: "Masquer l'icône de séquence de chaque projet",
          en: 'Hide sequence icon of each project row',
        },
        tooltip: {
          default: 'shouldHideProjectsSequenceIcon',
          fr: 'shouldHideProjectsSequenceIcon',
          en: 'shouldHideProjectsSequenceIcon',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProjectsATSIcon',
        title: {
          default: 'Hide ATS icon of each project',
          fr: "Masquer l'icône d'ATS de chaque projet",
          en: 'Hide ATS icon of each project row',
        },
        tooltip: {
          default: 'shouldHideProjectsATSIcon',
          fr: 'shouldHideProjectsATSIcon',
          en: 'shouldHideProjectsATSIcon',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideArchiveProjectOption',
        title: {
          default: 'Hide project archive option',
          fr: "Masquer l'option d'archivage d'un projet",
          en: 'Hide project archive option',
        },
        tooltip: {
          default: 'shouldHideArchiveProjectOption',
          fr: 'shouldHideArchiveProjectOption',
          en: 'shouldHideArchiveProjectOption',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldDisplayOnlyCurrentJobsUsers',
        title: {
          default: 'Use only available jobs owners in owner filter',
          fr: "N'afficher que les responsables disponibles dans le filtre de responsable",
          en: 'Use only available jobs owners in owner filter',
        },
        tooltip: {
          default: 'shouldDisplayOnlyCurrentJobsUsers',
          fr: 'shouldDisplayOnlyCurrentJobsUsers',
          en: 'shouldDisplayOnlyCurrentJobsUsers',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldHideContactsNumberHoverEffect',
        title: {
          default: 'Display mouse hover effect on contacts counts',
          fr: 'Afficher un effet de survol de souris sur les compteurs de contacts',
          en: 'Display mouse hover effect on contacts counts',
        },
        tooltip: {
          default: 'shouldHideContactsNumberHoverEffect',
          fr: 'shouldHideContactsNumberHoverEffect',
          en: 'shouldHideContactsNumberHoverEffect',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideEditOnArchivedProjects',
        title: {
          default: 'Hide edit option for archived projects',
          fr: "Cacher l'option de modification des projets archivés",
          en: 'Hide edit option for archived projects',
        },
        tooltip: {
          default: 'shouldHideEditOnArchivedProjects',
          fr: 'shouldHideEditOnArchivedProjects',
          en: 'shouldHideEditOnArchivedProjects',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldShowProfilVivierFilterInProjectsView',
        title: {
          default: 'Show profil-vivier filter',
          fr: 'Afficher le filtre "profil vivier"',
          en: 'Show profil-vivier filter',
        },
        tooltip: {
          default: 'shouldShowProfilVivierFilterInProjectsView',
          fr: 'shouldShowProfilVivierFilterInProjectsView',
          en: 'shouldShowProfilVivierFilterInProjectsView',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldBlockIfNoFilterInProjectsView',
        title: {
          default: 'Block if no selected filter',
          fr: 'Blocage si pas de filtre sélectionné',
          en: 'Block if no selected filter',
        },
        tooltip: {
          default: 'shouldBlockIfNoFilterInProjectsView',
          fr: 'shouldBlockIfNoFilterInProjectsView',
          en: 'shouldBlockIfNoFilterInProjectsView',
        },
        options: yesOrNoOptions,
      },
    ],
  },
  {
    id: 'tasks',
    title: {
      default: 'Tasks',
      fr: 'Tâches',
      en: 'Tasks',
    },
    editableParameters: [
      {
        key: 'shouldHideTaskBulkActions',
        title: {
          default: 'Hide contacts bulk edition',
          fr: 'Masquer les actions groupées',
          en: 'Hide contacts bulk edition',
        },
        tooltip: {
          default: 'shouldHideTaskBulkActions',
          fr: 'shouldHideTaskBulkActions',
          en: 'shouldHideTaskBulkActions',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideSequenceFilter',
        title: {
          default: 'Hide sequences filter',
          fr: 'Masquer le filtre de séquences',
          en: 'Hide sequences filter',
        },
        tooltip: {
          default: 'shouldHideSequenceFilter',
          fr: 'shouldHideSequenceFilter',
          en: 'shouldHideSequenceFilter',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideSequenceColumn',
        title: {
          default: 'Hide sequence column',
          fr: 'Masquer la colonne de séquence',
          en: 'Hide sequence column',
        },
        tooltip: {
          default: 'shouldHideSequenceColumn',
          fr: 'shouldHideSequenceColumn',
          en: 'shouldHideSequenceColumn',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldDisplayTaskSubtypeInTasksList',
        title: {
          default: 'Display category column',
          fr: 'Afficher la colonne de catégorie',
          en: 'Display category column',
        },
        tooltip: {
          default: 'shouldDisplayTaskSubtypeInTasksList',
          fr: 'shouldDisplayTaskSubtypeInTasksList',
          en: 'shouldDisplayTaskSubtypeInTasksList',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldDisplayTaskLateTagFromReferenceDueDate',
        title: {
          default: 'Display "late" tag',
          fr: 'Afficher le tag "En retard"',
          en: 'Display "late" tag',
        },
        tooltip: {
          default: 'shouldDisplayTaskLateTagFromReferenceDueDate',
          fr: 'shouldDisplayTaskLateTagFromReferenceDueDate',
          en: 'shouldDisplayTaskLateTagFromReferenceDueDate',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldDisplayTaskSubtypeFilterInTasksList',
        title: {
          default: 'Display category filter',
          fr: 'Afficher le filtre de catégorie',
          en: 'Display category filter',
        },
        tooltip: {
          default: 'shouldDisplayTaskSubtypeFilterInTasksList',
          fr: 'shouldDisplayTaskSubtypeFilterInTasksList',
          en: 'shouldDisplayTaskSubtypeFilterInTasksList',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldHideTasksListProjectFilter',
        title: {
          default: 'Hide project filter',
          fr: 'Masquer le filtre des projets',
          en: 'Hide project filter',
        },
        tooltip: {
          default: 'shouldHideTasksListProjectFilter',
          fr: 'shouldHideTasksListProjectFilter',
          en: 'shouldHideTasksListProjectFilter',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideTasksListTaskOwnerFilter',
        title: {
          default: 'Hide task owner column',
          fr: 'Masquer le filtre des responsables de tache',
          en: 'Hide task owner column',
        },
        tooltip: {
          default: 'shouldHideTasksListTaskOwnerFilter',
          fr: 'shouldHideTasksListTaskOwnerFilter',
          en: 'shouldHideTasksListTaskOwnerFilter',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldDisplayTasksListProjectOwner',
        title: {
          default: 'Display new project owner filter',
          fr: 'Afficher le nouveau filtre de responsable project',
          en: 'Display new project owner filter',
        },
        tooltip: {
          default: 'shouldDisplayTasksListProjectOwner',
          fr: 'shouldDisplayTasksListProjectOwner',
          en: 'shouldDisplayTasksListProjectOwner',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldDisplayTaskViewColumnSorting',
        title: {
          default: 'Display sorting options on columns',
          fr: 'Afficher les options de tri des colonnes',
          en: 'Display sorting options on columns',
        },
        tooltip: {
          default: 'shouldDisplayTaskViewColumnSorting',
          fr: 'shouldDisplayTaskViewColumnSorting',
          en: 'shouldDisplayTaskViewColumnSorting',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'tasksViewTableGrouping',
        title: {
          default: 'Group tasks by',
          fr: 'Grouper les tâches par',
          en: 'Group tasks by',
        },
        tooltip: {
          default: 'tasksViewTableGrouping',
          fr: 'tasksViewTableGrouping',
          en: 'tasksViewTableGrouping',
        },
        options: [
          {
            value: 'type',
            title: {
              default: 'Type',
              fr: 'Type',
            },
          },
          {
            value: 'mission',
            title: {
              default: 'Project',
              fr: 'Projet',
            },
          },
          {
            value: 'agency',
            title: {
              default: 'Agency',
              fr: 'Agence',
            },
          },
        ],
      },
      {
        key: 'shouldDisplayNextInteractionWithoutHours',
        title: {
          default: 'Display next interaction tag without hours',
          fr: 'Afficher le tag de prochaine interaction sans les heures',
          en: 'Display next interaction tag without hours',
        },
        tooltip: {
          default: 'shouldDisplayNextInteractionWithoutHours',
          fr: 'shouldDisplayNextInteractionWithoutHours',
          en: 'shouldDisplayNextInteractionWithoutHours',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'tasksViewMandatorySubsectionFilter',
        title: {
          default: 'Mandatory section filter (deprecated)',
          fr: 'Filtre par section obligatoire  - Déprécié',
          en: 'Mandatory section filter (deprecated)',
        },
        tooltip: {
          default: 'tasksViewMandatorySubsectionFilter',
          fr: 'tasksViewMandatorySubsectionFilter',
          en: 'tasksViewMandatorySubsectionFilter',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldBlockIfNoMainFilterInTasksView',
        title: {
          default: 'Block if no selected filter',
          fr: 'Blocage si pas de filtre sélectionné',
          en: 'Block if no selected filter',
        },
        tooltip: {
          default: 'shouldBlockIfNoMainFilterInTasksView',
          fr: 'shouldBlockIfNoMainFilterInTasksView',
          en: 'shouldBlockIfNoMainFilterInTasksView',
        },
        options: yesOrNoOptions,
      },
    ],
  },
  {
    id: 'project',
    title: {
      default: 'Main view of a Project',
      fr: "Affichage de la vue principale d'un projet",
      en: 'Main view of a Project',
    },
    editableParameters: [
      {
        key: 'shouldHideProjectSubheader',
        title: {
          default: 'Hide project subheader',
          fr: "Cacher l'entête de navigation du projet",
          en: 'Hide project subheader',
        },
        tooltip: {
          default: 'shouldHideProjectSubheader',
          fr: 'shouldHideProjectSubheader',
          en: 'shouldHideProjectSubheader',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProjectSearch',
        title: {
          default: 'Hide project search tab',
          fr: "Cacher l'onglet de recherche de contacs",
          en: 'Hide project search tab',
        },
        tooltip: {
          default: 'shouldHideProjectSearch',
          fr: 'shouldHideProjectSearch',
          en: 'shouldHideProjectSearch',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProjectTasks',
        title: {
          default: 'Hide project tasks tab',
          fr: "Cacher l'onglet Tâches",
          en: 'Hide project tasks',
        },
        tooltip: {
          default: 'shouldHideProjectTasks',
          fr: 'shouldHideProjectTasks',
          en: 'shouldHideProjectTasks',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProjectParameters',
        title: {
          default: 'Hide project parameters tab',
          fr: "Cacher l'onglet Paramètre",
          en: 'Hide project parameters tab',
        },
        tooltip: {
          default: 'shouldHideProjectParameters',
          fr: 'shouldHideProjectParameters',
          en: 'shouldHideProjectParameters',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'projectProfilesDefaultView',
        title: {
          default: "Default project's profiles view",
          fr: 'Vue par défaut des profils du projets',
          en: "Default project's profiles view",
        },
        tooltip: {
          default: 'projectProfilesDefaultView',
          fr: 'projectProfilesDefaultView',
          en: 'projectProfilesDefaultView',
        },
        options: [
          {
            value: 'all',
            title: {
              default: 'All contacts',
              fr: 'Tous les contacts',
            },
          },
          {
            value: 'planning',
            title: {
              default: 'Planning',
              fr: 'Vue planning',
            },
          },
        ],
      },
      {
        key: 'shouldHideProjectPipelineViewSwitch',
        title: {
          default: 'Hide project pipeline switch view',
          fr: 'Cacher le choix type de vue pipeline',
          en: 'Hide project pipeline switch view',
        },
        tooltip: {
          default: 'shouldHideProjectPipelineViewSwitch',
          fr: 'shouldHideProjectPipelineViewSwitch',
          en: 'shouldHideProjectPipelineViewSwitch',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProjectProfilesBulkActions',
        title: {
          default: 'Hide bulk actions & checkbox',
          fr: 'Cacher les actions groupées',
          en: 'Hide bulk actions & checkbox',
        },
        tooltip: {
          default: 'shouldHideProjectProfilesBulkActions',
          fr: 'shouldHideProjectProfilesBulkActions',
          en: 'shouldHideProjectProfilesBulkActions',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProjectDefaultPipelineSegmentation',
        title: {
          default: "Hide 'pipeline' segmentation",
          fr: "Cacher la segmentation 'pipeline'",
          en: "Hide 'pipeline' segmentation",
        },
        tooltip: {
          default: 'shouldHideProjectDefaultPipelineSegmentation',
          fr: 'shouldHideProjectDefaultPipelineSegmentation',
          en: 'shouldHideProjectDefaultPipelineSegmentation',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldDisableCustomFieldsEdition',
        title: {
          default: 'Disable project custom fields edition',
          fr: "Désactiver l'édition des champs personnalisés",
          en: 'Disable project custom fields edition',
        },
        tooltip: {
          default: 'shouldDisableCustomFieldsEdition',
          fr: 'shouldDisableCustomFieldsEdition',
          en: 'shouldDisableCustomFieldsEdition',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldDisableOrganisationEdition',
        title: {
          default: 'Disable project scope edition',
          fr: "Désactiver l'édition du cadre (organisation / département / agence ...)",
          en: 'Disable project scope edition',
        },
        tooltip: {
          default: 'shouldDisableOrganisationEdition',
          fr: 'shouldDisableOrganisationEdition',
          en: 'shouldDisableOrganisationEdition',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldDisableArchivedEdition',
        title: {
          default: 'Disable edition of archived projects',
          fr: "Désactiver l'édition de projets archivés",
          en: 'Disable edition of archived projects',
        },
        tooltip: {
          default: 'shouldDisableArchivedEdition',
          fr: 'shouldDisableArchivedEdition',
          en: 'shouldDisableArchivedEdition',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldHideProjectProfileStageUpdatedActivity',
        title: {
          default: "Hide 'stage updated' activity items",
          fr: "Cacher les activités de changement d'étapes (Pending, Contacted...)",
          en: "Hide 'stage updated' activity items",
        },
        tooltip: {
          default: 'shouldHideProjectProfileStageUpdatedActivity',
          fr: 'shouldHideProjectProfileStageUpdatedActivity',
          en: 'shouldHideProjectProfileStageUpdatedActivity',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'weeklyKanbanStartDay',
        title: {
          default: 'Weekly kanban starting day',
          fr: 'Jour de départ du kanban hebdomadaire',
          en: 'Weekly kanban starting day',
        },
        tooltip: {
          default: 'weeklyKanbanStartDay',
          fr: 'weeklyKanbanStartDay',
          en: 'weeklyKanbanStartDay',
        },
        options: [
          {
            value: '1',
            title: {
              default: 'Monday',
              fr: 'Lundi',
            },
          },
          {
            value: '2',
            title: {
              default: 'Tuesday',
              fr: 'Mardi',
            },
          },
          {
            value: '3',
            title: {
              default: 'Wednesday',
              fr: 'Mercredi',
            },
          },
          {
            value: '4',
            title: {
              default: 'Thursday',
              fr: 'Jeudi',
            },
          },
          {
            value: '5',
            title: {
              default: 'Friday',
              fr: 'Vendredi',
            },
          },
          {
            value: '6',
            title: {
              default: 'Saturday',
              fr: 'Samedi',
            },
          },
          {
            value: '0',
            title: {
              default: 'Sunday',
              fr: 'Dimanche',
            },
          },
        ],
      },
      {
        key: 'shouldDisableProfileOpeningFromArchivedProject',
        title: {
          default: 'Do not allow contacts display in archived projects',
          fr: "Ne pas autoriser l'affichage des contacts dans les projets archivés",
          en: 'Do not allow contacts display in archived projects',
        },
        tooltip: {
          default: 'shouldDisableProfileOpeningFromArchivedProject',
          fr: 'shouldDisableProfileOpeningFromArchivedProject',
          en: 'shouldDisableProfileOpeningFromArchivedProject',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldDisplayProfileSortingInProject',
        title: {
          default: 'Display profiles sorting options',
          fr: 'Afficher les options de tri des profils',
          en: 'Display profiles sorting options',
        },
        tooltip: {
          default: 'shouldDisplayProfileSortingInProject',
          fr: 'shouldDisplayProfileSortingInProject',
          en: 'shouldDisplayProfileSortingInProject',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'sortProjectsAlphabeticallyInBreadcrumbs',
        title: {
          default: 'Sort projects alphabetically in breadcrumbs',
          fr: 'Trier les projets par ordre alphabétique dans la navigation',
          en: 'Sort projects alphabetically in breadcrumbs',
        },
        tooltip: {
          default: 'sortProjectsAlphabeticallyInBreadcrumbs',
          fr: 'sortProjectsAlphabeticallyInBreadcrumbs',
          en: 'sortProjectsAlphabeticallyInBreadcrumbs',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'projectProfilesPreferedDisplayType',
        title: {
          default: 'Display type of profiles in project',
          fr: "Mode d'affichage des profils dans le projet",
          en: 'Display type of profiles in project',
        },
        tooltip: {
          default: 'projectProfilesPreferedDisplayType',
          fr: 'projectProfilesPreferedDisplayType',
          en: 'projectProfilesPreferedDisplayType',
        },
        options: [
          {
            value: 'list',
            title: {
              default: 'List',
              fr: 'Liste',
            },
          },
          {
            value: 'kanban',
            title: {
              default: 'Kanban',
              fr: 'Kanban',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'profile-header',
    title: {
      default: 'Main view of a Contact - Header Part',
      fr: "Affichage de la vue principale d'un contact - Partie entête (seulement via plugin)",
      en: 'Main view of a Contact - Header Part',
    },
    editableParameters: [
      {
        key: 'shouldHideProfileHeader',
        title: {
          default: 'Hide the header',
          fr: "Cacher l'ensemble de l'en-tête",
          en: 'Hide the header',
        },
        tooltip: {
          default: 'shouldHideProfileHeader',
          fr: 'shouldHideProfileHeader',
          en: 'shouldHideProfileHeader',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProjectAndSequenceOptions',
        title: {
          default: 'Hide current project & sequence',
          fr: 'Cacher le projet et la séquence en cours',
          en: 'Hide current project & sequence',
        },
        tooltip: {
          default: 'shouldHideProfileHeader',
          fr: 'shouldHideProfileHeader',
          en: 'shouldHideProfileHeader',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProfileHeaderEditionButtons',
        title: {
          default: 'Hide edition buttons (edit/delete)',
          fr: "Cacher les boutons d'édition/suppression",
          en: 'Hide edition buttons (edit/delete)',
        },
        tooltip: {
          default: 'shouldHideProfileHeaderEditionButtons',
          fr: 'shouldHideProfileHeaderEditionButtons',
          en: 'shouldHideProfileHeaderEditionButtons',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideResumeDownloadButton',
        title: {
          default: 'Hide pdf download button',
          fr: 'Cacher le bouton de téléchargement de PDF',
          en: 'Hide pdf download button',
        },
        tooltip: {
          default: 'shouldHideResumeDownloadButton',
          fr: 'shouldHideResumeDownloadButton',
          en: 'shouldHideResumeDownloadButton',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldReloadProfileOnEveryVisit',
        title: {
          default: 'Refresh profile on every visit',
          fr: 'Raffraichir le profil à chaque visite',
          en: 'Refresh profile on every visit',
        },
        tooltip: {
          default: 'shouldReloadProfileOnEveryVisit',
          fr: 'shouldReloadProfileOnEveryVisit',
          en: 'shouldReloadProfileOnEveryVisit',
        },
        options: yesOrNoOptions,
      },
    ],
  },
  {
    id: 'profile-panes',
    title: {
      default: 'Main view of a Contact - Panes',
      fr: "Affichage de la vue principale d'un contact - Pan gauche et droit",
      en: 'Main view of a Contact - Panes',
    },
    editableParameters: [
      {
        key: 'shouldHideProfileLeftPane',
        title: {
          default: 'Hide left pane',
          fr: "Cacher l'ensemble du pan gauche",
          en: 'Hide left pane',
        },
        tooltip: {
          default: 'shouldHideProfileLeftPane',
          fr: 'shouldHideProfileLeftPane',
          en: 'shouldHideProfileLeftPane',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProfileRightPane',
        title: {
          default: 'Hide right pane',
          fr: "Cacher l'ensemble du pan droit",
          en: 'Hide right pane',
        },
        tooltip: {
          default: 'shouldHideProfileRightPane',
          fr: 'shouldHideProfileRightPane',
          en: 'shouldHideProfileRightPane',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldDisplayProfileTimelineOnLeftPane',
        title: {
          default: 'Show Timeline on left pane',
          fr: 'Afficher la timeline dans le pan gauche',
          en: 'Show Timeline on left pane',
        },
        tooltip: {
          default: 'shouldDisplayProfileTimelineOnLeftPane',
          fr: 'shouldDisplayProfileTimelineOnLeftPane',
          en: 'shouldDisplayProfileTimelineOnLeftPane',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldHideProfileTimeline',
        title: {
          default: 'Hide timeline',
          fr: 'Cacher la timeline',
          en: 'Hide timeline',
        },
        tooltip: {
          default: 'shouldHideProfileTimeline',
          fr: 'shouldHideProfileTimeline',
          en: 'shouldHideProfileTimeline',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'threePanels',
        title: {
          default: 'Three-panel view in Adventure profile page',
          fr: 'Vue en trois panneaux dans la page profil Adventure',
          en: 'Three-panel view in Adventure profile page',
        },
        tooltip: {
          default: 'threePanels',
          fr: 'threePanels',
          en: 'threePanels',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'defaultProfileActivePane',
        title: {
          default: 'Default profile active pane',
          fr: 'Onglet actif par défaut du profil',
          en: 'Default profile active pane',
        },
        tooltip: {
          default: 'defaultProfileActivePane',
          fr: 'defaultProfileActivePane',
          en: 'defaultProfileActivePane',
        },
        options: [
          {
            value: 'profile',
            title: {
              default: 'Profile',
              fr: 'Profil',
            },
          },
          {
            value: 'ATS',
            title: {
              default: 'ATS',
              fr: 'ATS',
            },
          },
          {
            value: 'details',
            title: {
              default: 'Informations',
              fr: 'Informations',
            },
          },
          {
            value: 'missions',
            title: {
              default: 'Projects',
              fr: 'Projets',
            },
          },
          {
            value: 'timeline',
            title: {
              default: 'Timeline',
              fr: 'Timeline',
            },
          },
        ],
      },
      {
        key: 'shouldHideProfileSimilarProfilesPane',
        title: {
          default: 'Hide similar profiles pane',
          fr: "Masquer l'onglet Contacts Similaires",
          en: 'Hide Similar Contacts',
        },
        tooltip: {
          default: 'shouldHideProfileSimilarProfilesPane',
          fr: 'shouldHideProfileSimilarProfilesPane',
          en: 'shouldHideProfileSimilarProfilesPane',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProfileProjectsPane',
        title: {
          default: 'Hide profile projects pane',
          fr: "Masquer l'onglet Projects du profil",
          en: 'Hide profile projects pane',
        },
        tooltip: {
          default: 'shouldHideProfileProjectsPane',
          fr: 'shouldHideProfileProjectsPane',
          en: 'shouldHideProfileProjectsPane',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProfileATSPane',
        title: {
          default: 'Hide profile ATS pane',
          fr: "Masquer l'onglet ATS du profil",
          en: 'Hide profile ATS pane',
        },
        tooltip: {
          default: 'shouldHideProfileATSPane',
          fr: 'shouldHideProfileATSPane',
          en: 'shouldHideProfileATSPane',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProfileInformationPane',
        title: {
          default: 'Hide profile Information pane',
          fr: "Masquer l'onglet Information du profil",
          en: 'Hide profile Information pane',
        },
        tooltip: {
          default: 'shouldHideProfileInformationPane',
          fr: 'shouldHideProfileInformationPane',
          en: 'shouldHideProfileInformationPane',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHidePendingActionEditionInMenu',
        title: {
          default: 'Hide edition buttons on current task',
          fr: "Masquer les boutons d'éditions de la tâche courante",
          en: 'Hide edition buttons on current task',
        },
        tooltip: {
          default: 'shouldHidePendingActionEditionInMenu',
          fr: 'shouldHidePendingActionEditionInMenu',
          en: 'shouldHidePendingActionEditionInMenu',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideCompletelyPendingActionEditionInMenu',
        title: {
          default: 'Hide completely edition buttons on current task',
          fr: "Masquer complètement les boutons d'éditions de la tâche courante",
          en: 'Hide completely edition buttons on current task',
        },
        tooltip: {
          default: 'shouldHideCompletelyPendingActionEditionInMenu',
          fr: 'shouldHideCompletelyPendingActionEditionInMenu',
          en: 'shouldHideCompletelyPendingActionEditionInMenu',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideProfileTab',
        title: {
          default: "Hide 'Profile' tab",
          fr: "Masquer l'onglet 'Profil'",
          en: "Hide 'Profile' tab",
        },
        tooltip: {
          default: 'shouldHideProfileTab',
          fr: 'shouldHideProfileTab',
          en: 'shouldHideProfileTab',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideNewActivity',
        title: {
          default: 'Hide new activity button',
          fr: "Masquer le bouton de création d'une nouvelle activité",
          en: 'Hide new activity button',
        },
        tooltip: {
          default: 'shouldHideNewActivity',
          fr: 'shouldHideNewActivity',
          en: 'shouldHideNewActivity',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideNewTask',
        title: {
          default: 'Hide new task button',
          fr: "Masquer le bouton de création d'une nouvelle tâche",
          en: 'Hide new task button',
        },
        tooltip: {
          default: 'shouldHideNewTask',
          fr: 'shouldHideNewTask',
          en: 'shouldHideNewTask',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideNewEmail',
        title: {
          default: 'Hide new email button',
          fr: "Masquer le bouton de création d'un nouvel e-mail",
          en: 'Hide new email button',
        },
        tooltip: {
          default: 'shouldHideNewEmail',
          fr: 'shouldHideNewEmail',
          en: 'shouldHideNewEmail',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideEditTask',
        title: {
          default: "Hide task's edit button",
          fr: "Masquer le bouton de modification d'une tâche",
          en: "Hide task's edit button",
        },
        tooltip: {
          default: 'shouldHideEditTask',
          fr: 'shouldHideEditTask',
          en: 'shouldHideEditTask',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldDisplaySimplifiedAgencyActivityLog',
        title: {
          default: "Utiliser l'enregistrement d'action simplifié",
          fr: "Utiliser l'enregistrement d'action simplifié",
          en: 'Use simplified activity log menu',
        },
        tooltip: {
          default: 'shouldDisplaySimplifiedAgencyActivityLog',
          fr: 'shouldDisplaySimplifiedAgencyActivityLog',
          en: 'shouldDisplaySimplifiedAgencyActivityLog',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldDisplayUnipileConnectedAlert',
        title: {
          default:
            'Afficher une alerte si le contact et le sender sont connectés sur LinkedIn',
          fr: 'Afficher une alerte si le contact et le sender sont connectés sur LinkedIn',
          en: 'Display an alert if the contact and the sender are connected on LinkedIn',
        },
        tooltip: {
          default: 'shouldDisplayUnipileConnectedAlert',
          fr: 'shouldDisplayUnipileConnectedAlert',
          en: 'shouldDisplayUnipileConnectedAlert',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'shouldHideDeleteTask',
        title: {
          default: "Hide task's delete button",
          fr: "Masquer le bouton de suppression d'une tâche",
          en: 'Hide task delete button',
        },
        tooltip: {
          default: 'shouldHideDeleteTask',
          fr: 'shouldHideDeleteTask',
          en: 'shouldHideDeleteTask',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideFutureTaskHour',
        title: {
          default: 'Hide hours in future tasks due date',
          fr: "Masquer l'heure dans la date des actions futures",
          en: 'Hide hours in future tasks due date',
        },
        tooltip: {
          default: 'shouldHideFutureTaskHour',
          fr: 'shouldHideFutureTaskHour',
          en: 'shouldHideFutureTaskHour',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideSnoozeTimePicker',
        title: {
          default: 'Hide time picker on snooze-task calendar',
          fr: "Masquer le choix d'heure dans le calendrier de report de tâche",
          en: 'Hide time picker on snooze-task calendar',
        },
        tooltip: {
          default: 'shouldHideSnoozeTimePicker',
          fr: 'shouldHideSnoozeTimePicker',
          en: 'shouldHideSnoozeTimePicker',
        },
        options: shownOrHideOptions,
      },
      {
        key: 'shouldHideRelevantProjects',
        title: {
          default: 'Hide recommended projects',
          fr: 'Masquer les projets pertinents',
          en: 'Hide recommended projects',
        },
        tooltip: {
          default: 'shouldHideRelevantProjects',
          fr: 'shouldHideRelevantProjects',
          en: 'shouldHideRelevantProjects',
        },
        options: shownOrHideOptions,
      },
    ],
  },
  {
    id: 'stats',
    title: {
      default: '"Reports" view',
      en: '"Reports" view',
      fr: 'Vue "Rapports"',
    },
    editableParameters: [
      {
        key: 'shouldDisableDetailsRedirections',
        title: {
          default: 'Disable redirections in details modals',
          fr: 'Désactiver les redirections dans les modales de détails',
          en: 'Disable redirections in details modals',
        },
        tooltip: {
          default: 'shouldDisableDetailsRedirections',
          fr: 'shouldDisableDetailsRedirections',
          en: 'shouldDisableDetailsRedirections',
        },
        options: yesOrNoOptions,
      },
      {
        key: 'canUseStatsProjectTreeSelector',
        title: {
          default: 'Display project filter as tree',
          fr: 'Afficher le filtre de projets en arborescence',
          en: 'Display project filter as tree',
        },
        tooltip: {
          default: 'canUseStatsProjectTreeSelector',
          fr: 'canUseStatsProjectTreeSelector',
          en: 'canUseStatsProjectTreeSelector',
        },
        options: yesOrNoOptions,
      },
    ],
  },
];

const ConfigurationParameterRow = ({
  parameter,
  userConfigurationParameter,
  clientConfigurationParameter,
}: PropsWithChildren<{
  parameter: EditableParameter;
  userConfigurationParameter?: string;
  clientConfigurationParameter?: string;
}>) => {
  const { t } = useTranslation();
  const { title, tooltip, options, key } = parameter;

  const updateClientParam = useUpdateClientConfigurationParam();
  const updateUserParam = useUpdateUserConfigurationParam();

  const uiOptions = useMemo(() => {
    return _.map(options, (option) => {
      return {
        label: getTranslatedText(option.title),
        value: option.value,
      };
    });
  }, [options]);

  const clientUiOptions = useMemo(() => {
    return [
      ...uiOptions,
      {
        label: t('settings.configurationParameters.undefinedClient'),
        value: null,
      },
    ];
  }, [uiOptions, t]);

  const userUiOptions = useMemo(() => {
    return [
      ...uiOptions,
      {
        label: t('settings.configurationParameters.undefinedUser'),
        value: null,
      },
    ];
  }, [uiOptions, t]);

  const userDefaultValue = useMemo(() => {
    if (!userConfigurationParameter) {
      return _.findWhere(userUiOptions, { value: null });
    }

    return _.findWhere(userUiOptions, { value: userConfigurationParameter });
  }, [userConfigurationParameter, userUiOptions]);

  const clientDefaultValue = useMemo(() => {
    if (!clientConfigurationParameter) {
      return _.findWhere(clientUiOptions, { value: null });
    }

    return _.findWhere(clientUiOptions, {
      value: clientConfigurationParameter,
    });
  }, [clientConfigurationParameter, clientUiOptions]);

  const onUserOptionSelected = useCallback(
    ({ value }) => {
      updateUserParam({
        key,
        value,
      });
    },
    [key, updateUserParam],
  );

  const onClientOptionSelected = useCallback(
    ({ value }) => {
      updateClientParam({
        key,
        value,
      });
    },
    [key, updateClientParam],
  );

  return (
    <Table.Row className={styles.row}>
      <Table.Cell>
        {getTranslatedText(title)}
        {tooltip && <InfoTooltip>{getTranslatedText(tooltip)}</InfoTooltip>}
      </Table.Cell>
      <Table.Cell>
        <GenericSelect
          defaultValue={clientDefaultValue}
          options={clientUiOptions}
          onChange={onClientOptionSelected}
        />
      </Table.Cell>
      <Table.Cell>
        <GenericSelect
          defaultValue={userDefaultValue}
          options={userUiOptions}
          onChange={onUserOptionSelected}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const ConfigurationParametersGroup = ({
  group,
  userConfigurationParams,
  clientConfigurationParams,
}: PropsWithChildren<{
  group: EditableParameterGroup;
  userConfigurationParams: Record<string, string>;
  clientConfigurationParams: Record<string, string>;
}>) => {
  const { t } = useTranslation();
  const { title, editableParameters } = group;
  return (
    <div>
      <h2>{getTranslatedText(title)}</h2>
      <div className='section visible'>
        <Table basic className={styles.table} style={{ overflow: 'unset' }}>
          <Table.Header
            className={styles.header}
            style={{
              borderTopLeftRadius: '8px !important',
              borderTopRightRadius: '8px !important',
            }}
          >
            <Table.Row>
              <Table.HeaderCell className={styles.th}>
                {t('settings.configurationParameters.group.title')}
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.th}>
                {t('settings.configurationParameters.group.client')}
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.th}>
                {t('settings.configurationParameters.group.user')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {_.map(editableParameters, (parameter) => {
              return (
                <ConfigurationParameterRow
                  key={parameter.key}
                  userConfigurationParameter={
                    userConfigurationParams[parameter.key]
                  }
                  clientConfigurationParameter={
                    clientConfigurationParams[parameter.key]
                  }
                  parameter={parameter}
                />
              );
            })}
          </Table.Body>
        </Table>
        <br />
      </div>
    </div>
  );
};

export default function ConfigurationParameters() {
  const clientId = useClientId();
  const { t } = useTranslation();

  const {
    configurationParams: clientConfigurationParams,
    loading: loadingClientParams,
  } = useClientConfigurationParams();
  const {
    configurationParams: userConfigurationParams,
    loading: loadingUserParams,
  } = useUserConfigurationParams();

  if (loadingClientParams || loadingUserParams) {
    return null;
  }

  return (
    <SettingsLayout
      currentPage='configuration-parameters'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings'>
        <div className='settings-header'>
          <h1>{t('settings.configurationParameters.title')}</h1>
        </div>
        <div>
          {_.map(EDITABLE_CONFIGURATION_PARAMETERS_GROUPS, (group) => {
            return (
              <ConfigurationParametersGroup
                key={group.id}
                group={group}
                userConfigurationParams={userConfigurationParams || {}}
                clientConfigurationParams={clientConfigurationParams || {}}
              />
            );
          })}
        </div>
      </div>
    </SettingsLayout>
  );
}
