import React, { useMemo } from 'react';
import { GroupBase } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { useTranslation } from 'react-i18next';

import styles from './styles';

export type GenericAsyncSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<AsyncProps<Option, IsMulti, Group>, 'styles'>;

const GenericAsyncSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  isClearable,
  noOptionsMessage,
  placeholder,
  ...props
}: GenericAsyncSelectProps<Option, IsMulti, Group>): JSX.Element => {
  const { t } = useTranslation();

  const actualNoOptionsMessage = useMemo(
    () => noOptionsMessage || (() => t('common.noOptions')),
    [noOptionsMessage, t],
  );

  const actualPlaceholder = useMemo(
    () => placeholder || `${t('common.select')}...`,
    [placeholder, t],
  );

  return (
    <AsyncSelect
      {...props}
      isClearable={isClearable || false}
      styles={styles}
      noOptionsMessage={actualNoOptionsMessage}
      placeholder={actualPlaceholder}
    />
  );
};

export default GenericAsyncSelect;
