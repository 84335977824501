import React, { FC, useReducer } from 'react';

import ProfilesViewSettingsContext from './ProfilesViewSettingsContext';
import { profilesViewSettingsReducer } from './helpers';

const ProfilesViewSettingsContextProvider: FC = ({ children }) => {
  const value = useReducer(profilesViewSettingsReducer, {
    atsTagFilters: {},
  });

  return (
    <ProfilesViewSettingsContext.Provider value={value}>
      {children}
    </ProfilesViewSettingsContext.Provider>
  );
};

export default ProfilesViewSettingsContextProvider;
