import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GenericButton from '@/components/Common/GenericButton';
import Plus from '@/components/Reveal/Icons/Plus';
import { Dropdown, Modal } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import _, { compose } from 'underscore';
import { useTranslation } from 'react-i18next';
import useSharedMessagingAccounts, {
  UnipileAccount,
  getSharedMessagingAccounts,
} from '@/graphql/hooks/clients/useSharedServiceAccount';
import GenericTextInput from '@/components/Common/GenericTextInput';
import { useUpdateSharedMessagingAccount } from '@/graphql/hooks/clients/useUpdateSharedMessagingAccount';
import {
  DELETE_UNIPILE_CONNECTION,
  GET_UNIPILE_CONNECTION_URL,
  GET_UNIPILE_RECONNECTION_URL,
} from '@/graphql/unipile';
import withClientUsers from '@/hocs/clients/withClientUsers';
import { User } from '@/types/user';
import styles from '../AutomationIntegration.module.less';
import UnipileAutomationTable from './UnipileAutomationTable';
import UnipileConnexionModal from './UnipileConnexionModal';
import { recruitersToOptions } from '../../Settings/Senders/helpers';

interface UnipileAutomationProps {
  users: User[];
}

const UnipileAutomation: React.FC<UnipileAutomationProps> = ({ users }) => {
  const { t } = useTranslation();
  const [newAutomationModalOpen, setNewAutomationModalOpen] = useState(false);
  const [reconnectModalOpen, setReconnectModalOpen] = useState(false);
  const [
    createdAutomation,
    setCreatedAutomation,
  ] = useState<UnipileAccount | null>(null);
  const [expectedCode, setExpectedCode] = useState('');
  const [reconnectAccountId, setReconnectAccountId] = useState('');
  const [pollingRate, setPollingRate] = useState(0);
  const [hasValidated, setHasValidated] = useState(false);
  const [hasErrors, _setHasErrors] = useState(false);
  const [deletionModalOpen, setDeletionModalOpen] = useState(false);
  const [editionModalOpen, setEditionModalOpen] = useState(false);
  const [automationState, setAutomationState] = useState({});
  const { sharedMessagingAccounts } = useSharedMessagingAccounts({
    queryOptions: {
      pollInterval: pollingRate,
    },
  });

  const [getUnipileConnectionURL] = useMutation(GET_UNIPILE_CONNECTION_URL);
  const [getUnipileReconnectionURL] = useMutation(GET_UNIPILE_RECONNECTION_URL);
  const [deleteUnipileConnection] = useMutation(DELETE_UNIPILE_CONNECTION, {
    refetchQueries: [getSharedMessagingAccounts],
  });
  const [updateSharedMessagingAccount] = useUpdateSharedMessagingAccount();

  useEffect(() => {
    if (!expectedCode) {
      return;
    }
    const matchingAutomation = _.findWhere(sharedMessagingAccounts || [], {
      expectedCode,
    });

    if (matchingAutomation) {
      setCreatedAutomation(matchingAutomation);
      setExpectedCode('');
      setHasValidated(true);
      setPollingRate(0);
    }
  }, [expectedCode, sharedMessagingAccounts]);

  useEffect(() => {
    if (!reconnectAccountId) {
      return;
    }
    const matchingAutomation = _.findWhere(sharedMessagingAccounts || [], {
      serviceAccountId: reconnectAccountId,
      status: 'valid',
    });

    if (matchingAutomation) {
      setCreatedAutomation(matchingAutomation);
      setReconnectAccountId('');
      setHasValidated(true);
      setPollingRate(0);
    }
  }, [reconnectAccountId, sharedMessagingAccounts]);

  const onChangeUsers = useCallback(
    (e, data) => {
      setAutomationState({
        ...automationState,
        users: _.map(data.value, (user) => ({ email: user })),
      });
    },
    [automationState],
  );

  const activeUsers = useMemo(() => _.reject(users, { status: 'pending' }), [
    users,
  ]);
  const recruiterOptions = useMemo(
    () =>
      recruitersToOptions({
        users: activeUsers,
        ownerEmail: automationState?.owner?.email,
      }),
    [activeUsers, automationState],
  );

  return (
    <>
      <div className='section visible'>
        <h2>{t('settings.automations.unipile.title')}</h2>
        <UnipileAutomationTable
          accounts={sharedMessagingAccounts}
          onDeleteAccount={({ accountId }: { accountId: string }) => {
            setDeletionModalOpen(true);
            // setSelectedAutomationId(accountId || '');
            setAutomationState(
              _.findWhere(sharedMessagingAccounts, {
                serviceAccountId: accountId,
              }),
            );
            setPollingRate(1000);
            setTimeout(() => {
              setPollingRate(0);
            }, 1500);
          }}
          onEditAccount={({ accountId }: { accountId: string }) => {
            setAutomationState(
              _.findWhere(sharedMessagingAccounts, {
                serviceAccountId: accountId,
              }),
            );
            setEditionModalOpen(true);
          }}
          onReconnectAccount={({ accountId }: { accountId: string }) => {
            getUnipileReconnectionURL({ variables: { accountId } }).then(
              (response) => {
                const { url, expectedCode: _code } =
                  response?.data?.getUnipileReconnectionLink || {};
                window.open(url, '_blank');
                setReconnectAccountId(accountId);
                setPollingRate(2000);
              },
            );
            setReconnectModalOpen(true);
          }}
        />
        <div className='input-container'>
          <GenericButton
            onClick={() => {
              getUnipileConnectionURL().then((response) => {
                const { url, expectedCode: code } =
                  response?.data?.getUnipileConnectionLink || {};
                window.open(url, '_blank');
                setExpectedCode(code);
                setPollingRate(2000);
              });
              setNewAutomationModalOpen(true);
            }}
          >
            <Plus />
            {t('settings.automations.unipile.create')}
          </GenericButton>
        </div>
      </div>
      <UnipileConnexionModal
        open={newAutomationModalOpen || reconnectModalOpen}
        onClose={() => {
          setPollingRate(0);
          setCreatedAutomation(null);
          setHasValidated(false);
          setReconnectAccountId('');
        }}
        onCancel={() => {
          setNewAutomationModalOpen(false);
          setReconnectModalOpen(false);
          setPollingRate(0);
          setCreatedAutomation(null);
          setHasValidated(false);
        }}
        onConfirm={() => {
          setNewAutomationModalOpen(false);
          setReconnectModalOpen(false);
          setCreatedAutomation(null);
          setHasValidated(false);
        }}
        hasValidated={hasValidated}
        hasErrors={hasErrors}
        createdAutomation={createdAutomation}
      />
      <Modal
        open={deletionModalOpen}
        onClose={() => {}}
        closeOnEscape
        closeOnDimmerClick
        closeIcon
        size='tiny'
      >
        <Modal.Header>
          {t('settings.automations.unipile.deletionModal.title')}
        </Modal.Header>
        <Modal.Actions>
          <div className={styles.modalActions}>
            <GenericButton
              primacy='secondary'
              size='big'
              onClick={() => {
                setDeletionModalOpen(false);
              }}
            >
              {t('common.cancel')}
            </GenericButton>
            <GenericButton
              size='big'
              onClick={() => {
                deleteUnipileConnection({
                  variables: {
                    id: automationState?.serviceAccountId,
                  },
                });
                setDeletionModalOpen(false);
              }}
            >
              {t('common.delete')}
            </GenericButton>
          </div>
        </Modal.Actions>
      </Modal>
      <Modal
        open={editionModalOpen}
        onClose={() => {}}
        closeOnEscape
        closeOnDimmerClick
        closeIcon
        size='tiny'
      >
        <Modal.Header>
          {t('settings.automations.unipile.editionModal.title')}
        </Modal.Header>
        <Modal.Content>
          <div className={styles.formField}>
            <label className={styles.formLabel}>{t('common.name')}</label>
            <GenericTextInput
              value={automationState?.name}
              onValue={(value) =>
                setAutomationState({ ...automationState, name: value })
              }
            />
          </div>
          <div className={styles.formField}>
            <label className={styles.formLabel}>
              {t('settings.senders.editModal.sharedWith')}
            </label>
            <Dropdown
              multiple
              selection
              fluid
              options={recruiterOptions}
              value={_.pluck(automationState?.users || [], 'email')}
              onChange={onChangeUsers}
              placeholder={t('settings.senders.editModal.usersPlaceholder')}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div className={styles.modalActions}>
            <GenericButton
              primacy='secondary'
              size='big'
              onClick={() => {
                setEditionModalOpen(false);
                setAutomationState({});
              }}
            >
              {t('common.cancel')}
            </GenericButton>
            <GenericButton
              size='big'
              onClick={() => {
                updateSharedMessagingAccount({
                  variables: {
                    input: {
                      id: automationState?.serviceAccountId,
                      name: automationState?.name,
                      users: automationState?.users || [],
                    },
                  },
                });
                setEditionModalOpen(false);
                setAutomationState({});
              }}
            >
              {t('common.confirm')}
            </GenericButton>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default compose(withClientUsers)(UnipileAutomation);
