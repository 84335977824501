import _ from 'underscore';
import { Dropdown } from 'semantic-ui-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { lowerCaseAndUnaccent } from '@/common';
import {
  ATS_LOGOS_BY_TYPE,
  ATS_TITLE_AND_LOGO,
  ATS_WITHOUT_JOB,
  ATS_WITH_OWNER,
  ATS_WITH_SUBSECTION,
  CONNECTORS_WITHOUT_WORKFLOWS,
  SUPPORTED_ATS_TYPES,
} from '@/common/reveal';
import { Subsection } from '@/types/client';

import useClientId from '@/hooks/router/useClientId';
// import useClientUsers from '@/graphql/hooks/clients/useClientUsers';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';

import { IRevealConnector } from '@/graphql/fragments/RevealConnector';
import { getFullname } from '@/common/helpers/person';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import {
  getJobOptions,
  getUsersOptions,
} from '@/revealComponents/Modals/AddToATSJobModal';

import useFilteredClientUsers from '@/graphql/hooks/clients/useFilteredClientUsers';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { useAtsJobsAndUsers } from '..';

import styles from '../NewJobModal.module.less';

function ATSJobSelector({
  jobs: atsJobOptions,
  needsJob,
  selectedJob: atsJobId,
  connector,
  logo: atsLogo,
  onChange,
}: {
  needsJob: boolean;
  jobs: {
    text: string;
    value: any;
  }[];
  connector?: IRevealConnector;
  selectedJob?: string;
  logo: string | null;
  onChange: (id: string) => void;
}) {
  const { t } = useTranslation();

  if (!needsJob) {
    return null;
  }
  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputLabel}>
        {atsLogo && <img src={atsLogo} alt='ats' className={styles.atsLogo} />}
        {t('reveal.missions.newMissionModal.chooseAtsJob')}{' '}
        {connector?.type ? ATS_TITLE_AND_LOGO[connector.type]?.title : ''}
        <span className={styles.recommendation}>
          {t('reveal.missions.newMissionModal.recommended')}
        </span>
      </div>
      <div className='input-element'>
        <Dropdown
          placeholder={t('reveal.missions.newMissionModal.atsJobPlaceholder')}
          fluid
          value={atsJobId}
          onChange={(e, data) => onChange(data.value as string)}
          search={(options, query) => {
            return _.filter(
              options,
              (option) =>
                lowerCaseAndUnaccent(option.text as string).indexOf(
                  lowerCaseAndUnaccent(query),
                ) >= 0,
            );
          }}
          selection
          options={atsJobOptions}
          clearable
          selectOnBlur={false}
        />
      </div>
    </div>
  );
}

function ATSUserSelector({
  users: atsUsersOptions,
  selectedUser: atsUserId,
  needsUser,
  connector,
  logo: atsLogo,
  onChange,
}: {
  needsUser: boolean;
  users: {
    text: string;
    value: any;
  }[];
  connector?: IRevealConnector;
  selectedUser?: string;
  logo?: string | null;
  onChange: (id: string) => void;
}) {
  const { t } = useTranslation();
  if (!needsUser) {
    return null;
  }

  const usesOwner = connector?.type && ATS_WITH_OWNER.includes(connector.type);
  const atsWithoutJob =
    connector?.type && ATS_WITHOUT_JOB.includes(connector.type);

  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputLabel}>
        {!usesOwner && (
          <>
            {atsLogo && connector && atsWithoutJob && (
              <img src={atsLogo} alt='ats' className={styles.atsLogo} />
            )}
            {t('reveal.missions.newMissionModal.chooseAtsUser')}{' '}
            {connector?.type ? ATS_TITLE_AND_LOGO[connector.type]?.title : ''}
            {atsWithoutJob && (
              <span className={styles.recommendation}>
                {t('reveal.missions.newMissionModal.recommended')}
              </span>
            )}
          </>
        )}
        {usesOwner && t('reveal.missions.newMissionModal.chooseOwner')}
      </div>
      <div className='input-element'>
        <Dropdown
          placeholder={t('reveal.missions.newMissionModal.atsUserPlaceholder')}
          fluid
          value={atsUserId}
          onChange={(e, { value }) => onChange(value as string)}
          search={(options, query) => {
            return _.filter(
              options,
              (option) =>
                lowerCaseAndUnaccent(option.text as string).indexOf(
                  lowerCaseAndUnaccent(query),
                ) >= 0,
            );
          }}
          selection
          options={atsUsersOptions}
          clearable
          required
          selectOnBlur={false}
        />
      </div>
    </div>
  );
}

function HireSweetOwnerSelector({
  disabled,
  selectedOwnerEmail,
  filters,
  connector,
  onChange,
}: {
  disabled?: boolean;
  filters?: any[];
  selectedOwnerEmail?: string | null;
  connector?: IRevealConnector;
  onChange: (email: string) => void;
}) {
  const { t } = useTranslation();
  const clientId = useClientId();
  const userQueryResult = useCurrentUser();
  const currentUser = userQueryResult?.data?.user;

  const { users } = useFilteredClientUsers(clientId, filters || [], {
    fetchPolicy: 'cache-first',
  });

  const usersValues = useMemo(() => {
    const uniqueUsers = _.map(users, (user) => {
      return {
        text: `${getFullname(user)} (${user.email})`,
        value: user.email,
      };
    });

    if (
      currentUser?.email &&
      !_.findWhere(users, { email: currentUser.email })
    ) {
      uniqueUsers.push({
        text: `${getFullname(currentUser)} (${currentUser.email})`,
        value: currentUser.email,
      });
    }
    return uniqueUsers;
  }, [users, currentUser]);

  if (!ATS_WITH_OWNER.includes(connector?.type || '')) {
    return null;
  }

  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputLabel}>
        {t('reveal.missions.newMissionModal.chooseOwner')}
      </div>
      <div className='input-element'>
        <Dropdown
          disabled={disabled}
          placeholder={t('reveal.missions.newMissionModal.atsUserPlaceholder')}
          fluid
          value={selectedOwnerEmail || ''}
          onChange={(e, { value }) => onChange(value as string)}
          search={(options, query) => {
            return _.filter(
              options,
              (option) =>
                lowerCaseAndUnaccent(option.text as string).indexOf(
                  lowerCaseAndUnaccent(query),
                ) >= 0,
            );
          }}
          selection
          options={_.sortBy(usersValues, 'text')}
          clearable
          required
          selectOnBlur={false}
        />
      </div>
    </div>
  );
}

interface SubsectionWithParents extends Subsection {
  parentSectionId: string;
  parentDepartmentId: string;
}

interface Foldering {
  departmentId: string;
  sectionId: string;
  subsectionId: string;
}

export function SubsectionSelector({
  connector,
  selectedSubsection,
  onChange,
  disabled = false,
}: {
  connector?: IRevealConnector;
  selectedSubsection?: string | null;
  onChange: (foldering: Foldering) => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const [hasInitialized, setHasInitialized] = useState(false);

  const { departments } = useUserAllowedDepartments();

  const { subsectionsOptions, subsectionsById } = useMemo(() => {
    const allSubsections = departments
      .reduce((agg, { id: departmentId, sections }) => {
        const sectionSubsections =
          sections
            ?.reduce((subAgg, section) => {
              const subsWithParents: SubsectionWithParents[] = _.map(
                section.subsections || [],
                (subsection) => {
                  return {
                    ...subsection,
                    parentSectionId: section.id,
                    parentDepartmentId: departmentId,
                  };
                },
              );

              return [...subAgg, ...subsWithParents];
            }, [] as SubsectionWithParents[])
            .flat() || [];

        return [...agg, ...sectionSubsections];
      }, [] as SubsectionWithParents[])
      .flat();

    const internalSubsectionsOptions = _.map(allSubsections, (subsection) => {
      return {
        text: subsection.title,
        value: subsection.id,
      };
    });

    const internalSubsectionsById = _.indexBy(allSubsections, 'id');

    return {
      subsectionsOptions: internalSubsectionsOptions,
      subsectionsById: internalSubsectionsById,
    };
  }, [departments]);

  const onSubsectionSelected = useCallback(
    (subsectionId: string) => {
      if (!subsectionId) {
        return onChange({
          departmentId: '',
          sectionId: '',
          subsectionId: '',
        });
      }

      const selectedSubsectionOption = subsectionsById[subsectionId];
      if (!selectedSubsectionOption) {
        return onChange({
          departmentId: '',
          sectionId: '',
          subsectionId: '',
        });
      }

      const foldering: Foldering = {
        departmentId: selectedSubsectionOption.parentDepartmentId,
        sectionId: selectedSubsectionOption.parentSectionId,
        subsectionId: selectedSubsectionOption.id,
      };

      return onChange(foldering);
    },
    [subsectionsById, onChange],
  );

  
  useEffect(() => {
    if ((subsectionsOptions || []).length === 1 && !hasInitialized) {
      setHasInitialized(true);
      if (!disabled) {
        onSubsectionSelected(subsectionsOptions[0].value);
      }
    }
  }, [disabled,subsectionsOptions, onSubsectionSelected, hasInitialized]);
  

  if (!ATS_WITH_SUBSECTION.includes(connector?.type || '')) {
    return null;
  }


  return (
    <div className={styles.inputContainer}>
      <div className={styles.inputLabel}>
        {t('reveal.missions.newMissionModal.subsection')}
      </div>
      <div className='input-element'>
        <Dropdown
          placeholder={t(
            'reveal.missions.newMissionModal.subsectionPlaceholder',
          )}
          fluid
          value={selectedSubsection || ''}
          onChange={(e, { value }) => onSubsectionSelected(value as string)}
          search={(options, query) => {
            return _.filter(
              options,
              (option) =>
                lowerCaseAndUnaccent(option.text as string).indexOf(
                  lowerCaseAndUnaccent(query),
                ) >= 0,
            );
          }}
          selection
          options={subsectionsOptions}
          clearable
          required
          disabled={disabled}
          selectOnBlur={false}
        />
      </div>
    </div>
  );
}

export function NewRevealJobATSInfo({
  needsUser,
  needsJob,
  atsUserId,
  atsJobId,
  selectedSubsectionId,
  selectedOwnerEmail,
  onChangeAtsUserId,
  onChangeAtsJobId,
  onChangeOwner,
}: {
  needsUser: boolean;
  needsJob: boolean;
  atsUserId?: string;
  atsJobId?: string;
  selectedSubsectionId?: string | null;
  selectedOwnerEmail?: string | null;
  onChangeAtsUserId: (id: string) => void;
  onChangeAtsJobId: (id: string) => void;
  onChangeOwner: (email: string) => void;
}) {
  const clientId = useClientId();
  const connector = useClientRevealConnector(clientId);

  const { data: atsFilterOptionsData } = useAtsJobsAndUsers(clientId);

  const atsUsersOptions = getUsersOptions({
    atsFilterOptionsData,
  });

  const atsJobOptions = getJobOptions({
    atsFilterOptionsData,
  });

  const atsLogo = useMemo(() => {
    if (!connector) {
      return null;
    }
    return ATS_LOGOS_BY_TYPE[connector.type];
  }, [connector]);

  const usesAtsWithWorkflow =
    connector?.type &&
    SUPPORTED_ATS_TYPES.includes(connector.type) &&
    !CONNECTORS_WITHOUT_WORKFLOWS.includes(connector.type);

  if (!usesAtsWithWorkflow) {
    return null;
  }

  return (
    <>
      <ATSJobSelector
        needsJob={needsJob}
        selectedJob={atsJobId}
        jobs={atsJobOptions}
        connector={connector}
        logo={atsLogo}
        onChange={onChangeAtsJobId}
      />
      <ATSUserSelector
        selectedUser={atsUserId}
        needsUser={needsUser}
        users={atsUsersOptions}
        connector={connector}
        logo={atsLogo}
        onChange={onChangeAtsUserId}
      />
      {connector?.type === 'adventure' ? (
        <HireSweetOwnerSelector
          key={selectedSubsectionId}
          connector={connector}
          filters={[
            { key: 'explicit-subsection', descriptor: selectedSubsectionId },
          ]}
          disabled={!selectedSubsectionId}
          selectedOwnerEmail={selectedOwnerEmail}
          onChange={onChangeOwner}
        />
      ) : (
        <HireSweetOwnerSelector
          connector={connector}
          selectedOwnerEmail={selectedOwnerEmail}
          onChange={onChangeOwner}
        />
      )}
    </>
  );
}
