import _ from 'underscore';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import React, {
  MouseEvent,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import * as Sentry from '@sentry/browser';
import { Modal } from 'semantic-ui-react';
import { Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useClientMissionCustomFields } from '@/graphql/hooks/clients/useClientMissionCustomFields';
import {
  useClientCurrentAtsId,
  useClientRevealConnector,
} from '@/graphql/hooks/clients/useClientRevealProjects';

import {
  ATS_WITHOUT_USER,
  ATS_WITHOUT_JOB,
  ATS_WITH_SUBSECTION,
  ATS_WITHOUT_NAME,
  ATS_WITH_OWNER,
} from '@/common/reveal';

import MissionDepartmentsForm from '@/routes/RevealView/JobsView/JobView/InformationsTab/MissionDepartments';
import {
  CustomFieldDefinition,
  CustomFieldInput,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getJobOptions } from '@/revealComponents/Modals/AddToATSJobModal';
import { combineDataWithCustomFields } from '@/common/customFields';
import useClientUsers from '@/graphql/hooks/clients/useClientUsers';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import {
  NewJobCustomFields,
  formatCustomFieldInput,
} from './NewJobCustomFields';
import GenericButton from '../Common/GenericButton';
import GenericModal from '../Common/GenericModal';
import GenericTextInput from '../Common/GenericTextInput';
import { NewRevealJobATSInfo, SubsectionSelector } from './NewRevealJobATSInfo';

import styles from './NewJobModal.module.less';

interface NewRevealJobModalProps {
  open: boolean;
  onClose: () => void;
  searchPoolId: string;
  clientId: string;
  onSubmit: ({
    searchPoolId,
    input,
  }: {
    searchPoolId: string;
    input: any;
  }) => Promise<void>;
  loading: boolean;
  job?: any;
  isEdition?: boolean;
  queryResponse: any;
  readOnlyCustomFields?: string[];
}

export const useAtsJobsAndUsers = (clientId: string) => {
  return useQuery(
    gql`
      query getRevealProjectFiltersOptions($clientId: ID!) {
        client(id: $clientId) {
          id
          revealProjects {
            id
            connectors {
              id
              type
              filterOptions {
                users {
                  id
                  firstname
                  lastname
                }
                jobs {
                  id
                  name
                  locationName
                  isArchived
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        clientId,
      },
    },
  );
};

const NewRevealJobModal: React.FC<NewRevealJobModalProps> = ({
  open,
  onClose,
  searchPoolId,
  clientId,
  onSubmit,
  job,
  loading,
  isEdition = false,
  queryResponse,
  readOnlyCustomFields = [],
}) => {
  if (!open) {
    return null;
  }

  return (
    <NewRevealJobModalComponent
      open={open}
      onClose={onClose}
      searchPoolId={searchPoolId}
      clientId={clientId}
      onSubmit={onSubmit}
      job={job}
      loading={loading}
      isEdition={isEdition}
      queryResponse={queryResponse}
      readOnlyCustomFields={readOnlyCustomFields}
    />
  );
};

const NewRevealJobModalComponent: React.FC<NewRevealJobModalProps> = ({
  open,
  onClose,
  searchPoolId,
  clientId,
  onSubmit,
  job,
  loading,
  isEdition = false,
  queryResponse,
  readOnlyCustomFields = [],
}) => {
  const missionCategoryType = useContext(MissionCategoryContext);
  const { permissions } = useClientPermissions(clientId);
  const { salesProjects } = permissions || {};
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { users } = useClientUsers(clientId);
  const { t } = useTranslation();
  const notification = useNotificationSystem();
  const [jobTitle, setJobTitle] = useState(
    searchParams.get('newJobDefaultName') || '',
  );
  const atsId = useClientCurrentAtsId();
  const [customFields, setCustomFields] = useState<CustomFieldInput[]>([]);
  const [atsJobId, setAtsJobId] = useState('');
  const [atsUserId, setAtsUserId] = useState('');
  const [ownerEmail, setOwnerEmail] = useState<string | null>(
    job?.owner?.email || null,
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedFoldering, setSelectedFoldering] = useState({
    departmentId: job?.foldering?.department?.id || '',
    sectionId: job?.foldering?.section?.id || '',
    subsectionId: job?.foldering?.subsection?.id || '',
  });

  const selectedUser = _.findWhere(users || [], { email: ownerEmail || '' });

  const { missionCustomFields } = useClientMissionCustomFields(clientId);

  const customFieldsInitialValues = useMemo(() => {
    return combineDataWithCustomFields(missionCustomFields, job);
  }, [missionCustomFields, job]);

  const [filledCustomFields, setFilledCustomFields] = useState<
    CustomFieldDefinition[]
  >((customFieldsInitialValues || []) as CustomFieldDefinition[]);

  const connector = useClientRevealConnector(clientId);

  const { data: atsFilterOptionsData } = useAtsJobsAndUsers(clientId);

  useEffect(() => {
    const customFieldValues: any[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const missionCustomField of missionCustomFields || []) {
      const currentField: any = missionCustomField;
      if ((currentField.options || []).length === 1) {
        customFieldValues.push({
          ...currentField,
          type: currentField.type,
          clientCustomFieldId: currentField.id,
          id: currentField.id,
          selected: [currentField.options[0].id],
          rawValue: [currentField.options[0].id],
        });
      }
    }
    if (!isEdition) {
      setFilledCustomFields(customFieldValues);
    }
    setIsInitialized(true);
  }, [missionCustomFields, isEdition]);

  const atsJobOptions = getJobOptions({
    atsFilterOptionsData,
  });

  const onConfirm = async () => {
    try {
      await onSubmit({
        searchPoolId,
        input: {
          title: jobTitle,
          ...(!isEdition && { atsJobId }),
          ...(!isEdition && { atsUserId }),
          ...(isEdition && { jobId: job.id }),
          missionCategory: { type: missionCategoryType },
          customFields:
            isEdition || atsId === 'adventure'
              ? _.map(filledCustomFields, (customField) => {
                  const missionCustomField = _.findWhere(missionCustomFields, {
                    id: customField.clientCustomFieldId,
                  });
                  if (!missionCustomField) {
                    return null;
                  }

                  return formatCustomFieldInput(
                    customField.rawValue || customField.selected,
                    missionCustomField,
                  );
                })
              : customFields,
          ...(selectedFoldering.departmentId && {
            departmentId: selectedFoldering.departmentId,
          }),
          ...(selectedFoldering.sectionId && {
            sectionId: selectedFoldering.sectionId,
          }),
          ...(selectedFoldering.subsectionId && {
            subsectionId: selectedFoldering.subsectionId,
          }),
          ...(!isEdition && ownerEmail && { ownerEmail }),
          ...(isEdition &&
            selectedUser && {
              owner: {
                email: selectedUser?.email,
                firstname: selectedUser?.firstname,
                lastname: selectedUser?.lastname,
              },
            }),
        },
      });
      notification.success(
        isEdition
          ? t('reveal.missions.mission.modals.editSuccess')
          : t('reveal.missions.newMissionModal.success'),
      );
    } catch (e) {
      const error = e?.graphQLErrors?.[0]?.data?.details;
      console.error(e);
      Sentry.captureException(e);
      notification.error(
        error ||
          (isEdition
            ? t('reveal.missions.mission.modals.editError')
            : t('reveal.missions.newMissionModal.error')),
      );
    }
  };

  const handleChangeJobTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setJobTitle(event?.currentTarget?.value);
  };

  const handleChangeAtsJobId = (value: string) => {
    if (!value) {
      setAtsUserId('');
    }
    setAtsJobId(value);
    const option = _.findWhere(atsJobOptions, { value });
    if (option) {
      setJobTitle(option.text);
    }
  };

  const handleChangeAtsUserId = (value: string) => {
    setAtsUserId(value);
  };

  const areMandatoryFieldsFilled = useMemo(() => {
    const filledCustomFieldsById = _.indexBy(
      filledCustomFields,
      'clientCustomFieldId',
    );
    const requiredCustomFields = _.filter(
      missionCustomFields,
      ({ isMandatoryAtCreation }) => Boolean(isMandatoryAtCreation),
    );

    // eslint-disable-next-line
    for (const field of requiredCustomFields) {
      if (!filledCustomFieldsById[field.id]) {
        return false;
      }
    }

    return true;
  }, [filledCustomFields, missionCustomFields]);

  const createdJobId = queryResponse?.searchPoolJob?.create?.job?.id;
  const atsWithoutJob =
    connector?.type && ATS_WITHOUT_JOB.includes(connector.type);

  const needsUser = Boolean(
    missionCategoryType === 'recruiting' &&
      connector?.type &&
      (atsJobId || atsWithoutJob) &&
      !ATS_WITHOUT_USER.includes(connector.type),
  );

  const needsSubsection =
    missionCategoryType === 'recruiting' &&
    connector?.type &&
    ATS_WITH_SUBSECTION.includes(connector.type);

  const needsOwner =
    missionCategoryType === 'recruiting' &&
    ATS_WITH_OWNER.includes(connector?.type || '');
  const needsFoldering =
    missionCategoryType === 'recruiting' &&
    !ATS_WITH_SUBSECTION.includes(connector?.type || '');
  const needsName = !ATS_WITHOUT_NAME.includes(connector?.type || '');

  const isFormValid = useMemo(() => {
    if (!areMandatoryFieldsFilled) {
      return false;
    }

    if (needsName && !jobTitle) {
      return false;
    }

    if (needsUser && !atsUserId) {
      return false;
    }

    // const needsAtsJob = connector?.type !== 'custom' && !atsWithoutJob;
    const needsAtsJob = false;
    if (needsAtsJob && !atsWithoutJob && !atsJobId) {
      return false;
    }

    if (needsOwner && !ownerEmail) {
      return false;
    }

    if (!selectedFoldering.subsectionId && needsSubsection) {
      return false;
    }

    return true;
  }, [
    areMandatoryFieldsFilled,
    jobTitle,
    atsJobId,
    atsUserId,
    needsUser,
    needsName,
    needsOwner,
    ownerEmail,
    atsWithoutJob,
    needsSubsection,
    selectedFoldering,
  ]);

  const titleI18nKey = useMemo(() => {
    if (salesProjects && missionCategoryType === 'sales' && isEdition) {
      return 'reveal.missions.mission.modals.editSalesModalTitle';
    }
    if (salesProjects && missionCategoryType === 'sales' && !isEdition) {
      return 'reveal.missions.newMissionModal.salesTitle';
    }
    if (salesProjects && missionCategoryType === 'recruiting' && isEdition) {
      return 'reveal.missions.mission.modals.editHiringModalTitle';
    }
    if (salesProjects && missionCategoryType === 'recruiting' && !isEdition) {
      return 'reveal.missions.newMissionModal.hiringTitle';
    }
    if (isEdition) {
      return 'reveal.missions.mission.modals.editModalTitle';
    }
    return 'reveal.missions.newMissionModal.title';
  }, [salesProjects, isEdition, missionCategoryType]);

  if (!isInitialized) {
    return null;
  }

  if (createdJobId) {
    return (
      <Redirect
        to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${createdJobId}`}
      />
    );
  }

  return (
    <GenericModal
      open={open}
      onClose={onClose}
      size='tiny'
      onClick={(event: MouseEvent) => event.stopPropagation()}
    >
      <Modal.Header>{t(titleI18nKey)}</Modal.Header>
      <Modal.Content>
        <SubsectionSelector
          connector={connector}
          selectedSubsection={selectedFoldering.subsectionId}
          onChange={(id) => {
            setSelectedFoldering(id);
            setOwnerEmail(null);
          }}
          disabled={isEdition}
        />
        {missionCategoryType !== 'sales' && (
          <NewRevealJobATSInfo
            atsJobId={atsJobId}
            atsUserId={atsUserId}
            selectedSubsectionId={selectedFoldering?.subsectionId}
            selectedOwnerEmail={ownerEmail}
            needsJob={!atsWithoutJob}
            needsUser={needsUser}
            onChangeAtsJobId={handleChangeAtsJobId}
            onChangeAtsUserId={handleChangeAtsUserId}
            onChangeOwner={(email) => setOwnerEmail(email)}
          />
        )}
        {needsFoldering && (
          <div className={styles.departmentsContainer}>
            <MissionDepartmentsForm
              foldering={selectedFoldering}
              onUpdateFoldering={setSelectedFoldering}
              disabled={isEdition}
            />
          </div>
        )}
        {needsName && (
          <div className='input-container'>
            <div className='input-label'>
              {t('reveal.missions.newMissionModal.name')}
            </div>
            <div className='input-element'>
              <GenericTextInput
                fluid
                value={jobTitle}
                onChange={handleChangeJobTitle}
              />
            </div>
          </div>
        )}
        <NewJobCustomFields
          customFields={filledCustomFields as CustomFieldInput[]}
          missionCustomFields={missionCustomFields}
          onChange={
            atsId === 'adventure' ? setFilledCustomFields : setCustomFields
          }
          readOnlyFields={readOnlyCustomFields}
        />
      </Modal.Content>
      <Modal.Actions>
        <div className={styles.actions}>
          <GenericButton primacy='secondary' size='big' onClick={onClose}>
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            size='big'
            disabled={!isFormValid || loading}
            onClick={onConfirm}
          >
            {isEdition
              ? t('common.save')
              : t('reveal.missions.newMissionModal.create')}
          </GenericButton>
        </div>
      </Modal.Actions>
    </GenericModal>
  );
};

export default NewRevealJobModal;
