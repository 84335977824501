import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { Modal, Icon, Button, Loader } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { getRandomString } from '../../../common';

import withOffer from '../../../hocs/offers/withOffer';
import withClientIntegrations from '../../../hocs/integrations/withClientIntegrations';
import withEditOfferGreenhouseWorkflows from '../../../hocs/integrations/withEditOfferGreenhouseWorkflows';
import withEditOfferAshbyWorkflows from '../../../hocs/integrations/withEditOfferAshbyWorkflows';
import withEditOfferRecruiteeWorkflows from '../../../hocs/integrations/withEditOfferRecruiteeWorkflows';
import withEditOfferTeamtailorWorkflows from '../../../hocs/integrations/withEditOfferTeamtailorWorkflows';
import withEditOfferWorkableWorkflows from '../../../hocs/integrations/withEditOfferWorkableWorkflows';
import withEditOfferSmartrecruitersWorkflows from '../../../hocs/integrations/withEditOfferSmartrecruitersWorkflows';
import withEditOfferWelcomekitWorkflows from '../../../hocs/integrations/withEditOfferWelcomekitWorkflows';
import withEditOfferLeverWorkflows from '../../../hocs/integrations/withEditOfferLeverWorkflows';
import contextToProps from '../../../hocs/contextToProps';

import WorkflowForm from '../../../containers/Parameters/Integrations/components/WorkflowForm';
import {
  greenhouseLogo,
  recruiteeLogo,
  workableLogo,
  leverLogo,
  smartrecruitersLogo,
  welcomekitLogo,
  ashbyLogo,
  teamtailorLogo,
} from '../../../containers/Parameters/Integrations/logos';

import './OfferIntegrationWorkflowsModal.css';

const OfferIntegrationWorkflowsModal = ({
  clientId,
  offer,
  selectedIntegrationType,
  integrations,
  integrationsLoading,
  editOfferGreenhouseWorkflows,
  editOfferAshbyWorkflows,
  editOfferLeverWorkflows,
  editOfferRecruiteeWorkflows,
  editOfferTeamtailorWorkflows,
  editOfferWelcomekitWorkflows,
  editOfferSmartrecruitersWorkflows,
  editOfferWorkableWorkflows,
  open,
  onClose,
  t,
  onShowNotification,
}) => {
  const availableIntegrations = {
    greenhouse: {
      title: 'Greenhouse',
      logo: greenhouseLogo,
      editWorkflows: editOfferGreenhouseWorkflows,
      needsRecruiterSelection: true,
      needsStageSelection: true,
    },
    ashby: {
      title: 'Ashby',
      logo: ashbyLogo,
      editWorkflows: editOfferAshbyWorkflows,
      needsRecruiterSelection: true,
      needsStageSelection: true,
    },
    recruitee: {
      title: 'Recruitee',
      logo: recruiteeLogo,
      editWorkflows: editOfferRecruiteeWorkflows,
      needsRecruiterSelection: false,
      needsStageSelection: true,
    },
    teamtailor: {
      title: 'Teamtailor',
      logo: teamtailorLogo,
      editWorkflows: editOfferTeamtailorWorkflows,
      needsRecruiterSelection: false,
      needsStageSelection: true,
    },
    workable: {
      title: 'Workable',
      logo: workableLogo,
      editWorkflows: editOfferWorkableWorkflows,
      needsRecruiterSelection: true,
      needsStageSelection: true,
    },
    lever: {
      title: 'Lever',
      logo: leverLogo,
      editWorkflows: editOfferLeverWorkflows,
      needsRecruiterSelection: true,
      needsStageSelection: true,
    },
    smartrecruiters: {
      title: 'SmartRecruiters',
      logo: smartrecruitersLogo,
      editWorkflows: editOfferSmartrecruitersWorkflows,
      needsRecruiterSelection: false,
      needsStageSelection: true,
    },
    welcomekit: {
      title: 'Welcome Kit',
      logo: welcomekitLogo,
      editWorkflows: editOfferWelcomekitWorkflows,
      needsRecruiterSelection: false,
      needsStageSelection: true,
    },
  };

  const ATSIntegrations = _.filter(integrations, ({ type }) =>
    _.has(availableIntegrations, type),
  );
  const [integrationType, setIntegrationType] = useState(
    selectedIntegrationType ||
    ((ATSIntegrations || []).length === 1 && ATSIntegrations[0]?.type),
  );
  const integration = _.findWhere(ATSIntegrations, { type: integrationType });

  if (integrationsLoading) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        className='offer-integration-workflows-modal'
        size='small'
        closeIcon
      >
        <Modal.Header>
          {!integrationType
            ? t('offers.integrationWorkflows.chooseATSModalHeader')
            : t('offers.integrationWorkflows.editModalHeader', {
              integrationName: availableIntegrations[integrationType].title,
            })}
        </Modal.Header>
        <Modal.Content>
          <Loader active inline='centered' className='on-dimmer' />
        </Modal.Content>
      </Modal>
    );
  }
  if (!integrationType) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        className='offer-integration-workflows-modal'
        size='small'
        closeIcon
      >
        <Modal.Header>
          {t('offers.integrationWorkflows.chooseATSModalHeader')}
        </Modal.Header>
        <Modal.Content>
          {_.map(ATSIntegrations, ({ id, status, type }) => (
            <div
              key={id}
              className='integration-link-container'
              onClick={() => setIntegrationType(type)}
            >
              <div className='integration-link-logo'>
                {availableIntegrations[type].logo}
              </div>
              <div className='integration-link-title'>
                {availableIntegrations[type].title}
              </div>
              {status === 'active' && (
                <div className='integration-link-label label-active'>
                  {t('integrations.active')}
                </div>
              )}
              <div className='integration-link-arrow'>
                <Icon
                  className='integration-link-icon'
                  name='chevron right'
                  size='small'
                />
              </div>
            </div>
          ))}
        </Modal.Content>
      </Modal>
    );
  }

  return (
    <WorkflowsEditionModal
      clientId={clientId}
      offer={offer}
      availableIntegrations={availableIntegrations}
      integration={integration}
      editOfferGreenhouseWorkflows={editOfferGreenhouseWorkflows}
      editOfferAshbyWorkflows={editOfferAshbyWorkflows}
      editOfferLeverWorkflows={editOfferLeverWorkflows}
      editOfferRecruiteeWorkflows={editOfferRecruiteeWorkflows}
      editOfferTeamtailorWorkflows={editOfferTeamtailorWorkflows}
      editOfferWelcomekitWorkflows={editOfferWelcomekitWorkflows}
      editOfferSmartrecruitersWorkflows={editOfferSmartrecruitersWorkflows}
      editOfferWorkableWorkflows={editOfferWorkableWorkflows}
      open={open}
      onClose={onClose}
      t={t}
      onShowNotification={onShowNotification}
    />
  );
};

const WorkflowsEditionModal = ({
  clientId,
  offer,
  availableIntegrations,
  integration,
  open,
  onClose,
  t,
  onShowNotification,
}) => {
  const integrationType = integration?.type;
  const offerInitialIntegrationWorkflows =
    integrationType &&
    integration &&
    _.filter(
      integration.workflows,
      ({ owner }) => owner?.type === 'offer' && owner?.jobOfferId === offer.id,
    );
  const getEmptyWorkflow = () => ({
    id: getRandomString(6),
    rule: {
      type: null,
    },
    action: {
      command: 'upload-profile',
      integrationId: integration.id,
      target: {
        jobId: null,
        jobTitle: '',
        ...(availableIntegrations[integrationType].needsRecruiterSelection && {
          recruiterId: null,
          recruiterName: '',
        }),
        ...(availableIntegrations[integrationType].needsStageSelection && {
          stageStep: null,
          stageName: '',
        }),
      },
    },
  });
  const creationMode = _.isEmpty(offerInitialIntegrationWorkflows);
  const [workflows, setWorkflows] = useState(
    creationMode ? [getEmptyWorkflow()] : offerInitialIntegrationWorkflows,
  );

  const handleIntegrationTriggerChange = (currentWorkflowId) => (
    e,
    { value },
  ) =>
    setWorkflows((previousWorkflows) =>
      _.map(previousWorkflows, (workflow) => {
        if (currentWorkflowId !== workflow.id) {
          return workflow;
        }
        return {
          ...workflow,
          rule: {
            ...workflow.rule,
            type: value,
          },
        };
      }),
    );

  const handleIntegrationOfferChange = (currentWorkflowId) => (e, { value }) =>
    setWorkflows((previousWorkflows) =>
      _.map(previousWorkflows, (workflow) => {
        if (currentWorkflowId !== workflow.id) {
          return workflow;
        }
        return {
          ...workflow,
          action: {
            ...workflow.action,
            target: {
              ...workflow.action.target,
              jobId: value,
              jobTitle: _.find(integration?.jobs, { id: value })?.name,
            },
          },
        };
      }),
    );

  const handleStageChange = (currentWorkflowId) => ({ stageStep, stageName }) =>
    setWorkflows((previousWorkflows) =>
      _.map(previousWorkflows, (workflow) => {
        if (currentWorkflowId !== workflow.id) {
          return workflow;
        }
        return {
          ...workflow,
          action: {
            ...workflow.action,
            target: {
              ...workflow.action.target,
              stageStep,
              stageName,
            },
          },
        };
      }),
    );

  const handleRecruiterChange = (currentWorkflowId) => (e, { value }) =>
    setWorkflows((previousWorkflows) =>
      _.map(previousWorkflows, (workflow) => {
        if (currentWorkflowId !== workflow.id) {
          return workflow;
        }
        return {
          ...workflow,
          action: {
            ...workflow.action,
            target: {
              ...workflow.action.target,
              recruiterId: value,
              recruiterName: _.find(integration.users, { id: value })?.name,
            },
          },
        };
      }),
    );

  const saveWorkflows = async () => {
    const workflowsToSave = _.map(workflows, ({ rule, action }) => ({
      rule: {
        type: rule.type,
      },
      action: {
        command: action.command,
        integrationId: action.integrationId,
        target: {
          jobId: action.target.jobId,
          jobTitle: action.target.jobTitle,
          ...(availableIntegrations[integrationType]
            .needsRecruiterSelection && {
            recruiterId: action.target.recruiterId,
            recruiterName: action.target.recruiterName,
          }),
          ...(availableIntegrations[integrationType].needsStageSelection && {
            stageStep: action.target.stageStep,
            stageName: action.target.stageName,
          }),
        },
      },
    }));

    await availableIntegrations[integrationType]
      .editWorkflows({
        clientId,
        jobOfferId: offer.id,
        integrationId: integration.id,
        workflows: workflowsToSave,
      })
      .then(() => {
        onShowNotification({
          message: t('common.genericSuccess'),
          level: 'success',
        });
        onClose();
      })
      .catch(() => {
        onShowNotification({
          message: t('common.genericError'),
          level: 'error',
        });
      });
  };

  const addWorkflow = () => {
    const emptyWorkflow = getEmptyWorkflow();
    setWorkflows((previousWorkflows) => [...previousWorkflows, emptyWorkflow]);
  };

  const deleteWorkflow = (workflowToDeleteId) => () => {
    setWorkflows((previousWorkflows) =>
      _.filter(previousWorkflows, ({ id }) => workflowToDeleteId !== id),
    );
  };

  const checkIfWorkflowsAreValid = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const workflow of workflows) {
      if (!workflow.rule.type) {
        return false;
      }
      const target = workflow?.action?.target;
      if (!target?.jobId || !target?.jobTitle) {
        return false;
      }
      if (availableIntegrations[integrationType].needsRecruiterSelection) {
        if (!target?.recruiterId || !target?.recruiterName) {
          return false;
        }
      }
      if (availableIntegrations[integrationType].needsStageSelection) {
        if (!target?.stageStep || !target?.stageName) {
          return false;
        }
      }
    }
    return true;
  };
  const workflowsAreValid = checkIfWorkflowsAreValid();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className='offer-integration-workflows-modal'
      size='small'
      closeIcon
    >
      <Modal.Header>
        {creationMode
          ? t('offers.integrationWorkflows.createModalHeader', {
            integrationName: availableIntegrations[integrationType].title,
          })
          : t('offers.integrationWorkflows.editModalHeader', {
            integrationName: availableIntegrations[integrationType].title,
          })}
      </Modal.Header>
      <Modal.Content>
        <div>
          {t('offers.integrationWorkflows.modalDescription', {
            integrationName: availableIntegrations[integrationType].title,
          })}
        </div>
        <div>
          {_.map(workflows, (workflow) => {
            const trigger = workflow.rule?.type || '';
            const integrationOfferId = workflow.action?.target?.jobId || '';
            const stageStep = workflow.action?.target?.stageStep || null;
            const recruiterId = workflow.action?.target?.recruiterId || null;
            return (
              <div key={workflow.id} className='workflow-container'>
                <div className='workflow-button'>
                  <Button onClick={deleteWorkflow(workflow.id)}>
                    {t('offers.integrationWorkflows.remove')}
                  </Button>
                </div>
                <div className='workflow-form'>
                  <WorkflowForm
                    offers={[offer]}
                    integration={integration}
                    clientId={clientId}
                    hiresweetOfferId={offer.id}
                    handleHiresweetOfferChange={() => { }}
                    trigger={trigger}
                    handleIntegrationTriggerChange={handleIntegrationTriggerChange(
                      workflow.id,
                    )}
                    integrationOffers={integration?.jobs}
                    integrationOfferId={integrationOfferId}
                    handleIntegrationOfferChange={handleIntegrationOfferChange(
                      workflow.id,
                    )}
                    needsStageSelection={
                      availableIntegrations[integrationType].needsStageSelection
                    }
                    stageStep={stageStep}
                    handleStageChange={handleStageChange(workflow.id)}
                    needsRecruiterSelection={
                      availableIntegrations[integrationType]
                        .needsRecruiterSelection
                    }
                    recruiters={integration?.users}
                    recruiterId={recruiterId}
                    handleRecruiterChange={handleRecruiterChange(workflow.id)}
                    offerSpecificMode
                    t={t}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className='workflow-button'>
          <Button onClick={addWorkflow}>
            {t('offers.integrationWorkflows.add')}
          </Button>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className='align-left'>
          <Button onClick={onClose} className='dismiss'>
            {t('offers.integrationWorkflows.cancel')}
          </Button>
        </div>
        <Button
          onClick={saveWorkflows}
          disabled={!workflowsAreValid}
          primary
          size='big'
        >
          {creationMode
            ? t('offers.integrationWorkflows.create')
            : t('offers.integrationWorkflows.edit')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default compose(
  withOffer,
  withClientIntegrations,
  withEditOfferGreenhouseWorkflows,
  withEditOfferAshbyWorkflows,
  withEditOfferRecruiteeWorkflows,
  withEditOfferTeamtailorWorkflows,
  withEditOfferWorkableWorkflows,
  withEditOfferSmartrecruitersWorkflows,
  withEditOfferWelcomekitWorkflows,
  withEditOfferLeverWorkflows,
  withTranslation('translations'),
  contextToProps,
)(OfferIntegrationWorkflowsModal);
