import gql from 'graphql-tag';
import { useCallback } from 'react';
import {
  LazyQueryHookOptions,
  LazyQueryResult,
  useLazyQuery,
} from '@apollo/client';

import AttachmentFragment from '@/graphql/fragments/Attachment';
import { Attachment } from '@/common/reveal/SourceData.type';

const getTransformedResume = gql`
  query getTransformedResume(
    $searchPoolId: ID!
    $profileId: ID!
    $input: TransformedResumeInput!
  ) {
    searchPool(id: $searchPoolId) {
      id
      enrichedProfile(id: $profileId) {
        id
        transformedResume(input: $input) {
          ...Attachment
          file {
            content
          }
        }
      }
    }
  }
  ${AttachmentFragment}
`;

type Data = {
  searchPool?: {
    id: string;
    enrichedProfile?: {
      id: string;
      transformedResume?: Attachment;
    };
  };
};

type Variables = {
  searchPoolId: string;
  profileId: string;
  input: {
    transformId?: string;
  };
};

type Input = {
  searchPoolId?: string;
  queryOptions?: LazyQueryHookOptions<Data, Variables>;
};

function useTransformedResume({
  searchPoolId = 'reveal',
  queryOptions = {},
}: Input = {}) {
  const [query, result] = useLazyQuery(getTransformedResume, queryOptions);
  const fetch = useCallback(
    async (profileId: string) => {
      const queryResult = await query({
        variables: {
          searchPoolId,
          profileId,
          input: {},
        },
      });
      return enrichResult(queryResult);
    },
    [query, searchPoolId],
  );
  return [fetch, enrichResult(result)] as const;
}

function enrichResult(result: LazyQueryResult<Data, Variables>) {
  return {
    ...result,
    transformedResume:
      result.data?.searchPool?.enrichedProfile?.transformedResume,
  };
}

export default useTransformedResume;
