export enum DEPARTMENT {
  AGENCY = 'agency',
  CONTINENT = 'continent',
  COUNTRY = 'country',
  REGION = 'region',
  CITY = 'city',
  AREA = 'area',
  OFFICE = 'office',
  SUBSIDIARY = 'subsidiary',
  DEPARTMENT = 'department',
  TEAM = 'team',
  SUB_DEPARTMENT = 'sub-department',
  SUB_SUB_DEPARTMENT = 'sub-sub-department',
  SECTION = 'section',
  SQUAD = 'squad',
  SPECIALITY = 'speciality',
  COMPANY = 'company',
  ORGANIZATION = 'organization',
  UNIT = 'unit',
  DIVISION = 'division',
  BRANCH = 'branch',
  SUB_SECTION = 'sub-section',
  SUB_DIVISION = 'sub-division',
  SECTOR = 'sector',
}
