import React, { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';

import ArrowDown from '@/components/Reveal/Icons/ArrowDown';

import styles from './GenericAccordion.module.less';

interface GenericAccordionProps {
  title: ReactNode;
  className?: string;
  triggerClassName?: string;
}

const GenericAccordion: FC<GenericAccordionProps> = ({
  title,
  className,
  triggerClassName,
  children,
}) => {
  const [showContent, setShowContent] = useState(false);

  return (
    <div className={classNames(styles.accordion, className)}>
      <button
        type='button'
        className={classNames(styles.trigger, triggerClassName)}
        onClick={() => setShowContent(!showContent)}
      >
        <ArrowDown
          className={classNames(styles.arrow, {
            [styles.folded]: !showContent,
          })}
        />
        {title}
      </button>
      {showContent && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default GenericAccordion;
