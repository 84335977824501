import _ from 'underscore';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as Sentry from '@sentry/browser';

import useUserTableViewPreferences, {
  GenericViewType,
} from '@/graphql/hooks/users/useUserDisplayPreferences';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import useClientId from '@/hooks/router/useClientId';
import { getTranslatedText } from '@/common';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';

const DEFAULT_COLUMNS_DISPLAY_VALUES: ColumnDisplay[] = [
  { id: 'project-profiles-view-contact', hidden: false, available: true },
  { id: 'project-profiles-view-email', hidden: true, available: true },
  { id: 'project-profiles-view-relevance', hidden: false, available: true },
  { id: 'project-profiles-view-missions', hidden: false, available: true },
  {
    id: 'project-profiles-view-comment',
    hidden: true,
    available: true,
  },
  { id: 'project-profiles-view-sequence', hidden: false, available: true },
  // { id: 'project-profiles-view-campaign-state', hidden: false, available: true },
  {
    id: 'project-profiles-view-last-interaction',
    hidden: false,
    available: true,
  },
  {
    id: 'project-profiles-view-next-interaction',
    hidden: false,
    available: true,
  },
  {
    id: 'project-profiles-view-last-ats-applications',
    hidden: false,
    available: true,
  },
];

export function getProfilesDefaultColumns(
  defaultHiddenColumns: Partial<Record<ColumnIds, boolean>> = {},
  defaultColumns: ColumnDisplay[] = DEFAULT_COLUMNS_DISPLAY_VALUES,
) {
  return defaultColumns.map((column) => {
    if (defaultHiddenColumns[column.id]) {
      return {
        ...column,
        hidden: defaultHiddenColumns[column.id],
      };
    }
    return { ...column };
  });
}

const COLUMN_NAME_TRANSLATION_KEYS = {
  'project-profiles-view-contact':
    'reveal.missions.mission.tableHeader.contact',
  'project-profiles-view-email': 'reveal.missions.mission.tableHeader.email',
  'project-profiles-view-phone': 'reveal.missions.mission.tableHeader.phone',
  'project-profiles-view-relevance':
    'reveal.missions.mission.tableHeader.relevance',
  'project-profiles-view-missions':
    'reveal.missions.mission.tableHeader.missions',
  'project-profiles-view-sequence':
    'reveal.missions.mission.tableHeader.activeSequence',
  'project-profiles-view-campaign-state':
    'reveal.missions.mission.tableHeader.campaignState',
  'project-profiles-view-last-interaction':
    'reveal.missions.mission.tableHeader.lastInteraction',
  'project-profiles-view-next-interaction':
    'reveal.missions.mission.tableHeader.nextInteraction',
  'project-profiles-view-last-ats-applications':
    'reveal.missions.mission.tableHeader.atsColumnName',
  'task-profiles-view-subtype': 'reveal.missions.mission.tableHeader.category',
  'task-profiles-view-email': 'reveal.missions.mission.tableHeader.email',
  'task-profiles-view-last-interaction':
    'reveal.missions.mission.tableHeader.lastInteraction',
  'company-custom-deal-state': 'reveal.missions.mission.tableHeader.dealState',
  'project-profiles-view-comment':
    'reveal.missions.mission.tableHeader.comment',
};

export type ColumnIds =
  | keyof typeof COLUMN_NAME_TRANSLATION_KEYS
  | Exclude<string, keyof typeof COLUMN_NAME_TRANSLATION_KEYS>;
export type DisplayedColumnMap = Record<ColumnIds, ColumnDisplay>;

export interface ColumnDisplay {
  id: ColumnIds;
  hidden: boolean;
  name?: string;
  targetOrderIndex?: number;
  available: boolean;
  isCustomField?: boolean;
}

export interface ColumnGroup {
  groupId: string;
  title?: string;
  isExpandable?: boolean;
  columns: ColumnDisplay[];
}

interface UseColumnsProps {
  tableId?: string;
  defaultColumns?: ColumnDisplay[];
  defaultValues?: GenericViewType;
  groups?: ColumnGroup[];
  defaultHiddenColumns?: Partial<Record<ColumnIds, boolean>>;
  contactCategory?: 'human' | 'company';
}

// This hook could be refactored to be used by any table view
// by adding some return options (extra params for example) and
// some configuration props (default values mostly)
export default function useProfilesTableColumns({
  tableId = 'profiles-table-view',
  defaultValues,
  defaultColumns = DEFAULT_COLUMNS_DISPLAY_VALUES,
  defaultHiddenColumns = {},
  groups = [],
}: UseColumnsProps = {}) {
  const { t } = useTranslation();
  const { userTableViewPreferences, updateUserTableViewPreferences } =
    useUserTableViewPreferences({
      tableId,
      defaultValues,
    });

  const columnGroups = useMemo(() => {
    const filteredDefaultColumns = getProfilesDefaultColumns(
      defaultHiddenColumns,
      defaultColumns,
    );

    return [
      {
        groupId: 'default',
        title: '',
        columns: filteredDefaultColumns,
      } as ColumnGroup,
      ...groups,
    ];
  }, [groups, defaultColumns, defaultHiddenColumns]);

  const displayedColumns = useMemo(() => {
    const allColumns = _.map(columnGroups, (group) => group.columns).flat();

    return _.reduce(
      allColumns,
      (agg, column) => {
        const isGloballyUnavailable = column.available === false;

        const isHiddenInPreferences = isGloballyUnavailable || _.find(
          userTableViewPreferences?.columnsDisplay?.columns || [],
          (col) => col.id === column.id,
        )?.hidden;

        const availableInPreferences = !isGloballyUnavailable && (
          _.find(
            userTableViewPreferences?.columnsDisplay?.columns || [],
            (col) => col.id === column.id,
          )?.available ?? true);

        return {
          ...agg,
          [column.id]: {
            id: column.id,
            name: column.name || t(COLUMN_NAME_TRANSLATION_KEYS[column.id]),
            hidden: isHiddenInPreferences ?? column.hidden,
            available: availableInPreferences,
            isCustomField: column.isCustomField || false,
          },
        };
      },
      {} as DisplayedColumnMap,
    );
  }, [columnGroups, userTableViewPreferences, t]);

  const displayColumn = useCallback(
    (id: ColumnIds) => {
      if (displayedColumns[id]) {
        return !displayedColumns[id].hidden;
      }

      // If for some reason the column is not found, it is better to hide it
      return false;
    },
    [displayedColumns],
  );

  const updateColumnsDisplay = useCallback(
    (newColumnsDisplay) => {
      const { __typename, ...displayPrefs } = userTableViewPreferences;

      updateUserTableViewPreferences({
        ...displayPrefs,
        columnsDisplay: {
          columns: _.map(newColumnsDisplay, (column) => ({
            id: column.id,
            hidden: column.hidden,
            available: column.available ?? true,
          })),
        },
      }).catch((error) => {
        Sentry.captureException(error);
      });
    },
    [userTableViewPreferences, updateUserTableViewPreferences],
  );

  return {
    columnGroups,
    displayedColumns,
    displayColumn,
    updateColumnsDisplay,
  };
}

export function useProfilesTableColumnsWithCustomFields(args: UseColumnsProps) {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { profileCustomFields } = useClientProfileCustomFields(clientId);
  const atsId = useClientCurrentAtsId();

  const { contactCategory } = args;

  const customFields = useMemo(
    () =>
      _.filter(
        profileCustomFields,
        (field) =>
          (contactCategory === 'human' && !field.contactCategory) ||
          field.contactCategory?.type === contactCategory,
      ),
    [profileCustomFields, contactCategory],
  );

  const customFieldsColumnGroups: ColumnGroup[] = useMemo(() => {
    if (!customFields?.length) {
      return [];
    }

    const customFieldsColumns = _.map(customFields, (customField) => {
      return {
        id: customField.id,
        name: getTranslatedText(customField.title),
        hidden: customField.shouldBeHidden || !(atsId === 'adventure' && ['en-cdii', 'best-kaoutar'].includes(customField.id)),
        available: !customField.shouldBeHidden,
      };
    });

    return [
      {
        groupId: '',
        title: t('reveal.missions.columnSelectorGroups.customFields'),
        columns: customFieldsColumns,
      },
    ];
  }, [t, customFields, atsId]);

  const result = useProfilesTableColumns({
    ...args,
    groups: customFieldsColumnGroups,
  });

  return {
    ...result,
    profileCustomFields: customFields,
  };
}
