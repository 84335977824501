/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import useClientId from '@/hooks/router/useClientId';
import useMissionId from '@/hooks/router/useMissionId';
import {
  MissionWorkflow,
  WORKFLOW_RULE_TYPES,
  WORKFLOW_ACTION_COMMANDS,
} from '@/types/searchPoolJob';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { randomId } from '@/containers/Parameters/Settings/Senders/helpers';
import {
  getJobOptions,
  getStageOptions,
  getUsersOptions,
} from '@/revealComponents/Modals/AddToATSJobModal';
import {
  RECRUITEE,
  BOONDMANAGER,
  WELCOMEKIT,
  ATS_TITLE_AND_LOGO,
} from '@/common/reveal';

import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import useUpdateMissionATS from '@/graphql/hooks/searchPoolJob/workflows/useUpdateLinkedATS';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import useCreateMissionWorkflow from '@/graphql/hooks/searchPoolJob/workflows/useCreateMissionWorkflow';
import useUpdateMissionWorkflow from '@/graphql/hooks/searchPoolJob/workflows/useUpdateMissionWorkflow';
import useDeleteMissionWorkflow from '@/graphql/hooks/searchPoolJob/workflows/useDeleteMissionWorkflow';
import useCreateClientDefaultWorkflow from '@/graphql/hooks/clients/defaultWorkflows/useCreateClientDefaultWorkflow';
import useUpdateClientDefaultWorkflow from '@/graphql/hooks/clients/defaultWorkflows/useUpdateClientDefaultWorkflow';
import useDeleteClientDefaultWorkflow from '@/graphql/hooks/clients/defaultWorkflows/useDeleteClientDefaultWorkflow';
import useClient from '@/graphql/hooks/clients/useClient';
import { getActionType, sanitizeWorkflow } from '../utils';
import styles from '../MissionWorkflows.module.less';

export const INTEGRATION_WITHOUT_JOBS = [BOONDMANAGER];
export const INTEGRATION_WITHOUT_LINKED_USERS = [RECRUITEE, WELCOMEKIT];

interface ContextInterface {
  styles: any;
  loading: boolean;
  connector: any;
  atsJobOptions: any;
  atsUsersOptions: any;
  atsStageOptions: any;
  integrationTitle: string;
  workflows: MissionWorkflow[];
  setWorkflows: any;
  linkedATSJobId: string | null;
  linkedATSUserId: string | null;
  onLinkedJobChange: any;
  onLinkedUserChange: any;
  updateWorkflowState: ({
    workflow,
    objectToUpdate,
    keyToUpdate,
    value,
  }: {
    workflow: MissionWorkflow;
    objectToUpdate: 'rule' | 'action';
    keyToUpdate: string;
    value: string;
  }) => void;
  saveWorkflow: (workflow: MissionWorkflow) => void;
  deleteWorkflow: (id: string) => void;
  addStep: () => void;
  mode: WORKFLOWS_CONTEXT_MODE;
}

export enum WORKFLOWS_CONTEXT_MODE {
  MISSION_WORKFLOWS = 'mission_workflows',
  CLIENT_DEFAULT_WORKFLOWS = 'client_default_workflows',
}

type ContextProps = {
  mode: WORKFLOWS_CONTEXT_MODE;
};

const MissionWorkflowsContext = React.createContext<ContextInterface | null>(
  null,
);

const getSanitizedWorkflows = (
  workflows: MissionWorkflow[],
): MissionWorkflow[] => {
  return _.compact(_.map(workflows, (w) => sanitizeWorkflow(w)));
};

const MissionWorkflowsProvider: React.FC<ContextProps> = ({
  mode,
  children,
}) => {
  const { t } = useTranslation();
  const notification = useNotificationSystem();
  const clientId = useClientId();
  const missionId = useMissionId();

  const { data: clientData } = useClient(clientId);
  const connector = useClientRevealConnector(clientId);

  const { data: jobData, loading: jobLoading } = useSearchPoolJob(
    'reveal',
    missionId,
  );
  const {
    data: atsFilterOptionsData,
    loading: jobOptionsLoading,
  } = useAtsFilterOptions(clientId);

  const [updateMissionATS] = useUpdateMissionATS();
  const [createMissionWorkflowMutation] = useCreateMissionWorkflow();
  const [updateMissionWorkflowMutation] = useUpdateMissionWorkflow();
  const [deleteMissionWorkflowMutation] = useDeleteMissionWorkflow();

  const [
    createClientDefaultWorkflowMutation,
  ] = useCreateClientDefaultWorkflow();
  const [
    updateClientDefaultWorkflowMutation,
  ] = useUpdateClientDefaultWorkflow();
  const [
    deleteClientDefaultWorkflowMutation,
  ] = useDeleteClientDefaultWorkflow();

  const ats = connector?.type || null;
  const mission = jobData?.searchPool?.job;
  const loading = jobLoading || jobOptionsLoading;
  const integrationTitle =
    loading || !ats ? null : (ATS_TITLE_AND_LOGO as any)[ats]?.title;

  const [workflows, setWorkflows] = useState<MissionWorkflow[]>([]);

  useEffect(() => {
    if (
      !_.isEmpty(mission?.workflows) &&
      mode === WORKFLOWS_CONTEXT_MODE.MISSION_WORKFLOWS
    ) {
      setWorkflows(getSanitizedWorkflows(mission.workflows));
    }
    if (
      !_.isEmpty(clientData?.client?.defaultCrmWorkflows) &&
      mode === WORKFLOWS_CONTEXT_MODE.CLIENT_DEFAULT_WORKFLOWS
    ) {
      setWorkflows(
        getSanitizedWorkflows(clientData.client.defaultCrmWorkflows),
      );
    }
  }, [mission, clientData, mode]);

  const linkedATSJobId = useMemo(() => {
    return mission?.linkedATSJobId || null;
  }, [mission]);

  const linkedATSUserId = useMemo(() => {
    return mission?.linkedATSUserId || null;
  }, [mission]);

  const atsJobOptions = getJobOptions({
    atsFilterOptionsData,
  });

  const atsStageOptions = getStageOptions({
    atsFilterOptionsData,
    jobId: linkedATSJobId,
    ats,
  });
  const atsUsersOptions = getUsersOptions({
    atsFilterOptionsData,
  });

  const updateATSValues = async ({
    linkedATSUserId,
    linkedATSJobId,
  }: {
    linkedATSUserId?: string;
    linkedATSJobId?: string;
  }) => {
    try {
      if (mode === WORKFLOWS_CONTEXT_MODE.MISSION_WORKFLOWS) {
        await updateMissionATS({
          variables: {
            searchPoolId: 'reveal',
            input: {
              missionId,
              linkedATSUserId,
              linkedATSJobId,
            },
          },
        });
        notification.success(t('reveal.workflows.missionUpdated'));
      }
    } catch (error) {
      console.error(error);
      notification.error(t('common.errorOccurred'));
    }
  };

  /**
   *
   * Update mission ATS Linked User
   */
  const onLinkedUserChange = async (value: string) => {
    await updateATSValues({ linkedATSUserId: value });
  };

  /**
   *
   * Update mission ATS Linked Job
   */
  const onLinkedJobChange = async (value: string) => {
    await updateATSValues({ linkedATSJobId: value });
  };

  /**
   * Save workflows in DB
   */
  const saveWorkflow = async (workflow: MissionWorkflow) => {
    if (
      !workflow ||
      !workflow.rule?.condition?.revealStageId ||
      !workflow.action?.target?.atsStageId
    ) {
      return;
    }
    try {
      const type = getActionType(workflow.action.command);
      const updatedWorkflow = {
        ..._.omit(workflow, 'warnings'),
        action: {
          ...workflow.action,
          integrationId: connector?.id,
          service: connector?.type,
          type,
          target: {
            ...workflow.action.target,
            ...(type === 'external-service' && {
              linkedATSJobId,
            }),
            ...(type === 'external-service' && {
              linkedATSUserId,
            }),
          },
        },
      };
      if (workflow.id.startsWith('draft')) {
        const sanitized = _.omit(updatedWorkflow, 'isDraft', 'id');
        if (mode === WORKFLOWS_CONTEXT_MODE.MISSION_WORKFLOWS) {
          await createMissionWorkflowMutation({
            variables: {
              searchPoolId: 'reveal',
              input: {
                missionId,
                workflow: sanitized,
              },
            },
          });
        }
        if (mode === WORKFLOWS_CONTEXT_MODE.CLIENT_DEFAULT_WORKFLOWS) {
          await createClientDefaultWorkflowMutation({
            variables: {
              input: {
                workflow: sanitized,
              },
            },
          });
        }
      } else {
        if (mode === WORKFLOWS_CONTEXT_MODE.MISSION_WORKFLOWS) {
          await updateMissionWorkflowMutation({
            variables: {
              searchPoolId: 'reveal',
              input: {
                missionId,
                workflow: updatedWorkflow,
              },
            },
          });
        }
        if (mode === WORKFLOWS_CONTEXT_MODE.CLIENT_DEFAULT_WORKFLOWS) {
          await updateClientDefaultWorkflowMutation({
            variables: {
              input: {
                workflow: updatedWorkflow,
              },
            },
          });
        }
      }
      notification.success(t('reveal.workflows.workflowsSaved'));
    } catch (error) {
      notification.error(t('common.errorOccurred'));
    }
  };

  /**
   * Delete workflow in DB or in state if workflow is draft
   */
  const deleteWorkflow = async (id: string) => {
    try {
      if (!id.startsWith('draft')) {
        if (mode === WORKFLOWS_CONTEXT_MODE.MISSION_WORKFLOWS) {
          await deleteMissionWorkflowMutation({
            variables: {
              searchPoolId: 'reveal',
              input: {
                missionId,
                workflowId: id,
              },
            },
          });
        }
        if (mode === WORKFLOWS_CONTEXT_MODE.CLIENT_DEFAULT_WORKFLOWS) {
          await deleteClientDefaultWorkflowMutation({
            variables: {
              input: {
                workflowId: id,
              },
            },
          });
        }
        notification.success(t('reveal.workflows.workflowDeleted'));
      }
      setWorkflows((prev) => _.filter(prev, (w) => w.id !== id));
    } catch (error) {
      notification.error(t('common.errorOccurred'));
    }
  };

  /**
   * Add workflow in local state
   */
  const addStep = () => {
    setWorkflows((prev: any) => [
      ...prev,
      {
        rule: { type: WORKFLOW_RULE_TYPES.REVEAL_MISSION_UPDATE_PROFILE_STAGE },
        action: { command: WORKFLOW_ACTION_COMMANDS.MOVE_TO_ATS_STAGE },
        isDraft: true,
        id: `draft-${randomId()}`,
      },
    ]);
  };

  /**
   *
   * Update workflow state
   */
  const updateWorkflowState = ({
    workflow,
    objectToUpdate,
    keyToUpdate,
    value,
  }: {
    workflow: MissionWorkflow;
    objectToUpdate: 'rule' | 'action';
    keyToUpdate: string;
    value: string;
  }) => {
    const workflowUpdated = workflow;

    if (objectToUpdate === 'rule') {
      if (keyToUpdate === 'type') {
        workflowUpdated.rule.type = value as WORKFLOW_RULE_TYPES;
      }
      if (keyToUpdate === 'revealStageId') {
        workflowUpdated.rule.condition = { revealStageId: value };
      }
      if (keyToUpdate === 'atsStageId') {
        workflowUpdated.rule.condition = { atsStageId: value };
      }
    }

    if (objectToUpdate === 'action') {
      if (keyToUpdate === 'command') {
        workflowUpdated.action.command = value as WORKFLOW_ACTION_COMMANDS;
      }
      if (keyToUpdate === 'revealStageId') {
        workflowUpdated.action.target = { revealStageId: value };
      }
      if (keyToUpdate === 'atsStageId') {
        workflowUpdated.action.target = { atsStageId: value };
      }
    }

    if (workflow.isDraft && workflow.rule.type && workflow.action.command) {
      workflowUpdated.isDraft = false;
    }

    setWorkflows((prev) =>
      _.map(prev, (w) => {
        if (w.id === workflowUpdated.id) {
          return workflowUpdated;
        }
        return w;
      }),
    );
  };

  return (
    <MissionWorkflowsContext.Provider
      value={{
        loading,
        atsJobOptions,
        atsUsersOptions,
        atsStageOptions,
        integrationTitle,
        connector,
        styles,
        workflows,
        setWorkflows,
        linkedATSJobId,
        linkedATSUserId,
        onLinkedUserChange,
        onLinkedJobChange,
        updateWorkflowState,
        saveWorkflow,
        deleteWorkflow,
        addStep,
        mode,
      }}
    >
      {children}
    </MissionWorkflowsContext.Provider>
  );
};

function useMissionWorkflowsContext() {
  const context = React.useContext(MissionWorkflowsContext);
  if (!context) {
    throw new Error(
      'useMissionWorkflowsContext must be used within a MissionWorkflowsProvider',
    );
  }
  return context;
}

export { MissionWorkflowsProvider, useMissionWorkflowsContext };
