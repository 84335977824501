import SEARCH_POOL_MINI_PROFILE_BY_EMAIL_RESULTS from '@/graphql/searchPoolMiniProfileByEmail';
import { useLazyQuery } from '@apollo/client';

interface useMiniSearchPoolProfileByEmailParams {
  emails: string[];
}

const useMiniSearchPoolProfileByEmail = () =>
  useLazyQuery<any, { input: useMiniSearchPoolProfileByEmailParams, searchPoolId: string }>(SEARCH_POOL_MINI_PROFILE_BY_EMAIL_RESULTS, {
    fetchPolicy: 'no-cache'
  });

export default useMiniSearchPoolProfileByEmail;