import { SearchPoolProfile } from '@/types/searchPoolProfile';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const getCompanyTargetContacts = gql`
  query CompanyTargetContacts($profileId: ID!, $searchPoolId: ID!) {
    searchPool(id: $searchPoolId) {
      profile(id: $profileId) {
        companyTargetContacts {
          id
          resumeData {
            firstname
            lastname
            headline {
              content {
                text
              }
            }
            photoLink
          }
        }
      }
    }
  }
`;

type Data = {
  searchPool: {
    profile: SearchPoolProfile;
  };
};

type Variables = {
  searchPoolId: string;
  profileId: string;
};

type Input = {
  profileId: string;
  searchPoolId?: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

type Result = QueryResult<Data, Variables> & {
  companyTargetContacts: SearchPoolProfile[];
};

function useCompanyTargetContacts({
  profileId,
  searchPoolId = 'reveal',
  queryOptions = {},
}: Input): Result {
  const query = useQuery<Data, Variables>(getCompanyTargetContacts, {
    ...queryOptions,
    variables: {
      profileId,
      searchPoolId,
    },
  });

  const companyTargetContacts =
    query.data?.searchPool?.profile?.companyTargetContacts || [];

  return { ...query, companyTargetContacts };
}

export default useCompanyTargetContacts;
