import React, { useCallback, useMemo } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import _ from 'underscore';

import useIsPlugin from '@/hooks/common/useIsPlugin';
import useDraftSharedTimelineItems from '@/graphql/hooks/searchPoolProfile/useDraftSharedTimelineItems';
import useUpdateSharedActivityDraft from '@/graphql/hooks/searchPoolProfile/useUpdateSharedActivityDraft';
import { getRandomString } from '@/common';
import useSaveSharedActivity from '@/graphql/hooks/searchPoolProfile/useSaveSharedActivity';
import { sanitizeTypename } from '@/common/utils/apollo';

import { coerceSubtype } from './subtypes';
import SharedNoteForm, { FormValue } from './SharedNoteForm';

interface SharedNoteModalProps {
  onClose: () => void;
  profileId: string;
}

const SharedNoteModal: React.FC<SharedNoteModalProps> = ({
  onClose,
  profileId,
}) => {
  const isPlugin = useIsPlugin();
  const { draftSharedTimelineItems, loading } = useDraftSharedTimelineItems({
    profileId,
  });

  const [saveSharedActivity, { loading: isSaving }] = useSaveSharedActivity();

  const { draftId, initialValue } = useMemo(() => {
    if (loading) {
      return {
        draftId: '',
        initialValue: {
          text: '',
          subtype: 'comment' as const,
          missionIds: [],
          companyId: undefined,
          contactIds: [],
          date: 'now' as const,
        },
      };
    }
    const creationDraft = _.find(
      draftSharedTimelineItems,
      (item) => item.draftStatus === 'creation',
    );
    const targets = creationDraft?.targets || [];
    const companyTarget = _.findWhere(targets, { type: 'company' });
    const contactTargets = _.filter(
      targets,
      ({ isMain, type }) => type === 'profile' && !isMain,
    );
    const missionTargets = _.where(targets, { type: 'mission' });
    return {
      draftId: creationDraft?.id || getRandomString(12),
      initialValue: {
        text: creationDraft?.action?.comment?.text || '',
        format: creationDraft?.action?.format || undefined,
        subtype: coerceSubtype(creationDraft?.action?.subtype),
        customActivityId: creationDraft?.action?.customActivityId,
        companyId: companyTarget?.targetId || undefined,
        missionIds: _.pluck(missionTargets, 'targetId'),
        contactIds: _.pluck(contactTargets, 'targetId'),
        date: 'now' as const,
      },
    };
  }, [draftSharedTimelineItems, loading]);

  const [updateSharedActivityDraft] = useUpdateSharedActivityDraft();

  const handleValue = useMemo(
    () =>
      _.debounce((value: FormValue) => {
        updateSharedActivityDraft(getInput({ value, profileId, draftId }));
      }, 2000),
    [updateSharedActivityDraft, draftId, profileId],
  );

  const handleSave = useCallback(
    async (value: FormValue) => {
      handleValue.cancel();
      await saveSharedActivity({
        variables: {
          searchPoolId: 'reveal',
          input: getInput({ value, profileId, draftId }),
        },
      });
      onClose();
    },
    [profileId, draftId, saveSharedActivity, handleValue, onClose],
  );

  return (
    <div className={`new-activity-modal ${isPlugin ? 'plugin' : ''}`}>
      <div className='actions-tab email-tab timeline-default-card-wrapper'>
        {loading ? (
          <Dimmer active>
            <Loader />
          </Dimmer>
        ) : (
          <SharedNoteForm
            profileId={profileId}
            initialValue={initialValue}
            onValue={handleValue}
            onSave={handleSave}
            isSaving={isSaving}
          />
        )}
      </div>
    </div>
  );
};

export function getInput({
  value,
  profileId,
  draftId,
  sharedActivityId,
}: {
  value: FormValue;
  profileId: string;
  draftId: string;
  sharedActivityId?: string;
}) {
  const {
    text,
    companyId,
    contactIds,
    missionIds,
    subtype,
    date,
    format,
    customActivityId,
  } = value;
  const dateValue = date === 'now' ? new Date() : date;
  return {
    profileId,
    draftId,
    ...(sharedActivityId && { referenceId: sharedActivityId }),
    content: {
      type: 'logged-activity',
      subtype,
      customActivityId,
      date: dateValue.toISOString(),
      comment: { text },
      ...(format && { format: sanitizeTypename(format) }),
    },
    targets: [
      {
        type: 'profile',
        targetId: profileId,
        isMain: true,
      },
      ...(companyId ? [{ type: 'company', targetId: companyId }] : []),
      ..._.map(contactIds, (targetId) => ({
        type: 'profile',
        targetId,
      })),
      ..._.map(missionIds, (targetId) => ({
        type: 'mission',
        targetId,
      })),
    ],
  };
}

export default SharedNoteModal;
