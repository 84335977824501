import React, { FC, useContext, useCallback, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useSavedSearches from '@/graphql/hooks/savedSearches/useSavedSearches';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import Counter from '@/components/Common/Counter/Counter';
import useDeleteSavedSearch from '@/graphql/hooks/savedSearches/useDeleteSavedSearch';
import GenericButton from '@/components/Common/GenericButton';
import Delete from '@/components/Reveal/Icons/Delete';

import ProfilesViewSettingsContext from '../../../../ProfilesViewSettingsContext';
import {
  ProfilesViewSettings,
  emptySettings,
  getActiveFilterCount,
} from '../../../../ProfilesViewSettingsContext/helpers';
import { parseCriteria } from './helpers';

import styles from './SavedSearchesHandler.module.less';

type ParsedSavedSearch = {
  title: string;
  settings: ProfilesViewSettings;
};

const SavedSearchesHandler: FC = () => {
  const { missionId } = useParams<{ missionId?: string }>();
  const { t } = useTranslation();
  const [, dispatch] = useContext(ProfilesViewSettingsContext);
  const { savedSearches } = useSavedSearches({
    projectIds: missionId ? [missionId] : [],
  });
  const [deleteSavedSearch] = useDeleteSavedSearch();

  const parsedSearchMap = useMemo(() => {
    const result = {} as Record<string, ParsedSavedSearch>;
    _.each(savedSearches, ({ id, criteria, title }) => {
      const settings = parseCriteria(criteria);
      if (emptySettings(settings)) {
        return;
      }
      result[id] = {
        title,
        settings,
      };
    });
    return result;
  }, [savedSearches]);

  const handleSelect = useCallback(
    (id: string) => {
      const { settings } = parsedSearchMap[id] || {};
      dispatch({
        type: 'replaceAll',
        settings,
      });
    },
    [dispatch, parsedSearchMap],
  );

  if (_.isEmpty(parsedSearchMap)) {
    return null;
  }

  return (
    <div className={styles.handler}>
      <div className={styles.header}>
        <div className={styles.title}>
          {t('reveal.missions.mission.profiles.filter.savedSearches')}
        </div>
      </div>
      {_.map(parsedSearchMap, ({ title, settings }, id) => (
        <DropdownMenuItem
          key={id}
          onClick={() => handleSelect(id)}
          className={styles.option}
        >
          <span className={styles.name}>{title}</span>
          <Counter active value={getActiveFilterCount(settings)} />
          <GenericButton
            isIcon
            primacy='tertiary'
            size='small'
            onClick={async (e) => {
              e.stopPropagation();
              await deleteSavedSearch({ id });
            }}
          >
            <Delete />
          </GenericButton>
        </DropdownMenuItem>
      ))}
    </div>
  );
};

export default SavedSearchesHandler;
