import _ from 'underscore';
import React from 'react';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';

import './ProjectTypesTabs.css';

export enum TabId {
  RECOMMENDATIONS = 'recommendations',
  APPLICATIONS = 'applications',
  IMPORTED = 'imported',
  SEARCH = 'search',
  OTHER_PROJECTS = 'tasks',
  PROFILES = 'profiles',
  WORKFLOWS = 'workflows',
  SETTINGS = 'settings',
  CALIBRATION = 'calibration',
  TASKS = 'mission-tasks',
  SEQUENCES = 'sequences',
  PIPELINE = 'pipeline',
  JOB_POSTING = 'job-posting',
  APPLICANTS = 'applicants',
  DYNAMIC_IMPORTS = 'dynamic-imports',
  POSITION_DESCRIPTION = 'position-description',
}

interface ProjectTypesTabsProps {
  activeTab: TabId;
  applicationsCount?: number;
  recommendationsCount?: number;
  importedCount?: number;
  otherProjectTasksCount?: number;
  hideImported?: boolean;
  displayLeads: boolean;
  displayApplications: boolean;
}

const ProjectTypesTabs: React.FC<ProjectTypesTabsProps> = ({
  activeTab,
  recommendationsCount,
  applicationsCount,
  importedCount,
  otherProjectTasksCount,
  hideImported,
  displayLeads,
  displayApplications,
}) => {
  return (
    <div className='job-tabs'>
      <Tab
        active={activeTab === TabId.RECOMMENDATIONS}
        id={TabId.RECOMMENDATIONS}
        text='Recommended'
        icon='ri-award-line'
        count={recommendationsCount}
      />
      {displayApplications && (
        <Tab
          active={activeTab === TabId.APPLICATIONS}
          id={TabId.APPLICATIONS}
          text='Applications'
          icon='ri-login-box-line'
          count={applicationsCount}
        />
      )}
      {displayLeads && (
        <Tab
          active={activeTab === TabId.SEARCH}
          id={TabId.SEARCH}
          text='Search'
          icon='ri-group-line'
        />
      )}
      {!hideImported && (
        <Tab
          active={activeTab === TabId.IMPORTED}
          id={TabId.IMPORTED}
          text='Imported candidates'
          icon='ri-user-received-2-line'
          count={importedCount}
        />
      )}
      <Tab
        active={activeTab === TabId.OTHER_PROJECTS}
        id={TabId.OTHER_PROJECTS}
        text='Other projects'
        icon='ri-group-line'
        count={otherProjectTasksCount}
      />
    </div>
  );
};

interface TabProps {
  id: TabId;
  active: boolean;
  text: string;
  icon?: string;
  count?: number;
}

const Tab: React.FC<TabProps> = ({ active, id, text, icon, count }) => {
  const { params, path } = useRouteMatch();

  return (
    <Link
      className={`tab${active ? ' active' : ''}`}
      to={generatePath(path, {
        ...params,
        tabId: id,
      })}
      tabIndex={0}
    >
      {icon && <i className={icon} />}
      {text}
      {_.isNumber(count) && <span className='count'>{count}</span>}
    </Link>
  );
};

export default ProjectTypesTabs;
