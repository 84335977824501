import React, { FC, useContext, useMemo, useCallback, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import ChevronRight from '@/components/Reveal/Icons/ChevronRight';
import Dots from '@/components/Common/Icons/Dots';
import GenericButton from '@/components/Common/GenericButton';
import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import CreateSavedSearchSidebar from '@/components/Reveal/SavedSearch/CreateSavedSearchSidebar';

import {
  ProfilesViewSettings,
  getCriteriaFromSettings,
  emptySettings,
} from '../../../ProfilesViewSettingsContext/helpers';
import ProfilesViewSettingsContext from '../../../ProfilesViewSettingsContext';
import { FilterId } from '../helpers/filters';
import SavedSearchesHandler from './SavedSearchesHandler';

import styles from './FilterTypeMenu.module.less';

type FilterTypeMenuProps = {
  options: {
    label: string;
    id: FilterId;
    isActive: (state: ProfilesViewSettings) => boolean;
  }[];
  onSelect: (id: FilterId | undefined) => void;
};

const FilterTypeMenu: FC<FilterTypeMenuProps> = ({ options, onSelect }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useContext(ProfilesViewSettingsContext);
  const [showSaveSidebar, setShowSaveSidebar] = useState(false);

  const dotsOptions = useMemo(() => {
    const isEmpty = emptySettings(state);
    return [
      {
        id: 'clear',
        label: t('reveal.missions.mission.profiles.filter.clear'),
        disabled: isEmpty,
      },
      {
        id: 'save',
        label: t('reveal.missions.mission.profiles.filter.save'),
        disabled: isEmpty,
      },
    ];
  }, [t, state]);

  const onDotOption = useCallback(
    (optionId: string) => {
      if (optionId === 'clear') {
        dispatch({ type: 'replaceAll', settings: { atsTagFilters: {} } });
      }
      if (optionId === 'save') {
        setShowSaveSidebar(true);
      }
    },
    [dispatch],
  );

  return (
    <div className={styles.panel}>
      <div className={styles.header}>
        <span className={styles.title}>
          {t('reveal.missions.mission.profiles.filter.title')}
        </span>
        <GenericDropdown
          Trigger={({ onClick }) => (
            <GenericButton
              size='small'
              isIcon
              onClick={onClick}
              primacy='tertiary'
            >
              <Dots />
            </GenericButton>
          )}
          position='right'
        >
          <DropdownMenuPanel
            options={dotsOptions}
            onSelect={onDotOption}
            className={styles.dotsPanel}
          />
        </GenericDropdown>
      </div>
      {_.map(options, ({ id, label, isActive }) => (
        <button
          type='button'
          key={id}
          onClick={() => onSelect(id)}
          className={styles.option}
        >
          {isActive(state) && <span className={styles.dot} />}
          <span className={styles.label}>{label}</span>
          <span className={styles.chevron}>
            <ChevronRight />
          </span>
        </button>
      ))}
      <SavedSearchesHandler />
      {showSaveSidebar && (
        <CreateSavedSearchSidebar
          criteria={getCriteriaFromSettings(state)}
          close={() => setShowSaveSidebar(false)}
          defaultSharingSettingsType='all-users'
          lockProject
          lockSharingSettings
        />
      )}
    </div>
  );
};

export default FilterTypeMenu;
