import React, { useState } from 'react';
import {
  Input,
  Modal,
  ModalActions as Actions,
  ModalContent as Content,
  ModalHeader as Header,
  Form,
  Select,
} from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import {
  CREATE_CONNECT_FORM,
  UPDATE_CONNECT_FORM,
  GET_CONNECT_FORMS,
} from '@/graphql/connectForms';
import { ConnectForm, ConnectFormField } from '@/types/connectForm';
import { PlusIcon } from '@/assets/icons';
import GenericButton from '@/components/Common/GenericButton';
import { getRandomString } from '@/common';
import ContextMenu from '@/components/Tables/ContextMenu/ContextMenu';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { sanitizeTypename } from '@/common/utils/apollo';
import styles from './ConnectFormEditorModal.module.less';

interface ConnectFormEditorModalProps {
  open: boolean;
  clientId: string;
  connectForm: ConnectForm | null;
  closeModal: () => void;
}

const ConnectFormEditorModal: React.FC<ConnectFormEditorModalProps> = ({
  closeModal,
  open,
  connectForm,
  clientId,
}) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState(
    connectForm || {
      title: {
        default: '',
      },
      fields: [
        {
          title: {
            default: "What's your first name?",
          },
          type: 'firstname',
          id: getRandomString(6),
        } as ConnectFormField,
        {
          title: {
            default: "What's your last name?",
          },
          type: 'lastname',
          id: getRandomString(6),
        } as ConnectFormField,
        {
          title: {
            default: "What's your email?",
          },
          type: 'email',
          id: getRandomString(6),
        } as ConnectFormField,
      ],
    },
  );

  const { profileCustomFields: customFields } = useClientProfileCustomFields(
    clientId,
  );

  const [createConnectForm] = useMutation(CREATE_CONNECT_FORM, {
    refetchQueries: [
      {
        query: GET_CONNECT_FORMS,
        variables: {
          clientId,
        },
      },
    ],
  });

  const [updateConnectForm] = useMutation(UPDATE_CONNECT_FORM);

  const submitConnectForm = async () => {
    const { title, fields } = formState;
    if (connectForm?.id) {
      const form = sanitizeTypename(formState);
      await updateConnectForm({
        variables: {
          input: {
            id: connectForm.id,
            title: form.title,
            fields: form.fields,
          },
        },
      });
    } else {
      await createConnectForm({
        variables: {
          input: {
            title,
            fields: _.map(fields, (field) => ({
              title: field.title,
              type: field.type,
              ...(field.customFieldId && {
                customFieldId: field.customFieldId,
                customFieldType: field.customFieldType,
              }),
            })),
          },
        },
      });
    }
    closeModal();
  };

  const typeOptions = [
    {
      id: 'firstname',
      value: 'firstname',
      text: t('settings.connectSettings.connectForms.questionTypes.firstname'),
    },
    {
      id: 'lastname',
      value: 'lastname',
      text: t('settings.connectSettings.connectForms.questionTypes.lastname'),
    },
    {
      id: 'email',
      value: 'email',
      text: t('settings.connectSettings.connectForms.questionTypes.email'),
    },
    {
      id: 'inline-text',
      value: 'inline-text',
      text: t('settings.connectSettings.connectForms.questionTypes.inlineText'),
    },
    {
      id: 'resume',
      value: 'resume',
      text: t('settings.connectSettings.connectForms.questionTypes.resume'),
    },
    ..._.map(
      _.filter(
        customFields,
        (field) =>
          _.contains(
            ['inline-text', 'text', 'integer', 'enum', 'day'],
            field.type,
          ) && field.contactCategory?.type !== 'company',
      ),
      (customField) => ({
        id: customField.id,
        value: customField.id,
        text: customField.title.default,
      }),
    ),
  ];

  const isSubmitDisabled =
    _.isEmpty(formState.title.default) ||
    !_.isEmpty(
      _.filter(
        formState.fields,
        (field) => _.isEmpty(field.title.default) || _.isEmpty(field.type),
      ),
    );

  const handleTypeChange = ({ type, id }: { type: string; id: string }) => {
    const customField = _.findWhere(customFields, { id: type });
    const newFields = _.map(formState.fields, (field) => ({
      ...field,
      ...(id === field.id && { type }),
      ...(id === field.id &&
        !!customField && {
          customFieldId: customField.id,
          type: customField.id,
          customFieldType: customField?.type,
        }),
    }));
    setFormState({
      ...formState,
      fields: newFields,
    });
  };

  const handleQuestionChange = ({
    value,
    id,
  }: {
    value: string;
    id: string;
  }) => {
    const newFields = _.map(formState.fields, (field) => ({
      ...field,
      ...(id === field.id
        ? {
            title: {
              default: value,
            },
          }
        : {}),
    }));
    setFormState({
      ...formState,
      fields: newFields,
    });
  };

  const onMoveInList = ({
    questionId,
    direction,
  }: {
    questionId: string;
    direction: number;
  }) => {
    const { fields } = formState;
    const questionIndex = _.findIndex(formState.fields, {
      id: questionId,
    });
    [fields[questionIndex], fields[questionIndex + direction]] = [
      fields[questionIndex + direction],
      fields[questionIndex],
    ];
    setFormState({
      ...formState,
      fields,
    });
  };

  const onDelete = ({ questionId }: { questionId: string }) => {
    const questionIndexToRemove = _.findIndex(formState.fields, {
      id: questionId,
    });
    setFormState({
      ...formState,
      fields: [
        ...(formState.fields || []).slice(0, questionIndexToRemove),
        ...(formState.fields || []).slice(questionIndexToRemove + 1),
      ],
    });
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className='new-sender-modal'
      closeIcon
      closeOnDimmerClick
      size='small'
    >
      <Header>
        {connectForm?.id
          ? t('settings.connectSettings.connectForms.editionModal.updateTitle')
          : t('settings.connectSettings.connectForms.editionModal.createTitle')}
      </Header>
      <Content>
        <Form className={styles.fieldsContainer}>
          <Form.Field>
            <div className={styles.fieldLabel}>
              {t('settings.connectSettings.connectForms.title')}
            </div>
            <Input
              value={formState?.title?.default || ''}
              placeholder={t(
                'settings.connectSettings.connectForms.editionModal.titlePlaceholder',
              )}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  title: { default: e.target.value },
                })
              }
            />
            <div className={styles.fieldsSectionContainer}>
              <div className={styles.fieldsTagSection}>
                <span>
                  {t('settings.connectSettings.connectForms.editionModal.type')}
                </span>
                {_.map(formState?.fields, (field) => (
                  <Select
                    key={`type-${field.id}`}
                    placeholder={t(
                      'settings.connectSettings.connectForms.editionModal.typePlaceholder',
                    )}
                    selectOnBlur={false}
                    options={typeOptions}
                    value={field.type}
                    onChange={(_e, data) => {
                      handleTypeChange({
                        type: data.value as string,
                        id: field.id,
                      });
                    }}
                  />
                ))}
              </div>
              <div className={styles.fieldsQuestionSection}>
                <span>
                  {t(
                    'settings.connectSettings.connectForms.editionModal.question',
                  )}
                </span>
                {_.map(formState?.fields, (field) => (
                  <Input
                    fluid
                    key={`question-${field.id}`}
                    placeholder={t(
                      'settings.connectSettings.connectForms.editionModal.questionPlaceholder',
                    )}
                    value={field.title.default}
                    onChange={(_e, data) =>
                      handleQuestionChange({
                        value: data.value as string,
                        id: field.id,
                      })
                    }
                  />
                ))}
              </div>
              <div className={styles.fieldsActionsSection}>
                <span>&nbsp;</span>
                {_.map(formState?.fields, (field, index) => {
                  const actions = [];
                  if (index !== 0) {
                    actions.push({
                      icon: 'ri-arrow-up-line',
                      label: 'Move upward',
                      onClick: () =>
                        onMoveInList({
                          questionId: field.id,
                          direction: -1,
                        }),
                    });
                  }
                  if (index !== formState.fields.length - 1) {
                    actions.push({
                      icon: 'ri-arrow-down-line',
                      label: 'Move downward',
                      onClick: () =>
                        onMoveInList({
                          questionId: field.id,
                          direction: 1,
                        }),
                    });
                  }

                  return (
                    <ContextMenu
                      key={field.id}
                      actions={[
                        ...((formState?.fields?.length > 1 && actions) || []),
                        {
                          icon: 'ri-delete-bin-line',
                          label: t('settings.senders.deleteButton'),
                          onClick: () => onDelete({ questionId: field.id }),
                        },
                      ]}
                    />
                  );
                })}
              </div>
            </div>
            <GenericButton
              primacy='secondary'
              className={styles.newQuestionButton}
              onClick={() =>
                setFormState({
                  ...formState,
                  fields: [
                    ...formState.fields,
                    {
                      title: {
                        default: '',
                      },
                      type: '',
                      id: getRandomString(6),
                    },
                  ],
                })
              }
            >
              <>
                <PlusIcon />
                {t(
                  'settings.connectSettings.connectForms.editionModal.addQuestion',
                )}
              </>
            </GenericButton>
          </Form.Field>
        </Form>
      </Content>
      <Actions className={styles.modalActions}>
        <div className='align-left'>
          <GenericButton primacy='secondary' onClick={closeModal}>
            {t('common.cancel')}
          </GenericButton>
        </div>
        <GenericButton
          disabled={isSubmitDisabled}
          primacy='primary'
          onClick={submitConnectForm}
        >
          {connectForm?.id
            ? t('settings.connectSettings.connectForms.editionModal.update')
            : t('settings.connectSettings.connectForms.editionModal.create')}
        </GenericButton>
      </Actions>
    </Modal>
  );
};

export default ConnectFormEditorModal;
