import React, { useContext, useMemo, useState, VFC } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import GenericSelect from '@/components/Common/GenericSelect';
import GenericButton from '@/components/Common/GenericButton';

import ProfilesViewSettingsContext from '../../../ProfilesViewSettingsContext';

import styles from './ConstructionCardFilterPanel.module.less';

type ConstructionCardFilterPanelProps = {
  onSubmit?: () => void;
};

const ConstructionCardFilterPanel: VFC<
ConstructionCardFilterPanelProps
> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [selectorInput, setSelectorInput] = useState('');
  const [state, dispatch] = useContext(ProfilesViewSettingsContext);
  const [innerValue, setInnerValue] = useState(state.constructionCard);

  const options = useMemo(
    () => [
      {
        value: 'valid' as const,
        label: t('reveal.searchView.filters.constructionCard.valid'),
      },
      {
        value: 'invalid' as const,
        label: t('reveal.searchView.filters.constructionCard.invalid'),
      },
    ],
    [t],
  );

  return (
    <div className={styles.panel}>
      <GenericSelect
        options={options}
        value={_.findWhere(options, { value: innerValue })}
        onInputChange={(value) => setSelectorInput(value)}
        onChange={(newValue) => {
          if (!newValue) {
            setInnerValue(undefined);
          } else {
            setInnerValue(newValue.value);
          }
        }}
        inputValue={selectorInput}
      />
      <GenericButton
        onClick={() => {
          dispatch({
            type: 'setConstructionCard',
            value: innerValue,
          });
          if (onSubmit) {
            onSubmit();
          }
        }}
        className={styles.button}
        size='big'
      >
        {t('reveal.missions.mission.profiles.filter.apply')}
      </GenericButton>
    </div>
  );
};

export default ConstructionCardFilterPanel;
