import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const getTextingSenders = gql`
  query getTextingSenders {
    user {
      id
      textingSenders {
        id
        firstname
        lastname
        senderNumber
        owner {
          email
        }
        users {
          email
        }
      }
    }
  }
`;

export type TextingSender = {
  id: string;
  firstname?: string;
  lastname?: string;
  senderNumber?: string;
  owner?: {
    email: string;
  };
  users?: {
    email: string;
  }[];
};

type GetTextingSendersData = {
  user: {
    __typename: 'User';
    id: string;
    textingSenders: ({
      __typename: 'TextingSender';
    } & TextingSender)[];
  };
};

type GetTextingSendersVariables = Record<string, never>;

type UseTextingSendersInput = {
  queryOptions?: QueryHookOptions<
    GetTextingSendersData,
    GetTextingSendersVariables
  >;
};

interface UseTextingSendersResult
  extends QueryResult<GetTextingSendersData, GetTextingSendersVariables> {
  textingSenders: TextingSender[];
}

function useTextingSenders({
  queryOptions = {},
}: UseTextingSendersInput = {}): UseTextingSendersResult {
  const query = useQuery<GetTextingSendersData, GetTextingSendersVariables>(
    getTextingSenders,
    { ...queryOptions },
  );

  const { textingSenders = [] } = query.data?.user || {};

  return { ...query, textingSenders };
}

export default useTextingSenders;
