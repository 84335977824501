import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { Loader } from 'semantic-ui-react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { isProfileActive } from '@/common/helpers/followup';
import withClientActiveOffers from '../../../hocs/offers/withClientActiveOffers';
import withClientProfilesToFollowup from '../../../hocs/offers/withClientProfilesToFollowup';
import JobsSelector from './JobsSelector';
import FollowupKanban, { KANBAN_STEPS } from './FollowupKanban';

import './WatchCollectFollowup.css';

function getJobProfiles({ cleanProfiles, offerId, showDisabled }) {
  let jobProfiles = [...cleanProfiles];

  if (offerId !== undefined) {
    jobProfiles = _.where(jobProfiles, { jobOfferId: offerId });
  }

  if (!showDisabled) {
    jobProfiles = _.filter(jobProfiles, (profile) => isProfileActive(profile));
  }

  return jobProfiles;
}

const WatchCollectFollowup = ({
  clientId,
  activeOffers,
  activeOffersLoading,
  profilesToFollowup,
}) => {
  // TODO: This is only useful for unclassified candidates (avoids fetching searchPoolProfileState)
  // -> refactor All candidates skip to be a real skip, not a hide
  const [skippedProfileIds, setSkippedProfileIds] = useState();
  const [showDisabled, setShowDisabled] = useState(false);

  const { offerId } = useParams();
  const history = useHistory();

  const setOfferId = (value) =>
    history.push(`/client/${clientId}/discover/followup/${value || ''}`);

  const loading = profilesToFollowup?.loading || activeOffersLoading;
  if (loading) {
    return (
      <div className='watch-collect-followup'>
        <div className='followup-content-container loading'>
          <Loader active inline='centered' size='large' />
        </div>
      </div>
    );
  }

  const cleanProfiles = _.filter(
    profilesToFollowup?.data,
    ({ id, resumeData }) =>
      _.contains(KANBAN_STEPS, resumeData?.step) &&
      !_.contains(skippedProfileIds, id),
  );

  const profilesCountByJobId = {};
  _.each(cleanProfiles, ({ jobOfferId }) => {
    if (jobOfferId in profilesCountByJobId) {
      profilesCountByJobId[jobOfferId] += 1;
    } else {
      profilesCountByJobId[jobOfferId] = 1;
    }
  });

  const jobsWithCount = _.map(activeOffers, (job) => {
    return {
      ...(job || {}),
      profilesCount: profilesCountByJobId[job?.id] || 0,
    };
  });

  const cleanJobs = _.filter(
    jobsWithCount,
    (job) => job.isUnclassified === false,
  );

  if (offerId !== undefined && !_.some(cleanJobs, ({ id }) => id === offerId)) {
    return <Redirect to={`/client/${clientId}/discover/followup/`} />;
  }

  const jobProfiles = getJobProfiles({
    cleanProfiles,
    offerId,
    showDisabled,
  });

  return (
    <>
      <JobsSelector
        jobsWithCount={cleanJobs}
        setJobId={setOfferId}
        jobId={offerId}
        setShowDisabled={setShowDisabled}
      />
      <div className='watch-collect-followup'>
        <div className='followup-content-container'>
          {!loading && (
            <FollowupKanban
              clientId={clientId}
              unclassifiedJobId={
                _.findWhere(activeOffers, { isUnclassified: true })?.id
              }
              setSkippedProfileIds={setSkippedProfileIds}
              profiles={jobProfiles}
              jobs={cleanJobs}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default compose(
  withClientProfilesToFollowup,
  withClientActiveOffers,
)(WatchCollectFollowup);
