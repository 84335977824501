import React from 'react';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';
import sanitizeTimeLineItem from '../../../../common/sanitizeTimeLineItem';

export const getTranslatedDateOrDuration = ({
  plannedExecutionDate,
  trigger,
  t,
}) => {
  try {
    if (plannedExecutionDate) {
      const plannedOn = t('profile.contact.drafts.plannedOn');
      const dateStr = t('common.formattedDate', {
        date: new Date(plannedExecutionDate),
      });
      return `${plannedOn}${dateStr}`;
    }

    if (_.isEmpty(trigger)) {
      console.error('No plannedExecutionDate nor trigger provided');
      return null;
    }

    let translatedDelay;
    if (trigger.type === 'delay-after-action') {
      const { delay } = trigger;
      const { value, unit } = delay;
      if (unit === 'hours') {
        translatedDelay = t('contactFlow.triggers.delays.nbHoursLater', {
          count: value,
        });
      } else if (unit === 'day') {
        translatedDelay = t('contactFlow.triggers.delays.nbDaysLater', {
          count: value,
        });
      } else if (unit === 'week') {
        translatedDelay = t('contactFlow.triggers.delays.nbWeeksLater', {
          count: value,
        });
      } else if (unit === 'working-day') {
        translatedDelay = t('contactFlow.triggers.delays.nbWorkingDaysLater', {
          count: value,
        });
      } else if (unit === 'ms') {
        translatedDelay = delayInMsToReadableString(value, t);
      } else {
        throw new Error('Unsupported Delay Unit');
      }
    }

    if (trigger.type === 'manual-trigger') {
      translatedDelay = null;
    }

    if (trigger.type === 'after-date') {
      return t('contactFlow.triggers.afterDate', {
        date: new Date(trigger?.date),
      });
    }

    return translatedDelay;
  } catch (e) {
    Sentry.captureException(e);
    return '';
  }
};

const delayInMsToReadableString = (ms, t) => {
  if (ms === 0) {
    return t('contactFlow.triggers.delays.immediatelyAfter');
  }
  if (!ms) {
    throw Error('No value provided to delayInMsToReadableString');
  }
  const numberOfDays = Math.floor(ms / (24 * 3600 * 1000));
  const numberOfHours = Math.floor(
    (ms - numberOfDays * 24 * 3600 * 1000) / (3600 * 1000),
  );
  const numberOfMinutes = Math.floor(
    (ms - numberOfDays * 24 * 3600 * 1000 - numberOfHours * 3600 * 1000) /
      (60 * 1000),
  );
  const result = [];
  if (numberOfDays >= 1) {
    result.push(
      ` ${numberOfDays} ${t('contactFlow.triggers.delays.day', {
        count: numberOfDays,
      })}`,
    );
  }
  if (numberOfHours >= 1) {
    result.push(
      ` ${numberOfHours} ${t('contactFlow.triggers.delays.hour', {
        count: numberOfHours,
      })}`,
    );
  }
  if (numberOfMinutes >= 1) {
    result.push(
      ` ${numberOfMinutes} ${t('contactFlow.triggers.delays.minute', {
        count: numberOfMinutes,
      })}`,
    );
  }
  if (result.length === 0) {
    return t('contactFlow.triggers.delays.immediatelyAfter');
  }
  if (result.length > 1) {
    return `${t('contactFlow.triggers.delays.after')} ${_.first(
      result,
      result.length - 1,
    ).join(',')} and${_.last(result)}`;
  }
  return `${t('contactFlow.triggers.delays.after')} ${result[0]}`;
};

export const MessageBody = React.memo(({ content }) => {
  return (
    <div
      className='body-content'
      dangerouslySetInnerHTML={{ __html: sanitizeTimeLineItem(content) }}
    />
  );
});
