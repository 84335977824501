/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Input, Button, Image, Icon } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import { withTranslation } from 'react-i18next';

import GenericButton from '@/components/Common/GenericButton';
import { getTranslatedText } from '../../../../common';
import RichEditor from '../../../../components/RichEditor';
import RichReader from '../../../../components/RichEditor/RichReader';
import TranslatableText from '../../../../components/TranslatableText';
import CustomFileInput from '../../../../components/CustomFileInput';

const JobDescriptionSection = ({
  section,
  editMode,
  setJobDescriptionSectionState,
  deleteJobDescriptionSection,
  index,
  t,
}) => {
  if (section.__typename === 'ImageJobPostingDescriptionSection') {
    const setImageJobPostingDescriptionSectionState = ({ file }) =>
      setJobDescriptionSectionState({
        ...section,
        name: file.name,
        file: {
          content: file.content,
        },
      });
    return (
      <JobPostingImage
        editMode={editMode}
        file={section.file}
        setImageJobPostingDescriptionSectionState={
          setImageJobPostingDescriptionSectionState
        }
        index={index}
        deleteJobDescriptionSection={deleteJobDescriptionSection}
        t={t}
      />
    );
  }

  if (section.__typename === 'VideoJobPostingDescriptionSection') {
    const setVideoJobPostingDescriptionSectionState = ({ url }) =>
      setJobDescriptionSectionState({
        ...section,
        url,
      });
    return (
      <JobPostingVideo
        editMode={editMode}
        url={section.url}
        setVideoJobPostingDescriptionSectionState={
          setVideoJobPostingDescriptionSectionState
        }
        index={index}
        deleteJobDescriptionSection={deleteJobDescriptionSection}
        t={t}
      />
    );
  }

  if (section.__typename !== 'SimpleJobPostingDescriptionSection') {
    return null;
  }

  const setSimpleJobPostingDescriptionSectionState = ({ title, content }) =>
    setJobDescriptionSectionState({
      ...section,
      title,
      content,
    });

  return (
    <SimpleJobPostingDescriptionSection
      index={index}
      title={section?.title}
      content={section?.content}
      editMode={editMode}
      setSimpleJobPostingDescriptionSectionState={
        setSimpleJobPostingDescriptionSectionState
      }
      deleteJobDescriptionSection={deleteJobDescriptionSection}
      t={t}
    />
  );
};

const SimpleJobPostingDescriptionSection = ({
  title,
  content,
  editMode,
  setSimpleJobPostingDescriptionSectionState,
  deleteJobDescriptionSection,
  index,
  t,
}) => {
  if (editMode) {
    const handleChangeTitle = (ev) =>
      setSimpleJobPostingDescriptionSectionState({
        title: {
          ...title,
          default: ev.target.value,
        },
        content,
      });
    const handleChangeContent = (html) =>
      setSimpleJobPostingDescriptionSectionState({
        title,
        content: {
          ...content,
          default: html,
        },
      });
    return (
      <div className='job-posting-description-section'>
        <div className='input-container'>
          <div className='job-posting-description-section-header'>
            <div className='input-label'>
              {t('offers.jobPostings.form.description.sectionTitle')}
            </div>
            <div>
              {editMode && index > 0 && (
                <GenericButton
                  size='small'
                  primacy='secondary'
                  color='red'
                  onClick={deleteJobDescriptionSection}
                >
                  {t('offers.jobPostings.form.description.removeSection')}
                </GenericButton>
              )}
            </div>
          </div>
          <div className='input-element'>
            <Input fluid value={title?.default} onChange={handleChangeTitle} />
          </div>
        </div>
        <div className='input-container'>
          <div className='input-label'>
            {t('offers.jobPostings.form.description.sectionDescription')}
          </div>
          <div className='input-element'>
            <RichEditor
              initialHtml={getTranslatedText(content)}
              onChangeAsHtml={handleChangeContent}
            />
            {/* <TextArea value={content?.default} onChange={handleChangeContent} /> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='job-posting-description-section'>
      <h3>
        <TranslatableText text={title} />
      </h3>
      <div>
        <RichReader initialHtml={getTranslatedText(content)} />
      </div>
    </div>
  );
};

export const JobPostingVideo = ({
  editMode,
  url,
  setVideoJobPostingDescriptionSectionState,
  deleteJobDescriptionSection,
  index,
  t,
  disabled = false,
}) => {
  if (editMode || disabled) {
    const handleChangeURL = (ev) =>
      setVideoJobPostingDescriptionSectionState({
        url: ev.target.value,
      });
    return (
      <div className='job-posting-description-section'>
        <div className='input-container'>
          <div className='job-posting-description-section-header'>
            <div className='input-label'>
              {t('offers.jobPostings.form.description.videoLink')}
            </div>
            <div>
              {editMode && index > 0 && (
                <GenericButton
                  size='small'
                  primacy='secondary'
                  color='red'
                  onClick={deleteJobDescriptionSection}
                  disabled={disabled}
                >
                  {t('offers.jobPostings.form.description.removeVideo')}
                </GenericButton>
              )}
            </div>
          </div>
          <div className='input-description'>
            {t('offers.jobPostings.form.description.videoDescription')}
          </div>
          <div className='input-element'>
            <Input
              fluid
              onChange={handleChangeURL}
              value={url}
              placeholder='https://www.youtube.com/watch?v=YOUR_VIDEO'
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='job-posting-description-section'>
      <div className='video-container'>
        <div className='player-wrapper'>
          <ReactPlayer
            className='react-player'
            url={url}
            width='100%'
            height='100%'
            controls
            config={{
              youtube: {
                playerVars: { showinfo: 1, controls: 1 },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const JobPostingImage = ({
  file: imageFile,
  editMode,
  setImageJobPostingDescriptionSectionState,
  deleteJobDescriptionSection,
  index,
  t,
  disabled = false,
}) => {
  const { url, content } = imageFile;
  const [editingImage, setEditingImage] = useState(!(url || content));

  if (editMode) {
    const onChangeImage = (files) => {
      const file = (files || [])[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (evt) => {
          const result = evt?.target?.result;
          if (result) {
            setImageJobPostingDescriptionSectionState({
              file: {
                name: file.name,
                content: result,
              }
            });
          }
        };
        reader.readAsDataURL(file);
      }
      setEditingImage(false);
    };
    return (
      <div className='job-posting-description-section'>
        <div className='input-container'>
          <div className='job-posting-description-section-header'>
            <div className='input-label'>
              {t('offers.jobPostings.form.description.image')}
              {(url || content) &&
                (editingImage ? (
                  <Icon
                    className='ri-close-line'
                    onClick={() => setEditingImage(false)}
                  />
                ) : (
                  <Icon
                    className='ri-edit-line'
                    onClick={() => setEditingImage(true)}
                  />
                ))}
            </div>
            <div>
              {editMode && index > 0 && (
                <GenericButton
                  size='small'
                  primacy='secondary'
                  color='red'
                  onClick={deleteJobDescriptionSection}
                  disabled={disabled}
                >
                  {t('offers.jobPostings.form.description.removeImage')}
                </GenericButton>
              )}
            </div>
          </div>
          <div className='input-element'>
            {!editingImage ? (
              <div className='image-container'>
                <Image src={url || content} />
              </div>
            ) : (
              <CustomFileInput
                fileTypes='image/*'
                t={t}
                onChange={onChangeImage}
                description={t(
                  'offers.jobPostings.form.description.imageInput',
                )}
                disabled={disabled}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='job-posting-description-section'>
      <div className='image-container'>
        <Image src={url} />
      </div>
    </div>
  );
};

export default withTranslation('translations')(JobDescriptionSection);
