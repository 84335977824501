import React, { VFC } from 'react';
import _ from 'underscore';

import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import ProfileOverview from '@/revealComponents/ProfileOverview';
import AttachmentsSection from '@/containers/Profile/Resume/Attachments/AttachmentsSection';
import { getAllAttachments } from '@/common/helpers/profile';

import LinkedProjects from './LinkedProjects';
import LinkedContacts from './LinkedContacts';

type Props = {
  profileId: string;
};

const CompanyOverview: VFC<Props> = ({ profileId }) => {
  const { profile } = useCandidateViewContext();
  const attachments = getAllAttachments(profile);
  return (
    <>
      <ProfileOverview profile={profile} />
      <hr />
      <LinkedProjects profileId={profileId} />
      <hr style={{ marginTop: '18px' }} />
      <LinkedContacts profileId={profileId} />
      <hr style={{ marginTop: '18px' }} />
      <AttachmentsSection attachments={attachments} profileId={profileId} />
    </>
  );
};

export default CompanyOverview;
