import { getTranslatedText } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import RichEditor from '@/components/RichEditor';
import TranslatableText from '@/components/TranslatableText';
import { createRevealJobPostingMutation } from '@/hocs/offers/withAddJobPosting';
import {
  getCreateJobPostingInput,
  getJobPostingInput,
} from '@/containers/Parameters/OfferJobPostings/helpers';
import { getClientCareerPages } from '@/hocs/clients/withClientCareerPages';
import { useClientDepartments } from '@/hocs/clients/withClientDepartments';
import useClientId from '@/hooks/router/useClientId';
import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment } from 'semantic-ui-react';
import _ from 'underscore';
import GenericTextInput from '@/components/Common/GenericTextInput';
import AlertBox from '@/components/Common/AlertBox';
import { Link } from 'react-router-dom';
import WarningBox from '@/components/Common/WarningBox';
import { updateRevealJobPostingMutation } from '@/hocs/offers/withUpdateJobPosting';
import { revealJobWithJobPostings } from '@/hocs/offers/withOfferJobPostings';
import GenericDropdown from '@/components/Common/GenericDropdown';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import {
  JobPostingImage,
  JobPostingVideo,
} from '@/containers/Parameters/OfferJobPostings/components/JobDescriptionSection';
import '@/containers/Parameters/OfferJobPostings/components/JobPostingDescription.less';
import GenericToggle from '@/components/Common/GenericToggle';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import {
  CustomRadio,
  CustomSelect,
  SalaryRange,
} from '@/containers/Parameters/OfferCriteria/CustomFormItems';
import '@/containers/Parameters/OfferCriteria/PureCriteriaForm.css';
import useClientCriteriaOptions from '@/graphql/hooks/clients/useClientCriteriaOptions';
import { stripHtmlFromTextAndLinesBreaks } from '@/common/utils/string';
import useRepublishJobPosting from '@/graphql/hooks/searchPoolJob/useRepublishProjectJobPosting';
import styles from './JobPosting.module.less';
import JobPostingCreationModal from './JobPostingCreationModal';

type JobPostingType = {
  title: {
    default: string;
    fr?: string;
    en?: string;
  };
  description: {
    type: string;
    sections: JobPostingDescriptionSection[];
    __typename?: string;
  };
  locations?: {
    identifier: string;
    label: {
      default: string;
    };
  }[];
  salary?: {
    min?: number;
    max?: number;
    currency: string;
    type: string;
    period?: string;
    freeText?: string;
  };
  remote?: {
    identifier: string;
    label: {
      default: string;
    };
  };
  contractType?: {
    identifier: string;
    label: {
      default: string;
    };
  };
  foldering?: any;
  application?: {
    form: {
      type: string;
      questionsForm: {
        type: string;
        templateId?: string;
      };
      __typename?: string;
    };
  };
  published: boolean;
};

type JobPostingDescriptionSection = {
  type: string;
  title?: {
    default: string;
    fr?: string;
    en?: string;
  };
  content?: {
    default: string;
    fr?: string;
    en?: string;
  };
  file?: {
    content: string;
    url?: string;
    name?: string;
  };
  url?: string;
  name?: string;
  __typename?: string;
};

interface RevealJobPostingProps {
  jobId: string;
}

export const DEFAULT_JOB_POSTING: JobPostingType = {
  title: { default: '' || '', fr: '', en: '' },
  description: {
    type: 'multi-sections',
    sections: [
      {
        type: 'simple-section',
        title: { default: '' },
        content: { default: '' },
        __typename: 'SimpleJobPostingDescriptionSection',
      },
    ],
    __typename: 'MultiSectionsJobPostingDescription',
  },
  locations: [
    {
      identifier: 'paris',
      label: {
        default: 'Paris',
      },
    },
  ],
  salary: {
    min: 20000,
    max: 40000,
    currency: 'EUR',
    type: 'period-range',
    period: 'year',
  },
  remote: {
    identifier: 'full-time',
    label: {
      default: 'Télétravail complet accepté',
    },
  },
  contractType: {
    identifier: 'cdi',
    label: {
      default: 'CDI',
    },
  },
  foldering: {},
  application: {
    form: {
      type: 'contact-and-questions',
      __typename: 'ContactAndQuestionsJobPostingApplicationForm',
      questionsForm: {
        type: 'template',
        templateId: undefined,
      },
    },
  },
  published: false,
};

const RevealJobPosting: React.FC<RevealJobPostingProps> = ({ jobId }) => {
  const clientId = useClientId();
  const { data: criteriaOptionsData, loading: criteriaOptionsLoading } =
    useClientCriteriaOptions(clientId);
  const { data: jobData, loading: jobLoading } = useQuery(
    revealJobWithJobPostings,
    {
      variables: { searchPoolId: 'reveal', jobId },
    },
  );

  const { data: careerPageData, loading: careerPageLoading } = useQuery(
    getClientCareerPages,
    {
      variables: {
        id: clientId,
      },
    },
  );

  const job = jobData?.searchPool?.job;

  const options = useMemo(() => {
    const res: Record<string, { value: string; label: string }[]> = {};
    if (criteriaOptionsLoading) {
      return res;
    }
    const rawOptionMap = criteriaOptionsData.client.criteriaOptions;
    _.each(_.keys(rawOptionMap), (key) => {
      res[key] = _.map(rawOptionMap[key], (option) => ({
        value: option.identifier,
        label: getTranslatedText(option.label),
      }));
    });
    return res;
  }, [criteriaOptionsData, criteriaOptionsLoading]);

  const jobPosting = job?.jobPostings?.[0];
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showJobPostingForm, setShowJobPostingForm] = useState(false);
  const [jobPostingState, setJobPostingState] = useState<JobPostingType>(
    jobPosting || DEFAULT_JOB_POSTING,
  );

  useEffect(() => {
    if (jobPosting) {
      setJobPostingState(jobPosting);
    }
  }, [jobPosting]);

  const isJobPostingIncomplete = useMemo(() => {
    if (!getTranslatedText(jobPostingState?.title)) {
      return true;
    }
    const sections = jobPostingState?.description?.sections;
    if (_.isEmpty(sections)) {
      return true;
    }
    const incompleteSections = _.filter(sections, (section) => {
      if (section.type === 'image-section') {
        return !section.file?.content && !section.file?.url;
      }
      if (section.type === 'video-section') {
        return !section.url;
      }
      const title = getTranslatedText(section.title);
      if (!title) {
        return true;
      }
      const content = getTranslatedText(section.content);
      const sanitizedContent = stripHtmlFromTextAndLinesBreaks(
        content,
      ).replaceAll('\n', '');
      if (!sanitizedContent) {
        return true;
      }
      return false;
    });
    if (!_.isEmpty(incompleteSections)) {
      return true;
    }
    return false;
  }, [jobPostingState]);

  const foldering = jobData?.searchPool?.job?.foldering;

  const departments = useClientDepartments(clientId);

  const folderingDepartment = _.findWhere(departments, {
    id: foldering?.department?.id,
  });

  const folderingSection = _.findWhere(folderingDepartment?.sections || [], {
    id: foldering?.section?.id,
  });

  const folderingSubsection = _.findWhere(folderingSection?.subsections || [], {
    id: foldering?.subsection?.id,
  });

  const [createJobPosting] = useMutation(createRevealJobPostingMutation);
  const [updateJobPosting] = useMutation(updateRevealJobPostingMutation);
  const [republishJobPosting] = useRepublishJobPosting();

  const { t } = useTranslation();
  const published = jobPosting?.published;

  const handleChangeTitle = useCallback((title: string) => {
    setJobPostingState((current) => ({
      ...current,
      title: {
        default: title,
        fr: '',
        en: '',
      },
    }));
  }, []);

  const handleChangeContent = useCallback(
    (sectionIndex: number, content: string) => {
      setJobPostingState((current) => ({
        ...current,
        description: {
          ...current.description,
          sections: _.map(current.description.sections, (section, index) => {
            if (index !== sectionIndex) {
              return section;
            }
            return {
              ...section,
              content: {
                default: content,
              },
            };
          }),
        },
      }));
    },
    [],
  );

  const handleChangeSectionTitle = useCallback(
    (sectionIndex: number, title: string) => {
      setJobPostingState((current) => ({
        ...current,
        description: {
          ...current.description,
          sections: _.map(current.description.sections, (section, index) => {
            if (index !== sectionIndex) {
              return section;
            }
            return {
              ...section,
              title: {
                default: title,
              },
            };
          }),
        },
      }));
    },
    [],
  );

  const handleRemoveSection = useCallback((index: number) => {
    setJobPostingState((current) => ({
      ...current,
      description: {
        ...current.description,
        sections: [
          ...current.description.sections.slice(0, index),
          ...current.description.sections.slice(index + 1),
        ],
      },
    }));
  }, []);

  const handlePublishToggle = useCallback(async () => {
    setJobPostingState((current) => ({
      ...current,
      published: !current.published,
    }));
  }, []);

  const onSave = useCallback(async () => {
    if (!jobPosting) {
      const input = getCreateJobPostingInput({
        jobPostingState,
        careerPageId: careerPageData?.client?.careerPages?.[0]?.id,
        jobOfferId: jobId,
      });
      await createJobPosting({
        variables: {
          input,
          searchPoolId: 'reveal',
        },
      });
    } else {
      const input = getJobPostingInput({ jobPostingState });
      await updateJobPosting({
        variables: {
          input,
          searchPoolId: 'reveal',
        },
      });
    }
  }, [
    careerPageData,
    jobId,
    jobPostingState,
    createJobPosting,
    updateJobPosting,
    jobPosting,
  ]);

  const addJobDescriptionSection = useCallback((type: string) => {
    let newSection: JobPostingDescriptionSection;
    if (type === 'simple-section') {
      newSection = {
        __typename: 'SimpleJobPostingDescriptionSection',
        type: 'simple-section',
        title: { default: '', fr: '', en: '' },
        content: { default: '', fr: '', en: '' },
      };
    }
    if (type === 'video-section') {
      newSection = {
        __typename: 'VideoJobPostingDescriptionSection',
        type: 'video-section',
        url: '',
      };
    }
    if (type === 'image-section') {
      newSection = {
        __typename: 'ImageJobPostingDescriptionSection',
        type: 'image-section',
        name: '',
        file: {
          content: '',
        },
      };
    }
    setJobPostingState((current) => ({
      ...current,
      description: {
        ...current?.description,
        sections: [...(current?.description?.sections || []), newSection],
      },
    }));
  }, []);

  const udpateJobPostingSectionAtIndex = useCallback(
    (index: number, section: any) => {
      const newSection = {
        ...jobPostingState.description.sections[index],
        ..._.omit(section, 'file'),
        ...(section.file && {
          name: section.file.name,
          file: {
            content: section.file.content,
          },
        }),
      };
      setJobPostingState((current) => ({
        ...current,
        description: {
          ...current.description,
          sections: [
            ...current.description.sections.slice(0, index),
            newSection,
            ...current.description.sections.slice(index + 1),
          ],
        },
      }));
    },
    [jobPostingState],
  );

  const handleSalaryChange = useCallback(
    (salary: {
      label: 'salary';
      value: {
        value: {
          min: number;
          max: number;
          currency: string;
        };
      };
      error?: boolean | null;
    }) => {
      setJobPostingState((current) => ({
        ...current,
        salary: {
          ...current.salary,
          min: salary.value.value.min,
          max: salary.value.value.max,
          currency: salary.value.value.currency,
        },
      }));
    },
    [],
  );

  const handleLocationChange = useCallback((location: string) => {
    setJobPostingState((current) => ({
      ...current,
      locations: [
        {
          identifier: location.replaceAll(' ', '_'),
          label: {
            default: location,
          },
        },
      ],
    }));
  }, []);

  const handleRemoteChange = useCallback(
    (remote: {
      label: 'remote';
      value: {
        value: string;
        label: string;
      };
      error?: boolean | null;
    }) => {
      setJobPostingState((current) => ({
        ...current,
        remote: {
          identifier: remote.value.value,
          label: {
            default: remote.value.label,
          },
        },
      }));
    },
    [],
  );

  const handleChangeContractType = useCallback(
    (contractType: {
      label: 'contractType';
      value: {
        value: string;
        label: string;
      };
      error?: boolean | null;
    }) => {
      setJobPostingState((current) => ({
        ...current,
        contractType: {
          identifier: contractType.value.value,
          label: {
            default: contractType.value.label,
          },
        },
      }));
    },
    [],
  );

  const handleChangeSalaryType = useCallback(
    (salaryType: {
      label: 'salary-type';
      value: {
        value: string;
        label: string;
      };
      error?: boolean | null;
    }) => {
      setJobPostingState((current) => ({
        ...current,
        salary: {
          ...current.salary,
          type:
            salaryType?.value?.value === 'free-text'
              ? 'free-text'
              : 'period-range',
          ...(salaryType?.value?.value === 'free-text'
            ? {
                freeText: '',
                period: undefined,
                min: undefined,
                max: undefined,
              }
            : {
                period: salaryType?.value?.value,
                min: current.salary?.min,
                max: current.salary?.max,
                freeText: '',
              }),
        },
      }));
    },
    [],
  );

  const handleRepublish = useCallback(() => {
    if (!jobPosting) {
      return;
    }
    republishJobPosting({
      input: {
        id: jobPosting?.id || '',
      },
    });
  }, [jobPosting, republishJobPosting]);

  const careerPage = useMemo(() => {
    return careerPageData?.client?.careerPages?.[0];
  }, [careerPageData]);

  if (careerPageLoading || jobLoading) {
    return null;
  }

  const salaryTypeOptions = [
    {
      value: 'year',
      label: 'Annuel',
    },
    {
      value: 'month',
      label: 'Mensuel',
    },
    {
      value: 'week',
      label: 'Hebdomadaire',
    },
    {
      value: 'day',
      label: 'Journalier',
    },
    {
      value: 'hour',
      label: 'Horaire',
    },
    {
      value: 'free-text',
      label: 'Libre',
    },
  ];

  if (!jobPosting && !showJobPostingForm) {
    return (
      <Segment className='pure-criteria-form'>
        <div className='job-calibration-header'>
          <h2>{t('reveal.missions.mission.settingsTab.jobPosting.title')}</h2>
        </div>
        <p>{t('reveal.missions.mission.settingsTab.jobPosting.emptyState')}</p>
        <GenericButton onClick={() => setShowCreationModal(true)}>
          {t('reveal.missions.mission.settingsTab.jobPosting.createJobPosting')}
        </GenericButton>
        <JobPostingCreationModal
          open={showCreationModal}
          onSelectTemplate={(template) => {
            setJobPostingState(_.omit(template, ['id']));
            setShowCreationModal(false);
            setShowJobPostingForm(true);
          }}
          onClose={() => setShowCreationModal(false)}
        />
      </Segment>
    );
  }

  return (
    <Segment className='pure-criteria-form'>
      <div className='job-calibration-header'>
        <h2>{t('reveal.missions.mission.settingsTab.jobPosting.title')}</h2>
        <div className={styles.publishToggleContainer}>
          <GenericButton primacy='secondary' onClick={() => handleRepublish()}>
            {t('reveal.missions.mission.settingsTab.jobPosting.republish')}
          </GenericButton>
          {t('offers.jobPostings.display.published')}
          <GenericToggle
            isChecked={jobPostingState.published}
            size='large'
            onChange={handlePublishToggle}
            className={styles.publishToggle}
            name={t('offers.jobPostings.display.published') as string}
          />
        </div>
      </div>
      {!careerPage && (
        <AlertBox description={t('offers.jobPostings.infos.noCareerPage1')}>
          {t('offers.jobPostings.infos.noCareerPage2')}{' '}
          <Link to={`/client/${clientId}/careerPage`}>
            {t('offers.jobPostings.infos.noCareerPageLink')}
          </Link>{' '}
          {t('offers.jobPostings.infos.noCareerPage3')}
        </AlertBox>
      )}
      {careerPage && !careerPage?.enabled && (
        <WarningBox description={t('offers.jobPostings.infos.notPublished1')}>
          {t('offers.jobPostings.infos.notPublished2')}{' '}
          <Link to={`/client/${clientId}/careerPage`}>
            {t('offers.jobPostings.infos.notPublishedLink')}
          </Link>{' '}
          {t('offers.jobPostings.infos.notPublished3')}
        </WarningBox>
      )}
      {jobPosting?.title && (
        <div className={styles.displayHeader}>
          <div className={styles.displayHeaderInfo}>
            {jobPosting && (
              <div className={styles.headerAndStatus}>
                <h2 className={styles.header}>
                  <TranslatableText text={jobPosting.title} />
                </h2>
                <div>
                  <span
                    className={classNames(
                      styles.publicationStatus,
                      published ? styles.published : styles.unpublished,
                    )}
                  >
                    {published ? (
                      <i className='ri-checkbox-blank-circle-fill' />
                    ) : (
                      <i className='ri-checkbox-blank-circle-line' />
                    )}
                    {published
                      ? t('offers.jobPostings.display.published')
                      : t('offers.jobPostings.display.notPublished')}
                  </span>
                </div>
              </div>
            )}

            <div className={styles.folderingDisplay}>
              {folderingDepartment && <span>{folderingDepartment.title}</span>}
              {folderingSection && <span> / {folderingSection.title}</span>}
              {folderingSubsection && (
                <span> / {folderingSubsection.title}</span>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('offers.jobPostings.form.title')}
        </div>
        <div className={styles.inputElement}>
          <GenericTextInput
            fluid
            placeholder='Title'
            value={getTranslatedText(jobPostingState?.title)}
            onChange={({ target }) => handleChangeTitle(target.value)}
          />
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('offers.jobPostings.form.salaryRange')}
        </div>
        <div className={classNames(styles.inputElement, styles.inline)}>
          {jobPostingState.salary?.type === 'free-text' ? (
            <GenericTextInput
              className='free-salary-input'
              placeholder='Salaire'
              value={jobPostingState.salary?.freeText}
              onChange={({ target }) =>
                setJobPostingState({
                  ...jobPostingState,
                  salary: {
                    ...jobPostingState.salary,
                    freeText: target.value,
                  },
                })
              }
            />
          ) : (
            <SalaryRange
              label='salary'
              currentValue={jobPostingState.salary}
              onChange={handleSalaryChange}
              showUnit={jobPostingState?.salary?.period === 'year'}
            />
          )}
          <div className='salary-type'>
            <CustomSelect
              label='salary-type'
              value={
                jobPostingState?.salary?.type === 'free-text'
                  ? {
                      value: 'free-text',
                      label: 'Libre',
                    }
                  : {
                      value: jobPostingState?.salary?.period || 'year',
                      label:
                        _.findWhere(salaryTypeOptions, {
                          value: jobPostingState.salary?.period,
                        })?.label || 'Annuel',
                    }
              }
              options={salaryTypeOptions}
              onChange={handleChangeSalaryType}
            />
          </div>
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('offers.jobPostings.form.locations')}
        </div>
        <GenericTextInput
          fluid
          placeholder={t('criteria.locationsPlaceholder')}
          value={_.map(jobPostingState?.locations || [], (location) =>
            getTranslatedText(location?.label),
          ).join('; ')}
          onChange={(e) => handleLocationChange(e.target.value)}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('offers.jobPostings.form.remote')}
        </div>
        <CustomRadio
          label='remote'
          currentValue={{
            value: jobPostingState?.remote?.identifier,
          }}
          options={(options || {}).remote}
          onChange={handleRemoteChange}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('offers.jobPostings.form.contractType')}
        </div>
        <CustomSelect
          label='contractType'
          value={{
            value: jobPostingState?.contractType?.identifier,
            label: getTranslatedText(jobPostingState?.contractType?.label),
          }}
          options={[
            {
              value: 'cdi',
              label: 'CDI',
            },
            {
              value: 'cdd',
              label: 'CDD',
            },
            {
              value: 'freelance',
              label: 'Freelance',
            },
            {
              value: 'internship',
              label: 'Stage',
            },
            {
              value: 'part-time',
              label: 'Alternance',
            },
            {
              value: 'interim',
              label: 'Intérim',
            },
          ]}
          onChange={handleChangeContractType}
        />
      </div>
      <div className='job-posting-description'>
        {_.map(
          jobPostingState?.description?.sections || [],
          (section, index) => (
            <div
              className={styles.sectionsContainer}
              key={`job-posting-${index}`}
            >
              {section.type === 'simple-section' && (
                <>
                  <div className={styles.simpleSectionHeader}>
                    <h4>Section {index + 1}</h4>
                    {index > 0 && (
                      <GenericButton
                        size='small'
                        primacy='secondary'
                        color='red'
                        onClick={() => handleRemoveSection(index)}
                      >
                        {t('offers.jobPostings.form.description.removeSection')}
                      </GenericButton>
                    )}
                  </div>
                  <div className={styles.inputContainer} key={`title-${index}`}>
                    <div className={styles.inputElement}>
                      <GenericTextInput
                        fluid
                        placeholder='Section title'
                        value={getTranslatedText(section?.title)}
                        onChange={({ target }) =>
                          handleChangeSectionTitle(index, target.value)
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={styles.inputContainer}
                    key={`description-${index}`}
                  >
                    <div
                      className={styles.inputElement}
                      style={{ position: 'relative', zIndex: 0 }}
                    >
                      <RichEditor
                        smallControls
                        initialHtml={getTranslatedText(section.content)}
                        onChangeAsHtml={(value: any) =>
                          handleChangeContent(index, value)
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              {section.type === 'image-section' && (
                <JobPostingImage
                  editMode
                  file={section.file}
                  setImageJobPostingDescriptionSectionState={(
                    newSection: any,
                  ) => udpateJobPostingSectionAtIndex(index, newSection)}
                  index={index}
                  deleteJobDescriptionSection={() => handleRemoveSection(index)}
                  t={t}
                />
              )}
              {section.type === 'video-section' && (
                <JobPostingVideo
                  editMode
                  url={section.url}
                  setVideoJobPostingDescriptionSectionState={(
                    newSection: any,
                  ) => udpateJobPostingSectionAtIndex(index, newSection)}
                  index={index}
                  deleteJobDescriptionSection={() => handleRemoveSection(index)}
                  t={t}
                />
              )}
            </div>
          ),
        )}
      </div>

      <div className={styles.hsFlexSpacer} />
      <GenericDropdown
        trigger={
          <DropdownControlsContext.Consumer>
            {({ toggleDropdown }) => {
              return (
                <GenericButton
                  primacy='secondary'
                  type='button'
                  onClick={toggleDropdown}
                  className={styles.dropdownTrigger}
                >
                  <span className={styles.title}>
                    {t('offers.jobPostings.form.description.addSection')}
                  </span>
                  <span className={styles.arrow}>
                    <ArrowDown />
                  </span>
                </GenericButton>
              );
            }}
          </DropdownControlsContext.Consumer>
        }
      >
        <DropdownMenuPanel
          options={[
            {
              id: 'simple-section',
              label: t('offers.jobPostings.form.description.addSection'),
            },
            {
              id: 'image-section',
              label: t('offers.jobPostings.form.description.addImage'),
            },
            {
              id: 'video-section',
              label: t('offers.jobPostings.form.description.addVideo'),
            },
          ]}
          onSelect={(id) => addJobDescriptionSection(id)}
        />
      </GenericDropdown>
      <div className={styles.footer}>
        <GenericButton
          onClick={() => {
            setShowJobPostingForm(false);
            setJobPostingState(DEFAULT_JOB_POSTING);
          }}
          color='grey'
          primacy='secondary'
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          onClick={onSave}
          disabled={!careerPage || isJobPostingIncomplete}
        >
          {t('common.save')}
        </GenericButton>
      </div>
    </Segment>
  );
};

export default RevealJobPosting;
