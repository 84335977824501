import _ from 'underscore';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';

import SortingDropdown from '@/components/SortingDropdown';
import { getTranslatedText } from '@/common';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import {
  ColumnDisplay,
  ColumnGroup,
} from '@/routes/RevealView/JobsView/JobView/ProjectProfilesTab/columns/useProfilesTableColumns';
import TableColumnSelector from '@/routes/RevealView/JobsView/TableColumnSelector';
import { useMissionATSMetadataColumns } from '@/routes/RevealView/JobsView/ATSMetadata/columns';
import { ColumnRenderer } from '@/routes/RevealView/JobsView/ATSMetadata/types';

import { GenericViewType } from '@/graphql/hooks/users/useUserDisplayPreferences';
import { ProjectRowColumn } from '../ProjectRow/types';

import styles from './ProjectHeader.module.less';

export const COLUMN_NAME_TRANSLATION_PATH_FROM_ID = {
  'jobs-view-contacts': 'reveal.missions.contacts',
  'jobs-view-recommendations': 'reveal.missions.recommandation',
  'jobs-view-task': 'reveal.missions.tasks',
  'jobs-view-pending': 'reveal.pipelineSegmentations.pending',
  'jobs-view-interested': 'reveal.pipelineSegmentations.interested',
  'jobs-view-owner-and-date': 'reveal.missions.ownerAndDate',
  'jobs-view-contacted': 'reveal.pipelineSegmentations.in-progress',
  'jobs-view-replied': 'reveal.pipelineSegmentations.replied',
  'jobs-view-hired': 'reveal.pipelineSegmentations.hired',
  'jobs-view-archived': 'reveal.pipelineSegmentations.archived',
  'jobs-view-company': 'reveal.missions.mission.settingsTab.company',
};

interface ProjectsHeaderProps {
  columnsDisplay: ProjectRowColumn[];
  missionCustomFields: CustomFieldDefinition[];
  displayableColumnGroups: ColumnGroup[];
  onUpdateColumnsToDisplay: (columnsDisplay: ColumnDisplay[]) => void;
  columnSorting?: boolean;
  displayPrefs: GenericViewType;
  updateDisplayPrefs: (prefs: any) => void;
  showColumnsSelector?: boolean;
}

export const ProjectsHeader = ({
  columnsDisplay,
  missionCustomFields,
  displayableColumnGroups,
  onUpdateColumnsToDisplay,
  columnSorting,
  displayPrefs,
  updateDisplayPrefs,
  showColumnsSelector = false,
}: ProjectsHeaderProps) => {
  const { t } = useTranslation();
  const { headerRenderers: atsHeaderRenderers } = useMissionATSMetadataColumns({
    job: null,
    stats: null,
  });

  const missionCustomFieldsById = useMemo(
    () => _.indexBy(missionCustomFields, 'id'),
    [missionCustomFields],
  );

  const onUpdateSortBy = useCallback(
    ({
      selectorId,
      order,
    }: {
      selectorId: string;
      order: 'ascending' | 'descending';
    }) => {
      const sortOptionIndex = _.findIndex(
        displayPrefs?.sortingOptions?.sortBy || [],
        (sortingOption) => sortingOption.key === selectorId,
      );
      const hasSameOrder =
        displayPrefs?.sortingOptions?.sortBy?.[sortOptionIndex]?.order ===
        order;

      if (hasSameOrder) {
        updateDisplayPrefs({
          tableId: 'jobs-view',
          sortingOptions: {
            sortBy: [],
          },
        });
        return;
      }
      updateDisplayPrefs({
        tableId: 'jobs-view',
        sortingOptions: {
          sortBy: [
            {
              key: selectorId,
              order,
            },
          ],
        },
      });
    },
    [displayPrefs, updateDisplayPrefs],
  );

  const COLUMN_RENDERERS = useMemo(() => {
    return {
      'jobs-view-contacts': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-contacts']}`,
                  )}
                  selectorId='contacts'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'contacts',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(`${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-contacts']}`)
            )}
          </Table.HeaderCell>
        );
      },
      'jobs-view-recommendations': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-recommendations']}`,
                  )}
                  selectorId='recommendations'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'recommendations',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(
                `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-recommendations']}`,
              )
            )}
          </Table.HeaderCell>
        );
      },
      'jobs-view-task': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-task']}`,
                  )}
                  selectorId='tasks'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'tasks',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(`${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-task']}`)
            )}
          </Table.HeaderCell>
        );
      },
      'jobs-view-pending': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-pending']}`,
                  )}
                  selectorId='pending'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'pending',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(`${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-pending']}`)
            )}
          </Table.HeaderCell>
        );
      },
      'jobs-view-contacted': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-contacted']}`,
                  )}
                  selectorId='contacted'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'contacted',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(
                `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-contacted']}`,
              )
            )}
          </Table.HeaderCell>
        );
      },
      'jobs-view-replied': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-replied']}`,
                  )}
                  selectorId='replied'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'replied',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(`${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-replied']}`)
            )}
          </Table.HeaderCell>
        );
      },
      'jobs-view-interested': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-interested']}`,
                  )}
                  selectorId='interested'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'interested',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(
                `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-interested']}`,
              )
            )}
          </Table.HeaderCell>
        );
      },
      'jobs-view-hired': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-hired']}`,
                  )}
                  selectorId='hired'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'hired',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(`${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-hired']}`)
            )}
          </Table.HeaderCell>
        );
      },
      'jobs-view-archived': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-archived']}`,
                  )}
                  selectorId='archived'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'archived',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(`${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-archived']}`)
            )}
          </Table.HeaderCell>
        );
      },
      'jobs-view-owner-and-date': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-owner-and-date']}`,
                  )}
                  selectorId='owner'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'owner',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(
                `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-owner-and-date']}`,
              )
            )}
          </Table.HeaderCell>
        );
      },
      'jobs-view-company': (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t(
                    `${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-company']}`,
                  )}
                  selectorId='company'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'company',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t(`${COLUMN_NAME_TRANSLATION_PATH_FROM_ID['jobs-view-company']}`)
            )}
          </Table.HeaderCell>
        );
      },
      department: (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t('reveal.missions.columnSelectors.departments')}
                  selectorId='department'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'department',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t('reveal.missions.columnSelectors.departments')
            )}
          </Table.HeaderCell>
        );
      },
      section: (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t('reveal.missions.columnSelectors.sections')}
                  selectorId='section'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'section',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t('reveal.missions.columnSelectors.sections')
            )}
          </Table.HeaderCell>
        );
      },
      subsection: (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  triggerText={t('reveal.missions.columnSelectors.subsections')}
                  selectorId='subsection'
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: 'subsection',
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              t('reveal.missions.columnSelectors.subsections')
            )}
          </Table.HeaderCell>
        );
      },
      substep: (column) => {
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  fieldType='substep'
                  triggerText={column.name}
                  selectorId={`substep-${column.id}`}
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: `substep-${column.id}`,
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              column.name
            )}
          </Table.HeaderCell>
        );
      },
      customField: (column) => {
        const customField = missionCustomFieldsById[column.id];
        if (!customField) {
          return null;
        }
        return (
          <Table.HeaderCell key={column.id}>
            {columnSorting ? (
              <span className={styles.columnSortTrigger}>
                <SortingDropdown
                  fieldType={customField.type}
                  triggerText={getTranslatedText(customField.title)}
                  selectorId={`customField-${column.id}`}
                  selectedSortingOption={
                    _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                      key: `customField-${column.id}`,
                    })?.order as 'ascending' | 'descending'
                  }
                  onChangeSortingOption={({ selectorId, value }) =>
                    onUpdateSortBy({
                      selectorId,
                      order: value as 'ascending' | 'descending',
                    })
                  }
                />
              </span>
            ) : (
              getTranslatedText(customField.title)
            )}
          </Table.HeaderCell>
        );
      },
      ...atsHeaderRenderers,
    } as Record<string, ColumnRenderer>;
    // eslint-disable-next-line
  }, [
    missionCustomFieldsById,
    t,
    atsHeaderRenderers,
    columnSorting,
    displayPrefs,
  ]);

  const columnIsCustomField = useCallback(
    (columnId: string) => {
      return columnId in missionCustomFieldsById;
    },
    [missionCustomFieldsById],
  );

  const columns = useMemo(() => {
    return _.map(columnsDisplay, (column) => {
      if (column.hidden) {
        return null;
      }

      const renderer = COLUMN_RENDERERS[column.id];
      if (renderer) {
        return renderer(column, {
          columnSorting: columnSorting ?? false,
          sortBy: displayPrefs?.sortingOptions?.sortBy || [],
          onUpdateSortBy,
        });
      }

      const isCustomField = columnIsCustomField(column.id);
      if (isCustomField) {
        return COLUMN_RENDERERS.customField(column);
      }

      // must be substep
      return COLUMN_RENDERERS.substep(column);
    });
  }, [
    COLUMN_RENDERERS,
    columnIsCustomField,
    columnsDisplay,
    displayPrefs,
    columnSorting,
    onUpdateSortBy,
  ]);

  return (
    <Table.Row>
      <Table.HeaderCell>
        {columnSorting ? (
          <span className={styles.columnSortTrigger}>
            <SortingDropdown
              triggerText={t('reveal.missions.name')}
              selectorId='title'
              selectedSortingOption={
                _.findWhere(displayPrefs?.sortingOptions?.sortBy || [], {
                  key: 'title',
                })?.order as 'ascending' | 'descending'
              }
              onChangeSortingOption={({ selectorId, value }) =>
                onUpdateSortBy({
                  selectorId,
                  order: value as 'ascending' | 'descending',
                })
              }
            />
          </span>
        ) : (
          t('reveal.missions.name')
        )}
      </Table.HeaderCell>

      {columns}

      <Table.HeaderCell className='column-selector-header'>
        {showColumnsSelector && (
          <TableColumnSelector
            columnGroups={displayableColumnGroups}
            currentColumnValues={columnsDisplay}
            onUpdateColumnsToDisplay={onUpdateColumnsToDisplay}
          />
        )}
      </Table.HeaderCell>
    </Table.Row>
  );
};
