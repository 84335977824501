import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { Dropdown } from 'semantic-ui-react';
import GenericToggle from '@/components/Common/GenericToggle';
import styles from './DescriptionJobsLayout.module.less';
import { JobsLayout } from './DescriptionModal';

interface DescriptionJobsLayoutProps {
  jobsLayout: JobsLayout;
  onJobsLayout: (newlayout: JobsLayout) => void;
}

const DescriptionJobsLayout: React.FC<DescriptionJobsLayoutProps> = ({
  jobsLayout,
  onJobsLayout,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.jobsLayoutContainer}>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.displayType')}
        </div>
        <Dropdown
          selection
          className='hiresweet-rounded'
          onChange={(_e, { value }) => {
            onJobsLayout({
              ...jobsLayout,
              displayType: value as 'list' | 'grid',
            });
          }}
          options={[
            {
              value: 'list',
              text: t('settings.careerPage.description.modal.list'),
            },
            {
              value: 'grid',
              text: t('settings.careerPage.description.modal.grid'),
            },
          ]}
          value={jobsLayout?.displayType}
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>
          {t('settings.careerPage.description.modal.showFilter')}
        </div>
        <GenericToggle
          size='large'
          name='showFilter'
          isChecked={jobsLayout?.showFilter}
          onChange={() => {
            onJobsLayout({
              ...jobsLayout,
              showFilter: !jobsLayout?.showFilter,
            });
          }}
        />
      </div>
    </div>
  );
};

export default DescriptionJobsLayout;
