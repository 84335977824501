import _ from 'underscore';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import {
  useQuery,
  QueryResult,
  useMutation,
  MutationHookOptions,
  QueryHookOptions,
  MutationResult,
} from '@apollo/client';

import UserMarketplaceGlobalProfileDisplayPreferencesFragment from '@/graphql/fragments/MarketplaceProfileDisplayGlobalSettings';

const QUERY_USER_PROFILE_DISPLAY_PREFERENCES = gql`
  query getUserProfileDisplayPreferences {
    user {
      id
      displayPreferences {
        profileDisplay {
          marketplaceGlobalSettings {
            ...UserMarketplaceGlobalProfileDisplayPreferencesFragment
          }
        }
      }
    }
  }

  ${UserMarketplaceGlobalProfileDisplayPreferencesFragment}
`;

type QueryUserProfileDisplayPreferencesData = {
  user?: {
    id: string;
    displayPreferences?: {
      profileDisplay?: {
        marketplaceGlobalSettings?: MarketplaceGlobalSettings;
      };
    };
  };
};

export interface MarketplaceGlobalSettings {
  hideFullName?: boolean;
  hidePicture?: boolean;
  hideEducationSchoolNames?: boolean;
  hideEducationDates?: boolean;
  hideEducationDescriptions?: boolean;
}

interface UserProfileDisplayPreferences {
  marketplaceGlobalSettings: MarketplaceGlobalSettings;
}

interface UserProfileDisplayPreferencesReturnType {
  userProfileDisplayPreferences: UserProfileDisplayPreferences;
}

export function useUserProfileDisplayPreferences({
  queryOptions = {},
}: {
  queryOptions?: QueryHookOptions;
} = {}): QueryResult & UserProfileDisplayPreferencesReturnType {
  const query = useQuery<QueryUserProfileDisplayPreferencesData>(
    QUERY_USER_PROFILE_DISPLAY_PREFERENCES,
    queryOptions,
  );

  const { marketplaceGlobalSettings = {} } =
    query.data?.user?.displayPreferences?.profileDisplay || {};

  const userProfileDisplayPreferences = { marketplaceGlobalSettings };

  return {
    ...query,
    userProfileDisplayPreferences,
  };
}

interface UserMarketplaceProfileDisplayPreferencesReturnType {
  userMarketplaceProfileDisplayPreferences: MarketplaceGlobalSettings;
}

export default function useUserMarketplaceProfileDisplayPreferences({
  queryOptions = {},
}: {
  queryOptions?: QueryHookOptions;
} = {}): QueryResult & UserMarketplaceProfileDisplayPreferencesReturnType {
  const {
    userProfileDisplayPreferences,
    ...query
  } = useUserProfileDisplayPreferences({ queryOptions });

  return {
    ...query,
    userMarketplaceProfileDisplayPreferences: _.omit(
      userProfileDisplayPreferences?.marketplaceGlobalSettings,
      '__typename',
    ),
  };
}

// Note the `id` on the result query. This allows Apollo to auto-update
// the other queries from cache
const UPDATE_MARKETPLACE_GLOBAL_PROFILE_DISPLAY_SETTINGS = gql`
  mutation updateMarketplaceGlobalProfileDisplaySettings(
    $input: UserMarketplaceGlobalProfileDisplayPreferences!
  ) {
    updateUserMarketplaceGobalProfileDisplayPreferences(input: $input) {
      id
      displayPreferences {
        profileDisplay {
          marketplaceGlobalSettings {
            ...UserMarketplaceGlobalProfileDisplayPreferencesFragment
          }
        }
      }
    }
  }

  ${UserMarketplaceGlobalProfileDisplayPreferencesFragment}
`;

export function useUpdateUserMarketplaceGobalProfileDisplayPreferences(
  options?: MutationHookOptions,
): [
  (
    preferences: MarketplaceGlobalSettings,
    mutationOptions?: MutationHookOptions,
  ) => void,
  MutationResult<any>,
] {
  const [mutation, mutationData] = useMutation(
    UPDATE_MARKETPLACE_GLOBAL_PROFILE_DISPLAY_SETTINGS,
    options,
  );

  const updateGlobalSettings = useCallback(
    (
      preferences: MarketplaceGlobalSettings,
      mutationOptions?: MutationHookOptions,
    ) => {
      mutation({
        variables: {
          input: preferences,
        },
        ...mutationOptions,
      });
    },
    [mutation],
  );

  return [updateGlobalSettings, mutationData];
}
