import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './GenericFormLabel.module.less';

interface GenericFormLabelProps {
  secondary?: boolean;
  className?: string;
}

const GenericFormLabel: FC<GenericFormLabelProps> = ({
  secondary,
  children,
  className,
}) => (
  <div
    className={classNames(
      styles.label,
      { [styles.secondary]: secondary },
      className,
    )}
  >
    {children}
  </div>
);

export default GenericFormLabel;
