import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { Container, Input, TextArea, Dropdown } from 'semantic-ui-react';
import { useQuery, useMutation } from '@apollo/client';

import useSequences from '@/hooks/common/useSequences';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useLockHistory } from '@/context/LockHistoryContext';
import { isEmailAction } from '@/common/constants/taskTypes';
import GenericButton from '@/components/Common/GenericButton';
import { findLastModifiedActionIndex } from '@/revealComponents/Modals/ReapplySequenceModal/utils';
import checkContactFlowSnippets from '@/common/contactFlow/checkContactFlowSnippets';
import { sanitizeTypename } from '@/common/utils/apollo';
import SequenceLayout from '../SequenceLayout';
import SequencesSubHeader from '../SequencesSubHeader/SequencesSubHeader';
import FullContactFlowEditor from '../../../../routes/RevealView/revealComponents/FullContactFlowEditor';
import { CLIENT_SEQUENCE, UPDATE_SEQUENCE } from '../queries';
import getContactFlowInput from '../../../../common/contactFlow/getContactFlowInput';
import validateContactFlow from '../validateContactFlow';
import CancelButton from '../SequencesSubHeader/CancelButton';
import SequenceErrorMessages from '../SequenceErrorMessages';

const EditSequencePage = ({ clientId, sequenceId }) => {
  const history = useHistory();
  const notification = useNotificationSystem();
  const { t } = useTranslation();

  // Use this to prevent DraftJS editor from locking the history
  // because it triggers onChange for no reason
  const [shouldLockHistory, setShouldLockHistory] = useState(true);
  const [generationLoading, setGenerationLoading] = useState(false);

  const URLQueryParams = new URLSearchParams(useLocation().search);
  const showAIGenerationPanelParam = URLQueryParams.get('withAIGeneration');
  const [showAIGenerationPanel, setShowAIGenerationPanel] = useState(
    showAIGenerationPanelParam,
  );
  // QUERY get client sequence by Id
  const { data } = useQuery(CLIENT_SEQUENCE, {
    variables: {
      clientId,
      sequenceId,
    },
  });
  const sequence = data?.sequence;

  const { lockHistory, unlockHistory } = useLockHistory();

  // MUTATION update sequence
  const [updateSequence, { loading: updatingSequence }] = useMutation(
    UPDATE_SEQUENCE,
  );

  // FORM PROPS
  const [sequenceTitle, setSequenceTitle] = useState(
    sequence?.title || t('sequences.titlePlaceholder'),
  );
  const [sequenceDescription, setSequenceDescription] = useState(
    sequence?.description,
  );
  const [sequences, setSequences] = useState(sequence?.contactFlow?.sequences); // || DEFAULT_SEQUENCES // TODO: why ?
  const contactFlow = { sequences };

  useEffect(() => {
    setSequenceTitle(sequence?.title);
    setSequenceDescription(sequence?.description);
    setSequences(sequence?.contactFlow.sequences);
  }, [sequence]);

  // FORM ERRORS
  const [errorLevel, setErrorLevel] = useState('major');
  const formErrors = validateContactFlow({ contactFlow });
  const displayedErrors =
    errorLevel === 'major'
      ? _.filter(formErrors, ({ level }) => level === 'major')
      : formErrors;

  const lockHistoryWithMessage = useCallback(() => {
    lockHistory(t('sequences.sequenceEditor.lockHistory'));
  }, [lockHistory, t]);

  const {
    handleInsertAction,
    handleUpdateAction,
    handleRemoveAction,
    handleMoveUpwardAction,
    handleMoveDownwardAction,
    updateAfterTemplateCreation,
  } = useSequences({
    setSequences: (sequencesMutation) => {
      if (shouldLockHistory) {
        lockHistoryWithMessage();
      }
      setSequences(sequencesMutation);
    },
    formErrors,
    setErrorLevel,
    clientId,
  });

  // SUBMIT
  const onSubmit = async () => {
    if (!_.isEmpty(formErrors)) {
      setErrorLevel('minor');
      return {};
    }
    const missingSnippets = checkContactFlowSnippets({ contactFlow });
    if (!_.isEmpty(missingSnippets)) {
      notification.error(
        t('sequences.sequenceMissingSnippets', {
          count: missingSnippets.length,
        }),
      );
      throw new Error('invalid snippet in sequence');
    }
    const contactFlowInput = getContactFlowInput({
      contactFlow,
    });
    const sequenceInput = {
      id: sequenceId,
      title: sequenceTitle,
      description: sequenceDescription,
      contactFlow: contactFlowInput,
    };
    const lastModifiedActionIndex = findLastModifiedActionIndex({
      newContactFlow: contactFlow,
      oldContactFlow: sequence?.contactFlow,
    });

    if (lastModifiedActionIndex >= 0 || sequenceTitle !== sequence?.title) {
      await updateSequence({
        variables: { input: sanitizeTypename(sequenceInput) },
      });
    }

    return { lastModifiedActionIndex };
  };

  const clickHandler = async () => {
    unlockHistory();
    setShouldLockHistory(false);
    try {
      const { lastModifiedActionIndex } = await onSubmit();
      notification.success(t('sequences.edit.sequenceEdited'));
      const redirectURI = `/client/${clientId}/reveal/sequences/${sequenceId}`;
      if (lastModifiedActionIndex === -1) {
        history.push(redirectURI);
      } else {
        const searchParams = new URLSearchParams('reapplySequence=true');
        if (_.isNumber(lastModifiedActionIndex)) {
          searchParams.append(
            'lastModifiedActionIndex',
            lastModifiedActionIndex,
          );
        }
        history.push(`${redirectURI}?${searchParams.toString()}`);
      }
    } catch (e) {
      setShouldLockHistory(true);
      notification.error(t('sequences.edit.sequenceEditError'));
      console.error(e);
    }
  };

  const applyCcBccToNextMails = ({ cc, bcc, index }) => {
    const newSequence = _.first(contactFlow.sequences);
    if (!newSequence) {
      return;
    }
    const newActions = _.map(
      newSequence.actions || [],
      (action, actionIndex) => {
        if (!isEmailAction(action) || actionIndex <= index) {
          return action;
        }
        return {
          ...action,
          message: {
            ...action.message,
            ...{
              cc,
              bcc,
            },
          },
        };
      },
    );
    const newSequences = [
      {
        ...newSequence,
        actions: newActions,
      },
    ];
    setSequences(newSequences);
  };

  const updateGeneratedActions = (newActions) => {
    const currentSequence = _.first(contactFlow.sequences);
    if (!currentSequence) {
      return;
    }
    const newSequence = [
      {
        ...currentSequence,
        actions: newActions,
      },
    ];
    setSequences(newSequence);
  };

  return (
    <SequenceLayout
      withAiGenerationPanel={showAIGenerationPanel}
      hasSidePanel
      updateGeneratedActions={updateGeneratedActions}
      sequenceId={sequenceId}
      subHeader={
        <SequencesSubHeader
          breadcrumbs={[
            {
              text: t('sequences.breadcrumbs.sequences'),
              link: `/client/${clientId}/reveal/sequences`,
            },
            {
              text: sequenceTitle,
              link: `/client/${clientId}/reveal/sequences/${sequenceId}`,
            },
            {
              text: t('sequences.breadcrumbs.editSequence'),
              link: `/client/${clientId}/reveal/sequences/${sequenceId}/edit`,
            },
          ]}
          errorMessage={<SequenceErrorMessages errors={displayedErrors} />}
        >
          <Dropdown
            className='sequence-subheader-more-actions'
            icon={<i className='ri-more-fill ri-xl' />}
            basic
            floating
            direction='left'
          >
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setShowAIGenerationPanel(!showAIGenerationPanel)}
                text={
                  showAIGenerationPanel
                    ? t('sequences.generation.subHeader.hideAIPanel')
                    : t('sequences.generation.subHeader.showAIPanel')
                }
              />
            </Dropdown.Menu>
          </Dropdown>
          <CancelButton />
          <GenericButton
            size='big'
            disabled={
              !_.isEmpty(displayedErrors) ||
              generationLoading ||
              updatingSequence
            }
            onClick={clickHandler}
          >
            {t('sequences.buttons.save')}
          </GenericButton>
        </SequencesSubHeader>
      }
      onLoading={setGenerationLoading}
    >
      <Container className='new-sequence-edit'>
        <h3 className='input-container'>
          <Input
            className='sequence-title-input'
            fluid
            placeholder={t('sequences.title')}
            value={sequenceTitle || ''}
            onChange={(ev) => {
              lockHistoryWithMessage();
              setSequenceTitle(ev.target.value);
            }}
          />

          {(clientId === 'sequences-reveal' ||
            clientId === 'sequences-reveal-fr') && (
            <TextArea
              className='sequence-description-input'
              placeholder='Description (optional)'
              value={sequenceDescription}
              onChange={(e, { value }) => {
                lockHistoryWithMessage();
                setSequenceDescription(value);
              }}
              rows={6}
            />
          )}
        </h3>

        <FullContactFlowEditor
          clientId={clientId}
          contactFlow={contactFlow}
          t={t}
          onInsertAction={handleInsertAction}
          onUpdateAction={handleUpdateAction}
          onRemoveAction={handleRemoveAction}
          onMoveUpward={handleMoveUpwardAction}
          onMoveDownward={handleMoveDownwardAction}
          onQuitContactFlowEditor={onSubmit}
          updateAfterTemplateCreation={updateAfterTemplateCreation}
          formErrors={displayedErrors}
          applyCcBccToNextMails={applyCcBccToNextMails}
        />
      </Container>
    </SequenceLayout>
  );
};

export default EditSequencePage;
