import React, { useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './RevealCandidateViewTimeLineItems.css';
import { getATSDataItems } from '@/common/reveal/SourceData';
import { getActivityFeedWithScoreCards } from '@/revealComponents/ProfileTimeline';
import useNavSwitch from '@/containers/TopBar/Header/NavSwitch.hook';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import { SMARTRECRUITERS } from '@/common/reveal';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import GenericDropdown from '@/components/Common/GenericDropdown';
import GenericButton from '@/components/Common/GenericButton';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import { getTranslatedText } from '@/common';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { getContactFlowEmails, getTimelineWithoutEmails } from './utils';
import LoadingComponent from '../../../../components/LoadingComponent';
import TimeLineItem from './TimeLineItem';
import TimelineItemDiplaySettingsContext from './TimelineItemDiplaySettingsContext';

interface RevealCandidateViewTimeLineItemsProps {
  loading: boolean;
  clientId: string;
  isWatchProfile?: boolean;
  error?: any;
  searchPoolId: string;
  hideSourced: boolean;
  dense?: boolean;
  sequenceOwner: any;
  watchProfile?: any;
  openMessageModal?: () => void;
}

const RevealCandidateViewTimeLineItems: React.FC<RevealCandidateViewTimeLineItemsProps> = ({
  loading,
  clientId,
  isWatchProfile,
  error,
  searchPoolId,
  hideSourced,
  dense = false,
  sequenceOwner,
  watchProfile,
  openMessageModal,
}) => {
  const { t } = useTranslation();
  const configurationParams = useMergedConfigurationParams();
  const { profile: searchPoolProfile, profileId } = useCandidateViewContext();
  const profile = searchPoolProfile || watchProfile || null;
  const atsTimelineItems = getAtsTimelineItems({ profile });
  const profileViewsItems = getViewsTimelineItems({ profile });
  const clientPermissionsResult = useClientPermissions(clientId);
  const { permissions: clientPermissions } =
    clientPermissionsResult?.data?.client || {};
  const { applicationMode } = useNavSwitch(clientId);
  const isPlugin = useIsPlugin();
  const [showExternalItems, setShowExternalItems] = useState(false);

  if (!profile || !clientPermissions) {
    return null;
  }

  if (!profileId || loading || error) {
    return (
      <div style={{ marginLeft: 10, marginTop: 30 }}>
        <LoadingComponent loading length={30} as='div' rows={1} margin={2} />
        <div className='timeline-card-mail-wrapper' style={{ padding: 10 }}>
          <LoadingComponent
            loading
            length={30}
            as='div'
            rows={2}
            margin={1}
            randomCoef={4}
          />
        </div>
      </div>
    );
  }

  const isImportOrApplicationAction = (action: any) => {
    return _.contains(
      [
        'plugin-import',
        'app-manual-import',
        'app-csv-import',
        'reveal-plugin-import',
        'reveal-csv-import',
        'career-page-application',
      ],
      action?.type,
    );
  };
  const contactFlowEmails = getContactFlowEmails({
    contactFlow: profile?.contactFlow,
  });
  const timelineWithoutEmails = getTimelineWithoutEmails({
    timeline: profile?.contactData?.timeline,
  });

  const isISODate = (date: any) => _.isString(date) && date.length === 24;

  const timeline = _.map(
    _.union(
      timelineWithoutEmails,
      contactFlowEmails,
      atsTimelineItems,
      profileViewsItems,
    ),
    (x) => ({
      ...x,
      date: isISODate(x.date) ? `${new Date(x.date).getTime()}` : x.date,
    }),
  );

  let timelineItemsList = timeline;

  if (
    !isWatchProfile &&
    !_.find(timeline, (item) => isImportOrApplicationAction(item?.action))
  ) {
    timelineItemsList = [...timeline, { action: { type: 'sourced' } }];
  }
  timelineItemsList = _.reject(
    timelineItemsList,
    (item) =>
      item?.action?.type === 'messageSequence' ||
      item?.action?.type === 'profileView' ||
      (hideSourced && item?.action?.type === 'sourced') ||
      (configurationParams?.shouldHideProjectProfileStageUpdatedActivity ===
        'true' &&
        ['mission-stage-updated'].includes(item?.action?.type)),
  );

  if (!clientPermissions.crmMarketing) {
    timelineItemsList = _.filter(
      timelineItemsList,
      (timelineItem) =>
        !_.contains(
          ['enrolled-in-campaign', 'unenrolled-from-campaign'],
          timelineItem.action.type,
        ),
    );
  }

  timelineItemsList = _.filter(
    _.sortBy(
      _.sortBy(timelineItemsList, (item) => +item?.date * -1),
      item => item.isPinned ? 0 : 1
    ),
    (item) => showExternalItems || !item.isExternal,
  );

  const { linkedProfileReferences } =
    searchPoolProfile?.linkedProfilesGroup || {};
  const currentLinkedProfileTitle = getTranslatedText(
    _.findWhere(linkedProfileReferences || [], { profileId: profile.id })
      ?.title || { default: '' },
  );

  try {
    timelineItemsList = _.reject(
      timelineItemsList,
      ({ action }) =>
        action?.type === 'task-completed' &&
        ((action?.subtype || '').includes('linkedin') ||
          (action?.subtype || '').includes('whatsapp')) &&
        (action?.messageBody || '').includes('{{') &&
        (action?.messageBody || '').includes('}}'),
    );
  } catch (e) {
    console.error(e);
  }

  return (
    <div
      className={classNames('timeline', {
        dense,
      })}
    >
      {!dense && (
        <h4 className='past-activities-title'>
          <span>
            {t('profile.contact.timeline.past')}
            <span style={{ marginLeft: 5 }} className='ui label'>
              {timelineItemsList?.length}
            </span>
          </span>

          {(linkedProfileReferences || []).length > 1 && (
            <GenericDropdown
              position='right'
              Trigger={({ onClick }) => {
                return (
                  <GenericButton
                    className='select-show-external'
                    onClick={onClick}
                  >
                    {showExternalItems
                      ? t('profile.contact.timeline.allActivities')
                      : currentLinkedProfileTitle}
                    <ArrowDown />
                  </GenericButton>
                );
              }}
            >
              <DropdownMenuPanel
                options={[
                  {
                    id: 'show-external',
                    label: t('profile.contact.timeline.allActivities'),
                  },
                  {
                    id: 'not-show-external',
                    label: currentLinkedProfileTitle,
                  },
                ]}
                onSelect={(optionId) => {
                  setShowExternalItems(optionId === 'show-external');
                }}
              />
            </GenericDropdown>
          )}
        </h4>
      )}
      {_.isEmpty(timelineItemsList) ? (
        <div className='empty-past-activity'>
          {t('reveal.candidatesView.timeline.noPastActivity')}
        </div>
      ) : (
        <TimelineItemDiplaySettingsContext.Provider
          value={{ showAgencies: showExternalItems, currentLinkedProfileTitle }}
        >
          {_.map(timelineItemsList, (timelineItem, i) => (
            <TimeLineItem
              key={`${i}_${profileId}_timeline_item${timelineItem.date}_${
                (timelineItem.action || {}).type
              }`}
              clientId={clientId}
              searchPoolId={searchPoolId}
              timelineItem={timelineItem}
              profile={profile}
              sequenceOwner={sequenceOwner}
              applicationMode={isPlugin ? 'reveal' : applicationMode}
              openMessageModal={openMessageModal}
            />
          ))}
        </TimelineItemDiplaySettingsContext.Provider>
      )}
    </div>
  );
};

export const getViewsTimelineItems = ({ profile }: { profile: any }): any => {
  const views = profile?.views?.timeline;
  return _.map(views, (view) => ({
    date: view.date,
    author: view.author || {},
    action: {
      type: 'profileView',
    },
  }));
};

export const getAtsTimelineItems = ({ profile }: { profile: any }): any => {
  const synchronizedTimelineItemSourceIds = _.flatten(
    _.map(profile?.contactData?.timeline, (timelineItem) =>
      _.pluck(timelineItem?.atsSynchronizations, 'sourceItemId'),
    ),
  );
  const sourceData = profile?.resumeData?.sourceData;
  const atsDataItems = sourceData ? getATSDataItems(sourceData) : [];

  const isHiredApplication = (application: any) =>
    (application?.status?.sourceStatusId || '').toLowerCase().includes('hired');

  const isHired = _.some(atsDataItems, (atsDataItem) =>
    _.some(atsDataItem?.applications || [], isHiredApplication),
  );

  if (isHired) {
    return [];
  }

  const atsActivities = _.flatten(
    _.map(atsDataItems, (atsDataItem) =>
      getActivityFeedWithScoreCards({ atsDataItem }),
    ),
  );
  const notSyncedActivities = _.filter(atsActivities, (activity) => {
    if (activity?.type === 'scorecard') {
      return true;
    }
    if (
      activity?.ats === SMARTRECRUITERS &&
      (activity?.body || '').includes('#SyncedByHireSweet')
    ) {
      return false;
    }
    return !_.contains(
      synchronizedTimelineItemSourceIds,
      activity?.sourceActivityId,
    );
  });
  return _.map(notSyncedActivities, getTimelineItemFromAtsActivity);
};

const atsActivityTypeToTimelineType: any = {
  note: 'comment',
  'linkedin-note': 'comment',
  email: 'send-email-action',
  'linkedin-inmail': 'linkedin-send-inmail',
  activity: 'atsActivity',
  scorecard: 'scorecard',
};

const getTimelineItemFromAtsActivity = (atsActivity: any) => {
  const item: any = {
    sourceActivityId: atsActivity?.sourceActivityId,
    date: atsActivity?.creationDate,
    author: atsActivity?.user || {},
    action: {
      type: atsActivityTypeToTimelineType[atsActivity?.type],
    },
    ats: atsActivity?.ats,
  };
  // console.log('ogItem with new type :', item);
  if (atsActivity.type === 'note') {
    item.action = {
      ...item.action,
      comment: {
        text:
          (atsActivity?.subject ? `${atsActivity.subject}\n` : '') +
          (atsActivity?.body || ''),
      },
    };
  } else if (atsActivity.type === 'activity') {
    item.action = {
      ...item.action,
      subject: atsActivity?.subject,
      body: atsActivity?.body,
    };
  } else if (
    atsActivity.type === 'linkedin-note' ||
    atsActivity.type === 'email' ||
    atsActivity.type === 'linkedin-inmail'
  ) {
    item.action = {
      ...item.action,
      description:
        (atsActivity?.subject ? `${atsActivity?.subject}-` : '') +
        atsActivity?.body,
    };
  } else if (atsActivity.type === 'scorecard') {
    item.action = {
      ...atsActivity,
    };
  }
  // console.log('final item :', item);
  return item;
};

export default RevealCandidateViewTimeLineItems;
