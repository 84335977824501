import { Scorecard } from '@/graphql/fragments/Scorecard';
import { TextWithEnrichments, TranslatableText } from '@/types/text';
import { SweetDate } from '@/types/date';
import { Author } from '@/types/author';
import { ProfileEnrichmentResults } from '@/graphql/hooks/searchPoolProfile/useAskProfileEnrichment';
import { AdventureATSData } from '@/graphql/fragments/AdventureData';
import { ATStype } from '.';

interface AtsUser {
  sourceUserId?: string;
  firstname?: string;
  lastname?: string;
}

export interface ActivityFeedItem {
  sourceActivityId: string;
  type: 'email' | 'activity' | 'note' | 'linkedin-note' | 'linkedin-inmail';
  creationDate: string;
  subject?: string;
  body?: string;
  to?: string;
  from?: string;
  user?: AtsUser;
  isPrivate?: boolean;
  visibility?: string;
  ats?: string;
}

export interface ApplicationJob {
  sourceJobId?: string;
  title?: string;
}

interface ApplicationStatusStage {
  sourceStageId?: string;
  sourceStageName?: string;
}

interface ApplicationStatusRejectionCategory {
  type?: string;
  name?: string;
}

interface ApplicationStatusRejectionReason {
  sourceRejectionReasonId?: string;
  sourceRejectionReasonName?: string;
}

interface ApplicationStatusRejection {
  category?: ApplicationStatusRejectionCategory;
  reason?: ApplicationStatusRejectionReason;
  lastStage?: ApplicationStatusStage;
}

interface ApplicationStatus {
  sourceStatusId?: string;
  stage?: ApplicationStatusStage;
  rejection?: ApplicationStatusRejection;
}

export interface Application {
  sourceApplicationId?: string;
  applicationDate?: string;
  endDate?: string;
  lastActivityDate?: string;
  jobs?: ApplicationJob[];
  scorecards?: Scorecard[];
  status?: ApplicationStatus;
}

type ContractMetadata = {
  key: string;
  value: string | null;
};

export interface Contract {
  id: string;
  lastActivityDate?: string;

  flattenedContract: Contract;

  sourceContractId: string;
  sourceContractNumber?: string | null;
  creationDate: string;
  isAddendum: false;

  actualStartDate?: string | null;
  theoreticalEndDate?: string | null;

  endDate?: string | null;
  actualEndDate?: string | null;
  leewayEndDates: [string | null, string | null];

  metadata: ContractMetadata[];

  externalContractor: {
    sourceId: string;
    name: string;
    sourceLegalId: string;
  };

  status: {
    state: 'unknown' | 'validated';
    history: {
      oldState: string;
      newState: string;
      transitionDate: string | null;
      addendumId: string | null;
      comments: {
        author: Author;
        comment: string;
        date: string;
      }[];
    }[];
  };

  addendums: {
    addendumId: string;
    isAddendum: true;
    actualStartDate?: string | null;
    theoreticalEndDate?: string | null;
    actualEndDate?: string | null;
    leewayEndDates: [string | null, string | null];

    metadata: ContractMetadata[];
  };
}

export interface ScorecardInActivityFeed extends Scorecard {
  type: 'scorecard';
}

export interface Attachment {
  id?: string;
  name?: string;
  creationDate?: string;
  type?: string;
  author?: {
    firstname?: string;
    lastname?: string;
    photoLink?: string;
    email?: string;
  };
  from?: ATStype | 'hiresweet';
  file?: {
    content?: string;
    url?: string;
  };
}

export interface AtsData {
  type: ATStype;
  asAtsType?: ATStype;
  creationDate?: string;
  sourceCandidateId?: string;
  activityFeed?: Array<ActivityFeedItem>;
  applications?: Array<Application>;
  contracts?: Contract[];
  attachments?: Array<Attachment>;
  profileURL?: string;
  phoneNumbers?: { value: string }[];
}

export interface SourceData {
  generic?: AtsData;
  adventure?: AdventureATSData;
  ashby?: AtsData;
  smartrecruiters?: AtsData;
  greenhouse?: AtsData;
  lever?: AtsData;
  teamtailor?: AtsData;
  workable?: AtsData;
  recruitee?: AtsData;
  recruitcrm?: AtsData;
  welcomekit?: AtsData;
  boondmanager?: AtsData;
  boondmanager_sales?: AtsData;
  successfactors?: AtsData;
  zoho?: AtsData;
  contactout?: { enrichmentResults: ProfileEnrichmentResults[] };
  kaspr?: { enrichmentResults: ProfileEnrichmentResults[] };
  rocketreach?: { enrichmentResults: ProfileEnrichmentResults[] };
  connect: ConnectData;
  github?: GithubData;
  stackoverflow?: StackoverflowData;
  linkedin?: LinkedinData;
  linkedinRecruiter?: LinkedinDataWithId;
  linkedinCommon?: LinkedinDataWithId;
  linkedinSalesNavigator?: LinkedinDataWithId;
  hiresweet?: HiresweetData;
}

interface ConnectData {
  sourceContactId: string;
  firstname: string;
  lastname: string;
  emails: ConnectEmail[];
  phoneNumbers: ConnectPhoneNumber[];
  interestForms: ConnectInterestForm[];
}

export interface ConnectEmail {
  type: string;
  value: string;
}

export interface ConnectPhoneNumber {
  type: string;
  value: string;
}

interface ConnectInterestForm {
  id: string;
  creationDate: string;
  formId: string;
  fields: ConnectFormFieldWithAnswer[];
}

interface ConnectFormFieldWithAnswer {
  type: string;
  id: string;
  title: TranslatableText;
  value: ConnectFormFieldValue;
}

interface ConnectFormFieldValue {
  text: string;
}

interface GithubData {
  relevantFacts?: {
    title?: TranslatableText;
    description?: TranslatableText;
  }[];
  repositories?: {
    name?: TextWithEnrichments;
    nameWithEnrichments?: TextWithEnrichments;
    fullname?: TextWithEnrichments;
    fullnameWithEnrichments?: TextWithEnrichments;
    description?: TextWithEnrichments;
    descriptionWithEnrichments?: TextWithEnrichments;
    fork?: boolean;
    createdAt?: SweetDate;
    updatedAt?: SweetDate;
    stargazersCount?: number;
    watchersCounts?: number;
    forksCount?: number;
    language?: string;
  }[];
}

interface StackoverflowData {
  relevantFacts?: {
    title?: TranslatableText;
    description?: TranslatableText;
  }[];
}

interface LinkedinData {
  skills?: {
    label: TranslatableText;
    endorsementsCount?: number;
    relevance?: number;
  }[];
}

interface LinkedinDataWithId {
  id?: string;
}

export interface CandidateClientInterestState {
  clientId: string;
  interestItems: CandidateInterestItem[];
}

export enum CANDIDATE_INTEREST_ITEM_TYPE {
  COMPANY_GLOBAL = 'company-global-interest',
  COMPANY_DEPARTMENT = 'company-department-interest',
  JOB_OFFER = 'job-offer-interest',
}

export interface CandidateInterestItem {
  id: string;
  type: CANDIDATE_INTEREST_ITEM_TYPE;
  state: string;
  message?: { text: string };
  jobOfferId?: string;
  departmentId?: string;
  creationDate: string;
  lastEditionDate: string;
}

interface HiresweetData {
  wishes?: any;
  availability?: any;
  seekingLevel?: TranslatableText;
  languages?: {
    name?: TranslatableText;
    level?: string;
  }[];
  clientInterestStates?: CandidateClientInterestState[];
}
