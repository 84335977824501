import React, { FC, useMemo, useState } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useRevealSearchPoolResults from '@/graphql/hooks/searchPool/useRevealSearchPoolResults';
import useSearchPoolProfiles from '@/graphql/hooks/searchPoolProfile/useSearchPoolProfiles';
import GenericSelect from '@/components/Common/GenericSelect';
import useDebounceValue from '@/hooks/common/useDebounceValue';
import useMiniProfile from '@/graphql/hooks/searchPoolProfile/useMiniProfile';

import styles from './TargetsForm.module.less';

const commonSearchVariables = {
  categories: [
    {
      categoryId: 'all',
      withCount: false,
      withResults: true,
    },
  ],
  searchesCriteria: [
    {
      free: JSON.stringify({
        criteria: {
          categoryFilters: [
            {
              filterType: 'in',
              categories: [
                {
                  type: 'company',
                },
              ],
            },
          ],
        },
      }),
    },
  ],
  queryOptions: {
    fetchPolicy: 'network-only' as const,
  },
};

type TargetsFormProps = {
  linkedCompany: string | undefined;
  onLinkedCompany: (value: string | undefined) => void;
  label?: string;
  maxMenuHeight?: number;
};

const TargetsForm: FC<TargetsFormProps> = ({
  linkedCompany,
  onLinkedCompany,
  label,
  maxMenuHeight,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  // Use value debouncer to avoid multiple search to be thrown
  // at the same time
  const debouncedSearchText = useDebounceValue(searchText, 250);
  const searchVariables = useMemo(
    () => ({
      ...commonSearchVariables,
      searchText: debouncedSearchText.trim(),
    }),
    [debouncedSearchText],
  );
  const { miniProfile } = useMiniProfile({
    profileId: linkedCompany ?? '',
    queryOptions: {
      skip: !linkedCompany,
    },
  });
  const { searchResults, loading } =
    useRevealSearchPoolResults(searchVariables);
  const category = useMemo(
    () => _.findWhere(searchResults.categories, { categoryId: 'all' }),
    [searchResults],
  );
  const { results = [] } = category || {};
  const profileIds = useMemo(
    () => _.map(results, ({ searchPoolProfileId }) => searchPoolProfileId),
    [results],
  );
  const { profiles = [] } = useSearchPoolProfiles({ profileIds });
  const companyOptions = useMemo(
    () =>
      _.chain(profiles)
        .compact()
        .map(({ id, name, resumeData }) => ({
          value: id,
          label: name || resumeData?.firstname || id,
        }))
        .sortBy('label')
        .value(),
    [profiles],
  );
  const selectedOption = useMemo(
    () =>
      miniProfile
        ? {
            value: miniProfile.id,
            label: miniProfile.name || miniProfile.resumeData.firstname || '',
          }
        : undefined,
    [miniProfile],
  );
  return (
    <>
      <div className={styles.label}>
        {label || t('reveal.missions.mission.settingsTab.company')}
      </div>
      <GenericSelect
        options={companyOptions}
        isClearable
        value={selectedOption}
        inputValue={searchText}
        onInputChange={setSearchText}
        onChange={(option) => {
          if (!option) {
            onLinkedCompany(undefined);
          }
          if (
            option &&
            typeof option === 'object' &&
            'value' in option &&
            typeof option.value === 'string'
          ) {
            onLinkedCompany(option.value);
          }
        }}
        maxMenuHeight={maxMenuHeight}
        noOptionsMessage={loading ? () => t('common.loading') : undefined}
      />
    </>
  );
};

export default TargetsForm;
