import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { FreeTrialConfiguration } from '@/types/client';

const GET_FREE_TRIAL_CONFIGURATION = gql`
  query getFreeTrialConfiguration($clientId: ID!) {
    client(id: $clientId) {
      id
      freeTrialConfiguration {
        upgraded
        upgradeDate
        startDate
        endDate
      }
    }
  }
`;

type GetFreeTrialConfigurationData = {
  client: {
    __typename: 'Client';
    freeTrialConfiguration?: {
      __typename: 'FreeTrialConfiguration';
    } & FreeTrialConfiguration;
  };
};

type GetFreeTrialConfigurationVariables = {
  clientId: string;
};

interface UseFreeTrialConfigurationInput {
  clientId: string;
  queryOptions?: QueryHookOptions<
    GetFreeTrialConfigurationData,
    GetFreeTrialConfigurationVariables
  >;
}

interface UseFreeTrialConfigurationResult
  extends QueryResult<
    GetFreeTrialConfigurationData,
    GetFreeTrialConfigurationVariables
  > {
  freeTrialConfiguration: FreeTrialConfiguration;
}

function useFreeTrialConfiguration({
  clientId,
  queryOptions = {},
}: UseFreeTrialConfigurationInput): UseFreeTrialConfigurationResult {
  const query = useQuery<
    GetFreeTrialConfigurationData,
    GetFreeTrialConfigurationVariables
  >(GET_FREE_TRIAL_CONFIGURATION, { ...queryOptions, variables: { clientId } });

  const { freeTrialConfiguration = {} } = query.data?.client || {};

  return { ...query, freeTrialConfiguration };
}

export default useFreeTrialConfiguration;
