import { getTranslatedText } from '@/common';
import RichReader from '@/components/RichEditor/RichReader';
import React from 'react';
import ReactPlayer from 'react-player';
import { Image } from 'semantic-ui-react';
import _ from 'underscore';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import GenericDropdown from '@/components/Common/GenericDropdown';
import GenericButton from '@/components/Common/GenericButton';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import { JobsLayout, Theme } from './DescriptionModal';
import styles from './DescriptionPreview.module.less';

interface DescriptionPreviewProps {
  sections: any[];
  theme: Theme;
  layout: JobsLayout;
}

const DescriptionPreview: React.FC<DescriptionPreviewProps> = ({
  sections,
  theme,
  layout,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {_.map(sections || [], (section, index) => (
        <div className={styles.previewSection} key={index}>
          {section.type === 'simple-section' && (
            <div className='simple-section'>
              <div
                className={classNames('title', styles.sectionTitle)}
                style={{ color: theme?.font || '' }}
              >
                <h3>{getTranslatedText(section?.title)}</h3>
              </div>
              <div className='content' style={{ color: theme?.font || '' }}>
                <RichReader initialHtml={getTranslatedText(section?.content)} />
              </div>
            </div>
          )}
          {section.type === 'video-section' && (
            <div className='video-section'>
              <div className='react-player-container'>
                <ReactPlayer
                  className='react-player'
                  url={section.url}
                  width='100%'
                  height='100%'
                  controls
                  config={{
                    youtube: {
                      playerVars: { showinfo: 1, controls: 1 },
                    },
                  }}
                />
              </div>
            </div>
          )}
          {section.type === 'image-section' && (
            <div className='image-section'>
              <Image
                className='react-player'
                src={section.file.url}
                width='100%'
                height='100%'
              />
            </div>
          )}
          {section.type === 'job-postings-list' && (
            <>
              {layout?.showFilter && (
                <GenericDropdown
                  disabled
                  className={styles.jobsfilter}
                  Trigger={({ onClick }) => (
                    <GenericButton
                      primacy='secondary'
                      type='button'
                      onClick={onClick}
                      className={styles.dropdownTrigger}
                      disabled
                    >
                      <span className={styles.title}>
                        {t('reveal.missions.mission.profiles.filter.button')}
                      </span>
                      <span className={styles.arrow}>
                        <ArrowDown />
                      </span>
                    </GenericButton>
                  )}
                />
              )}
              <div
                className={classNames(
                  styles.jobPostingCardsContainer,
                  layout?.displayType === 'grid' ? styles.gridLayout : '',
                )}
              >
                <div
                  className={styles.jobPostingCard}
                  style={{
                    backgroundColor: theme?.jobs?.background,
                    color: theme?.jobs?.font,
                    borderRadius: theme?.jobs?.border?.radius,
                    ...(theme?.jobs?.border?.color && {
                      borderColor: theme?.jobs?.border?.color,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                    }),
                  }}
                >
                  {t('settings.careerPage.description.modal.exampleJobPosting')}{' '}
                  #1
                </div>
                <div
                  className={styles.jobPostingCard}
                  style={{
                    backgroundColor: theme?.jobs?.background,
                    color: theme?.jobs?.font,
                    borderRadius: theme?.jobs?.border?.radius,
                    ...(theme?.jobs?.border?.color && {
                      borderColor: theme?.jobs?.border?.color,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                    }),
                  }}
                >
                  {t('settings.careerPage.description.modal.exampleJobPosting')}{' '}
                  #2
                </div>
                <div
                  className={styles.jobPostingCard}
                  style={{
                    backgroundColor: theme?.jobs?.background,
                    color: theme?.jobs?.font,
                    borderRadius: theme?.jobs?.border?.radius,
                    ...(theme?.jobs?.border?.color && {
                      borderColor: theme?.jobs?.border?.color,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                    }),
                  }}
                >
                  {t('settings.careerPage.description.modal.exampleJobPosting')}{' '}
                  #3
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default DescriptionPreview;
