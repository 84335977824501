import { Link } from 'react-router-dom';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import _ from 'underscore';

import { TabId } from '@/revealComponents/ProjectTypesTabs';
import {
  CalibrationAndRecommendationIcon,
  ImportIcon,
  InfosIcon,
} from '@/assets/icons';
import MissionWorkflows from '@/components/Reveal/MissionWorkflows/MissionWorkflows';
import {
  MissionWorkflowsProvider,
  WORKFLOWS_CONTEXT_MODE,
} from '@/components/Reveal/MissionWorkflows/context/useMissionWorkflowsContext';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import usePipelineSubSteps from '@/graphql/hooks/clients/usePipelineSubSteps';

import JobInformationsTab from '../InformationsTab/InformationTab';
import JobCalibrationView from '../../Calibration';
import JobSequencesTab from '../JobSequencesTab/JobSequencesTab';
import JobPipelineView from '../../JobPipelineView/index';
import RevealJobPosting from '../JobPostingsTab';

import './allSettingsTab.css';
import DynamicImports from '../DynamicImportsTab';
import PositionDescription from '../PositionDescription/PositionDescription';

interface AllSettingsTabProps {
  jobId: string;
  clientId: string;
  activeTab: string;
}

const AllSettingsTab: React.FC<AllSettingsTabProps> = ({
  jobId,
  clientId,
  activeTab,
}) => {
  const { t } = useTranslation();
  const { permissions } = useClientPermissions(clientId);
  const missionCategoryType = useContext(MissionCategoryContext);
  const { pipelineSubSteps } = usePipelineSubSteps();
  const subStepsDefinition = useMemo(
    () =>
      _.find(
        pipelineSubSteps,
        ({ missionCategory, stepId }) =>
          missionCategory.type === missionCategoryType &&
          stepId === 'interested',
      ),
    [pipelineSubSteps, missionCategoryType],
  );
  const missionInterestedSubSteps = useMemo(
    () => subStepsDefinition?.subSteps || [],
    [subStepsDefinition],
  );

  return (
    <div className='settingTab'>
      <div className='menu'>
        <div className='ui list'>
          <div className='item'>
            <Button
              className={`default settingLink${
                activeTab === 'settings' ? ' active' : ''
              }`}
              as={Link}
              to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/settings`}
            >
              <InfosIcon className='menu-icon' />
              {t('reveal.missions.mission.settingsTab.informations')}
            </Button>
          </div>
          <div className='item'>
            <Button
              className={`default settingLink${
                activeTab === 'sequences' ? ' active' : ''
              }`}
              as={Link}
              to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/sequences`}
            >
              <i className='ri-stack-line menu-icon' />
              {t('reveal.missions.mission.settingsTab.sequences')}
            </Button>
          </div>
          {permissions?.customPipelines &&
            !_.isEmpty(missionInterestedSubSteps) && (
              <div className='item'>
                <Button
                  className={`default settingLink${
                    activeTab === 'pipeline' ? ' active' : ''
                  }`}
                  as={Link}
                  to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/pipeline`}
                >
                  <i className='ri-list-ordered menu-icon' />
                  {t('reveal.missions.mission.settingsTab.pipeline.title')}
                </Button>
              </div>
            )}
          {missionCategoryType === 'recruiting' && (
            <>
              <div className='item'>
                <Button
                  className={`default settingLink${
                    activeTab === 'calibration' ? ' active' : ''
                  }`}
                  as={Link}
                  to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/calibration`}
                >
                  <CalibrationAndRecommendationIcon className='menu-icon' />
                  {t('reveal.missions.mission.settingsTab.calibration')}
                </Button>
              </div>
              <div className='item'>
                <Button
                  className={`default settingLink${
                    activeTab === 'workflows' ? ' active' : ''
                  }`}
                  as={Link}
                  to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/workflows`}
                >
                  <i className='ri-speed-mini-fill menu-icon' />
                  {t('reveal.workflows.missionWorkflows.title')}
                </Button>
              </div>
            </>
          )}
          {permissions?.projectDynamicImports && (
            <div className='item'>
              <Button
                className={`default settingLink${
                  activeTab === 'dynamic-imports' ? ' active' : ''
                }`}
                as={Link}
                to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/dynamic-imports`}
              >
                <ImportIcon className='menu-icon' />
                {t('reveal.missions.mission.settingsTab.imports.title')}
              </Button>
            </div>
          )}
          {missionCategoryType === 'recruiting' && (
            <>
              {permissions?.positionDescription && (
                <div className='item'>
                  <Button
                    className={`default settingLink${
                      activeTab === 'position-description' ? ' active' : ''
                    }`}
                    as={Link}
                    to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/position-description`}
                  >
                    <i className='ri-account-pin-circle-fill menu-icon' />
                    {t(
                      'reveal.missions.mission.settingsTab.positionDescription.title',
                    )}
                  </Button>
                </div>
              )}
              {permissions?.careerPage && (
                <div className='item'>
                  <Button
                    className={`default settingLink${
                      activeTab === 'job-posting' ? ' active' : ''
                    }`}
                    as={Link}
                    to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${jobId}/job-posting`}
                  >
                    <i className='ri-file-list-3-line menu-icon' />
                    {t('reveal.missions.mission.settingsTab.jobPosting.title')}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className='contentTab'>
        {activeTab === TabId.CALIBRATION && (
          <JobCalibrationView
            clientId={clientId}
            searchPoolId='reveal'
            jobId={jobId}
          />
        )}

        {activeTab === TabId.SEQUENCES && (
          <JobSequencesTab
            clientId={clientId}
            searchPoolId='reveal'
            jobId={jobId}
          />
        )}

        {activeTab === TabId.PIPELINE && <JobPipelineView jobId={jobId} />}

        {activeTab === TabId.SETTINGS && (
          <JobInformationsTab
            clientId={clientId}
            searchPoolId='reveal'
            jobId={jobId}
          />
        )}

        {activeTab === TabId.WORKFLOWS && (
          <MissionWorkflowsProvider
            mode={WORKFLOWS_CONTEXT_MODE.MISSION_WORKFLOWS}
          >
            <MissionWorkflows />
          </MissionWorkflowsProvider>
        )}

        {activeTab === TabId.DYNAMIC_IMPORTS && (
          <DynamicImports jobId={jobId} />
        )}

        {activeTab === TabId.JOB_POSTING && permissions?.careerPage && (
          <RevealJobPosting jobId={jobId} />
        )}

        {activeTab === TabId.POSITION_DESCRIPTION &&
          permissions?.positionDescription && (
            <PositionDescription jobId={jobId} />
          )}
      </div>
    </div>
  );
};

export default AllSettingsTab;
