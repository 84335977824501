import { RawLocationAndRemoteCriteria } from './locationAndRemote';

export const includeProfilesInJobValues = [
  'pending-only',
  'all',
  'none',
] as const;

export type IncludeProfilesInJob = (typeof includeProfilesInJobValues)[number];

export interface SearchCriteria {
  locationsAndRemote?: RawLocationAndRemoteCriteria;
  schoolTypes?: unknown[];
  jobPositions?: unknown[];
  jobPositionsOperator?: 'OR' | 'AND';
  experienceYearsMin?: number;
  experienceYearsMax?: number;
  skills?: unknown[];
  skillsOperator?: 'OR' | 'AND';
  salaryMin?: number;
  salaryMax?: number;
  contracts?: unknown[];
  responsibilities?: unknown[];
  excludeHidden?: boolean;
  excludeProfilesInJob?: boolean;
  includeProfilesInJob?: IncludeProfilesInJob;
  shownInterestOnly?: boolean;
  free?: string;
}

export interface StorageSearchCriteria {
  locationsAndRemote?: RawLocationAndRemoteCriteria;
  schoolTypes?: unknown[];
  jobPositions?: unknown[];
  jobPositionsOperator?: 'OR' | 'AND';
  experienceYearsMin?: string;
  experienceYearsMax?: string;
  skills?: unknown[];
  skillsOperator?: 'OR' | 'AND';
  salaryMin?: string;
  salaryMax?: string;
  contracts?: unknown[];
  responsibilities?: unknown[];
  excludeHidden?: boolean;
  excludeProfilesInJob?: boolean;
  includeProfilesInJob?: IncludeProfilesInJob;
  shownInterestOnly?: boolean;
}

export type AvailabilityFilter = {
  nextAvailabilityDateFilter?: DateFilter;
  medicalExaminationExpirationDateFilter?: DateFilter;
  constructionCardExpirationDateFilter?: DateFilter;
};

type RelativeMaxDateFilter = {
  maxDelayInDays: number;
};

type AbsoluteMaxDateFilter = {
  maxDate: Date;
};

type AbsoluteMinDateFilter = {
  minDate: Date;
};

export type DateFilter = (
  | RelativeMaxDateFilter
  | AbsoluteMaxDateFilter
  | AbsoluteMinDateFilter
) & {
  strict?: boolean;
  orNull?: boolean;
};

export function isRelativeMax(
  filter: DateFilter,
): filter is RelativeMaxDateFilter {
  return (filter as RelativeMaxDateFilter).maxDelayInDays !== undefined;
}

export function isAbsoluteMax(
  filter: DateFilter,
): filter is AbsoluteMaxDateFilter {
  return (filter as AbsoluteMaxDateFilter).maxDate !== undefined;
}
