import gql from "graphql-tag";
import TextWithEnrichmentsAndStack from "./fragments/TextWithEnrichmentsAndStack";
import CustomFieldsValues from "./fragments/CustomFieldsValues";

export const OVERVIEW_MINI_PROFILE = gql`
  query getOverviewMiniProfile($searchPoolId: ID!, $profileId: ID!) {
    searchPool(id: $searchPoolId) {
      id
      profile(id: $profileId) {
        resumeData {
          firstname
          lastname
          photoLink
          email
          headline {
            ...TextWithEnrichmentsAndStack
          }
          customFields {
            ...CustomFieldsValues
          }
        }
      }
    }
  }
  ${TextWithEnrichmentsAndStack}
  ${CustomFieldsValues}
`;