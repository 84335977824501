import gql from 'graphql-tag';
import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

const GET_CLIENT_ACTIVITY = gql`
  query getClientActivity($clientId: ID!) {
    client(id: $clientId) {
      id
      activity {
        events {
          type
          subtype
          date
          ... on ProfilesActivityEvent {
            profiles {
              id
              resumeData {
                firstname
                lastname
                headline {
                  content {
                    text
                  }
                }
                photoLink
              }
            }
            offer {
              id
              title
              isUnclassified
            }
            totalProfilesCount
          }
          ... on SingleProfileActivityEvent {
            profile {
              id
              resumeData {
                firstname
                lastname
                headline {
                  content {
                    text
                  }
                }
                photoLink
              }
            }
            offer {
              id
              title
              isUnclassified
            }
          }
          ... on UserActivityEvent {
            user {
              email
              firstname
              lastname
            }
          }
          ... on OfferActivityEvent {
            offer {
              id
              title
              isUnclassified
            }
          }
          # ... on OfferPushFlowPolicyActivityEvent {
          #   offer {
          #     id
          #     title
          #   }
          #   pushFlowPolicy {
          #     nbPerDay
          #   }
          # }
        }
      }
    }
  }
`;

interface ActivityEvent {
  type: string;
  subtype: string;
  date: Date;
}

interface GetClientActivityData {
  client: {
    id: string;
    activity?: {
      events?: ActivityEvent[];
    };
  };
}

interface GetClientActivityVariables {
  clientId: string;
}

interface UseClientActivityResult
  extends QueryResult<GetClientActivityData, GetClientActivityVariables> {
  events: ActivityEvent[];
}

interface UseClientActivityInput extends GetClientActivityVariables {
  queryOptions?: QueryHookOptions<
    GetClientActivityData,
    GetClientActivityVariables
  >;
}

const useClientActivity = ({
  clientId,
  queryOptions = {},
}: UseClientActivityInput): UseClientActivityResult => {
  const query = useQuery<GetClientActivityData, GetClientActivityVariables>(
    GET_CLIENT_ACTIVITY,
    {
      ...queryOptions,
      variables: {
        clientId,
      },
    },
  );

  const { events = [] } = query.data?.client.activity || {};

  return { ...query, events };
};

export default useClientActivity;
