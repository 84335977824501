import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { Image } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ActionButton from '@/components/ActionButton';
import { combineDataWithCustomFields } from '@/common/customFields';
import TranslatableText from '@/components/TranslatableText';

import {
  getSecureLink,
  getTranslatedText,
  getRandomDefaultAvatarLink,
  removeEmptyKeyValues,
} from '@/common';
import logAction from '@/common/logAction';
import withProfileCurrentTask from '@/hocs/searchPoolProfile/withProfileCurrentTask';
import withActionLogger from '@/hocs/withActionLogger';
import withUserFromJWToken from '@/hocs/users/withUserFromJWToken';
import withMarkProfileAsShared from '@/hocs/profiles/withMarkProfileAsShared';
import LoadingComponent from '@/components/LoadingComponent';
import SaveProfileInfoToClipboard from '@/containers/hotkeys/SaveProfileInfoToClipboard';
import ProfileSources from '@/containers/Profile/ProfileSources';

import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import './RevealProfileHeader.css';
import NewProfileMissionsManagement from '@/revealComponents/ProfileProjectsTab/NewProfileMissionsManagement/NewProfileMissionsManagement';
import DoNotContactPill from '@/components/Reveal/Profile/privacy/DoNotContactPill/DoNotContactPill';
import useResizableItemList from '@/hooks/common/useResizableItemList';
import ProfileViews from '@/components/Profile/ProfileViews';
import GenericTooltip from '@/components/Common/GenericTooltip';
import { getTaskType } from '@/common/constants/taskTypes';
import { sweetDateToMomentDate } from '@/common/dates';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import classNames from 'classnames';
import CompanyStageEdition from '@/revealComponents/ProfileProjectsTab/CompanyStageEdition';
import withClientPermissions from '@/hocs/clients/withClientPermissions';
import ProfileActionsMenu from './ProfileActionsMenu';
import DownloadResumeButton from './DownloadResumeButton';
import RevealLinkedProfilesSwitch from './RevealLinkedProfilesSwitch';

export const replacePhotoLink = (link: string) =>
  (link || '').replace('shrinknp_400_400', 'shrinknp_75_75');

function isProfileDeletable(profile: any) {
  const ATS_KEYS = [
    'smartrecruiters',
    'lever',
    'greenhouse',
    'teamtailor',
    'workable',
    'recruitee',
  ];
  return _.isEmpty(
    removeEmptyKeyValues(_.pick(profile?.resumeData?.sourceData, ATS_KEYS)),
  );
}

const getElementWidth = (id: string) => {
  return document.getElementById(id)?.getBoundingClientRect()?.width;
};

class RevealProfileHeader extends React.PureComponent {
  static defaultProps = {
    editable: false,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      linkCopiedToClipboard: false,
    };
  }

  shareProfile = async () => {
    const { markProfileAsShared, clientId, profileId, offerId, profile }: any =
      this.props;
    if (!clientId || !profileId || !(offerId || profile?.offer?.id)) {
      console.error('Missing props');
      return;
    }
    await markProfileAsShared({ profileId });
    await navigator.clipboard.writeText(
      `${window.origin}/client/${clientId}/jobs/${
        offerId || profile?.offer?.id
      }/profiles?pid=${encodeURIComponent(btoa(profileId))}`,
    );
    this.setState({ linkCopiedToClipboard: true });
  };

  copyEmail = async () => {
    const { profile }: any = this.props;
    if (profile?.resumeData?.email) {
      await navigator.clipboard.writeText(profile?.resumeData?.email);
    }
  };

  render() {
    const {
      t,
      location: routerLocation,
      profileId,
      stepId,
      resumeData,
      firstname,
      lastname,
      user,
      currentTask,
      clientId,
      profile,
      offerId,
      customFields,
      showEmailInHeader,
      // withBottomMargin,
      applicationReviewMode,
      editable,
      shouldHideProfileHeaderEditionButtons,
      shouldHideResumeDownloadButton,
      displayProjectAndSequenceOptions = true,
      toggleEditMode,
      deleteProfile,
      missionsInfo,
      searchPoolId,
      onChangeStage,
      permissions,
    }: any = this.props;
    const { linkCopiedToClipboard }: any = this.state;

    const unbiasedModeEnabled =
      (user || {}).unbiasedModeEnabled &&
      (stepId === 'pending' || applicationReviewMode);
    const { mainEducation, photoLink, headline, location, phoneNumber } =
      resumeData || {};
    const headlineText = ((headline || {}).content || {}).text;
    const locationName = getTranslatedText((location || {}).name || '');
    const locationCanBeDisplayed = getLocationCanBeDisplayed(locationName);
    const { schoolName } = mainEducation || {};

    const endDate = sweetDateToMomentDate(
      mainEducation && mainEducation.endDate,
    );

    // const studiedAt =
    //   resumeData?.firstname && isFemaleName(resumeData?.firstname)
    //     ? t('profile.resume.femaleStudiedAt')
    //     : t('profile.resume.studiedAt');

    const mainEducationText = schoolName
      ? `${schoolName} ${endDate ? `(${endDate.fromNow()})` : ''}`
      : '';

    const isDeletable = isProfileDeletable(profile);

    const getFullHeadline = () => {
      let text = headlineText || '';
      if (headlineText && locationCanBeDisplayed && locationName) text += ' • ';
      if (locationCanBeDisplayed && locationName) text += locationName;
      return text;
    };

    const onClickSource = ({ e, sourceId }: { e: any; sourceId: string }) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }

      logAction({
        searchPoolProfileId: profile?.id,
        type: 'click-profile-source',
        info: {
          sourceId,
          from: 'profile-header',
        },
      });

      if (sourceId === 'linkedin') {
        setPluginTaskState({ task: currentTask });
      }
    };

    const windowLocation = routerLocation.pathname;
    const displayLinkedProfiles =
      !/reveal\/tasks/.test(windowLocation) &&
      !/reveal\/tasks/.test(windowLocation);

    const hasBackgroundValues =
      !_.isEmpty(profile.resumeData?.experiences) ||
      !_.isEmpty(profile.resumeData?.education) ||
      !_.isEmpty(profile.resumeData?.summary?.content?.text);

    return (
      <SaveProfileInfoToClipboard
        profile={profile}
        offerId={offerId || profile?.offer?.id}
      >
        <div className='reveal-profile-top-header-container'>
          <div className='avatar-and-infos'>
            <AvatarAndEnrichmentDateIndicator
              firstname={firstname}
              lastname={lastname}
              photoLink={photoLink}
              unbiasedModeEnabled={unbiasedModeEnabled}
              lastEnrichmentDate={
                resumeData?.enrichmentInfo?.lastEnrichmentDate
              }
              creationTimestamp={+profile.creationTimestamp}
              sourceType={profile.source?.type}
              sourceLinkedin={resumeData?.sources?.linkedin}
              hasBackgroundValues={hasBackgroundValues}
              t={t}
            />
            <div className='infos'>
              <LoadingComponent
                as='div'
                loading={_.isEmpty(resumeData)}
                length={30}
                margin={0.6}
              >
                <div className='inline-middle'>
                  <h2
                    className={`fullname${
                      unbiasedModeEnabled ? ' unbiased' : ''
                    }`}
                    data-openreplay-masked
                  >
                    {resumeData?.firstname} {resumeData?.lastname}
                    {user?.isAdmin &&
                      clientId &&
                      profileId &&
                      (offerId || profile?.offer?.id) && (
                        <i
                          className={`ri-external-link-line${
                            linkCopiedToClipboard ? ' copied' : ''
                          }`}
                          onClick={() => this.shareProfile()}
                        />
                      )}
                  </h2>
                </div>
                <ProfileViews profile={profile} />
                {profile.privacyState?.markedAsDoNotContact && (
                  <DoNotContactPill
                    text={getTranslatedText(
                      profile.privacyState?.reason?.title || {},
                    )}
                  />
                )}
                {!shouldHideProfileHeaderEditionButtons && (
                  <>
                    {editable && (
                      <div className='icons-container inline-middle'>
                        <ActionButton
                          icon='edit-2-fill ri-lg'
                          onClick={() => toggleEditMode()}
                        />
                        {isDeletable && (
                          <ActionButton
                            icon='delete-bin-fill ri-lg'
                            onClick={() => deleteProfile()}
                          />
                        )}
                      </div>
                    )}
                    <ProfileActionsMenu profile={profile} clientId={clientId} />
                  </>
                )}
                {displayLinkedProfiles && (
                  <RevealLinkedProfilesSwitch profile={profile} />
                )}
              </LoadingComponent>
              <LoadingComponent
                as='div'
                loading={_.isEmpty(resumeData)}
                length={50}
                margin={0.6}
              >
                <p className='headline'>{getFullHeadline()}</p>
                <p className='main-education'>{mainEducationText}</p>
              </LoadingComponent>
              <LoadingComponent
                as='div'
                loading={_.isEmpty(resumeData)}
                length={50}
                margin={0.6}
              />
              <div className='sources-container'>
                <PhoneNumber phoneNumber={phoneNumber} />
                <Email
                  email={resumeData?.email}
                  showEmailInHeader={showEmailInHeader}
                />
                <ProfileSources
                  sources={resumeData?.sources}
                  onClickSource={onClickSource}
                />
                {!shouldHideResumeDownloadButton && (
                  <DownloadResumeButton
                    profileId={profileId}
                    searchPoolId='reveal'
                  />
                )}
              </div>
            </div>
          </div>
          {searchPoolId === 'reveal' && displayProjectAndSequenceOptions && (
            <div className='mission-sequence-container'>
              <NewProfileMissionsManagement
                clientId={clientId}
                missionsInfo={missionsInfo}
                missions={_.pluck(missionsInfo || [], 'mission')}
                onChangeStage={onChangeStage}
              />
            </div>
          )}
          {searchPoolId === 'reveal' &&
            !displayProjectAndSequenceOptions &&
            profile.contactCategory?.type === 'company' &&
            permissions?.companiesDealStates && (
              <CompanyStageEdition
                company={profile}
                onChangeStage={onChangeStage}
              />
            )}
        </div>
        <ProfileHeaderCustomFields
          customFields={customFields}
          resumeData={resumeData}
        />
      </SaveProfileInfoToClipboard>
    );
  }
}

export function ProfileHeaderCustomFields({
  customFields,
  resumeData,
}: {
  customFields: any;
  resumeData: any;
}) {
  const isPlugin = useIsPlugin();
  const atsId = useClientCurrentAtsId();
  const topFields = (customFields || []).filter(
    (cf: any) => cf.display === 'profile-top',
  );

  const { containerRef, listRef, displayedItemsNumber, finalWidth } =
    useResizableItemList<HTMLDivElement>(24);

  const fields = combineDataWithCustomFields(topFields, resumeData);
  const fieldsWithValue = _.filter(fields, (field: any) => field.readableValue);

  if (_.isEmpty(fieldsWithValue)) {
    return null;
  }

  return (
    <div
      className={classNames(
        'custom-fields-container',
        isPlugin ? 'plugin-custom-fields' : '',
        atsId === 'adventure' ? 'adventure' : '',
      )}
      ref={containerRef}
    >
      <div
        className='custom-fields-list'
        ref={listRef}
        style={{ width: finalWidth + displayedItemsNumber * 4 }}
      >
        {fieldsWithValue.map((f, index) =>
          !f.readableValue ? null : (
            <div
              key={f.clientCustomFieldId}
              className={`header-custom-field ${
                index > displayedItemsNumber ? 'hidden' : ''
              }`}
            >
              <div className='custom-field-title'>
                <TranslatableText text={f.title} />
              </div>
              <div className='custom-field-value'>{f.readableValue}</div>
            </div>
          ),
        )}
      </div>
      {fieldsWithValue.length - displayedItemsNumber > 0 && (
        <div className='header-custom-field'>
          <div className='custom-field-value'>
            +{fieldsWithValue.length - displayedItemsNumber}
          </div>
        </div>
      )}
    </div>
  );
}

const setPluginTaskState = ({ task }: any) => {
  let message;
  if (task) {
    message = {
      type: 'set-task-state',
      data: { task, activeTaskListId: getTaskType(task) },
    };
  } else {
    message = {
      type: 'clear-task-state',
    };
  }
  window.postMessage(message, '*');
};

const getLocationCanBeDisplayed = (locationName: any) => {
  if (/\d/.test(locationName)) {
    // No digits in locationName
    return false;
  }
  return true;
};

export const getBeautifiedPhoneNumber: any = ({ phoneNumber }: any) => {
  if (!phoneNumber) {
    return phoneNumber;
  }
  if (phoneNumber.indexOf('.') >= 0) {
    return phoneNumber;
  }
  const withoutSpace = phoneNumber.replace(/\s/g, '');

  if (withoutSpace.indexOf('+336') === 0) {
    return `+336 ${getBeautifiedPhoneNumber({
      phoneNumber: withoutSpace.slice(4),
    })}`;
  }
  if (withoutSpace.indexOf('+337') === 0) {
    return `+337 ${getBeautifiedPhoneNumber({
      phoneNumber: withoutSpace.slice(4),
    })}`;
  }

  if (/^[0-9]*$/.test(withoutSpace)) {
    if (withoutSpace.length === 8 || withoutSpace.length === 10) {
      let result = '';
      _.each(withoutSpace.split(''), (c) => {
        if (
          result.length > 1 &&
          result[result.length - 1] !== ' ' &&
          result[result.length - 2] !== ' '
        ) {
          result += ' ';
        }
        result += c;
      });
      return result;
    }
  }
  return phoneNumber;
};

interface AvatarAndEnrichmentDateIndicatorProps {
  photoLink?: string;
  unbiasedModeEnabled?: boolean;
  firstname?: string;
  lastname?: string;
  lastEnrichmentDate?: string;
  creationTimestamp?: number;
  sourceType?: string;
  sourceLinkedin?: string;
  hasBackgroundValues?: boolean;
  t: any;
}

export const AvatarAndEnrichmentDateIndicator: React.FC<
  AvatarAndEnrichmentDateIndicatorProps
> = ({
  photoLink,
  unbiasedModeEnabled,
  firstname,
  lastname,
  lastEnrichmentDate,
  creationTimestamp,
  sourceType,
  sourceLinkedin,
  hasBackgroundValues,
  t,
}) => {
  const avatarImageUrl =
    photoLink && !unbiasedModeEnabled
      ? replacePhotoLink(photoLink)
      : getRandomDefaultAvatarLink(`${firstname}${lastname}`);

  const nbMsInAMonth = 30 * 86400 * 1000;
  const isNotRecentlyUpdated =
    lastEnrichmentDate &&
    new Date(lastEnrichmentDate) < new Date(Date.now() - 4 * nbMsInAMonth);

  return (
    <div className='avatar' data-openreplay-masked>
      <Image
        data-openreplay-masked
        className='card-company-image'
        src={getSecureLink(avatarImageUrl || '')}
        circular
        onError={(e: any) => {
          e.target.src = getRandomDefaultAvatarLink(`${firstname}${lastname}`);
        }}
      />
      {(lastEnrichmentDate ||
        (sourceType === 'reveal-plugin-import' &&
          hasBackgroundValues &&
          sourceLinkedin)) && (
        <GenericTooltip
          trigger={
            <i
              className={`ri-refresh-fill last-sync-icon ${
                isNotRecentlyUpdated ? ' not-updated' : ''
              }`}
            />
          }
          content={linkedinLastSyncDiff({
            date:
              lastEnrichmentDate ||
              new Date(creationTimestamp || 0).toISOString(),
            t,
          })}
          position='right center'
          popperModifiers={[
            {
              // Fix a positioning problem
              name: 'preventOverflow',
              options: {
                boundariesElement: 'offsetParent',
              },
            },
          ]}
        />
      )}
    </div>
  );
};

interface EmailProps {
  email?: string;
  showEmailInHeader?: boolean;
}

const Email: React.FC<EmailProps> = ({ email, showEmailInHeader }) => {
  const [emailCopiedToClipboard, setEmailCopiedToClipboard] = useState(false);
  const [popupWidth, setPopupWidth] = useState('100px');
  if (!email || !showEmailInHeader) {
    return null;
  }

  const copyMailToClipBoard = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setPopupWidth(`${getElementWidth('mail-popup') || 100}px`);
    await navigator.clipboard.writeText(email);
    setEmailCopiedToClipboard(true);
    setTimeout(() => {
      setEmailCopiedToClipboard(false);
    }, 1000);
  };

  return (
    <button type='button' className='hover-icon' onClick={copyMailToClipBoard}>
      <GenericTooltip
        trigger={<i className='ri-mail-fill' />}
        content={
          emailCopiedToClipboard ? (
            <div
              style={{
                width: popupWidth,
                textAlign: 'center',
              }}
            >
              Copied
            </div>
          ) : (
            <span id='mail-popup'>{email}</span>
          )
        }
        position='bottom center'
        positionFixed
        popperModifiers={[
          {
            // Fix a positioning problem
            name: 'preventOverflow',
            options: {
              boundariesElement: 'offsetParent',
            },
          },
        ]}
        on='hover'
      />
    </button>
  );
};

interface PhoneNumberProps {
  phoneNumber?: string;
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({ phoneNumber }) => {
  const [numberCopiedToClipboard, setNumberCopiedToClipboard] = useState(false);
  const [popupWidth, setPopupWidth] = useState('100px');

  if (!phoneNumber) {
    return null;
  }

  const copyNumberToClipBoard = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setPopupWidth(`${getElementWidth('number-popup') || 100}px`);
    await navigator.clipboard.writeText(phoneNumber);
    setNumberCopiedToClipboard(true);
    setTimeout(() => {
      setNumberCopiedToClipboard(false);
    }, 1000);
  };

  return (
    <button
      type='button'
      className='hover-icon'
      onClick={copyNumberToClipBoard}
    >
      <GenericTooltip
        trigger={<i className='ri-phone-fill' />}
        content={
          numberCopiedToClipboard ? (
            <div
              style={{
                width: popupWidth,
                textAlign: 'center',
              }}
            >
              Copied
            </div>
          ) : (
            <span id='number-popup'>
              {getBeautifiedPhoneNumber({ phoneNumber })}
            </span>
          )
        }
        position='bottom center'
        positionFixed
        popperModifiers={[
          {
            // Fix a positioning problem
            name: 'preventOverflow',
            options: {
              boundariesElement: 'offsetParent',
            },
          },
        ]}
        on='hover'
      />
    </button>
  );
};

const linkedinLastSyncDiff = ({ date, t }: { date: string; t: any }) => {
  if (!date) {
    return t('profile.resume.notSync');
  }
  const lastSync = Date.parse(date);
  const now = Date.now();
  const diff = (now - lastSync) / 1000;
  const DAY = 86400;
  // less than one day
  if (diff < DAY) {
    return t('profile.resume.lastLinkedInSyncToday');
  }
  // less than 5 days
  if (diff < DAY * 6) {
    return t('profile.resume.lastLinkedInSyncSinceDays', {
      count: Math.floor(diff / 84600),
    });
  }
  // weeks
  if (diff < 28 * DAY) {
    return t('profile.resume.lastLinkedInSyncSinceWeeks', {
      count: Math.max(1, Math.floor(diff / (7 * 84600))),
    });
  }
  // months
  if (diff < 7 * 30 * DAY) {
    return t('profile.resume.lastLinkedInSyncSinceMonths', {
      count: Math.max(1, Math.floor(diff / (30 * DAY))),
    });
  }
  return t('profile.resume.notSync');
};

export default compose(
  withRouter,
  withActionLogger,
  withUserFromJWToken,
  withMarkProfileAsShared,
  withTranslation('translations'),
  withProfileCurrentTask,
  withClientPermissions,
)(RevealProfileHeader);
