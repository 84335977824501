import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import TranslatableTextFragment from '@/graphql/fragments/TranslatableText';
import useClientId from '@/hooks/router/useClientId';
import { TranslatableText } from '@/types/text';

const getCustomCompanyDealStates = gql`
  query getCustomCompanyDealStates($clientId: ID!) {
    client(id: $clientId) {
      customCompanyDealStates {
        customCompanyDealStateId
        title {
          ...TranslatableText
        }
        type
      }
    }
  }
  ${TranslatableTextFragment}
`;

export type ClientCompanyDealState = {
  customCompanyDealStateId: string;
  title: TranslatableText;
  type: string;
};

type Data = {
  client: {
    id: string;
    customCompanyDealStates?: ClientCompanyDealState[];
  };
};

type Variables = {
  clientId: string;
};

function useClientCustomCompanyDealStates() {
  const clientId = useClientId();
  const query = useQuery<Data, Variables>(getCustomCompanyDealStates, {
    variables: {
      clientId,
    },
  });
  const customCompanyDealStates =
    query.data?.client.customCompanyDealStates || [];
  return { ...query, customCompanyDealStates };
}

export default useClientCustomCompanyDealStates;
