import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { sanitizeTypename } from '@/common/utils/apollo';

import ActionButton from '@/components/ActionButton';
import {
  CustomProfileActionInput,
  DoNotContactInput,
  SearchPoolProfile,
} from '@/types/searchPoolProfile';
import {
  CLIENT_CUSTOM_PROFILE_ACTION,
  MARK_AS_DO_NOT_CONTACT,
  UNMARK_AS_DO_NOT_CONTACT,
} from '@/graphql/searchPoolProfile';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import RevealUploadFileModal from '@/components/modals/RevealUploadFileModal';
import AddToCampaignModal from '@/revealComponents/Modals/AddToCampaignModal';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import useTransformedResume from '@/graphql/hooks/searchPoolProfile/useTransformedResume';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import downloadPDF from '@/common/downloadPDF';
import Paper from '@/components/Common/Icons/Paper';

import EditPictureLinkModal from '@/revealComponents/Modals/EditPictureLinkModal';
import {
  GET_DO_NOT_CONTACT_REASONS,
  DoNotContactReason,
} from '@/graphql/doNotContactReasons';
import _ from 'underscore';
import classnames from 'classnames';
import styles from './ProfileActionsMenu.module.less';

interface ProfileActionsMenuProps {
  profile: SearchPoolProfile;
  clientId: string;
}

const ProfileActionsMenu: React.FC<ProfileActionsMenuProps> = ({
  profile,
  clientId,
}) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { error, success, onClearNotifications } = useNotificationSystem();
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [photoLinkModalOpen, setPhotoLinkModalOpen] = useState(false);
  const [doNotContactReasonId, setDoNotContactReasonId] = useState('');
  const [markAsDoNotContact] = useMutation<unknown, DoNotContactInput>(
    MARK_AS_DO_NOT_CONTACT,
  );
  const [unmarkAsDoNotContact] = useMutation<unknown, DoNotContactInput>(
    UNMARK_AS_DO_NOT_CONTACT,
  );
  const [customProfileActionClick] = useMutation<
    unknown,
    CustomProfileActionInput
  >(CLIENT_CUSTOM_PROFILE_ACTION);
  const [addToCampaignModalOpen, setAddToCampaignModalOpen] = useState(false);
  const clientPermissionsResult = useClientPermissions(clientId);
  const { permissions: clientPermissions } =
    clientPermissionsResult?.data?.client || {};
  const [getTransformedResume] = useTransformedResume();

  const { data: doNotContactReasonsData } = useQuery(
    GET_DO_NOT_CONTACT_REASONS,
    {
      variables: { clientId },
      fetchPolicy: 'network-only',
      skip: !modalOpen,
    },
  );

  const clientDoNotContactReasons: DoNotContactReason[] =
    doNotContactReasonsData?.client?.customDoNotContactReasons ?? [];

  const atsID = useClientCurrentAtsId();

  const translationPrefix = profile.privacyState?.markedAsDoNotContact
    ? 'okToContact'
    : 'doNotContact';

  const optionIcon = profile.privacyState?.markedAsDoNotContact
    ? 'ri-chat-check-line'
    : 'ri-chat-off-line';

  const modalAction = async () => {
    const input = {
      variables: {
        searchPoolId: 'reveal',
        profileId: profile.id,
        ...(doNotContactReasonId && {
          reason: {
            id: doNotContactReasonId,
            title:
              sanitizeTypename(
                _.findWhere(clientDoNotContactReasons, {
                  id: doNotContactReasonId,
                })?.title,
              ) ?? '???',
          },
        }),
      },
    };
    if (profile.privacyState?.markedAsDoNotContact) {
      await unmarkAsDoNotContact(input);
    } else {
      await markAsDoNotContact(input);
    }
    setDoNotContactReasonId('');
    setModalOpen(false);
  };

  const attachments = profile?.attachments;

  const fetchResume = async () => {
    success(t('profile.resume.attachments.loading'));
    try {
      const { transformedResume } = await getTransformedResume(profile.id);
      onClearNotifications();
      if (transformedResume?.file?.content) {
        downloadPDF({
          filename: transformedResume.name || 'cv.pdf',
          objectURL: transformedResume.file.content,
        });
      }
    } catch (_e) {
      error(t('profile.resume.attachments.loadingError'));
    }
  };

  return (
    <div className={styles.menuContainer}>
      <ActionButton
        icon='more-fill ri-lg'
        onClick={() => setMenuOpen(!menuOpen)}
        onBlur={() => setMenuOpen(false)}
      />
      <div className={`${styles.menu} ${menuOpen ? styles.open : ''}`}>
        <button
          type='button'
          className={styles.option}
          onClick={() => setModalOpen(true)}
          onMouseDown={(e) => e.preventDefault()}
        >
          <i className={`${optionIcon} ri-lg ${styles.icon}`} />
          <span className={styles.label}>
            {t(`reveal.profileModal.privacy.${translationPrefix}.menuOption`)}
          </span>
        </button>
        <button
          type='button'
          className={styles.option}
          onClick={() => setUploadFileModalOpen(true)}
          onMouseDown={(e) => e.preventDefault()}
        >
          <i className={`ri-upload-cloud-2-line ri-lg ${styles.icon}`} />
          <span className={styles.label}>
            {t('fileUpload.uploadAttachment')}
          </span>
        </button>
        {clientPermissions?.transformedResume && (
          <button
            type='button'
            className={styles.option}
            onClick={fetchResume}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Paper className={styles.icon} />
            <span className={styles.label}>
              {t('reveal.profileModal.transformedResume.download')}
            </span>
          </button>
        )}
        {clientPermissions?.crmMarketing && (
          <button
            type='button'
            className={styles.option}
            onClick={() => setAddToCampaignModalOpen(true)}
            onMouseDown={(e) => e.preventDefault()}
          >
            <i className={`ri-send-plane-2-line ri-lg ${styles.icon}`} />
            <span className={styles.label}>
              {t('reveal.missions.mission.actions.addToCampaign')}
            </span>
          </button>
        )}
        {atsID === 'adventure' && (
          <button
            type='button'
            className={styles.option}
            onClick={() =>
              customProfileActionClick({
                variables: {
                  searchPoolId: 'reveal',
                  input: {
                    profileId: profile.id,
                    key: 'adequat-contactflow',
                    value: '',
                  },
                },
              })
            }
            onMouseDown={(e) => e.preventDefault()}
          >
            <i className={`ri-send-plane-2-line ri-lg ${styles.icon}`} />
            <span className={styles.label}>
              {t('reveal.missions.mission.actions.customAction')}
            </span>
          </button>
        )}
        <button
          type='button'
          className={styles.option}
          onClick={() => setPhotoLinkModalOpen(true)}
          onMouseDown={(e) => e.preventDefault()}
        >
          <i className={`ri-image-edit-fill ri-lg ${styles.icon}`} />
          <span className={styles.label}>
            {t(`reveal.profileModal.editPhotoLink`)}
          </span>
        </button>
      </div>
      <ConfirmationModal
        header={t(
          `reveal.profileModal.privacy.${translationPrefix}.modal.title`,
          { firstname: profile.resumeData?.firstname },
        )}
        submit={t(
          `reveal.profileModal.privacy.${translationPrefix}.modal.submit`,
        )}
        content={
          <>
            {t(
              `reveal.profileModal.privacy.${translationPrefix}.modal.content`,
              { firstname: profile.resumeData?.firstname },
            )}
            {!profile.privacyState?.markedAsDoNotContact &&
              !_.isEmpty(clientDoNotContactReasons) && (
                <div className={styles.reasonContainer}>
                  <div className={styles.reasonTitle}>
                    {t(
                      `reveal.profileModal.privacy.${translationPrefix}.reason`,
                    )}
                  </div>
                  <div className={styles.buttons}>
                    {_.map(clientDoNotContactReasons, (reason) => (
                      <button
                        type='button'
                        key={reason.id}
                        className={classnames(
                          styles.customButton,
                          doNotContactReasonId === reason.id &&
                            styles.buttonActive,
                        )}
                        onClick={() => {
                          if (doNotContactReasonId === reason.id) {
                            setDoNotContactReasonId('');
                          } else {
                            setDoNotContactReasonId(reason.id);
                          }
                        }}
                      >
                        {reason.title.default}
                      </button>
                    ))}
                  </div>
                </div>
              )}
          </>
        }
        onSubmit={modalAction}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
      />
      <RevealUploadFileModal
        open={uploadFileModalOpen}
        profileId={profile.id}
        attachments={attachments || []}
        onClose={() => setUploadFileModalOpen(false)}
      />
      {clientPermissions?.crmMarketing && addToCampaignModalOpen && (
        <AddToCampaignModal
          clientId={clientId}
          profileId={profile.id}
          onClose={() => setAddToCampaignModalOpen(false)}
        />
      )}
      {photoLinkModalOpen && (
        <EditPictureLinkModal
          profileId={profile?.id}
          profilePictureLink={profile?.resumeData?.photoLink || ''}
          onClose={() => setPhotoLinkModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ProfileActionsMenu;
