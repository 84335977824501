import gql from 'graphql-tag';

import { useMutation } from '@apollo/client';

export const saveSharedActivity = gql`
  mutation saveSharedActivity(
    $input: SaveSharedActivityInput!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      saveSharedActivity(input: $input) {
        sharedActivityId
      }
    }
  }
`;

type Data = {
  data: {
    searchPoolProfile: {
      saveSharedActivity: {
        sharedActivityId: string;
      };
    };
  };
};

type Variables = {
  searchPoolId: string;
  input: {
    profileId: string;
    draftId: string;
    content: {
      type: string;
      date?: string;
      subtype?: string;
      comment?: {
        text: string;
      };
    };
    targets?: {
      type: string;
      targetId: string;
      isMain?: boolean;
    }[];
  };
};

const useSaveSharedActivity = () =>
  useMutation<Data, Variables>(saveSharedActivity, {
    refetchQueries: [
      'getEnrichedSearchPoolProfile',
      'getMissionSharedActivities',
    ],
  });

export default useSaveSharedActivity;
