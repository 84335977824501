import * as Sentry from '@sentry/browser';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Dropdown, Segment } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { Segmentations } from '@/components/PipelineSegmentation/data';
import { useAddProfilesToMissionAndSequenceMutation } from '@/graphql/searchPoolProfiles';
import { useAddToMissionAsRecommendedMutation } from '@/graphql/recommendedMissions';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import MissionItem from './MissionItem';
import './ProfileMissionsManagement.css';

const defaultPipelineId = Segmentations[0].id;
const defaultPipelineState = Segmentations[0].segments[0].id;

const defaultSegmentationState = {
  archived: false,
  segmentationStates: [
    {
      segmentationId: defaultPipelineId,
      state: defaultPipelineState,
    },
  ],
};

function useHasQueryParam(param, value = '') {
  const { location } = useHistory();
  return Boolean(location?.search?.includes(`${param}=${value}`));
}

const ProfileMissionsManagement = ({
  profileId,
  missions,
  missionsInfo,
  clientId,
}) => {
  const { user } = useCurrentUser();
  const { t } = useTranslation();
  const { error, success } = useNotificationSystem();
  const { jobOptions, jobOptionsLoading } = useSearchPoolJobOptions('reveal', {
    activeOnly: true,
  });

  const allowAddAsRecommendation =
    useHasQueryParam('reco', '1') && user?.isAdmin;

  const missionOptions = useMemo(() => {
    const missionsMap = _.indexBy(missions, 'id');
    return _.map(jobOptions, ({ id, data }) => ({
      value: id,
      text: data?.title,
      disabled: !!missionsMap[id],
    }));
  }, [jobOptions, missions]);

  const [addProfilesToMissionAndSequence, { loading: addToMissionLoading }] =
    useAddProfilesToMissionAndSequenceMutation();
  const [
    addToMissionAsRecommended,
    { loading: addToMissionAsRecommendedLoading },
  ] = useAddToMissionAsRecommendedMutation();

  const onChooseMission = async (ev, { value }) => {
    try {
      if (allowAddAsRecommendation) {
        await addToMissionAsRecommended(profileId, value);
      } else {
        const { data } = await addProfilesToMissionAndSequence({
          variables: {
            searchPoolId: 'reveal',
            input: {
              missionId: value,
              profileIds: [profileId],
            },
          },
        });
        const results =
          data?.searchPool?.addProfilesToMissionAndSequence?.results || [];
        const errors = _.filter(results, ({ success }) => !success);
        if (!_.isEmpty(errors)) {
          throw new Error('Backend error');
        }
      }

      success(t('reveal.candidatesView.actionsBox.addToMissionSuccess'));
    } catch (e) {
      Sentry.captureException(e);
      error(t('reveal.candidatesView.actionsBox.addToMissionError'));
    }
  };

  const labelAdd = allowAddAsRecommendation
    ? t('reveal.profileModal.addToMissionAsRecommendation')
    : t('reveal.profileModal.addToMission');

  const loading =
    jobOptionsLoading ||
    addToMissionLoading ||
    addToMissionAsRecommendedLoading;

  return (
    <Segment.Group className='profile-missions-management'>
      {_.map(missionsInfo, ({ data, mission, missionId }) => {
        const missionTitle = mission?.data?.title;
        const missionsInfoData = data || defaultSegmentationState;
        const segmentationId = defaultPipelineId;
        const stageId = missionsInfoData.archived
          ? 'archived'
          : _.find(missionsInfoData?.segmentationStates, { segmentationId })
              ?.state || defaultPipelineState;
        return (
          <MissionItem
            key={missionId}
            searchPoolId='reveal'
            stageId={stageId}
            missionId={missionId}
            missionTitle={missionTitle}
            profileId={profileId}
            segmentationId={defaultPipelineId}
            missionsInfoData={missionsInfoData}
            clientId={clientId}
          />
        );
      })}
      <Segment className='mission-dropdown'>
        <Dropdown
          className={classNames('button-dropdown icon', {
            simplified: !_.isEmpty(missionsInfo),
          })}
          icon={<i className='ri-add-line' />}
          floating
          labeled
          fluid
          button
          basic
          text={labelAdd}
          options={missionOptions}
          loading={loading}
          disabled={loading}
          onChange={onChooseMission}
          selectOnNavigation={false}
          selectOnBlur={false}
          value={null}
          direction='left'
        />
      </Segment>
    </Segment.Group>
  );
};

export default ProfileMissionsManagement;
