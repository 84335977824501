import _ from 'underscore';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  CustomFieldDefinition,
  CustomFieldInput,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import CustomField from '@/revealComponents/CustomField';

import styles from './NewJobCustomFields.module.less';

interface NewJobCustomFieldsProps {
  customFields?: CustomFieldInput[];
  missionCustomFields: CustomFieldDefinition[];
  onChange: (cfs: CustomFieldInput[]) => void;
  readOnlyFields?: string[];
}

export function formatCustomFieldInput(
  value: any,
  customField: CustomFieldDefinition,
): CustomFieldInput {
  const typeValueGetters: Record<
    CustomFieldDefinition['type'],
    (value: unknown) => Record<string, unknown>
  > = {
    day: (fieldValue) => {
      return {
        date: new Date(fieldValue as string).toISOString(),
      };
    },
    text: (fieldValue) => {
      return {
        text: fieldValue,
      };
    },
    'inline-text': (fieldValue) => {
      return {
        text: fieldValue,
      };
    },
    enum: (fieldValue) => {
      return {
        selected: fieldValue,
      };
    },
    float: (fieldValue) => {
      return {
        float: Number.parseFloat(fieldValue as string),
      };
    },
    integer: (fieldValue) => {
      return {
        integer: Number.parseInt(fieldValue as string, 10),
      };
    },
  };

  const valueObj = typeValueGetters[customField.type](value);
  return {
    clientCustomFieldId: customField.id,
    type: customField.type,
    ...valueObj,
  };
}

export function NewJobCustomFields(props: NewJobCustomFieldsProps) {
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <NewJobCustomFieldsHandler {...props} />
    </FormProvider>
  );
}

export function NewJobCustomFieldsHandler({
  customFields,
  missionCustomFields,
  onChange,
  readOnlyFields,
}: NewJobCustomFieldsProps) {
  const displayedCustomFields = useMemo(() => {
    return _.filter(
      missionCustomFields,
      ({ isMandatoryAtCreation, isDisplayedAtCreation }) =>
        Boolean(isMandatoryAtCreation || isDisplayedAtCreation),
    );
  }, [missionCustomFields]);
  // eslint-disable-next-line
  const onChangeCustomField = useCallback(
    (fieldId, value) => {
      const missionCustomField = _.findWhere(missionCustomFields, {
        id: fieldId,
      });
      if (!missionCustomField) {
        return null;
      }

      const customFieldInput: CustomFieldInput = formatCustomFieldInput(
        value,
        missionCustomField,
      );

      const customFieldsById = _.indexBy(
        customFields || [],
        'clientCustomFieldId',
      );
      customFieldsById[fieldId] = customFieldInput;

      return onChange(Object.values(customFieldsById));
    },
    [onChange, customFields, missionCustomFields],
  );

  return (_.map(displayedCustomFields, (customField) => {
    return (
      <CustomField
        key={customField.id}
        definition={customField}
        name={customField.id}
        onChange={onChangeCustomField}
        className={styles.inputControl}
        disabled={readOnlyFields?.includes(customField.id)}
        currentValue={
          _.findWhere(customFields || [], {
            clientCustomFieldId: customField.id,
          })?.rawValue || ''
        }
      />
    );
  }) as unknown) as JSX.Element;
}
