import React, { FC, useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { Project } from '@/components/Common/ProjectDropdown/sortFilter';
import { User } from '@/types/user';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { useAddToMissionAsRecommendedMutation } from '@/graphql/recommendedMissions';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import useDataUpdateSubscription from '@/graphql/dataUpdateSubscription/useDataUpdateSubscription';
import { useAddProfilesToMissionAndSequenceMutation } from '@/graphql/searchPoolProfiles';
import ProjectDropdown from '@/components/Common/ProjectDropdown/ProjectDropdown';
import DropdownControlsContext from '@/context/DropdownControlsContext';

import MissionDropdownControlsContext from '../context/MissionDropdownControlsContext';

import styles from './ProjectsProfileManagement.module.less';

interface MissionDropdownContainerProps {
  missions: Project[];
  user: User;
  profileId: string;
}

function useHasQueryParam(param: string, value = '') {
  const { location } = useHistory();
  return Boolean(location?.search?.includes(`${param}=${value}`));
}

const MissionDropdownContainer: FC<MissionDropdownContainerProps> = ({
  missions,
  user,
  profileId,
  children,
}) => {
  const { error, success } = useNotificationSystem();
  const { t } = useTranslation();

  const allowAddAsRecommendation =
    useHasQueryParam('reco', '1') && user?.isAdmin;
  const [addToMissionAsRecommended] = useAddToMissionAsRecommendedMutation();
  const [
    addProfilesToMissionAndSequence,
  ] = useAddProfilesToMissionAndSequenceMutation();

  const { jobOptions, refetch } = useSearchPoolJobOptions('reveal', {
    activeOnly: true,
  });

  const disabledProjects = useMemo(() => {
    return _.pluck(missions, 'id');
  }, [missions]);

  useDataUpdateSubscription({
    type: 'onMissionsListUpdated',
    onData: () => {
      refetch();
    },
  });

  const onChooseMission = async (id: string) => {
    try {
      if (allowAddAsRecommendation) {
        await addToMissionAsRecommended(profileId, id);
      } else {
        const { data } = await addProfilesToMissionAndSequence({
          variables: {
            searchPoolId: 'reveal',
            input: {
              missionId: id,
              profileIds: [profileId],
            },
          },
        });
        const results =
          data?.searchPool?.addProfilesToMissionAndSequence?.results || [];
        const errors = _.filter(
          results,
          ({ success: isSuccess }) => !isSuccess,
        );
        if (!_.isEmpty(errors)) {
          throw new Error('Backend error');
        }
      }
      success(t('reveal.candidatesView.actionsBox.addToMissionSuccess'));
    } catch (e) {
      Sentry.captureException(e);
      error(t('reveal.candidatesView.actionsBox.addToMissionError'));
    }
  };

  return (
    <ProjectDropdown
      className={styles.missionSelectorContainer}
      projects={jobOptions}
      disabledProjectIds={disabledProjects}
      onProjectSelected={({ id }) => {
        onChooseMission(id);
      }}
      position='right'
      trigger={
        <DropdownControlsContext.Consumer>
          {({ toggleDropdown }) => (
            <MissionDropdownControlsContext.Provider
              value={{ toggleMissionDropdown: toggleDropdown }}
            >
              {children}
            </MissionDropdownControlsContext.Provider>
          )}
        </DropdownControlsContext.Consumer>
      }
    />
  );
};

export default MissionDropdownContainer;
