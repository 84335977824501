import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import GenericDropdown from '../Common/GenericDropdown';
import DropdownPanel from '../Common/DropdownPanel';
import DropdownMenuItem from '../Common/DropdownMenuItem';
import ArrowDown from '../Reveal/Icons/ArrowDown';
import Cross from '../Common/Icons/Cross';

import styles from './SortingDropdown.module.less';

interface SortingDropdownProps {
  selectorId: string;
  selectedSortingOption: string;
  onChangeSortingOption: ({
    selectorId,
    value,
  }: {
    selectorId: string;
    value: string;
  }) => void;
  triggerText?: string;
  fieldType?: string;
  sortType?: 'alphabetical' | 'numerical';
}

// TODO: replace in each usage of the component
const SORT_TYPE_FROM_KEY_MAP: Record<string, 'alphabetical' | 'numerical'> = {
  name: 'alphabetical',
  title: 'alphabetical',
  owner: 'alphabetical',
  department: 'alphabetical',
  section: 'alphabetical',
  subsection: 'alphabetical',
  contacts: 'numerical',
  recommendations: 'numerical',
  tasks: 'numerical',
  pending: 'numerical',
  contacted: 'numerical',
  replied: 'numerical',
  interested: 'numerical',
  hired: 'numerical',
  archived: 'numerical',
  enum: 'alphabetical',
  day: 'alphabetical',
  'inline-text': 'alphabetical',
  text: 'alphabetical',
  integer: 'numerical',
  float: 'numerical',
  substep: 'numerical',
  subtype: 'alphabetical',
  company: 'alphabetical',
};

function SortingTrigger({
  isSelected,
  order,
  children,
  onClick,
}: PropsWithChildren<{
  isSelected: boolean;
  order: 'ascending' | 'descending';
  onClick: () => void;
}>) {
  return (
    <span className={styles.triggerContent} onClick={onClick}>
      {isSelected ? (
        <b>
          <u>{children}</u>
        </b>
      ) : (
        children
      )}
      <ArrowDown
        className={classNames({
          [styles.ascending]: order === 'ascending',
          [styles.inactive]: !isSelected,
        })}
      />
    </span>
  );
}

const SortingDropdown: React.FC<SortingDropdownProps> = ({
  selectorId,
  selectedSortingOption,
  onChangeSortingOption,
  triggerText,
  fieldType,
  sortType,
}) => {
  const { t } = useTranslation();
  const type =
    sortType ||
    (fieldType
      ? SORT_TYPE_FROM_KEY_MAP[fieldType]
      : SORT_TYPE_FROM_KEY_MAP[selectorId]);

  return (
    <GenericDropdown
      position='left'
      contentClassName={styles.dropdownContent}
      Trigger={({ onClick }) => (
        <SortingTrigger
          onClick={onClick}
          isSelected={Boolean(selectedSortingOption)}
          order={selectedSortingOption as 'ascending' | 'descending'}
        >
          {triggerText}
        </SortingTrigger>
      )}
    >
      <DropdownPanel className={styles.actionPanel}>
        <DropdownMenuItem
          className={classNames(
            styles.actionPanelItem,
            selectedSortingOption === 'ascending' && styles.selected,
          )}
          onClick={() => {
            onChangeSortingOption({ selectorId, value: 'ascending' });
          }}
        >
          <span className='ri-sort-asc' />
          {t(`common.sorting.${type}.ascending`)}
          {selectedSortingOption === 'ascending' && <Cross />}
        </DropdownMenuItem>
        <DropdownMenuItem
          className={classNames(
            styles.actionPanelItem,
            selectedSortingOption === 'descending' && styles.selected,
          )}
          onClick={() => {
            onChangeSortingOption({ selectorId, value: 'descending' });
          }}
        >
          <span className='ri-sort-desc' />
          {t(`common.sorting.${type}.descending`)}
          {selectedSortingOption === 'descending' && <Cross />}
        </DropdownMenuItem>
      </DropdownPanel>
    </GenericDropdown>
  );
};

export default SortingDropdown;
