import { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown, DropdownProps, Radio } from 'semantic-ui-react';
import { compose } from 'underscore';
import { CustomFieldEnumOption } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { getTranslatedText } from '../../../../../common';
import { OPERATORS_OPTION } from './operatorsOptions';
import { EnumOperator } from './types';

import './CustomCriteriaFilters.css';

const CustomCriteriaEnum = (props: {
  options: CustomFieldEnumOption[];
  selectedOperator: EnumOperator | undefined;
  onSelectedOperatorChange: (operator: EnumOperator) => void;
  value: string[];
  onValueChange: (value: string[]) => void;
  onClose: () => void;
  t: TFunction;
}) => {
  const {
    options,
    selectedOperator,
    onSelectedOperatorChange,
    value,
    onValueChange,
    onClose,
    t,
  } = props;

  const handleChange = (e: any, data: DropdownProps) => {
    onValueChange(checkStringArray(data?.value ?? []));
  };

  return (
    <div className='property-details-wrapper'>
      {OPERATORS_OPTION.enum.map((opt) => (
        <div className='radio-wrapper' key={opt.operator}>
          <div
            className='radio-row'
            onClick={() => {
              onSelectedOperatorChange(opt.operator);
            }}
          >
            <Radio
              className='radio'
              checked={selectedOperator === opt.operator}
            />
            <div className='label'>{t(opt.translation)}</div>
          </div>
          {opt.withInput && selectedOperator === opt.operator && (
            <div className='property-details-selector'>
              <Dropdown
                className='multi-dropdown'
                multiple
                placeholder='Select'
                onChange={handleChange}
                defaultValue={value ?? []}
                options={options.map((option) => ({
                  value: option.id,
                  text: getTranslatedText(option.title),
                  active: value.includes(option.id),
                }))}
              />
            </div>
          )}
        </div>
      ))}
      <div className='save-button' onClick={onClose}>
        {t('reveal.searchView.search.save')}
      </div>
    </div>
  );
};

const checkStringArray = (
  values: string | number | boolean | (string | number | boolean)[],
): string[] => {
  if (Array.isArray(values)) {
    return values.map((v) => {
      if (typeof v === 'string') {
        return v;
      }
      return '';
    });
  }
  return [];
};

export default compose(withTranslation('translations'))(CustomCriteriaEnum);
