import _ from 'underscore';
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useClientMissionCustomFields } from '@/graphql/hooks/clients/useClientMissionCustomFields';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { getSegmentationIdFromConfigurationParam } from '@/routes/RevealView/JobsView/JobView/ProjectProfilesTab/useMissionSegmentations';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Placeholder, Popup, Table } from 'semantic-ui-react';

import { CalibrationAndRecommendationIcon } from '@/assets/icons';
import ContextMenu from '@/components/Tables/ContextMenu/ContextMenu';
import { InlineCustomField } from '@/components/CustomFields/InlineCustomField';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { ISearchPoolJob } from '@/graphql/searchPoolJobs';
import { useMissionATSMetadataColumns } from '@/routes/RevealView/JobsView/ATSMetadata/columns';

import MissionCategoryContext from '@/context/MissionCategoryContext';
import SelectableColumn from '../SelectableColumn';
import ProjectRowTitle from '../Title';
import { ProjectRowColumn, ProjectRowProps } from './types';

import jobsViewStyles from '../../../../JobsView.module.less';
import styles from '../Title/title.module.less';

const LinkCell = ({
  to,
  loading = false,
  clickDisabled = false,
  children,
}: PropsWithChildren<{
  to: string;
  loading?: boolean;
  clickDisabled?: boolean;
}>) => {
  const history = useHistory();
  const redirect = useCallback(
    (e) => {
      e.stopPropagation();
      history.push(to);
    },
    [history, to],
  );

  if (loading) {
    return (
      <Table.Cell>
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Table.Cell>
    );
  }

  if (clickDisabled) {
    return (
      <Table.Cell>
        <span>{children}</span>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell onClick={redirect}>
      <Link to={to}>{children}</Link>
    </Table.Cell>
  );
};

const isColumnHidden = (columns: ProjectRowColumn[], columnId: string) => {
  return _.find(columns, (column) => column.id === columnId)?.hidden;
};

const CustomFieldCell = ({
  job,
  customField,
  show,
}: {
  job: ISearchPoolJob;
  customField: CustomFieldDefinition;
  show: boolean;
}) => {
  const missionCustomField = _.findWhere(job?.customFields, {
    clientCustomFieldId: customField.id,
  });
  return (
    <SelectableColumn show={show}>
      <Table.Cell className={jobsViewStyles.customFieldCell}>
        <InlineCustomField
          customField={missionCustomField}
          customFields={[customField]}
        />
      </Table.Cell>
    </SelectableColumn>
  );
};

export const ProjectRow = ({
  clientId,
  job,
  stats,
  linkedAts,
  sequences,
  setOpenModal,
  setActionJob,
  onArchiveMission,
  onUnarchiveMission,
  missionsStatsLoading,
  columnsDisplay,
  departments,
  shouldHideProjectEditionButtons = false,
  shoulPreventFromEnteringProject = false,
  shouldHideSequence = false,
  shouldHideATS = false,
  shouldHideArchiveOption = false,
  shouldHideEditOption = false,
}: ProjectRowProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const missionCategoryType = useContext(MissionCategoryContext);

  const configurationParams = useMergedConfigurationParams();
  const { valueRenderers: atsColumnRenderers } = useMissionATSMetadataColumns({
    job,
    stats: stats ?? null,
  });

  const profilesUrl = useMemo(() => {
    const { segmentationId, segmentId } =
      getSegmentationIdFromConfigurationParam(
        configurationParams?.projectProfilesDefaultView,
      );

    return `/client/${clientId}/reveal/projects/${missionCategoryType}/${job.id}/profiles?segmentationId=${segmentationId}&segmentId=${segmentId}`;
  }, [
    clientId,
    job,
    configurationParams?.projectProfilesDefaultView,
    missionCategoryType,
  ]);

  const { missionCustomFields } = useClientMissionCustomFields(clientId);

  const owner =
    job?.owner?.firstname || job?.owner?.lastname
      ? `${job?.owner?.firstname} ${job?.owner?.lastname}`
      : job?.owner?.email;

  const showColumn = useCallback(
    (columnId: string) => {
      const column = _.find(columnsDisplay, (c) => c.id === columnId);
      if (!column) {
        return false;
      }
      return !column.hidden;
    },
    [columnsDisplay],
  );

  const customFieldsById = useMemo(() => {
    return _.indexBy(missionCustomFields, 'id');
  }, [missionCustomFields]);

  const columnIsCustomField = useCallback(
    (columnId: string) => {
      return columnId in customFieldsById;
    },
    [customFieldsById],
  );

  const COLUMN_RENDERERS = useMemo(() => {
    return {
      'jobs-view-contacts': (column) => {
        return (
          <Table.Cell key={column.id}>
            {shoulPreventFromEnteringProject ? (
              <span>
                <span className='profiles-count'>
                  {job?.profilesCount || '-'}
                </span>
              </span>
            ) : (
              <Link
                to={profilesUrl}
                onClick={(e) => e.stopPropagation()}
                className={
                  configurationParams?.shouldHideContactsNumberHoverEffect ===
                  'true'
                    ? 'no-effect'
                    : ''
                }
              >
                <span className='profiles-count'>
                  {job?.profilesCount || '-'}
                </span>
              </Link>
            )}

            {job?.stats?.recommendations &&
              isColumnHidden(columnsDisplay, 'jobs-view-recommendations') ? (
                <Popup
                  basic
                  className={styles.revealPopup}
                  trigger={
                    <Link
                      to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${job?.id}/recommendations`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <span className='pill-message blue spaced recommendations'>
                        <CalibrationAndRecommendationIcon className='recommendation-icon' />
                        {job.stats.recommendations}
                      </span>
                    </Link>
                  }
                  content={t('reveal.missions.recommandationPopup', {
                    count: job.stats.recommendations,
                  })}
                  inverted
                  style={{ borderRadius: '5px' }}
                />
              ) : ''}
          </Table.Cell>
        );
      },
      'jobs-view-recommendations': (column) => {
        return (
          <LinkCell
            key={column.id}
            to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${job.id}/recommendations`}
            clickDisabled={!!shoulPreventFromEnteringProject}
          >
            {job.stats?.recommendations || '-'}
          </LinkCell>
        );
      },
      'jobs-view-task': (column) => {
        return (
          <LinkCell
            key={column.id}
            loading={missionsStatsLoading}
            to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${job.id}/mission-tasks`}
            clickDisabled={!!shoulPreventFromEnteringProject}
          >
            {stats?.tasks || '-'}
          </LinkCell>
        );
      },
      'jobs-view-pending': (column) => {
        return (
          <LinkCell
            key={column.id}
            loading={missionsStatsLoading}
            to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${job.id}/profiles?segmentId=pending`}
            clickDisabled={!!shoulPreventFromEnteringProject}
          >
            {stats?.notContacted || '-'}
          </LinkCell>
        );
      },
      'jobs-view-contacted': (column) => {
        return (
          <LinkCell
            key={column.id}
            loading={missionsStatsLoading}
            to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${job.id}/profiles?segmentId=in-progress`}
            clickDisabled={!!shoulPreventFromEnteringProject}
          >
            {stats?.contacted || '-'}
          </LinkCell>
        );
      },
      'jobs-view-replied': (column) => {
        return (
          <LinkCell
            key={column.id}
            loading={missionsStatsLoading}
            to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${job.id}/profiles?segmentId=replied`}
            clickDisabled={!!shoulPreventFromEnteringProject}
          >
            {stats?.replied || '-'}
          </LinkCell>
        );
      },
      'jobs-view-interested': (column) => {
        return (
          <LinkCell
            key={column.id}
            loading={missionsStatsLoading}
            to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${job.id}/profiles?segmentId=interested`}
            clickDisabled={!!shoulPreventFromEnteringProject}
          >
            {stats?.interested || '-'}
          </LinkCell>
        );
      },
      'jobs-view-hired': (column) => {
        return (
          <LinkCell
            key={column.id}
            loading={missionsStatsLoading}
            to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${job.id}/profiles?segmentId=hired`}
            clickDisabled={!!shoulPreventFromEnteringProject}
          >
            {stats?.hired || '-'}
          </LinkCell>
        );
      },
      'jobs-view-archived': (column) => {
        return (
          <LinkCell
            key={column.id}
            loading={missionsStatsLoading}
            to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${job.id}/profiles?segmentId=archived`}
            clickDisabled={!!shoulPreventFromEnteringProject}
          >
            {stats?.archived || '-'}
          </LinkCell>
        );
      },
      'jobs-view-owner-and-date': (column) => {
        return (
          <Table.Cell key={column.id} className='align-left ellipsis'>
            <div className='job-owner-date'>
              <span className='job-owner'>{owner || '-'}</span>
              <span className='job-date'>
                {job?.data?.openingDate
                  ? t('common.simpleDate', {
                      date: new Date(job?.data?.openingDate),
                    })
                  : '-'}
              </span>
            </div>
          </Table.Cell>
        );
      },
      'jobs-view-company': (column) => {
        return (
          <Table.Cell key={column.id} className='align-left ellipsis'>
            {job.targets?.companies?.[0]?.name || '-'}
          </Table.Cell>
        );
      },
      department: (column) => {
        return (
          <Table.Cell key={column.id} className='align-left ellipsis'>
            {_.findWhere(departments, { id: job?.foldering?.department?.id })
              ?.title || '-'}
          </Table.Cell>
        );
      },
      section: (column) => {
        return (
          <Table.Cell key={column.id} className='align-left ellipsis'>
            {_.findWhere(departments, { id: job?.foldering?.section?.id })
              ?.title || '-'}
          </Table.Cell>
        );
      },
      subsection: (column) => {
        return (
          <Table.Cell key={column.id} className='align-left ellipsis'>
            {_.findWhere(departments, { id: job?.foldering?.subsection?.id })
              ?.title || '-'}
          </Table.Cell>
        );
      },
      substep: (column) => {
        return _.contains(
          _.pluck(stats?.interestedSubSteps ?? [], 'subStepId'),
          column.id,
        ) ? (
          <LinkCell
            key={column.id}
            to={`/client/${clientId}/reveal/projects/${missionCategoryType}/${job.id}/profiles?segmentId=${column.id}`}
          >
            {_.findWhere(stats?.interestedSubSteps ?? [], {
              subStepId: column.id,
            })?.count || '-'}
          </LinkCell>
        ) : (
          <Table.Cell key={column.id} className='align-left'>
            -
          </Table.Cell>
        );
      },
      customField: (column) => {
        const customField = customFieldsById[column.id];
        if (!customField) {
          return null;
        }
        return (
          <CustomFieldCell
            key={column.id}
            show={showColumn(column.id)}
            job={job}
            customField={customField}
          />
        );
      },
      ...atsColumnRenderers,
    } satisfies Record<string, (column: ProjectRowColumn) => JSX.Element>;
  }, [
    clientId,
    columnsDisplay,
    job,
    departments,
    missionsStatsLoading,
    owner,
    customFieldsById,
    profilesUrl,
    showColumn,
    shoulPreventFromEnteringProject,
    stats,
    t,
    atsColumnRenderers,
    configurationParams,
    missionCategoryType,
  ]);

  const options = useMemo(() => {
    const result = [] as { icon: string; label: string; onClick: () => void }[];

    if (!shouldHideEditOption) {
      result.push({
        icon: 'ri-pencil-line',
        label: t('sequences.buttons.edit'),
        onClick: () => {
          setActionJob(job);
          setOpenModal('edit');
        },
      });
    }

    if (!shouldHideArchiveOption) {
      result.push(
        !job.isArchived
          ? {
              icon: 'ri-archive-line',
              label: t('sequences.buttons.archive'),
              onClick: () => onArchiveMission(job),
            }
          : {
              icon: 'ri-inbox-unarchive-line',
              label: t('templates.buttons.restore'),
              onClick: () => onUnarchiveMission(job),
            },
      );
    }

    return result;
  }, [
    t,
    job,
    onArchiveMission,
    onUnarchiveMission,
    setActionJob,
    setOpenModal,
    shouldHideEditOption,
    shouldHideArchiveOption,
  ]);

  const columns = useMemo(() => {
    return _.map(columnsDisplay, (column) => {
      if (column.hidden) {
        return null;
      }

      const renderer = COLUMN_RENDERERS[column.id];
      if (renderer) {
        return renderer(column);
      }

      const isCustomField = columnIsCustomField(column.id);
      if (isCustomField) {
        return COLUMN_RENDERERS.customField(column);
      }

      // must be substep
      return COLUMN_RENDERERS.substep(column);
    });
  }, [COLUMN_RENDERERS, columnsDisplay, columnIsCustomField]);

  return (
    <Table.Row
      onClick={() => {
        if (!shoulPreventFromEnteringProject) {
          history.push(profilesUrl);
        }
      }}
      className={`job-row ${
        shoulPreventFromEnteringProject ? 'not-clickable' : ''
      }`}
    >
      <Table.Cell className='title'>
        <div>
          <ProjectRowTitle
            linkedAts={linkedAts}
            clientId={clientId}
            project={job}
            sequences={sequences}
            shoulPreventFromEnteringProject={shoulPreventFromEnteringProject}
            shouldHideSequence={shouldHideSequence}
            shouldHideATS={shouldHideATS}
          />
        </div>
      </Table.Cell>

      {columns}

      <Table.Cell>
        {!shouldHideProjectEditionButtons && !_.isEmpty(options) && (
          <ContextMenu actions={options} />
        )}
      </Table.Cell>
    </Table.Row>
  );
};
