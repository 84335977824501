import React, { useCallback } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import GenericTextInput from '@/components/Common/GenericTextInput';
import { getTranslatedText } from '@/common';
import RichEditor from '@/components/RichEditor';

import GenericDropdown from '@/components/Common/GenericDropdown';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import styles from './DescriptionForm.module.less';
import {
  JobPostingImage,
  JobPostingVideo,
} from '../../OfferJobPostings/components/JobDescriptionSection';

interface DescriptionFormProps {
  sections: any[];
  selectedMenu?: string;
  onUpdateSections?: (description: any) => void;
  readOnly?: boolean;
}

const DescriptionForm: React.FC<DescriptionFormProps> = ({
  sections,
  selectedMenu = '',
  onUpdateSections = () => {},
  readOnly = false,
}) => {
  const { t } = useTranslation();

  const handleChangeContent = useCallback(
    (sectionIndex, content) => {
      onUpdateSections([
        ...sections.slice(0, sectionIndex),
        {
          ...sections[sectionIndex],
          content: {
            default: content,
          },
        },
        ...sections.slice(sectionIndex + 1),
      ]);
    },
    [onUpdateSections, sections],
  );

  const handleChangeSectionTitle = useCallback(
    (sectionIndex, title) => {
      onUpdateSections([
        ...sections.slice(0, sectionIndex),
        {
          ...sections[sectionIndex],
          title: {
            default: title,
          },
        },
        ...sections.slice(sectionIndex + 1),
      ]);
    },
    [sections, onUpdateSections],
  );

  const handleRemoveSection = useCallback(
    (index) => {
      onUpdateSections([
        ...sections.slice(0, index),
        ...sections.slice(index + 1),
      ]);
    },
    [sections, onUpdateSections],
  );

  const addDescriptionSection = useCallback(
    (type: string) => {
      let newSection;
      if (type === 'simple-section') {
        newSection = {
          __typename: 'SimpleJobPostingDescriptionSection',
          type: 'simple-section',
          title: { default: '', fr: '', en: '' },
          content: { default: '', fr: '', en: '' },
        };
      }
      if (type === 'video-section') {
        newSection = {
          __typename: 'VideoJobPostingDescriptionSection',
          type: 'video-section',
          url: '',
        };
      }
      if (type === 'image-section') {
        newSection = {
          __typename: 'ImageJobPostingDescriptionSection',
          type: 'image-section',
          name: '',
          file: {
            content: '',
          },
        };
      }
      onUpdateSections([...(sections || []), newSection]);
    },
    [sections, onUpdateSections],
  );

  const udpateDescriptionSectionAtIndex = useCallback(
    (index: number, section: any) => {
      const newSection = {
        ...sections[index],
        ..._.omit(section, 'file'),
        ...(section.file && {
          name: section.file.name,
          file: {
            content: section.file.content,
          },
        }),
      };
      onUpdateSections([
        ...sections.slice(0, index),
        newSection,
        ...sections.slice(index + 1),
      ]);
    },
    [sections, onUpdateSections],
  );

  return (
    <div className='job-posting-description'>
      {_.map(sections, (section, index) => {
        return (
          <div className={styles.sectionContainer} key={`job-posting-${index}`}>
            {section.type === 'simple-section' && (
              <>
                <div className={styles.simpleSectionHeader}>
                  <h4>Section {index + 1}</h4>
                  {sections.length > 1 && !readOnly && (
                    <GenericButton
                      size='small'
                      primacy='secondary'
                      color='red'
                      onClick={() => handleRemoveSection(index)}
                      disabled={readOnly}
                    >
                      {t('offers.jobPostings.form.description.removeSection')}
                    </GenericButton>
                  )}
                </div>
                <div className={styles.inputContainer} key={`title-${index}`}>
                  <div className={styles.inputElement}>
                    <GenericTextInput
                      fluid
                      placeholder='Section title'
                      value={getTranslatedText(section?.title)}
                      onChange={({ target }) =>
                        handleChangeSectionTitle(index, target.value)
                      }
                      disabled={readOnly}
                    />
                  </div>
                </div>
                <div
                  className={styles.inputContainer}
                  key={`description-${index}`}
                >
                  <div className={styles.inputElement} key={selectedMenu}>
                    <RichEditor
                      placeholder='Section description'
                      disabled={readOnly}
                      smallControls
                      initialHtml={
                        getTranslatedText(section.content) ||
                        'Section description'
                      }
                      onChangeAsHtml={(value: any) =>
                        handleChangeContent(index, value)
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {section.type === 'image-section' && (
              <JobPostingImage
                editMode
                file={section.file}
                setImageJobPostingDescriptionSectionState={(newSection: any) =>
                  udpateDescriptionSectionAtIndex(index, newSection)
                }
                index={index}
                deleteJobDescriptionSection={() => handleRemoveSection(index)}
                t={t}
                disabled={readOnly}
              />
            )}
            {section.type === 'video-section' && (
              <JobPostingVideo
                editMode
                url={section.url}
                setVideoJobPostingDescriptionSectionState={(newSection: any) =>
                  udpateDescriptionSectionAtIndex(index, newSection)
                }
                index={index}
                deleteJobDescriptionSection={() => handleRemoveSection(index)}
                t={t}
                disabled={readOnly}
              />
            )}
          </div>
        );
      })}
      {!readOnly && (
        <GenericDropdown
          Trigger={({ onClick }) => (
            <GenericButton
              primacy='secondary'
              type='button'
              onClick={onClick}
              className={styles.dropdownTrigger}
            >
              <span className={styles.title}>
                {t('offers.jobPostings.form.description.addSection')}
              </span>
              <span className={styles.arrow}>
                <ArrowDown />
              </span>
            </GenericButton>
          )}
        >
          <DropdownMenuPanel
            options={[
              {
                id: 'simple-section',
                label: t('offers.jobPostings.form.description.addSection'),
              },
              {
                id: 'image-section',
                label: t('offers.jobPostings.form.description.addImage'),
              },
              {
                id: 'video-section',
                label: t('offers.jobPostings.form.description.addVideo'),
              },
            ]}
            onSelect={(id) => addDescriptionSection(id)}
          />
        </GenericDropdown>
      )}
    </div>
  );
};

export default DescriptionForm;
