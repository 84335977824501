import React, { useMemo } from 'react';
import { useFormContext, useController, Controller } from 'react-hook-form';
import classNames from 'classnames';
import moment from 'moment';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import {
  Checkbox,
  TextArea,
  CheckboxProps as BaseCheckboxProps,
  TextAreaProps as BaseTextAreaProps,
} from 'semantic-ui-react';

import CustomSelect from '@/containers/Parameters/OfferCriteria/CustomFormItems/CustomSelect';
import EmailEditor from '@/containers/Editor';
import { useTranslation } from 'react-i18next';
import { getShortLanguage } from '@/common/utils/i18n';
import {
  RadioButtonSelector,
  RadioButtonSelectorProps,
} from './RadioButtonSelector';

import styles from './FormFields.module.less';
import GenericToggle from './Common/GenericToggle';

interface FormFieldProps {
  className?: string;
  horizontal?: boolean;
  children: React.ReactNode;
}

export function FormField(props: FormFieldProps) {
  const { className, horizontal, children } = props;
  return (
    <div
      className={classNames('input-container', className, {
        'input-container-horizontal': horizontal,
      })}
    >
      {children}
    </div>
  );
}
FormField.defaultProps = {
  className: null,
  horizontal: false,
};

FormField.Label = (props: React.ComponentProps<'label'>) => {
  const { className, ...otherProps } = props;
  // eslint-disable-next-line
  return (
    <label className={classNames(className, 'input-label')} {...otherProps} />
  );
};

FormField.Wrapper = function FormFieldWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className='input-container-wrapper'>{children}</div>;
};

FormField.Error = function FormFieldError({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className={classNames('input-error', styles.inputError)}>
      {children}
    </div>
  );
};

FormField.HelpText = ({ children }: { children: React.ReactNode }) => {
  return <div className='input-description'>{children}</div>;
};

type ControlProps = {
  label?: React.ReactNode | string;
  name: string;
  id?: string;
  errorMessage?: string;
  horizontal?: boolean;
  helpText?: string;
  containerClassName?: string;
  defaultValue?: string | boolean;
};
export function RadioButtonControl(
  props: ControlProps & Omit<RadioButtonSelectorProps, 'onChange'>,
) {
  const {
    label,
    name,
    errorMessage,
    containerClassName,
    horizontal,
    defaultValue,
    ...otherProps
  } = props;
  const { fieldState } = useController({ name });
  const { control } = useFormContext();
  return (
    <FormField horizontal={horizontal} className={containerClassName}>
      {label && <FormField.Label>{label}</FormField.Label>}
      <FormField.Wrapper>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <RadioButtonSelector
              {...otherProps}
              defaultValue={defaultValue}
              onChange={(option) => field.onChange(option.value)}
            />
          )}
        />
        {fieldState.invalid && errorMessage && (
          <FormField.Error>{errorMessage}</FormField.Error>
        )}
      </FormField.Wrapper>
    </FormField>
  );
}
RadioButtonControl.defaultProps = { label: null, id: null, errorMessage: null };

interface ToggleProps extends ControlProps {
  shouldDisplayInCard?: boolean;
}

export function ToggleControl(props: ControlProps & ToggleProps) {
  const {
    label,
    name,
    errorMessage,
    containerClassName,
    horizontal,
    defaultValue,
    ...otherProps
  } = props;
  const { fieldState } = useController({ name });
  const { control } = useFormContext();
  return (
    <FormField horizontal={horizontal} className={containerClassName}>
      <FormField.Wrapper>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <GenericToggle
              {...otherProps}
              defaultValue={defaultValue || false}
              label={label as string}
              name='shouldDisplayInCard'
              onChange={({ value }: { value: boolean }) => {
                field.onChange(value);
              }}
            />
          )}
        />
        {fieldState.invalid && errorMessage && (
          <FormField.Error>{errorMessage}</FormField.Error>
        )}
      </FormField.Wrapper>
    </FormField>
  );
}
ToggleControl.defaultProps = { label: null, id: null, errorMessage: null };

interface TextAreaProps extends BaseTextAreaProps {
  horizontal?: boolean;
  onChange: (value: string) => void;
}
export function TextAreaControl(props: ControlProps & TextAreaProps) {
  const {
    onChange,
    label,
    id,
    name,
    errorMessage,
    horizontal,
    className,
    ...otherProps
  } = props;
  const htmlId = id || `field-${name}`;
  const { fieldState } = useController({ name });
  const { control } = useFormContext();
  return (
    <FormField horizontal={horizontal} className={className}>
      {label && <FormField.Label htmlFor={htmlId}>{label}</FormField.Label>}
      <FormField.Wrapper>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <TextArea
              id={htmlId}
              {...otherProps}
              {...field}
              onChange={(_, { value }) => {
                field.onChange(value);
                onChange?.(value as string);
              }}
            />
          )}
        />
        {fieldState.invalid && errorMessage && (
          <FormField.Error>{errorMessage}</FormField.Error>
        )}
      </FormField.Wrapper>
    </FormField>
  );
}
TextAreaControl.defaultProps = { label: null, id: null, errorMessage: null };

type EmailEditorProps = {
  clientId: string;
  profileId: string;
  placeholder?: string;
};
export function RichEditorControl(props: ControlProps & EmailEditorProps) {
  const { label, id, name, errorMessage, ...otherProps } = props;
  const htmlId = id || `field-${name}`;
  const { fieldState } = useController({ name });
  const { control } = useFormContext();
  return (
    <FormField>
      {label && <FormField.Label htmlFor={htmlId}>{label}</FormField.Label>}
      <FormField.Wrapper>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <EmailEditor
              id={htmlId}
              mode='comment'
              alwaysShowMenu
              {...otherProps}
              {...field}
            />
          )}
        />
        {fieldState.invalid && errorMessage && (
          <FormField.Error>{errorMessage}</FormField.Error>
        )}
      </FormField.Wrapper>
    </FormField>
  );
}
RichEditorControl.defaultProps = {
  label: null,
  id: null,
  errorMessage: null,
  placeholder: null,
};

type CustomSelectProps = {
  placeholder?: string;
  multiple?: boolean;
  horizontal?: boolean;
  options: { label: string; value: string }[];
  defaultValue?: string[];
  disabled?: boolean;
  className?: string;
  onChange?: (value: string[]) => void;
  required?: boolean;
};

export function SelectControl(
  props: Omit<ControlProps, 'defaultValue'> & CustomSelectProps,
): JSX.Element {
  const {
    label,
    id,
    name,
    horizontal,
    placeholder,
    errorMessage,
    multiple,
    options,
    helpText,
    defaultValue,
    className,
    onChange,
    required = false,
    ...otherProps
  } = props;
  const { t } = useTranslation();
  const htmlId = id || `field-${name}`;
  const htmlIdLabel = `${htmlId}-label`;
  const { fieldState } = useController({ name });
  const { control } = useFormContext();
  return (
    <FormField horizontal={horizontal} className={className}>
      {label && <FormField.Label id={htmlIdLabel}>{label}</FormField.Label>}
      <FormField.Wrapper>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            const currentValue = (field.value || []).map((v: string) =>
              options.find((o) => o.value === v),
            );
            return (
              <CustomSelect
                label={name}
                placeholderText={placeholder || t('common.select')}
                multi={multiple}
                {...otherProps}
                {...field}
                aria-labelledby={htmlIdLabel}
                options={options}
                value={currentValue}
                required={required}
                onChange={({
                  value,
                }: {
                  value: { label: string; value: string }[] | { value: string };
                }) => {
                  const selected = Array.isArray(value)
                    ? value.map((k) => k.value)
                    : [value.value];
                  onChange?.(selected);
                  return field.onChange(selected);
                }}
              />
            );
          }}
        />
        {helpText && <FormField.HelpText>{helpText}</FormField.HelpText>}
        {fieldState.invalid && errorMessage && (
          <FormField.Error>{errorMessage}</FormField.Error>
        )}
      </FormField.Wrapper>
    </FormField>
  );
}

type DatePickerProps = Omit<ReactDatePickerProps<string>, 'onChange'>;

export function getDateValueAsDate(value: string | Date): Date | null {
  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'string') {
    const date = moment(moment(value).format('YYYY-MM-DD'));
    if (date.isValid()) return date.toDate();
    return null;
  }
  return value;
}

export function DatePickerControl(props: ControlProps & DatePickerProps) {
  const { i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const { label, id, name, horizontal, errorMessage, ...otherProps } = props;
  const htmlId = id || `field-${name}`;
  const { fieldState } = useController({ name });
  const { control } = useFormContext();
  const dateFormat = useMemo(
    () => (lang === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'),
    [lang],
  );

  return (
    <FormField horizontal={horizontal}>
      {label && <FormField.Label htmlFor={htmlId}>{label}</FormField.Label>}
      <FormField.Wrapper>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <DatePicker
              id={htmlId}
              {...otherProps}
              wrapperClassName={classNames(
                styles.datePickerWrapper,
                otherProps.wrapperClassName,
              )}
              onChange={field.onChange}
              onBlur={field.onBlur}
              ref={field.ref}
              name={field.name}
              selected={getDateValueAsDate(field.value)}
              locale={lang}
              dateFormat={dateFormat}
            />
          )}
        />
        {fieldState.invalid && errorMessage && (
          <FormField.Error>{errorMessage}</FormField.Error>
        )}
      </FormField.Wrapper>
    </FormField>
  );
}

interface CheckboxProps extends BaseCheckboxProps {
  horizontal?: boolean;
  defaultValue?: boolean;
}

export function CheckboxControl(
  props: Omit<ControlProps, 'defaultValue'> & CheckboxProps,
) {
  const {
    id: _id,
    name,
    errorMessage,
    horizontal,
    defaultValue,
    ...otherProps
  } = props;
  const { fieldState } = useController({ name });
  const { control } = useFormContext();
  return (
    <FormField horizontal={horizontal}>
      <FormField.Wrapper>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            const { value, onChange } = field;
            return (
              <Checkbox
                checked={value}
                {...otherProps}
                onChange={(_option, data) => onChange(data.checked)}
              />
            );
          }}
        />
        {fieldState.invalid && errorMessage && (
          <FormField.Error>{errorMessage}</FormField.Error>
        )}
      </FormField.Wrapper>
    </FormField>
  );
}
RadioButtonControl.defaultProps = { label: null, id: null, errorMessage: null };
