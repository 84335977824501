import React, { useMemo } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import { getATSName } from '@/common/reveal/utils';
import downloadPDF from '@/common/downloadPDF';
import { DownloadIcon } from '@/assets/icons';
import { useClientDocumentTypes } from '@/graphql/hooks/clients/useClientDocumentTypes';
import { getTranslatedText } from '@/common';
import {
  ATTACHMENT_TYPE_RESUME,
  ATTACHMENT_TYPE_COVER_LETTER,
} from '../../../../common/constants/attachments';

import DownloadButton from './DownloadButton';
import DeleteButton from './DeleteButton';
import AttachmentViewer, { AttachmentViewerRaw } from './AttachmentViewer';

import styles from './AttachmentsSection.module.less';

/**
 * @param {Object} props
 * @param {Object[]} props.attachments
 * @param {boolean} [props.hideAttachmentActions]
 * @param {string} props.profileId
 * */
const AttachmentsSection = ({
  attachments,
  hideAttachmentActions,
  profileId,
}) => {
  const { t } = useTranslation();
  const { applicationMode } = useNavApplicationMode();
  const { clientDocumentTypes } = useClientDocumentTypes();

  const customDocumentTypeKeys = _.compact(_.pluck(clientDocumentTypes, 'key'));

  const documentGroupsByCustomType = useMemo(() => {
    const result = [];
    _.each(attachments ?? [], (attachment) => {
      if (customDocumentTypeKeys.includes(attachment.type)) {
        const documentType = _.findWhere(clientDocumentTypes, { key: attachment.type });
        if (!_.findWhere(result, { key: attachment.type })) {
          result.push({
            key: attachment.type,
            title: getTranslatedText(documentType?.value ?? { default: '??? '}),
            attachments: [],
          })
        }
        const group = _.findWhere(result, { key: attachment.type });
        group.attachments.push(attachment);
      };
    });

    return result;
  }, [attachments, clientDocumentTypes, customDocumentTypeKeys]);


  if (_.isEmpty(attachments)) {
    return null;
  }

  const resumeAttachment = _.findWhere(attachments, {
    type: ATTACHMENT_TYPE_RESUME,
  });
  const coverLetterAttachment = _.findWhere(attachments, {
    type: ATTACHMENT_TYPE_COVER_LETTER,
  });
  const otherAttachments = _.filter(
    attachments,
    ({ type, id }) => (
      !(customDocumentTypeKeys ?? []).includes(type) && (
        (type !== ATTACHMENT_TYPE_RESUME &&
          type !== ATTACHMENT_TYPE_COVER_LETTER
        ) ||
        (id !== resumeAttachment?.id && id !== coverLetterAttachment?.id)  
      )
    )
  );

  return (
    <div className='background-section attachments-section'>
      <div className={styles.title}>
        {t('profile.resume.attachments.header')}
      </div>
      {resumeAttachment && (
        <div className='subsection' id={resumeAttachment.id}>
          <h4 className={styles.attachmentType}>
            <i className='ri-file-user-line' />
            <span>{t('profile.resume.attachments.resume')}</span>
          </h4>
          <Attachment
            attachment={resumeAttachment}
            hideAttachmentActions={hideAttachmentActions}
            profileId={profileId}
            applicationMode={applicationMode}
            t={t}
          />
        </div>
      )}
      {coverLetterAttachment && (
        <div className='subsection' id={coverLetterAttachment.id}>
          <h4 className={styles.attachmentType}>
            <i className='ri-file-text-line' />
            <span>{t('profile.resume.attachments.coverLetter')}</span>
          </h4>
          <Attachment
            attachment={coverLetterAttachment}
            hideAttachmentActions={hideAttachmentActions}
            profileId={profileId}
            applicationMode={applicationMode}
            t={t}
          />
        </div>
      )}
      {_.map(documentGroupsByCustomType, ({ key, title, attachments: customTypeAttachments }) => (
        <div key={key} className='subsection'>
          <h4 className={styles.attachmentType}>
            <i className='ri-file-line' />
            <span>{title ?? '???'}</span>
          </h4>
          {_.map(customTypeAttachments, (attachment) => (
            <Attachment
              key={attachment.id}
              attachment={attachment}
              hideAttachmentActions={hideAttachmentActions}
              profileId={profileId}
              applicationMode={applicationMode}
              t={t}
            />
          ))}
        </div>
      ))}
      {!_.isEmpty(otherAttachments) && (
        <div className='subsection'>
          <h4 className={styles.attachmentType}>
            <i className='ri-file-line' />
            <span>{t('profile.resume.attachments.others')}</span>
          </h4>
          {_.map(otherAttachments, (attachment) => (
            <Attachment
              attachment={attachment}
              hideAttachmentActions={hideAttachmentActions}
              profileId={profileId}
              applicationMode={applicationMode}
              t={t}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const DownloadNotSupportedFileButton = ({ attachment, t }) => {
  if (!attachment?.file?.url || !attachment?.name) {
    return null;
  }
  const fileUrl = new URL(attachment?.file?.url);
  const filePath = fileUrl.pathname;
  if (filePath.match(/.pdf$/) || filePath.match(/.txt$/)) {
    // No Download if file can be viewed
    return null;
  }
  return (
    <Button
      href={attachment?.file?.url}
      download={attachment?.name}
      className='tertiary'
    >
      <i className='ri-file-download-line' />
      {t('profile.resume.attachments.download')}
    </Button>
  );
};

const AttachmentActions = ({
  hideAttachmentActions,
  attachment,
  applicationMode,
  profileId,
  t,
}) => {
  if (hideAttachmentActions) {
    return null;
  }
  const attachmentIsImmutable =
    attachment?.from && attachment?.from !== 'hiresweet';
  if (attachmentIsImmutable) {
    return <DownloadNotSupportedFileButton attachment={attachment} t={t} />;
  }
  return (
    <>
      {applicationMode === 'classic' ? (
        <DownloadButton id={attachment.id} profileId={profileId} icon />
      ) : (
        <span className='download-icon-container'>
          <Button
            className='download-button'
            onClick={() =>
              downloadPDF({
                filename: attachment.name,
                objectURL: attachment.file.url,
              })
            }
          >
            <DownloadIcon className='download-icon' />
          </Button>
        </span>
      )}
      <DeleteButton
        id={attachment.id}
        profileId={profileId}
        searchPoolId={applicationMode === 'reveal' ? 'reveal' : undefined}
        icon
      />
    </>
  );
};

const Attachment = ({
  attachment,
  hideAttachmentActions,
  profileId,
  applicationMode,
  t,
}) => {
  const from = attachment?.from !== 'hiresweet' && attachment?.from;
  const atsName = getATSName(from);
  return (
    <div id={attachment.id} key={attachment.id}>
      <h5 className={styles.attachmentHeader}>
        <span>
          {attachment.name} {atsName ? `(${atsName})` : ''}
        </span>
        {' - '}
        <div className={styles.attachmentDate}>
          {t('common.shortDate', { date: new Date(attachment.creationDate) })}
        </div>
        <AttachmentActions
          hideAttachmentActions={hideAttachmentActions}
          profileId={profileId}
          attachment={attachment}
          applicationMode={applicationMode}
          t={t}
        />
      </h5>
      {applicationMode === 'classic' ? (
        <AttachmentViewer id={attachment.id} profileId={profileId} t={t} />
      ) : (
        <AttachmentViewerRaw
          className={styles.attachmentViewer}
          attachment={attachment}
          t={t}
        />
      )}
    </div>
  );
};

export default AttachmentsSection;
