import GenericModal from '@/components/Common/GenericModal';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import GenericButton from '@/components/Common/GenericButton';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import _ from 'underscore';
import { getClientCareerPages } from '@/hocs/clients/withClientCareerPages';
import useClientId from '@/hooks/router/useClientId';
import { getTranslatedText } from '@/common';
import GenericTextInput from '@/components/Common/GenericTextInput';
import GenericPopup from '@/components/Common/GenericPopup';
import DescriptionForm from '@/containers/Parameters/CareerPages/CareerPageDescription/DescriptionForm';
import { DEFAULT_JOB_POSTING } from '.';
import styles from './JobPostingCreationModal.module.less';

interface JobPostingCreationModalProps {
  open: boolean;
  onSelectTemplate: (jobPostingTemplate: any) => void;
  onClose: () => void;
}

const JobPostingCreationModal: React.FC<JobPostingCreationModalProps> = ({
  open,
  onSelectTemplate,
  onClose,
}) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const { data: clientCareerPages } = useQuery(getClientCareerPages, {
    variables: {
      id: clientId,
    },
  });

  const jobPostings = useMemo(
    () => clientCareerPages?.client?.careerPages?.[0]?.jobPostings || [],
    [clientCareerPages],
  );
  const [offerSearch, setOfferSearch] = useState('');
  const [showCreationFromTemplate, setShowCreationFromTemplate] = useState(
    false,
  );

  const handleSelectType = useCallback(
    (type) => {
      if (type === 'template') {
        setShowCreationFromTemplate(true);
      } else {
        onSelectTemplate(DEFAULT_JOB_POSTING);
      }
    },
    [onSelectTemplate],
  );

  const filteredJobPostings = useMemo(() => {
    if (_.isEmpty(offerSearch)) {
      return jobPostings;
    }
    return _.filter(
      jobPostings,
      (jobPosting) =>
        getTranslatedText(jobPosting.title)
          .toLowerCase()
          .indexOf(offerSearch.toLowerCase()) >= 0,
    );
  }, [offerSearch, jobPostings]);

  return (
    <GenericModal
      open={open}
      closeOnDimmerClick
      closeOnEscape
      onClose={() => onClose()}
    >
      <Modal.Header>
        {t('reveal.missions.mission.settingsTab.jobPosting.modal.title')}
      </Modal.Header>
      <Modal.Content className={styles.modalContent}>
        <div className={styles.jobPostingmodalContainer}>
          <div
            className={classnames(
              styles.choicesContainer,
              showCreationFromTemplate ? styles.hidden : '',
            )}
          >
            <JobPostingCreationTypeCard
              title={t(
                'reveal.missions.mission.settingsTab.jobPosting.modal.fromTemplate',
              )}
              illustrationPath='/images/illustrations/newJobPostingFromTemplate.svg'
              alt='Generate job posting from template'
              type='template'
              onSelect={handleSelectType}
            />
            <JobPostingCreationTypeCard
              title={t(
                'reveal.missions.mission.settingsTab.jobPosting.modal.fromScratch',
              )}
              illustrationPath='/images/illustrations/newJobPostingFromScratch.svg'
              alt='Generate job posting from scratch'
              type='blank'
              onSelect={handleSelectType}
            />
          </div>
          <div className={styles.templateSelectorContainer}>
            <div className={styles.description}>
              {t(
                'reveal.missions.mission.settingsTab.jobPosting.modal.fromTemplateDescription',
              )}
            </div>
            <div>
              <GenericTextInput
                className={styles.offerSearch}
                value={offerSearch}
                placeholder='Search offer'
                onValue={setOfferSearch}
              />
            </div>
            <div className={styles.jobPostingsContainer}>
              {_.isEmpty(filteredJobPostings) ? (
                <p className={styles.emptyState}>
                  {t(
                    'reveal.missions.mission.settingsTab.jobPosting.modal.noResult',
                  )}
                </p>
              ) : (
                <>
                  {_.map(filteredJobPostings, (jobPosting) => (
                    <div className={styles.jobPosting} key={jobPosting.id}>
                      <div className={styles.jobPostingHeader}>
                        <h4>{getTranslatedText(jobPosting.title)}</h4>
                        <span
                          className={classnames(
                            styles.publicationStatus,
                            jobPosting.published
                              ? styles.published
                              : styles.unpublished,
                          )}
                        >
                          {jobPosting.published ? (
                            <i className='ri-checkbox-blank-circle-fill' />
                          ) : (
                            <i className='ri-checkbox-blank-circle-line' />
                          )}
                          {jobPosting.published
                            ? t('offers.jobPostings.display.published')
                            : t('offers.jobPostings.display.notPublished')}
                        </span>
                      </div>
                      <div className={styles.jobPostingActions}>
                        <GenericPopup
                          popperModifiers={[
                            {
                              // Fix a positioning problem
                              name: 'preventOverflow',
                              options: {
                                boundariesElement: 'offsetParent',
                              },
                            }
                          ]}
                          trigger={
                            <i
                              className={classnames(
                                'ri-eye-line ri-lg',
                                styles.jobPostingPreviewTrigger,
                              )}
                            />
                          }
                          closeOnPortalMouseLeave
                          content={
                            <div className={styles.jobPostingPreview}>
                              <DescriptionForm
                                sections={jobPosting.description.sections}
                                readOnly
                              />
                            </div>
                          }
                          position='left center'
                          on='hover'
                        />
                        <GenericButton
                          primacy='primary'
                          onClick={() =>
                            onSelectTemplate(_.omit(jobPosting, 'id'))
                          }
                        >
                          {t(
                            'reveal.missions.mission.settingsTab.jobPosting.modal.useOffer',
                          )}
                        </GenericButton>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        {showCreationFromTemplate ? (
          <GenericButton
              color='grey'
              primacy='secondary'
              onClick={() => setShowCreationFromTemplate(false)}
            >
              {t('common.back')}
            </GenericButton>
        ) : (
          <GenericButton color='grey' primacy='secondary' onClick={onClose}>
            {t('common.cancel')}
          </GenericButton>
        )}
      </Modal.Actions>
    </GenericModal>
  );
};

interface JobPostingCreationTypeCardProps {
  illustrationPath: string;
  title: string;
  alt: string;
  type: string;
  onSelect: (type: string) => void;
}

const JobPostingCreationTypeCard: React.FC<JobPostingCreationTypeCardProps> = ({
  illustrationPath,
  title,
  alt,
  type,
  onSelect,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.choiceCard}>
      <img src={illustrationPath} alt={alt} />
      <h3 className={styles.choiceTitle}>{title}</h3>
      <div className={styles.choiceCardAction}>
        <GenericButton
          className={styles.choiceButton}
          onClick={() => onSelect(type)}
        >
          {t('sequences.newSequenceModal.choose')}
        </GenericButton>
        <div className={styles.gradientButtonBackground}>
          {t('sequences.newSequenceModal.choose')}
        </div>
      </div>
    </div>
  );
};

export default JobPostingCreationModal;
