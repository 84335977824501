import _ from 'underscore';
import classNames from 'classnames';
import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useDataUpdateSubscription from '@/graphql/dataUpdateSubscription/useDataUpdateSubscription';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import { useAddProfilesToMissionAndSequenceMutation } from '@/graphql/searchPoolProfiles';

import SequenceDropdown from '@/components/Reveal/Sequences/SequenceDropdown';
import ProfileRowSequence from '@/routes/RevealView/ProfileRow/ProfileRowSequence';
import { CLIENT_SEQUENCES } from '@/containers/Parameters/Sequences/queries';
import RemoveFromSequenceModal from '@/revealComponents/Modals/RemoveFromSequenceModal';
import { GET_JOB_DEFAULT_SEQUENCES } from '@/graphql/searchPoolJob';
import DropdownTrigger from '@/components/Reveal/Dropdown/DropdownTrigger/DropdownTrigger';
import DropdownContainer from '@/components/Reveal/Dropdown/DropdownContainer/DropdownContainer';
import AddToSequence from './AddToSequence';
import SequenceDropdownContainer from './SequenceDropdownContainer';

import styles from './SequenceProfileManagement.module.less';

interface SequenceProfileManagementProps {
  clientId: string;
  missionId: string;
  shiftSidebar: boolean;
  onSequenceEdited?: () => void;
}

const SequenceProfileManagement: React.FC<SequenceProfileManagementProps> = ({
  clientId,
  missionId,
  shiftSidebar,
  onSequenceEdited,
}) => {
  const { profile } = useCandidateViewContext();
  const { t } = useTranslation();
  const isPlugin = useIsPlugin();
  const notification = useNotificationSystem();
  const { isMinimized } = useMinimizedView();
  const [submitting, setSubmitting] = useState(false);
  const [
    displayInterruptSequenceModal,
    setDisplayInterruptSequenceModal,
  ] = useState(false);
  const { data: jobData } = useQuery(GET_JOB_DEFAULT_SEQUENCES, {
    variables: { searchPoolId: 'reveal', jobId: missionId },
    skip: !missionId,
  });
  const attachedSequences = jobData?.searchPool?.job?.attachedSequences;
  const defaultSequenceId = _.findWhere(attachedSequences, { isDefault: true })
    ?.sequenceId;
  const secondarySequenceIds = _.pluck(
    _.filter(
      attachedSequences,
      ({ sequenceId }) => sequenceId !== defaultSequenceId,
    ),
    'sequenceId',
  );
  const { data, refetch } = useQuery(CLIENT_SEQUENCES, {
    variables: { clientId },
  });
  const [
    addProfilesToMissionAndSequence,
  ]: any = useAddProfilesToMissionAndSequenceMutation();

  const clientSequencesList = _.filter(
    data?.client?.sequences,
    ({ isArchived }) => !isArchived,
  );
  const isMarkedAsDoNotContact = profile?.privacyState?.markedAsDoNotContact;
  const currentSequence = profile?.currentSequenceInfo;

  useDataUpdateSubscription({
    type: 'onSequencesListUpdated',
    onData: () => refetch(),
  });

  const handleAddSequence = async (id: string) => {
    if (submitting) {
      return;
    }
    // closes the popup
    setSubmitting(true);
    try {
      const profileIds = [profile?.id];
      const { data } = await addProfilesToMissionAndSequence({
        variables: {
          searchPoolId: 'reveal',
          input: {
            sequenceId: id,
            profileIds,
            ...{ interruptCurrentSequence: true },
          },
        },
        sequence: _.findWhere(clientSequencesList, {
          id,
        }),
      });

      if (_.isEmpty(data)) {
        setSubmitting(false);
        return;
      }

      const results =
        data?.searchPool?.addProfilesToMissionAndSequence?.results || [];
      const successes = _.filter(results, ({ success }) => success);
      const errors = _.filter(results, ({ success }) => !success);

      if (!_.isEmpty(errors)) {
        notification.error(
          t('reveal.candidatesView.actionsBox.addToSequenceError', {
            count: errors.length > 1 ? 2 : 1,
            nb: errors.length,
          }),
        );
      }
      if (!_.isEmpty(successes)) {
        notification.success(
          t('applySequenceDropdown.success', {
            count: successes.length > 1 ? 2 : 1,
            nb: successes.length,
          }),
        );
      }

      setSubmitting(false);

      if (onSequenceEdited) {
        onSequenceEdited();
      }
    } catch (e) {
      Sentry.captureException(e);
      notification.error(t('reveal.recommendedProfiles.sequence.addError'));
      setSubmitting(false);
    }
  };

  return (
    <>
      <div
        className='sequences-management-container'
        id='sequences-management-container'
      >
        <div className='title'>
          {t('profile.missionsAndSequences.sequences')}
        </div>
        {isMarkedAsDoNotContact && (
          <div className='empty-sequence'>
            {t('profile.missionsAndSequences.cannotBeAddedToSequence')}
          </div>
        )}
        {!isMarkedAsDoNotContact && !_.isEmpty(currentSequence) && (
          <div
            className={classNames('sequences', styles.selectedSequenceName, {
              plugin: isPlugin,
            })}
            id='sequences-dropdown'
          >
            {isMinimized ? (
              <div className='sequences-infos'>
                <div className='sequence-divider' />
                <ProfileRowSequence
                  currentSequenceInfo={currentSequence}
                  sequences={profile?.contactFlow?.sequences}
                  nextInteraction={null}
                  loading={false}
                  isStandalone={false}
                  mini
                  hideTitle
                  hideTooltip
                />
              </div>
            ) : (
              <SequenceDropdown
                position='right'
                isPlugin={isPlugin}
                clientSequencesList={clientSequencesList}
                defaultSequenceId={defaultSequenceId}
                secondarySequenceIds={secondarySequenceIds}
                currentSequence={currentSequence}
                onSequenceSelected={handleAddSequence}
                clientId={clientId}
                shiftSidebar={shiftSidebar}
                Trigger={({ onClick }) => (
                  <button
                    type='button'
                    onClick={onClick}
                    style={{ display: 'block', width: '100%', padding: 0 }}
                  >
                    <DropdownContainer mini>
                      <DropdownTrigger
                        clearable
                        onClear={() => {
                          setDisplayInterruptSequenceModal(true);
                        }}
                      >
                        <div className='sequences-infos'>
                          <div className='sequence-divider' />
                          <span>{currentSequence?.sequence?.title}</span>
                          {!isPlugin && (
                            <ProfileRowSequence
                              currentSequenceInfo={currentSequence}
                              sequences={profile?.contactFlow?.sequences}
                              nextInteraction={null}
                              loading={false}
                              isStandalone={false}
                              mini={isPlugin}
                              hideTitle
                              hideTooltip
                            />
                          )}
                        </div>
                      </DropdownTrigger>
                    </DropdownContainer>
                  </button>
                )}
              />
            )}
          </div>
        )}
        {!isMarkedAsDoNotContact && _.isEmpty(currentSequence) && (
          <SequenceDropdownContainer
            sequences={clientSequencesList}
            defaultSequenceId={defaultSequenceId}
            secondarySequenceIds={secondarySequenceIds}
            clientId={clientId}
            onClickAdd={handleAddSequence}
            shiftSidebar={shiftSidebar}
            className={styles.dropdownContainer}
          >
            <div className='empty-sequence'>
              <p title={t('profile.missionsAndSequences.notInSequence')}>
                {t('profile.missionsAndSequences.notInSequence')}
              </p>
              {(!isMinimized || _.isEmpty(currentSequence)) && (
                <AddToSequence />
              )}
            </div>
          </SequenceDropdownContainer>
        )}
      </div>
      {displayInterruptSequenceModal && (
        <RemoveFromSequenceModal
          sequenceId={profile?.currentSequenceInfo?.sequenceId}
          candidates={[profile]}
          onCancel={() => setDisplayInterruptSequenceModal(false)}
          onSuccess={onSequenceEdited}
          isProfileView
        />
      )}
    </>
  );
};

export default SequenceProfileManagement;
