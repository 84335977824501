import React, { useMemo } from 'react';
import _ from 'underscore';
import * as yup from 'yup';
import { Form, Input, Modal } from 'semantic-ui-react';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import InputControl from '@/components/InputControl';
import {
  SelectControl,
  RadioButtonControl,
  FormField,
  DatePickerControl,
  ToggleControl,
} from '@/components/FormFields';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';

import { getCustomFieldJSONValue } from '@/common/customFields';
import { NonEmpty, pure } from '@/common/utils/array';
import styles from './CustomFieldForm.module.less';
import ProfileCustomFieldsPreview from './ProfileCustomFieldsPreview';
import GenericButton from '../Common/GenericButton';
import GenericModal from '../Common/GenericModal';

type EnumOptionType = 'enum:multiple' | 'enum';
export type TypeOptionType =
  | EnumOptionType
  | 'inline-text'
  | 'text'
  | 'day'
  | 'float'
  | 'integer';
const getTypeOptions = (
  t: TFunction,
  options: { onlyCustomFieldTypes?: TypeOptionType[] } = {},
) => {
  const allOptions: { label: string; value: TypeOptionType }[] = [
    {
      label: t('customFieldsSettings.fieldTypeLabel.multiselect'),
      value: 'enum:multiple',
    },
    {
      label: t('customFieldsSettings.fieldTypeLabel.select'),
      value: 'enum',
    },
    {
      label: t('customFieldsSettings.fieldTypeLabel.inline-text'),
      value: 'inline-text',
    },
    {
      label: t('customFieldsSettings.fieldTypeLabel.text'),
      value: 'text',
    },
    {
      label: t('customFieldsSettings.fieldTypeLabel.day'),
      value: 'day',
    },
    {
      label: t('customFieldsSettings.fieldTypeLabel.float'),
      value: 'float',
    },
    {
      label: t('customFieldsSettings.fieldTypeLabel.integer'),
      value: 'integer',
    },
  ];

  return allOptions.filter(({ value }) =>
    options.onlyCustomFieldTypes
      ? options.onlyCustomFieldTypes.includes(value)
      : true,
  );
};

function isEnum(type: TypeOptionType): type is 'enum:multiple' | 'enum' {
  return ['enum:multiple', 'enum'].includes(type);
}

export const isCustomFieldEnum = isEnum;

const getDisplayOptions = (t: TFunction) => [
  {
    label: t('customFieldsSettings.displayOptionsLabel.in-details'),
    value: 'in-details',
  },
  {
    label: t('customFieldsSettings.displayOptionsLabel.profile-top'),
    value: 'profile-top',
  },
];

const CustomFieldSchema = yup.object().shape({
  title: yup.string().required(),
  type: yup
    .array()
    .of(
      yup.string().oneOf(getTypeOptions((x: string) => x).map((x) => x.value)),
    )
    .min(1),
  options: yup.array().when('type', {
    is: (value: TypeOptionType | TypeOptionType[]) =>
      isEnum(Array.isArray(value) ? value[0] : value),
    then: yup
      .array()
      .of(yup.object().shape({ title: yup.string() }))
      .compact((item) => {
        return typeof item?.title !== 'string' || item?.title.length === 0;
      })
      .min(1)
      .test('unique-items', 'Options are not unique', (items) => {
        if (!items?.length) return false;
        const uniqueValues = _.uniq(_.compact(_.map(items, (v) => v?.title)));
        return uniqueValues.length === items?.length;
      }),
    otherwise: yup.array(),
  }),
  defaultValue: yup.mixed(),
});

function getOptionsErrorMessage(error: { message: string; type: string }) {
  if (['min', 'unique-items'].includes(error.type)) return error.type;
  return 'default';
}

type FormData = {
  originalId?: string;
  title: string;
  type: NonEmpty<TypeOptionType[]>;
  options?: { originalId?: string; title: string }[];
  display: 'in-details' | 'profile-top';
  defaultValue: string | string[] | number | undefined;
  shouldDisplayInCard?: boolean;
  isMultiselect?: boolean;
  shouldBeHidden?: boolean;
};

function randomString(count: number) {
  return Math.random().toString(36).substr(2, count).toUpperCase();
}

export function generateId(
  name: string | null,
  options: { exclude: string[] } = { exclude: [] },
): string {
  const id = (name || '').toLowerCase().replace(/[^a-z0-9]/g, '-');

  let uniqueId = id;
  while (options.exclude.includes(uniqueId)) {
    uniqueId = `${id}-${randomString(1)}`;
  }
  return uniqueId;
}

/**
 * Normalize the form data to the format that the API expects.
 */
function getNormalizedValues(
  rawValues: FormData,
  { customFields }: { customFields: CustomFieldDefinition[] },
): CustomFieldDefinition & { defaultValue?: string | string[] | number } {
  const type = _.first(rawValues.type) || 'text';
  const candidateId = generateId(rawValues.title, {
    exclude: customFields.map((x) => x.id),
  });
  if (isEnum(type)) {
    return {
      id: rawValues.originalId || candidateId,
      title: { default: rawValues.title },
      display: rawValues.display,
      type: 'enum',
      isMultiselect: !!(type === 'enum:multiple' || rawValues.isMultiselect),
      options: _.map(rawValues.options || [], (option) => ({
        id: option.originalId || generateId(option.title),
        title: { default: option.title },
      })),
      defaultValue: rawValues.defaultValue,
      shouldDisplayInCard: rawValues.shouldDisplayInCard || false,
      shouldBeHidden: rawValues.shouldBeHidden || false,
    };
  }
  const base = {
    id: rawValues.originalId || candidateId,
    title: { default: rawValues.title },
    display: rawValues.display,
    defaultValue: getCustomFieldJSONValue(type, rawValues.defaultValue || ''),
    shouldDisplayInCard: rawValues.shouldDisplayInCard || false,
    isMultiselect: rawValues.isMultiselect || false,
    shouldBeHidden: rawValues.shouldBeHidden || false
  };

  if (type === 'inline-text' || type === 'text' || type === 'day') {
    return {
      ...base,
      type,
    };
  }

  return {
    ...base,
    type,
  };
}

function getInitialForOptionType(type: TypeOptionType): FormData {
  if (isEnum(type)) {
    return {
      title: '',
      type: [type],
      options: [],
      display: 'in-details',
      defaultValue: '',
    };
  }
  return { title: '', type: [type], display: 'in-details', defaultValue: '' };
}

function getInitials(
  initial?: Partial<CustomFieldDefinition>,
  { onlyCustomFieldTypes }: { onlyCustomFieldTypes?: TypeOptionType[] } = {},
): FormData {
  if (!initial) {
    return getInitialForOptionType(
      onlyCustomFieldTypes?.length ? onlyCustomFieldTypes[0] : 'enum',
    );
  }
  const base = {
    originalId: initial?.id || '',
    title: initial?.title?.default || '',
    type: pure(initial?.type || 'enum'),
    display: initial?.display || 'in-details',
    defaultValue: '',
    shouldDisplayInCard: initial?.shouldDisplayInCard || false,
    isMultiselect: initial?.isMultiselect || false,
    shouldBeHidden: initial?.shouldBeHidden || false,
  };
  if (initial.type !== 'enum') return base;
  return {
    ...base,
    options:
      (initial?.options || []).map((op) => ({
        originalId: op.id || '',
        title: op.title.default,
      })) || [],
  };
}

export const CustomFieldForm = ({
  open,
  closeModal,
  initial,
  onSubmit,
  isProfile = true,
  customFields = [],
  onlyCustomFieldTypes,
  initialEnumOptions,
  shouldProposeDefaultValue = false,
}: {
  open: boolean;
  isProfile?: boolean;
  closeModal: () => void;
  initial?: Partial<CustomFieldDefinition>;
  initialEnumOptions?: { title: string }[];
  onSubmit: (
    field: CustomFieldDefinition & {
      defaultValue?: string | string[] | number | boolean;
    },
  ) => void;
  customFields?: CustomFieldDefinition[];
  onlyCustomFieldTypes?: TypeOptionType[];
  shouldProposeDefaultValue?: boolean;
}): JSX.Element => {
  const { i18n, t } = useTranslation('translations');
  const addOptionBtnRef = React.useRef<HTMLButtonElement | null>(null);
  const shouldFocusNewField = React.useRef(false);

  const typeOptions = React.useMemo(
    () => getTypeOptions(t, { onlyCustomFieldTypes }),
    [t, onlyCustomFieldTypes],
  );
  const displayOptions = React.useMemo(() => getDisplayOptions(t), [t]);
  const defaultValues: FormData = React.useMemo(
    () => getInitials(initial, { onlyCustomFieldTypes }),
    [initial, onlyCustomFieldTypes],
  );
  const hasInitial = Boolean(initial?.id);
  const methods = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(CustomFieldSchema),
  });
  const { handleSubmit, watch, control, setValue, formState, reset } = methods;
  const { errors } = formState;
  React.useEffect(() => {
    reset(getInitials(initial, { onlyCustomFieldTypes }));
    // eslint-disable-next-line
  }, [initial]);
  const [type] = watch('type') as NonEmpty<TypeOptionType[]>;
  const options = useMemo(
    () => (watch('options') || []) as { title: string }[],
    [watch],
  );
  const typeDescKey = `customFieldsSettings.fieldTypeDescription.${type}`;
  const typeDescription = i18n.exists(typeDescKey) ? t(typeDescKey) : undefined;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });

  const fieldsRefsById = React.useMemo(() => {
    const refs: Record<string, React.RefObject<Input>> = {};
    _.each(fields || [], (field) => {
      if (field.id) {
        refs[field.id] = React.createRef<Input>();
      }
    });
    return refs;
  }, [fields]);

  const addField = () => {
    append({ title: '' }, true);
    shouldFocusNewField.current = true;
  };

  React.useEffect(() => {
    if (shouldFocusNewField.current) {
      const lastField = _.last(fields);
      const ref = fieldsRefsById[lastField?.id || ''];
      if (ref?.current) {
        ref.current.focus();
        shouldFocusNewField.current = false;
      }
    }
    // eslint-disable-next-line
  }, [shouldFocusNewField.current]);

  React.useEffect(() => {
    if (!isEnum(type) && options.length) {
      setValue('options', []);
    }
    if (isEnum(type) && !options.length) {
      setValue('options', initialEnumOptions || [{ title: '' }]);
    }
  }, [type, options, setValue, initialEnumOptions]);

  React.useEffect(() => {
    const handleKeypress = (event: KeyboardEvent) => {
      event.stopImmediatePropagation();
      if (event.key === 'Enter' && addOptionBtnRef.current) {
        addOptionBtnRef.current.click();
      }
    };
    document.addEventListener('keypress', handleKeypress);
    return () => document.removeEventListener('keypress', handleKeypress);
  }, []);

  const submitField = (rawValues: FormData) => {
    onSubmit(getNormalizedValues(rawValues, { customFields }));
  };

  const activeField = getNormalizedValues(watch(), { customFields });
  const customFieldsPreview = getCustomFieldsPreview({
    customFields,
    defaultValues,
    activeField,
  });
  const hasDefaultValue =
    shouldProposeDefaultValue &&
    [
      'text',
      'inline-text',
      'float',
      'integer',
      'enum',
      'enum:multiple',
    ].includes(type);

  const editMode = hasInitial ? 'edition' : 'creation';

  return (
    <GenericModal
      open={open}
      onClose={closeModal}
      closeOnEscape
      closeOnDimmerClick
      className={styles.modal}
    >
      <Modal.Header>
        {t(`customFieldsSettings.${editMode}Modal.title`)}
      </Modal.Header>
      <FormProvider {...methods}>
        <Modal.Content className={styles.modal}>
          <div
            className={styles.form}
            aria-hidden='true'
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                // Prevent form submission by clicking on Enter key
                event.preventDefault();
              }
            }}
          >
            <Form
              onSubmit={handleSubmit(submitField)}
              id='custom-field-form'
              data-testid='custom-field-form'
            >
              <input type='hidden' {...methods.register('originalId')} />
              <InputControl
                name='title'
                label={t('customFieldsSettings.fieldLabel')}
                type='text'
                placeholder={t('customFieldsSettings.fieldPlaceholder')}
                errorMessage={t('customFieldsSettings.fieldError')}
              />
              <SelectControl
                name='type'
                options={typeOptions}
                helpText={hasInitial ? '' : typeDescription}
                errorMessage={t('customFieldsSettings.typeError')}
                disabled={hasInitial}
                aria-label={t('customFieldsSettings.typeLabel')}
              />
              {isEnum(type) && hasInitial && (
                <ToggleControl
                  name='isMultiselect'
                  label={t('customFieldsSettings.allowMultiselect')}
                  defaultValue={defaultValues.isMultiselect}
                />
              )}
              {isEnum(type) && (
                <>
                  <div className={styles.options}>
                    {fields.map((item, index) => (
                      <div className={styles.rowOption} key={item.id}>
                        <InputControl
                          name={`options[${index}].originalId`}
                          type='hidden'
                          defaultValue={item.originalId || ''}
                        />
                        <InputControl
                          ref={fieldsRefsById[item.id || '']}
                          name={`options[${index}].title`}
                          type='text'
                          className={styles.inputOption}
                          containerClassName={styles.inputOptionRow}
                          placeholder={t(
                            'customFieldsSettings.enumOptionPlaceholder',
                            { index: index + 1 },
                          )}
                          defaultValue={item.title || ''}
                        />
                        <button
                          type='button'
                          onClick={() => remove(index)}
                          aria-label={t('customFieldsSettings.deleteOption')}
                          className={styles.deleteOption}
                          disabled={hasInitial && item.originalId}
                        >
                          <i className='ri-close-line' />
                        </button>
                      </div>
                    ))}
                    <div className={styles.addOptionContainer}>
                      <button
                        type='button'
                        ref={addOptionBtnRef}
                        className={styles.addOption}
                        onClick={() => addField()}
                      >
                        <i className='ri-add-line' />
                        {t('customFieldsSettings.addOption')}
                      </button>
                    </div>
                  </div>
                  {errors?.options && (
                    <FormField.Error>
                      {t(
                        `customFieldsSettings.enumOptionErrors.${getOptionsErrorMessage(
                          errors.options as unknown as {
                            message: string;
                            type: string;
                          },
                        )}`,
                      )}
                    </FormField.Error>
                  )}
                </>
              )}
              {hasDefaultValue && isEnum(type) && (
                <SelectControl
                  name='defaultValue'
                  multiple={type === 'enum:multiple'}
                  options={options.map(({ title }) => ({
                    value: title,
                    label: title,
                  }))}
                  label={t('customFieldsSettings.defaultValueLabel')}
                  placeholder={t(
                    'customFieldsSettings.defaultValuePlaceholder',
                  )}
                  errorMessage={t('customFieldsSettings.defaultValueError')}
                  className={styles.defaultValueSelectField}
                />
              )}
              {hasDefaultValue && !isEnum(type) && type !== 'day' && (
                <InputControl
                  name='defaultValue'
                  label={t('customFieldsSettings.defaultValueLabel')}
                  type='text'
                  placeholder={t(
                    'customFieldsSettings.defaultValuePlaceholder',
                  )}
                  errorMessage={t('customFieldsSettings.defaultValueError')}
                />
              )}
              {hasDefaultValue && type === 'day' && (
                <DatePickerControl
                  name='defaultValue'
                  label={t('customFieldsSettings.defaultValueLabel')}
                  errorMessage={t('customFieldsSettings.defaultValueError')}
                />
              )}
              {isProfile && (
                <RadioButtonControl
                  name='display'
                  label={t('customFieldsSettings.displayLabel')}
                  options={displayOptions}
                  containerClassName={styles.displayOption}
                  defaultValue={defaultValues.display}
                />
              )}
              <div className={styles.toggleContainer}>
                <ToggleControl
                  name='shouldDisplayInCard'
                  label={t('customFieldsSettings.displayInCard')}
                  defaultValue={defaultValues.shouldDisplayInCard}
                />
              </div>
            </Form>
          </div>
          {isProfile && (
            <ProfileCustomFieldsPreview
              className={styles.preview}
              customFields={customFieldsPreview}
              activeFieldId={activeField.id}
            />
          )}
        </Modal.Content>
        <Modal.Actions className={styles.actions}>
          <GenericButton size='big' primacy='secondary' onClick={closeModal}>
            {t('customFieldsSettings.cancel')}
          </GenericButton>
          <GenericButton size='big' type='submit' form='custom-field-form'>
            {t(`customFieldsSettings.${editMode}Modal.save`)}
          </GenericButton>
        </Modal.Actions>
      </FormProvider>
    </GenericModal>
  );
};

function getCustomFieldsPreview({
  customFields,
  defaultValues,
  activeField,
}: {
  customFields: CustomFieldDefinition[];
  defaultValues: FormData;
  activeField: CustomFieldDefinition;
}): CustomFieldDefinition[] {
  if (!defaultValues.originalId) return [...customFields, activeField];
  const index = customFields.findIndex(
    (field) => field.id === defaultValues.originalId,
  );
  return [
    ...customFields.slice(0, index),
    activeField,
    ...customFields.slice(index + 1),
  ];
}

export default CustomFieldForm;
