import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getTranslatedText } from '@/common';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import _ from 'underscore';
import { UNIPILE_TASK_TYPES } from '@/common/constants/taskTypes';
import useLocalStorage from '@/hooks/storage/useLocalStorage';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import { getTranslatedDateOrDuration } from '../../helpers';
import ActionItemContainer from './ActionItemContainer';
import ActionItemHeader from './ActionItemHeader';
import { LinkedinSenderConfig } from './SendUnipileLinkedinMessageButton';

const DEFAULT_HEIGHT = 100;
interface ActionItemProps {
  action: any;
  isLast: boolean;
  isBox?: boolean;
  onRemove?: () => void;
  onEdit?: () => void;
  onChangeToNow?: () => void;
  displayAdvanceTask: boolean;
  isEditable?: boolean;
  isInlineEdition?: boolean;
  setIsInlineEdition?: React.Dispatch<React.SetStateAction<boolean>>;
  isFolded: boolean;
  setIsFolded: React.Dispatch<React.SetStateAction<boolean>>;
  setHeightToAddToContainer?: (heightToAddToContainer: number) => void;
}

const ActionItem: React.FC<ActionItemProps> = ({
  action,
  isLast,
  children,
  isBox,
  onRemove,
  onEdit,
  onChangeToNow,
  displayAdvanceTask,
  isEditable,
  isInlineEdition,
  setIsInlineEdition,
  isFolded,
  setIsFolded,
  setHeightToAddToContainer,
}) => {
  const { t } = useTranslation();
  const { plannedExecutionDate, trigger, type } = action || {};
  const heightRef = useRef<HTMLInputElement>(null);
  const [messageHeight, setMessageHeight] = useState(DEFAULT_HEIGHT);
  const configurationParameters = useMergedConfigurationParams();
  const description = action?.description;

  const [selectedUnipileSenderId, setSelectedUnipileSenderId] = useState(
    action?.message?.senderId || action?.senderId,
  );

  const [unipileLinkedinSenderConfig] = useLocalStorage<LinkedinSenderConfig>(
    'revealLinkedinSender',
    null,
  );

  const { sharedMessagingAccounts } = useSharedMessagingAccounts({
    skip: !_.includes(UNIPILE_TASK_TYPES, action?.type),
  });

  useEffect(() => {
    if (!heightRef || !heightRef.current) {
      return;
    }
    const messageBox = heightRef.current.getBoundingClientRect();
    setMessageHeight(messageBox.height + (isInlineEdition ? 20 : 0));
  }, [description, heightRef, children, isInlineEdition]);

  useEffect(() => {
    if (!selectedUnipileSenderId) {
      setSelectedUnipileSenderId(unipileLinkedinSenderConfig);
    }
  }, [unipileLinkedinSenderConfig, selectedUnipileSenderId]);

  const selectedMessagingAccount = useMemo(
    () =>
      _.includes(UNIPILE_TASK_TYPES, action.type)
        ? _.findWhere(sharedMessagingAccounts, { id: selectedUnipileSenderId })
        : undefined,
    [selectedUnipileSenderId, sharedMessagingAccounts, action],
  );

  const handleActionFolding = () => {
    setIsFolded(!isFolded);
    const newHeightToAddToContainer = messageHeight - DEFAULT_HEIGHT;
    if (setHeightToAddToContainer) {
      setHeightToAddToContainer(
        isFolded ? newHeightToAddToContainer : newHeightToAddToContainer * -1,
      );
    }
  };

  const handleDescriptionClick = () => {
    if (setIsInlineEdition) {
      setIsInlineEdition(true);
    }
    if (isFolded) {
      setIsFolded(false);
      const newHeightToAddToContainer = messageHeight - DEFAULT_HEIGHT;
      if (setHeightToAddToContainer) {
        setHeightToAddToContainer(newHeightToAddToContainer);
      }
    }
  };

  return (
    <ActionItemContainer
      isBox={isBox}
      isLast={isLast}
      action={action}
      withIcon={false}
    >
      <ActionItemHeader
        action={action}
        onRemove={onRemove}
        onEdit={onEdit}
        withIcon
        selectedUnipileSenderId={selectedUnipileSenderId}
        setSelectedUnipileSenderId={setSelectedUnipileSenderId}
        messagingAccount={selectedMessagingAccount}
        sharedMessagingAccounts={sharedMessagingAccounts}
      />
      <div className='action-item-content-subtitle'>
        {getTranslatedDateOrDuration({
          configurationParameters,
          plannedExecutionDate,
          trigger,
          type,
          t,
        })}
        {displayAdvanceTask && (
          <span onClick={onChangeToNow}>
            {t('reveal.candidatesView.timeline.changeToNow')}
          </span>
        )}
      </div>
      {action?.message?.sender?.senderAddress && (
        <div className='sender'>
          <span className='intro-word'>
            {t('profile.contact.timeline.from')}
          </span>
          <span className='sender-address'>
            {action?.message?.sender?.senderAddress}
          </span>
        </div>
      )}
      {/* Invisible copy of action item to compute height */}
      <div className='action-item-content-description copy' ref={heightRef}>
        {action.description && getTranslatedText(action.description)}
        {children}
      </div>
      {(action.description || children) && (
        <div
          className={classNames(
            'action-item-content-description',
            isFolded && 'folded',
            isEditable && 'editable',
          )}
          style={{
            maxHeight: !isFolded ? `${messageHeight}px` : `${DEFAULT_HEIGHT}px`,
          }}
          onClick={handleDescriptionClick}
        >
          {!isInlineEdition && action.description && (
            <span
              dangerouslySetInnerHTML={{
                __html: getTranslatedText(action.description).replace(
                  /(?:\r\n|\r|\n)/g,
                  '<br/>',
                ),
              }}
            />
          )}
          {children}
          {messageHeight > DEFAULT_HEIGHT && isFolded && (
            <div className='gradient-cover' />
          )}
        </div>
      )}
      {messageHeight > DEFAULT_HEIGHT && !isInlineEdition && (
        <span
          className='show-more'
          onClick={handleActionFolding}
          onKeyPress={handleActionFolding}
          role='button'
        >
          {t(`profile.contact.timeline.${isFolded ? 'seeMore' : 'seeLess'}`)}
        </span>
      )}
    </ActionItemContainer>
  );
};

export default ActionItem;
