import gql from 'graphql-tag';
import MailAccount from './MailAccount';

export interface IUserSettings {
  id: string;
  email: string;
  alias: string;
  isAdmin: boolean;
  signature: string;
  firstLogin: boolean;
  firstLoginReveal: boolean;
  language?: string;
  unbiasedModeEnabled: boolean;
  applicationMode: string;
  showConfirmationModal: boolean;
  mailAccount: any;
  notifications?: {
    crm?: {
      pendingTasks?: {
        type: string;
      };
      pendingRecommendations?: {
        type: string;
      };
      weeklyStats?: {
        type: string;
      };
      unipile?: boolean;
    };
  };
  newPricingValidated: boolean;
  greenhouseUserId: string;
  greenhouseUserName: string;
  leverUserId: string;
  leverUserName: string;
  smartrecruitersUserId: string;
  smartrecruitersUserName: string;
  teamtailorUserId: string;
  teamtailorUserName: string;
  workableUserId: string;
  workableUserName: string;
  recruiteeUserId: string;
  recruiteeUserName: string;
  recruitcrmUserId: string;
  recruitcrmUserName: string;
  welcomekitUserId: string;
  welcomekitUserName: string;
  boondmanagerUserId: string;
  boondmanagerUserName: string;
  ashbyUserId: string;
  ashbyUserName: string;
}
const UserSettings = gql`
  fragment UserSettings on User {
    id
    email
    alias
    isAdmin
    signature
    description
    firstLogin
    firstLoginReveal
    language
    unbiasedModeEnabled
    applicationMode
    showConfirmationModal
    mailAccount {
      ...MailAccount
    }
    emailNotificationsSettings {
      discover
      newFeatures
    }
    notifications {
      crm {
        pendingTasks {
          type
        }
        pendingRecommendations {
          type
        }
        weeklyStats {
          type
        }
        unipile
      }
    }
    newPricingValidated
    greenhouseUserId
    greenhouseUserName
    leverUserId
    leverUserName
    smartrecruitersUserId
    smartrecruitersUserName
    teamtailorUserId
    teamtailorUserName
    workableUserId
    workableUserName
    recruiteeUserId
    recruiteeUserName
    recruitcrmUserId
    recruitcrmUserName
    welcomekitUserId
    welcomekitUserName
    boondmanagerUserId
    boondmanagerUserName
    ashbyUserId
    ashbyUserName
  }
  ${MailAccount}
`;

export default UserSettings;
