import gql from 'graphql-tag';

export default gql`
  fragment Sources on Sources {
    linkedin
    github
    twitter
    website
    stackoverflow
    dribbble
    behance
  }
`;
