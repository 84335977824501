import React, { Component } from 'react';
import RSelect from 'react-select';

const SECONDARY_BLUE_100 = '#4864C9';
const SECONDARY_BLUE_80 = '#6D83D4';
const SECONDARY_BLUE_40 = '#B6C1E9';
const SECONDARY_BLUE_20 = '#DAE0F4';
const SECONDARY_BLUE_10 = '#EDF0FA';
const PRIMARY_COBALT_05 = '#F3F4F8';
const SECONDARY_DARK_80 = '#404564';

const fullFormWidth = '100%';

/*
  props: {
    currentValue,
    label,
    options,
    multi,
    placeholderText,
  }
*/
class CustomSelect extends Component {
  customMultiSelectStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: 'white',
      width: this.props.width || fullFormWidth,
      border: isFocused
        ? `1px solid ${SECONDARY_BLUE_100};`
        : `1px solid ${SECONDARY_BLUE_20};`,
      ':hover': {
        border: isFocused
          ? `1px solid ${SECONDARY_BLUE_100};`
          : `1px solid ${SECONDARY_BLUE_80};`,
      },
      boxShadow: isFocused ? 0 : 0,
    }),

    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? SECONDARY_BLUE_20
        : isFocused
        ? SECONDARY_BLUE_10
        : null,
      color: isDisabled ? '#ccc' : SECONDARY_DARK_80,
      cursor: isDisabled ? 'not-allowed' : 'default',
      height: '30px',
      'min-height': '30px',
    }),
    menu: (styles) => ({
      ...styles,
      width: this.props.width || fullFormWidth,
    }),
    input: (styles) => ({
      ...styles,
      padding: '1px',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: 'rgba(191, 191, 191, 0.87)',
    }),
    singleValue: (styles) => ({
      ...styles,
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: PRIMARY_COBALT_05,
      color: SECONDARY_BLUE_100,
      padding: '2px',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: SECONDARY_BLUE_100,
      fontSize: '100%',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: SECONDARY_BLUE_100,
      ':hover': {
        backgroundColor: PRIMARY_COBALT_05,
        color: SECONDARY_DARK_80,
      },
    }),
  };

  handleChange = (value) => {
    const { onChange, label } = this.props;
    onChange({ label, value });
  };

  render() {
    const {
      label,
      options,
      multi,
      placeholderText,
      disabled,
      value,
      required,
      ...otherProps
    } = this.props;

    return (
      <RSelect
        isDisabled={disabled}
        className='custom-select'
        placeholder={placeholderText}
        styles={this.customMultiSelectStyles}
        {...otherProps}
        name={label}
        value={value}
        onChange={this.handleChange}
        isMulti={!!multi}
        options={options}
        isClearable={false}
        required={required}
        theme={(theme) => ({
          ...theme,
          borderRadius: 2,
          colors: {
            ...theme.colors,
            primary50: SECONDARY_BLUE_40,
            primary: SECONDARY_BLUE_100,
          },
        })}
      />
    );
  }
}

export default CustomSelect;
