import React from 'react';
import { Input, InputProps } from 'semantic-ui-react';
import classNames from 'classnames';
import { useController, useFormContext, Controller } from 'react-hook-form';
import { FormField } from '@/components/FormFields';

import styles from './InputControl.module.less';

const InputControl = React.forwardRef(
  (
    props: InputProps & {
      label?: string;
      horizontal?: boolean;
      errorMessage?: string;
      containerClassName?: string;
      onChange: (value: string) => void;
      legend?: string;
      clickableLegend?: boolean;
      onLegendClicked?: () => void;
    },
    ref: React.LegacyRef<Input>,
  ): JSX.Element => {
    const {
      label,
      name,
      id,
      horizontal = false,
      errorMessage,
      containerClassName,
      className,
      defaultValue,
      onChange,
      legend,
      clickableLegend,
      onLegendClicked,
      ...otherProps
    } = props;
    const htmlId = id || `field-${name}`;

    const { fieldState } = useController({ name, defaultValue });
    const { control } = useFormContext();

    return (
      <FormField horizontal={horizontal} className={containerClassName}>
        {label && <FormField.Label htmlFor={htmlId}>{label}</FormField.Label>}
        <FormField.Wrapper>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <Input
                fluid
                className={classNames('form-row-input', className)}
                {...otherProps}
                {...field}
                onChange={(_, { value }) => {
                  field.onChange(value);
                  onChange?.(value);
                }}
                input={{
                  autoComplete: 'chrome-off',
                  'data-form-type': 'other',
                }}
                id={htmlId}
                error={fieldState.invalid}
                ref={ref}
              />
            )}
          />
          {fieldState.invalid && errorMessage && (
            <FormField.Error>{errorMessage}</FormField.Error>
          )}
          {legend && (
            <div
              className={classNames(
                styles.formInputLegend,
                clickableLegend ? styles.clickable : '',
              )}
              onClick={onLegendClicked || (() => {})}
            >
              {legend}
            </div>
          )}
        </FormField.Wrapper>
      </FormField>
    );
  },
);

InputControl.displayName = 'InputControl';

export default InputControl;
