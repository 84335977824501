import gql from 'graphql-tag';

import TranslatableText from './TranslatableText';
import TextWithEnrichmentsAndStack from './TextWithEnrichmentsAndStack';
import SweetDate from './SweetDate';

export default gql`
  fragment GenericSourceData on GenericSourceData {
    type
    asAtsType
    sourceCandidateId

    creationDate
    lastUpdateDate
    lastActivityDate

    attachments {
      ...Attachment
      file {
        url
      }
    }

    firstname
    lastname
    headline {
      ...TextWithEnrichmentsAndStack
    }
    currentCompany {
      name
    }
    emails {
      type
      value
    }
    phoneNumbers {
      type
      value
    }
    addresses {
      type
      value
    }
    education {
      degree {
        text
      }
      startDate {
        ...SweetDate
      }
      endDate {
        ...SweetDate
      }
    }
    experiences {
      title {
        text
      }
      startDate {
        ...SweetDate
      }
      endDate {
        ...SweetDate
      }
      companyName
    }

    applications {
      sourceApplicationId
      status {
        sourceStatusId
        stage {
          sourceStageId
          sourceStageName
        }
      }
    }
    activityFeed {
      sourceActivityId
      type
      creationDate
      subject
      body
      user {
        sourceUserId
        firstname
        lastname
      }
      isPrivate
      visibility
    }
    tags {
      sourceTagId
      name {
        ...TranslatableText
      }
    }
  }

  ${SweetDate}
  ${TextWithEnrichmentsAndStack}
  ${TranslatableText}
`;
