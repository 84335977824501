import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Message } from 'semantic-ui-react';
import _ from 'underscore';

import { useMutation } from '@apollo/client';
import CustomFileInput from '@/components/CustomFileInput';
import ActionBox from '@/revealComponents/ActionBox';
import { getTranslatedText } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import { useAddProfilesToMissionAndSequenceMutation } from '@/graphql/searchPoolProfiles';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import {
  createCustomFieldsPayload,
  CustomFieldRawValue,
} from '@/common/customFields';
import { ADD_PROFILES_CUSTOM_FIELDS } from '@/graphql/searchPoolProfile';
import GenericModal from '@/components/Common/GenericModal';
import './ImportCandidatesCSVModal.css';
import ColoredBox from '@/common/ColoredBox';
import AddProfileToSequenceContextProvider from '@/context/AddProfileToSequenceContext';
import { ProfileRowError } from './helpers';
import ImportFromLinkedInTutorial from './ImportFromLinkedInTutorial';
import CSVOptionsForm from './CSVOptionsForm';
import { DetectedNewEnumOptions } from './analysis.helpers';
import Results from './Results';
import CSVPreview from './CSVPreview';
import useProfilesCSVImport from './useProfilesCSVImport';
import NewCandidateModal from './NewCandidateModal';

function useModal(defaultOpen: boolean) {
  const [open, setOpen] = React.useState(defaultOpen);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return { open, handleOpen, handleClose };
}

type ImportCandidatesCSVModalProps = {
  clientId: string;
  onClose: () => void;
  onSuccess: () => void;
  jobId: string;
  isLinkedinImport?: boolean;
};

const ImportCandidatesCSVModal = ({
  clientId,
  onSuccess,
  onClose,
  jobId,
  isLinkedinImport = true,
}: ImportCandidatesCSVModalProps): JSX.Element => {
  const { t } = useTranslation();
  const csvImport = useProfilesCSVImport({
    onSuccess,
    onDone: onClose,
    jobId,
    clientId,
  });
  const customFieldsQuery = useClientProfileCustomFields(clientId);
  const clientCustomFields = customFieldsQuery.profileCustomFields;
  const [
    addProfilesToMissionAndSequence,
  ] = useAddProfilesToMissionAndSequenceMutation();
  const [addProfilesCustomFields] = useMutation(ADD_PROFILES_CUSTOM_FIELDS);
  const newCandidateModal = useModal(false);
  const [errorDetailsOpen, setErrorDetailsOpen] = useState(false);
  const [showInstructions, setShowInstruction] = useState(false);
  const {
    rows,
    firstLineIsHeader,
    columnsMapping,
    setFirstLineIsHeader,
    profileErrors,
    importProfilesResults,
    profilesToUpload,
    isImporting,
    newEnumOptions,
  } = csvImport;

  const customFieldsToSave = useMemo(() => {
    if (!csvImport.isUploadSuccess) {
      return [];
    }
    return _.filter(
      csvImport.columnsMapping,
      (mapping) => mapping?.type === 'custom-field',
    );
  }, [csvImport]);

  const handleResultsActions = async ({
    addDuplicates,
    saveCustomFields,
  }: {
    addDuplicates: boolean;
    saveCustomFields: boolean;
  }) => {
    if (addDuplicates) {
      const profileIds = _.map(
        importProfilesResults?.duplicateProfiles,
        (profile) => profile.id,
      );
      if (_.isEmpty(profileIds)) {
        return;
      }
      try {
        await addProfilesToMissionAndSequence({
          variables: {
            searchPoolId: 'reveal',
            input: {
              missionId: jobId,
              profileIds,
            },
          },
        });
      } catch (error) {
        console.error({ error });
      }
    }

    if (saveCustomFields) {
      const customFields: Record<string, CustomFieldRawValue> | undefined = {};
      _.each(customFieldsToSave, (cf) => {
        if (!cf?.id) {
          return;
        }
        customFields[cf.id] = cf.defaultValue as CustomFieldRawValue;
      });
      const customFieldsPayload = createCustomFieldsPayload(
        customFields,
        clientCustomFields,
      );
      const profilesIds = _.map(
        csvImport.importProfilesResults.duplicateProfiles || [],
        (duplicateProfile) => duplicateProfile.id,
      );
      try {
        await addProfilesCustomFields({
          variables: {
            searchPoolId: 'reveal',
            input: {
              profilesIds,
              customFields: customFieldsPayload,
            },
          },
        });
      } catch (error) {
        console.error({ error });
      }
    }

    onClose();
  };

  const showError = (open: boolean) => {
    setErrorDetailsOpen(open);
  };

  const profilesToUploadCount = profilesToUpload?.length || 0;
  const profileErrorsCount = profileErrors?.length || 0;

  return showInstructions ? (
    <GenericModal
      onClose={() => setShowInstruction(false)}
      onOpen={() => setShowInstruction(true)}
      open={showInstructions}
    >
      <Modal.Header>
        {t('importProfiles.importFromLinkedin.instructions.title')}
      </Modal.Header>
      <Modal.Content>
        <ImportFromLinkedInTutorial />
      </Modal.Content>
      <Modal.Actions>
        <GenericButton size='big' onClick={() => setShowInstruction(false)}>
          {t('common.close')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  ) : (
    <AddProfileToSequenceContextProvider>
      <GenericModal
        open={!newCandidateModal.open}
        size={isImporting ? 'fullscreen' : 'small'}
        className='import-candidates-csv-modal'
        onClose={csvImport.onCancel}
      >
        <Modal.Header>{t('CSVImport.title')}</Modal.Header>
        <Modal.Content>
          {!isImporting && (
            <>
              <CustomFileInput
                onChange={csvImport.onDrop}
                fileTypes='.csv, application/vnd.ms-excel, text/csv'
                description={t('CSVImport.dragFile')}
                imageSrc='/images/placeholders/importContacts.png'
              />
            </>
          )}
          {isImporting && (
            <>
              <CSVPreview
                headerOptionsForm={
                  <CSVOptionsForm
                    firstLineIsHeader={firstLineIsHeader}
                    setFirstLineIsHeader={setFirstLineIsHeader}
                    setFileName={csvImport.setFileName}
                    fileName={csvImport.fileName}
                    recruiter={csvImport.recruiter}
                    setRecruiter={csvImport.setRecruiter}
                  />
                }
                headers={csvImport.headers}
                rows={rows}
                columnsMapping={columnsMapping}
                updateColumnMapping={csvImport.updateColumnMapping}
              />
              {Boolean(newEnumOptions.length) && (
                <NewEnumOptionsMessage newEnumOptions={newEnumOptions} />
              )}
              {!_.isEmpty(profileErrors) && !importProfilesResults && (
                <div className='results'>
                  {profileErrorsCount > 0 && (
                    <ColoredBox color='yellow' radius='8px'>
                      <div className='description negative'>
                        {t('CSVImport.errorDescription', {
                          count: profileErrorsCount,
                        })}
                      </div>
                      <span
                        className={`details-toggle ${
                          errorDetailsOpen ? 'active' : ''
                        }`}
                        onClick={() => showError(!errorDetailsOpen)}
                      >
                        <i className='ri-arrow-down-s-line ri-lg' />
                        {errorDetailsOpen
                          ? t('common.seeLess')
                          : t('common.seeMore')}
                      </span>
                      <div
                        className={`see-more-details ${
                          errorDetailsOpen ? 'open' : ''
                        }`}
                      >
                        <CSVImportErrorsList errors={profileErrors} />
                      </div>
                    </ColoredBox>
                  )}
                </div>
              )}
            </>
          )}

          {csvImport.isUploadSuccess && (
            <Results
              clientId={clientId}
              results={importProfilesResults}
              t={t}
              onSubmit={handleResultsActions}
              jobId={jobId}
              customFieldsToSave={customFieldsToSave}
            />
          )}
        </Modal.Content>
        {!isImporting && (
          <>
            {isLinkedinImport && (
              <ActionBox
                title={t('importProfiles.importFromLinkedin.title')}
                description={t('importProfiles.importFromLinkedin.description')}
                buttonText={t('importProfiles.importFromLinkedin.buttonText')}
                onClick={() => setShowInstruction(true)}
              />
            )}
            <button
              type='button'
              className='import-one'
              onClick={newCandidateModal.handleOpen}
            >
              {t('importProfiles.importOne.title')}
            </button>
          </>
        )}
        {isImporting && !importProfilesResults && (
          <Modal.Actions>
            <div className='actions'>
              <div>
                {profilesToUploadCount > 0 && profileErrorsCount > 0 && (
                  <GenericButton
                    size='big'
                    primacy='secondary'
                    onClick={() => csvImport.onUpload(true)}
                    disabled={!csvImport.canUpload || csvImport.isUploading}
                  >
                    {t('CSVImport.uploadXContacts', {
                      count: profilesToUploadCount,
                    })}
                  </GenericButton>
                )}
                <GenericButton
                  size='big'
                  onClick={() => csvImport.onUpload()}
                  disabled={
                    !_.isEmpty(profileErrors) ||
                    !csvImport.canUpload ||
                    csvImport.isUploading
                  }
                >
                  {t('CSVImport.upload')}
                </GenericButton>
              </div>
              <GenericButton
                size='big'
                primacy='secondary'
                onClick={csvImport.onCancel}
              >
                {t('CSVImport.cancel')}
              </GenericButton>
            </div>
          </Modal.Actions>
        )}
        {csvImport.isUploadSuccess && (
          <Modal.Actions>
            <GenericButton size='big' onClick={csvImport.onCancel}>
              {t('CSVImport.close')}
            </GenericButton>
          </Modal.Actions>
        )}
      </GenericModal>
      <NewCandidateModal
        jobId={jobId}
        onClose={() => {
          newCandidateModal.handleClose();
          onClose();
        }}
        open={newCandidateModal.open}
      />
    </AddProfileToSequenceContextProvider>
  );
};

function NewEnumOptionsMessage({
  newEnumOptions,
}: {
  newEnumOptions: DetectedNewEnumOptions;
}) {
  const { t } = useTranslation();
  return (
    <>
      {newEnumOptions.map(({ customField, newEnumOptions: options }) => (
        <Message key={customField.id} className='results'>
          {t('CSVImport.newEnumOptions', {
            fieldName: getTranslatedText(customField.title),
            options: options.map(({ title }) => title).join(', '),
          })}
        </Message>
      ))}
    </>
  );
}

function CSVImportErrorsList(props: { errors: ProfileRowError[] | null }) {
  const { errors } = props;
  const { t } = useTranslation();
  if (!errors?.length) return null;
  const errorsWithLines: any[] = [];
  _.each(errors, (error) => {
    const matchingErrorMapItem = _.filter(
      errorsWithLines,
      (errorsWithLinesItem) =>
        _.isEqual(
          _.sortBy(errorsWithLinesItem.reasons),
          _.sortBy(error.reasons),
        ),
    )[0];
    if (_.isEmpty(matchingErrorMapItem)) {
      errorsWithLines.push({
        reasons: error.reasons,
        lines: [error.row + 1],
      });
    } else {
      matchingErrorMapItem.lines.push(error.row + 1);
    }
  });
  return (
    <>
      {_.map(errorsWithLines, ({ lines, reasons }, rowIndex) => (
        <div className='error-row' key={rowIndex}>
          <div className='error-lines'>
            {t('CSVImport.lines', {
              lines:
                _.size(lines) > 1
                  ? `${lines.slice(0, lines.length - 1).join(', ')} ${t(
                      'common.AND',
                    )} ${_.last(lines)}`
                  : lines[0],
            })}
          </div>
          <div className='error-reason'>
            {_.map(reasons, ({ text }, reasonIndex) => (
              <div key={reasonIndex}>{t(`CSVImport.errors.${text}`)}</div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

export default ImportCandidatesCSVModal;
