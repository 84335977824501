import gql from 'graphql-tag';
import TranslatableItem from './TranslatableItem';

export default gql`
  fragment Criteria on Criteria {
    jobPositions {
      required {
        ...TranslatableItem
      }
      important {
        ...TranslatableItem
      }
      bonus {
        ...TranslatableItem
      }
    }
    locations {
      ...TranslatableItem
    }
    locationsAndRemote {
      target {
        remoteWish
        location {
          id
        }
      }
      okWith {
        remoteWish
        location {
          id
        }
      }
    }
    remote {
      ...TranslatableItem
    }
    freelance {
      ...TranslatableItem
    }
    education {
      required {
        ...TranslatableItem
      }
      important {
        ...TranslatableItem
      }
      bonus {
        ...TranslatableItem
      }
    }
    experience {
      years {
        min
        max
      }
    }
    salary {
      min
      max
      currency
    }
    skills {
      required {
        ...TranslatableItem
      }
      important {
        ...TranslatableItem
      }
      bonus {
        ...TranslatableItem
      }
    }
    languages {
      required {
        ...TranslatableItem
      }
      important {
        ...TranslatableItem
      }
      bonus {
        ...TranslatableItem
      }
    }
    collaboratorsInCharge {
      email
      firstname
      lastname
      position
    }
    blacklistedCompanies
    relevantCompanies
    onlineLinks
    extraKeyWords
    moreInfo
    exampleProfiles
    filteringParameter {
      minExperience
      maxExperience
      maxSalary
      locationAndRemote
    }
  }
  ${TranslatableItem}
`;
