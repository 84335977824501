import React, { FC, useContext } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import useClientId from '@/hooks/router/useClientId';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import Cross from '@/components/Common/Icons/Cross';
import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownPanel from '@/components/Common/DropdownPanel';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';

import classNames from 'classnames';
import ProfilesViewSettingsContext from '../../ProfilesViewSettingsContext';
import { sectionDefinitions } from '../ProfilesViewFilterDropdown/helpers/filters';

import styles from './ProfilesViewFilterPills.module.less';

const ProfilesViewFilterPills: FC = () => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { data } = useAtsFilterOptions(clientId);
  const [state, dispatch] = useContext(ProfilesViewSettingsContext);

  return (
    <div>
      {_.map(
        sectionDefinitions,
        (
          {
            isAvailable,
            isAlwaysShown,
            isActive,
            i18nKey,
            clear,
            getPreview,
            serialize,
            Component,
          },
          key,
        ) => {
          if (!isAvailable({ client: data?.client })) {
            return null;
          } 
          if (!isActive(state) && !isAlwaysShown?.({ client: data?.client })) {
              return null;
          }

          const preview = getPreview(state);
          const value = serialize(state);

          return (
            <div style={{ display: 'inline-block', marginRight: 5, marginBottom: 5 }}>
              <GenericDropdown
                key={key}
                position='right'
                trigger={
                  <DropdownControlsContext.Consumer>
                    {({ toggleDropdown }) => (
                      <span  className={classNames(styles.pill, isActive(state) && styles.activePill)} onClick={toggleDropdown}>
                        {isActive(state) ? (
                          <button
                            type='button'
                            className={styles.close}
                            onClick={(e) => {
                              e.stopPropagation();
                              clear(dispatch);
                            }}
                          >
                            <Cross />
                          </button>
                        ) : ''}
                        {t(i18nKey)}
                        {preview && (
                          <span className={styles.preview}>{preview}</span>
                        )}
                        <ArrowDown className={styles.arrow} />
                      </span>
                    )}
                  </DropdownControlsContext.Consumer>
                }
              >
                <DropdownControlsContext.Consumer>
                  {({ closeDropdown }) => (
                    <DropdownPanel className={styles.dropdownPanel}>
                      <Component onSubmit={closeDropdown} key={value} />
                    </DropdownPanel>
                  )}
                </DropdownControlsContext.Consumer>
              </GenericDropdown>
            </div>
          );
        },
      )}
    </div>
  );
};

export default ProfilesViewFilterPills;
