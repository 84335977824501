import { getSmartrecruitersCandidateURL } from './smartrecruitersURL';
import { getGreenhouseCandidateURL } from './greenhouseURL';
import { getLeverCandidateURL } from './leverURL';
import { getTeamtailorCandidateURL } from './teamtailorURL';
import { getWelcomekitCandidateURL } from './welcomekitURL';
import getWorkableCandidateURL from './workableURL';
import { getRecruiteeCandidateURL } from './recruiteeURL';
import { getRecruitcrmCandidateURL } from './recruitcrmURL';
import { getBoondmanagerCandidateURL } from './boondmanagerURL';
import getAshbyCandidateURL from './ashbyURL';
import { AtsData } from './SourceData.type';
import { ADVENTURE, RECRUITCRM, SUCCESSFACTORS } from '.';
import { getAdventureCandidateURL } from './adventureAtsURL';
import { getSuccessfactorsCandidateURL } from './successfactorsURL';

export const getCandidateAtsURL = ({
  atsDataItem,
  accountId,
}: {
  atsDataItem: AtsData;
  accountId?: string;
}): string | null => {
  const type = atsDataItem?.type === 'generic' && atsDataItem.asAtsType ? (
    atsDataItem.asAtsType
  ) : atsDataItem?.type;
  const candidateId = atsDataItem?.sourceCandidateId;
  if (!candidateId) {
    return null;
  }

  if (type === ADVENTURE) {
    return getAdventureCandidateURL(candidateId);
  }

  if (type === 'ashby') {
    return getAshbyCandidateURL(candidateId);
  }
  if (type === 'smartrecruiters') {
    return getSmartrecruitersCandidateURL(candidateId);
  }
  if (type === 'greenhouse') {
    return getGreenhouseCandidateURL(candidateId);
  }
  if (type === 'lever') {
    return getLeverCandidateURL(candidateId);
  }
  if (type === 'teamtailor') {
    return getTeamtailorCandidateURL(candidateId, accountId);
  }
  if (type === 'welcomekit') {
    return getWelcomekitCandidateURL({
      candidateId,
      accountId,
      applications: atsDataItem?.applications,
    });
  }
  if (type === 'workable') {
    return getWorkableCandidateURL(atsDataItem);
  }
  if (type === 'recruitee') {
    return getRecruiteeCandidateURL(candidateId);
  }
  if (type === RECRUITCRM) {
    return getRecruitcrmCandidateURL(candidateId);
  }
  if (type === 'boondmanager') {
    return getBoondmanagerCandidateURL(candidateId);
  }
  if (type === 'boondmanager_sales') {
    return getBoondmanagerCandidateURL(candidateId,'sales');
  }
  if (type === SUCCESSFACTORS) {
    return getSuccessfactorsCandidateURL(candidateId);
  }

  return null;
};

export default getCandidateAtsURL;
