import { useRef, useState, useEffect, useCallback } from 'react';

import useClientId from '@/hooks/router/useClientId';
import { FreeTrialConfiguration } from '@/types/client';

import useFreeTrialConfiguration from './useFreeTrialConfiguration';

const DEFAULT_MAX_REFETCH_ATTEMPTS_NUMBER = 120;
const DEFAULT_REFETCH_DELAY_IN_MS = 5000;

interface UsePollFreeTrialConfigurationOptions {
  maxRefetchAttemptsNumber?: number;
  refetchDelayInMs?: number;
}

interface UsePollFreeTrialConfigurationResult {
  freeTrialConfiguration: FreeTrialConfiguration;
  startPolling: () => void;
}

function usePollFreeTrialConfiguration(
  options: UsePollFreeTrialConfigurationOptions = {},
): UsePollFreeTrialConfigurationResult {
  const clientId = useClientId();
  const {
    maxRefetchAttemptsNumber = DEFAULT_MAX_REFETCH_ATTEMPTS_NUMBER,
    refetchDelayInMs = DEFAULT_REFETCH_DELAY_IN_MS,
  } = options;
  const [remainingRefetches, setRemainingRefetches] = useState(0);
  const {
    freeTrialConfiguration,
    refetch,
    loading,
  } = useFreeTrialConfiguration({
    clientId,
  });

  const refetchTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      if (refetchTimeout.current) {
        clearTimeout(refetchTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (remainingRefetches === 0) {
      return;
    }
    refetchTimeout.current = setTimeout(() => {
      refetch();
      setRemainingRefetches((current) => current - 1);
    }, refetchDelayInMs);
  }, [refetch, refetchDelayInMs, remainingRefetches, loading]);

  useEffect(() => {
    if (freeTrialConfiguration.upgraded) {
      setRemainingRefetches(0);
    }
  }, [freeTrialConfiguration]);

  const startPolling = useCallback(
    () => setRemainingRefetches(maxRefetchAttemptsNumber),
    [maxRefetchAttemptsNumber],
  );

  return {
    freeTrialConfiguration,
    startPolling,
  };
}

export default usePollFreeTrialConfiguration;
