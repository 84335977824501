import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';
import JobPosting from './JobPosting';

export default gql`
  fragment CareerPageAndJobPostings on CareerPage {
    id
    title {
      ...TranslatableText
    }
    enabled
    url
    organizationWebsite
    organizationLogoURL
    description {
      type
      sections {
        type
        title {
          ...TranslatableText
        }
        content {
          ...TranslatableText
        }
        url
        file {
          url
        }
      }
    }
    theme {
      background
      font
      jobs {
        background
        font
        border {
          color
          radius
        }
      }
    }
    layout {
      displayType
      showFilter
    }
    jobPostings {
      ...JobPosting
    }
  }
  ${JobPosting}
  ${TranslatableText}
`;
