import _ from 'underscore';

import { ATS_FILTERS_KEY_BY_TYPE, ATStype } from '@/common/reveal';
import {
  AvailabilityFilter,
  isAbsoluteMax,
  isRelativeMax,
} from '@/types/search';

import {
  ProfilesViewSettings,
  AvailabilityDateFilter,
  ATSTagFilter,
  MedicalExaminationFilter,
  ConstructionCardFilter,
} from '../../../../ProfilesViewSettingsContext/helpers';

export function parseCriteria({
  free,
}: {
  free?: string;
}): ProfilesViewSettings {
  if (!free) {
    return { atsTagFilters: {} };
  }

  const freeObject = JSON.parse(free);

  const atsTagFilters = {} as ProfilesViewSettings['atsTagFilters'];

  _.each(freeObject, (value, key) => {
    const ats = getAtsTypeFromFilterKey(key);
    if (!ats) {
      return;
    }

    const tagTypeOccurences = {} as Record<string, string[]>;
    _.each(value.tagIds || [], (tagId) => {
      const tagType = tagId.split('__')[0] as string;
      if (!tagTypeOccurences[tagType]) {
        tagTypeOccurences[tagType] = [];
      }
      tagTypeOccurences[tagType].push(tagId);
    });

    const tagFilterMap = {} as Record<string, ATSTagFilter>;
    _.each(tagTypeOccurences, (tagIds, tagType) => {
      if (tagIds.length === 1) {
        tagFilterMap[tagType] = {
          operator: 'or',
          tagIds: tagIds[0].split(';'),
        };
      } else {
        tagFilterMap[tagType] = {
          operator: 'and',
          tagIds,
        };
      }
    });

    atsTagFilters[ats] = tagFilterMap;
  });

  const result = { atsTagFilters } as ProfilesViewSettings;

  if (freeObject.criteria?.availabilityFilters) {
    result.availabilityDate = getAvailabilityDateFromFilter(
      freeObject.criteria.availabilityFilters,
    );
    result.medicalExamination = getMedicalExaminationFilter(
      freeObject.criteria.availabilityFilters,
    );
    result.constructionCard = getConstructionCardFilter(
      freeObject.criteria.availabilityFilters,
    );
  }

  if (freeObject.shouldExcludeExpiredCertifications) {
    result.excludeExpiredCertifications = true;
  }

  return result;
}

function getAvailabilityDateFromFilter(
  availabilityFilter: AvailabilityFilter,
): AvailabilityDateFilter | undefined {
  if (!availabilityFilter.nextAvailabilityDateFilter) {
    return undefined;
  }
  const { nextAvailabilityDateFilter } = availabilityFilter;
  if (isRelativeMax(nextAvailabilityDateFilter)) {
    return {
      type: 'relative',
      days: nextAvailabilityDateFilter.maxDelayInDays,
    };
  }
  if (isAbsoluteMax(nextAvailabilityDateFilter)) {
    return {
      type: 'absolute',
      date: nextAvailabilityDateFilter.maxDate,
    };
  }
  // Default case: absoluteMin
  return undefined;
}

function getMedicalExaminationFilter(
  availabilityFilter: AvailabilityFilter,
): MedicalExaminationFilter | undefined {
  if (!availabilityFilter.medicalExaminationExpirationDateFilter) {
    return undefined;
  }
  const { medicalExaminationExpirationDateFilter } = availabilityFilter;
  if (isRelativeMax(medicalExaminationExpirationDateFilter)) {
    return undefined;
  }
  if (isAbsoluteMax(medicalExaminationExpirationDateFilter)) {
    return 'invalid';
  }
  // Default case: absoluteMin
  return 'valid';
}

function getConstructionCardFilter(
  availabilityFilter: AvailabilityFilter,
): ConstructionCardFilter | undefined {
  if (!availabilityFilter.constructionCardExpirationDateFilter) {
    return undefined;
  }
  const { constructionCardExpirationDateFilter } = availabilityFilter;
  if (isRelativeMax(constructionCardExpirationDateFilter)) {
    return undefined;
  }
  if (isAbsoluteMax(constructionCardExpirationDateFilter)) {
    return 'invalid';
  }
  // Default case: absoluteMin
  return 'valid';
}

function getAtsTypeFromFilterKey(key: string): ATStype | null {
  let result: ATStype | null = null;
  _.each(ATS_FILTERS_KEY_BY_TYPE, (filterKey, ats) => {
    if (result) {
      return;
    }
    if (key === filterKey) {
      result = ats as ATStype;
    }
  });
  return result;
}
