import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import gql from 'graphql-tag';
import _ from 'underscore';
import * as Sentry from '@sentry/browser';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Loader, Table, Dropdown, Modal, Dimmer } from 'semantic-ui-react';
import classNames from 'classnames';

import useClient from '@/graphql/hooks/clients/useClient';
import useQueryParams from '@/hooks/router/useQueryParams';
import useSearchPoolJobs from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobs';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useSearchPoolJobsStats from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobsStats';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { useClientMissionCustomFields } from '@/graphql/hooks/clients/useClientMissionCustomFields';
import { getTranslatedText } from '@/common';
import { newJobModalParam } from '@/revealComponents/OnboardingModal/constants';
import {
  ARCHIVE_SEARCH_POOL_JOB,
  UNARCHIVE_SEARCH_POOL_JOB,
  UPDATE_SEARCH_POOL_JOB,
} from '@/graphql/searchPoolJob';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import OnboardingModal from '@/revealComponents/OnboardingModal';
import EmptyState from '@/revealComponents/EmptyState/EmptyState';
import NewRevealJobModal from '@/components/NewRevealJobModal';
import UserFilter from '@/components/Filters/UserFilter';
import ContactFlow from '@/graphql/fragments/ContactFlow';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import useCreateSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useCreateSearchPoolJob';
import useUserTableViewPreferences, {
  sortColumns,
} from '@/graphql/hooks/users/useUserDisplayPreferences';
import GenericButton from '@/components/Common/GenericButton';
import GenericTextInput from '@/components/Common/GenericTextInput';
import Search from '@/components/Reveal/Icons/Search';
import { caseInsensitiveIncludes } from '@/common/utils/string';
import Plus from '@/components/Reveal/Icons/Plus';
import InfoTooltip from '@/components/Common/InfoTooltip';
import usePipelineStatisticsDetails from '@/graphql/hooks/clients/usePipelineStatisticsDetails';
import LabeledCheckbox from '@/components/Common/LabeledCheckbox/LabeledCheckbox';
import { useUserAllowedDepartments } from '@/graphql/hooks/users/useUserAllowedDepartments';
import { getFullname } from '@/common/helpers/person';
import { getValueFromCustomField } from '@/components/CustomFields/InlineCustomField';
import useClientId from '@/hooks/router/useClientId';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import usePipelineSubSteps from '@/graphql/hooks/clients/usePipelineSubSteps';

import { segments } from '../RevealAnalytics/components/PipelineStatisticsByCategory/helpers/segments';
import StatsProfileAndMissionTable from '../RevealAnalytics/components/StatsProfileAndMissionTable';
import { getCurrentCountsBySegment } from '../RevealAnalytics/components/PipelineStatisticsByCategory/helpers/count';
import {
  usePipelineCounts,
  usePipelineDetails,
} from '../RevealAnalytics/components/PipelineStatisticsByCategory/hooks';
import PipelineFunnelSummary from '../RevealAnalytics/components/PipelineStatisticsByCategory/PipelineFunnelSummary';
import DetailsModalHeader from '../RevealAnalytics/components/DetailsModalHeader';
import EditMissionModal from './JobView/EditMissionModal';
import { ProjectRow } from './components/Projects/Row/ProjectRow/ProjectRow';
import {
  COLUMN_NAME_TRANSLATION_PATH_FROM_ID,
  ProjectsHeader,
} from './components/Projects/Row/ProjectHeader';
import { useMissionATSMetadataColumns } from './ATSMetadata/columns';

import './JobsView.css';

const getKeyCounts = (key, params, jobId) => {
  const detailedCounts = params.countsByCategory?.[jobId] || defaultJobStats;
  const counts = getCurrentCountsBySegment(detailedCounts);
  return parseInt(counts?.[key], 10);
};

const COLUMN_SORT_MAP = {
  title: (jobs, order) => {
    const sortedJobs = _.sortBy(jobs, (job) => job.data.title?.toLowerCase());
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  company: (jobs, order) => {
    const sortedJobs = _.sortBy(
      jobs,
      (job) => job.targets?.companies?.[0]?.name,
    );
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  owner: (jobs, order) => {
    const sortedJobs = _.sortBy(jobs, (job) =>
      getFullname(job?.owner).toLowerCase(),
    );
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  // MAX_ALPHA_VALUE_FROM_ORDER and MAX_NUMERIC_VALUE_FROM_ORDER are used to make sure the jobs with no value for the specified column will be at the bottom at all time
  department: (jobs, order, params) => {
    const sortedJobs = _.sortBy(
      jobs,
      (job) =>
        _.findWhere(params.departments, {
          id: job.foldering?.department?.id,
        })?.title?.toLowerCase() || '',
    );
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  section: (jobs, order, params) => {
    const sortedJobs = _.sortBy(
      jobs,
      (job) =>
        _.findWhere(params.departments, {
          id: job.foldering?.section?.id,
        })?.title?.toLowerCase() || '',
    );
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  subsection: (jobs, order, params) => {
    const sortedJobs = _.sortBy(
      jobs,
      (job) =>
        _.findWhere(params.departments, {
          id: job.foldering?.subsection?.id,
        })?.title?.toLowerCase() || '',
    );
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  contacts: (jobs, order, params) => {
    const sortedJobs = _.sortBy(
      jobs,
      (job) => parseInt(job.profilesCount, 10) || 0,
    );
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  recommendations: (jobs, order, params) => {
    const sortedJobs = _.sortBy(jobs, (job) => job.stats?.recommendations || 0);
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  tasks: (jobs, order, params) => {
    const sortedJobs = _.sortBy(
      jobs,
      (job) => params.statsByMission[job.id]?.tasks || 0,
    );
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  pending: (jobs, order, params) => {
    const sortedJobs = _.sortBy(
      jobs,
      (job) => params.statsByMission[job.id]?.notContacted || 0,
    );
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  contacted: (jobs, order, params) => {
    const sortedJobs = _.sortBy(jobs, (job) => {
      const count = getKeyCounts('in-progress', params, job.id);
      return count || 0;
    });
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  replied: (jobs, order, params) => {
    const sortedJobs = _.sortBy(jobs, (job) => {
      const count = getKeyCounts('replied', params, job.id);
      return count || 0;
    });
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  interested: (jobs, order, params) => {
    const sortedJobs = _.sortBy(jobs, (job) => {
      const count = getKeyCounts('interested', params, job.id);
      return count || 0;
    });
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  hired: (jobs, order, params) => {
    const sortedJobs = _.sortBy(jobs, (job) => {
      const count = getKeyCounts('hired', params, job.id);
      return count || 0;
    });
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  archived: (jobs, order, params) => {
    const sortedJobs = _.sortBy(jobs, (job) => {
      const count = getKeyCounts('archived', params, job.id);
      return count || 0;
    });
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  customField: (jobs, order, params) => {
    // this has not been tested with every custom field type
    const sortedJobs = _.sortBy(jobs, (job) => {
      const missionCustomField = _.findWhere(job?.customFields, {
        clientCustomFieldId: params.customFieldId,
      });
      const value = getValueFromCustomField({
        customField: missionCustomField,
      });
      if (typeof value === 'number') {
        return value || 0;
      }
      return value === '-' ? '' : value || '';
    });
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  substep: (jobs, order, params) => {
    const sortedJobs = _.sortBy(jobs, (job) => {
      const count =
        _.findWhere(params.statsByMission[job.id]?.interestedSubSteps, {
          subStepId: params.substepId,
        })?.count || 0;
      return count || 0;
    });
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  'temps-in-job': (jobs, order, params) => {
    const sortedJobs = _.sortBy(jobs, (job) => {
      const count = Number.parseInt(
        _.findWhere(params.statsByMission[job.id]?.atsMetadata, {
          key: 'tempsInJob',
        })?.value || 0,
        10,
      );
      return count || 0;
    });
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
  'percent-occupied': (jobs, order, params) => {
    const sortedJobs = _.sortBy(jobs, (job) => {
      const tempsInJob = Number.parseInt(
        _.findWhere(params.statsByMission[job.id]?.atsMetadata, {
          key: 'tempsInJob',
        })?.value || 0,
        10,
      );

      if (!job.profilesCount) {
        return 0;
      }

      const percent = tempsInJob / job.profilesCount;
      return percent;
    });
    return order === 'ascending' ? sortedJobs : sortedJobs.reverse();
  },
};

export const DEFAULT_COLUMNS_DISPLAY_VALUES = [
  {
    id: 'jobs-view-contacts',
    hidden: false,
    targetOrderIndex: 0,
  },
  {
    id: 'jobs-view-recommendations',
    hidden: true,
    targetOrderIndex: 1,
  },
  {
    id: 'jobs-view-task',
    hidden: false,
    targetOrderIndex: 2,
  },
  {
    id: 'jobs-view-pending',
    hidden: false,
    targetOrderIndex: 3,
  },
  {
    id: 'jobs-view-contacted',
    hidden: true,
    targetOrderIndex: 4,
  },
  {
    id: 'jobs-view-replied',
    hidden: true,
    targetOrderIndex: 5,
  },
  {
    id: 'jobs-view-interested',
    hidden: false,
    targetOrderIndex: 6,
  },
  {
    id: 'jobs-view-hired',
    hidden: true,
    targetOrderIndex: 7,
  },
  {
    id: 'jobs-view-archived',
    hidden: true,
    targetOrderIndex: 8,
  },
  {
    id: 'jobs-view-owner-and-date',
    hidden: false,
    targetOrderIndex: 9,
  },
];

const useMinimalClientSequences = (clientId) => {
  return useQuery(
    gql`
      query getMinimalClientSequences($clientId: ID!) {
        client(id: $clientId) {
          id
          sequences {
            id
            title
            contactFlow {
              ...ContactFlow
            }
          }
        }
      }

      ${ContactFlow}
    `,
    {
      variables: {
        clientId,
      },
      fetchPolicy: 'network-only',
    },
  );
};

const defaultJobStats = {
  nonArchived: {
    pending: 0,
    'in-progress': 0,
    replied: 0,
    interested: 0,
    hired: 0,
  },
  archived: {
    pending: 0,
    'in-progress': 0,
    replied: 0,
    interested: 0,
    hired: 0,
  },
};

const JobsView = ({ revealProject, searchPoolId, user }) => {
  const clientId = useClientId();
  const { t } = useTranslation();
  const { onShowNotification } = useNotificationSystem();
  const { data: clientData } = useClient(clientId);
  const departments = clientData?.client?.departments;
  const atsId = useClientCurrentAtsId();
  const missionCategoryType = useContext(MissionCategoryContext);
  const { permissions } = useClientPermissions(clientId);
  const { salesProjects, canHandleCompanyContacts } = permissions || {};

  const { pipelineSubSteps } = usePipelineSubSteps();
  const subStepsDefinition = useMemo(
    () =>
      _.find(
        pipelineSubSteps,
        ({ missionCategory, stepId }) =>
          missionCategory.type === missionCategoryType &&
          stepId === 'interested',
      ),
    [pipelineSubSteps, missionCategoryType],
  );
  const missionInterestedSubSteps = useMemo(
    () => subStepsDefinition?.subSteps || [],
    [subStepsDefinition],
  );

  const [
    createNewJobMutation,
    { loading: creatingNewJob, data: createJobData },
  ] = useCreateSearchPoolJob();

  const [editMission, { loading: updatingJob }] = useMutation(
    UPDATE_SEARCH_POOL_JOB,
  );

  const flattenDepartments = _.flatten(
    _.map(departments, (department) => {
      return [
        {
          ...department,
          type: 'department',
        },
        ..._.flatten(
          _.map(department?.sections || [], (section) => {
            return [
              {
                ...section,
                type: 'section',
              },
              ..._.map(section?.subsections || [], (subsection) => ({
                ...subsection,
                type: 'subsection',
              })),
            ];
          }),
        ),
      ];
    }),
  );

  const { isAdmin, email: userEmail } = user || {};
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const configurationParams = useMergedConfigurationParams();

  let linkedAts = revealProject?.connectors?.[0]?.type ?? null;
  linkedAts = linkedAts === 'custom' ? null : linkedAts;

  const openNewJobModalDefault =
    search.includes(newJobModalParam) ||
    searchParams.get('newJobDefaultName') !== null;

  const { putParams, ...params } = useQueryParams();

  const setNewJobModalOpen = React.useCallback(
    (enable = true) => {
      const newParams = { ...params };
      if (!enable) {
        delete newParams.newJobModal;
        delete newParams.newJobDefaultName;
        return putParams(newParams, { method: 'replace' });
      }

      newParams.newJobModal = true;
      return putParams(newParams, { method: 'replace' });
    },
    [params, putParams],
  );

  const [displayArchived, setDisplayArchived] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const [archiveMission] = useMutation(ARCHIVE_SEARCH_POOL_JOB);
  const [unArchiveMission] = useMutation(UNARCHIVE_SEARCH_POOL_JOB);
  const [actionJob, setActionJob] = useState(null);

  const [shouldReloadIfEmptyJobs, setReloadIfEmptyJobs] = useState(true);

  const [nameFilter, setNameFilter] = useState('');
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();

  const displayTypeSwitchOptions = [
    {
      key: 'ever',
      text: t('reveal.missions.pipeline.displayType.ever'),
      value: 'ever',
    },
    {
      key: 'current',
      text: t('reveal.missions.pipeline.displayType.current'),
      value: 'current',
    },
  ];

  const { columns: atsMetadataColumns } = useMissionATSMetadataColumns({
    job: null,
    stats: null,
  });

  const {
    userTableViewPreferences,
    loading: loadingDisplayPrefs,
    updateUserTableViewPreferences,
    updateExtraParameter,
    extraParameters,
  } = useUserTableViewPreferences({
    tableId:
      missionCategoryType === 'recruiting'
        ? 'jobs-view'
        : `jobs-view-${missionCategoryType}`,
    defaultValues: {
      tableId:
        missionCategoryType === 'recruiting'
          ? 'jobs-view'
          : `jobs-view-${missionCategoryType}`,
      filteringOptions: {
        filters: [
          {
            key: 'ownerEmail',
            filter: {
              eq: isAdmin ? 'all' : userEmail,
            },
          },
          {
            key: 'departmentId',
            filter: {
              eq: 'all',
            },
          },
          {
            key: 'sectionId',
            filter: {
              eq: 'all',
            },
          },
          {
            key: 'subsectionId',
            filter: {
              eq: 'all',
            },
          },
        ],
      },
      columnsDisplay: {
        columns: DEFAULT_COLUMNS_DISPLAY_VALUES,
      },
    },
  });

  const countArchivedInStats = extraParameters?.countArchivedInStats || false;

  const displayType = extraParameters?.displayType || 'ever';

  const { missionCustomFields } = useClientMissionCustomFields(clientId);

  const displayableColumnGroups = useMemo(() => {
    const customFieldsColumns = _.map(missionCustomFields, (customField) => {
      return {
        id: customField.id,
        name: getTranslatedText(customField.title),
        hidden: true,
      };
    });

    const groups = [
      {
        groupId: 'default',
        title: '',
        columns: [
          ...DEFAULT_COLUMNS_DISPLAY_VALUES,
          ...(canHandleCompanyContacts && missionCategoryType === 'recruiting'
            ? [
                {
                  id: 'jobs-view-company',
                  hidden: true,
                  targetOrderIndex: 10,
                },
              ]
            : []),
        ],
      },
      {
        groupId: 'substeps',
        title: t('reveal.missions.columnSelectorGroups.seeSubsteps'),
        isExpandable: true,
        columns: _.map(missionInterestedSubSteps, (substep) => ({
          id: substep.id,
          name: getTranslatedText(substep.title),
          hidden: true,
        })),
      },
      ...(flattenDepartments.length > 0
        ? [
            {
              groupId: 'departments',
              title: t('reveal.missions.columnSelectorGroups.departments'),
              columns: [
                {
                  id: 'department',
                  name: t('reveal.missions.columnSelectors.departments'),
                  hidden: true,
                },
                {
                  id: 'section',
                  name: t('reveal.missions.columnSelectors.sections'),
                  hidden: true,
                },
                {
                  id: 'subsection',
                  name: t('reveal.missions.columnSelectors.subsections'),
                  hidden: true,
                },
              ],
            },
          ]
        : []),
      {
        groupId: 'project-custom-fields',
        title: t('reveal.missions.columnSelectorGroups.customFields'),
        columns: customFieldsColumns,
      },
    ];

    if (atsMetadataColumns) {
      groups.push({
        groupId: 'project-ats-metadata',
        title: t('reveal.missions.columnSelectorGroups.atsMetadata'),
        columns: atsMetadataColumns,
      });
    }

    return groups;
  }, [
    missionCategoryType,
    canHandleCompanyContacts,
    atsMetadataColumns,
    missionCustomFields,
    t,
    flattenDepartments,
    missionInterestedSubSteps,
  ]);

  const currentDisplayColumns = useMemo(() => {
    const allColumns = _.map(displayableColumnGroups, (group) =>
      _.map(group.columns, (column) => ({
        ...column,
        groupId: group.groupId,
      })),
    ).flat();

    const columns = _.reduce(
      allColumns,
      (aggregator, currentDefaultColumn) => {
        if (
          missionCategoryType !== 'recruiting' &&
          currentDefaultColumn.id === 'jobs-view-recommendations'
        ) {
          return aggregator;
        }
        const columnInPreferences = _.find(
          userTableViewPreferences?.columnsDisplay?.columns,
          (column) => column.id === currentDefaultColumn.id,
        );

        const isHiddenInPreferences = columnInPreferences?.hidden;
        const targetOrderInPreferences =
          columnInPreferences?.targetOrderIndex ?? undefined;
        const availableInPreferences = columnInPreferences?.available ?? true;
        const newAggregator = [
          ...aggregator,
          {
            ...currentDefaultColumn,
            name:
              currentDefaultColumn.name ||
              t(COLUMN_NAME_TRANSLATION_PATH_FROM_ID[currentDefaultColumn.id]),
            // use ?? because isHiddenInPreferences could be false
            hidden: isHiddenInPreferences ?? currentDefaultColumn.hidden,
            groupId: currentDefaultColumn.groupId,
            targetOrderIndex: targetOrderInPreferences,
            available: availableInPreferences,
          },
        ];
        return newAggregator;
      },
      [],
    );

    return sortColumns(columns);
  }, [
    userTableViewPreferences,
    t,
    displayableColumnGroups,
    missionCategoryType,
  ]);

  const userFilterEmail = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'ownerEmail' },
  )?.filter.eq;

  const departmentFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'departmentId' },
  )?.filter.eq;

  const sectionFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'sectionId' },
  )?.filter.eq;

  const subsectionFilter = _.findWhere(
    userTableViewPreferences?.filteringOptions?.filters || [],
    { key: 'subsectionId' },
  )?.filter.eq;

  const customFieldFilters = useMemo(
    () =>
      _.filter(
        userTableViewPreferences?.filters,
        (preferencesFilter) =>
          !_.includes(
            ['ownerEmail', 'departmentId', 'sectionId', 'subsectionId'],
            preferencesFilter.key,
          ),
      ),
    [userTableViewPreferences],
  );

  const customFieldsFilters = _.filter(
    missionCustomFields,
    (customField) =>
      customField.isFilteringOption ||
      (customField.id === 'profil-vivier' &&
        configurationParams?.shouldShowProfilVivierFilterInProjectsView ===
          'true'),
  );

  const customFieldsFilterFromPreferences = _.filter(
    userTableViewPreferences?.filteringOptions?.filters,
    (filter) =>
      _.findWhere(missionCustomFields, { id: filter.key }) &&
      _.pluck(customFieldsFilters, 'id').includes(filter.key),
  );

  const customFieldsFiltersToApply = _.isEmpty(customFieldFilters)
    ? customFieldsFilterFromPreferences
    : customFieldFilters;

  const customFieldsFilter = _.map(
    customFieldsFiltersToApply,
    (customFieldFilter) => ({
      customFieldId: customFieldFilter.key,
      value:
        customFieldFilter.filter.eq === 'all'
          ? null
          : customFieldFilter.filter.eq,
    }),
  );

  const ownerEmail = userFilterEmail === 'all' ? null : userFilterEmail;
  const departmentId = departmentFilter === 'all' ? null : departmentFilter;
  const sectionId = sectionFilter === 'all' ? null : sectionFilter;
  const subsectionId = subsectionFilter === 'all' ? null : subsectionFilter;

  const filterSet = {
    ownerEmail,
    departmentId,
    sectionId,
    subsectionId,
    customFields: customFieldsFilter,
  };

  const shouldBlockIfNoFilter =
    configurationParams?.shouldBlockIfNoFilterInProjectsView === 'true';
  const hasNoFilter = !_.some(filterSet || [], (filter, key) =>
    key === 'customFields'
      ? _.some(filter, (val) => !!val?.value)
      : !_.isEmpty(filter),
  );

  const shouldBlockBecauseNoFilter = shouldBlockIfNoFilter && hasNoFilter;

  const { data: searchPoolJobsData, loading: searchPoolJobsLoading } =
    useSearchPoolJobs(
      searchPoolId,
      {
        ...filterSet,
        missionCategory: { type: missionCategoryType },
      },
      'network-only',
      {
        skip:
          loadingDisplayPrefs ||
          !configurationParams ||
          shouldBlockBecauseNoFilter,
      },
      {
        includeCustomFields: true,
      },
    );

  const jobsList = useMemo(() => {
    // TODO: In the future this could be integrated in the graphql request
    // TODO: but since for now we request the whole list this will be faster for the client
    return _.filter(searchPoolJobsData?.searchPool?.jobs, ({ hide }) => !hide);
  }, [searchPoolJobsData]);

  const activeJobs = useMemo(() => {
    return _.filter(jobsList || [], (job) => !job.isArchived);
  }, [jobsList]);

  const displayedActiveJobs = useMemo(
    () =>
      _.filter(activeJobs, ({ data }) =>
        caseInsensitiveIncludes(data?.title, nameFilter),
      ),
    [activeJobs, nameFilter],
  );

  const archivedJobs = useMemo(() => {
    return _.filter(jobsList || [], (job) => job.isArchived);
  }, [jobsList]);

  const displayedArchivedJobs = useMemo(
    () =>
      _.filter(archivedJobs, ({ data }) =>
        caseInsensitiveIncludes(data?.title, nameFilter),
      ),
    [archivedJobs, nameFilter],
  );

  const statsJobIds = useMemo(
    () => [
      ..._.pluck(activeJobs, 'id'), 
      ...(countArchivedInStats || displayArchived) ? _.pluck(archivedJobs, 'id') : [],
    ],
    [displayArchived, countArchivedInStats, activeJobs, archivedJobs],
  );

  const { data: missionsStats, loading: missionsStatsLoading } =
    useSearchPoolJobsStats(
      searchPoolId,
      {
        ...filterSet,
        in: statsJobIds,
        missionCategory: { type: missionCategoryType },
      },
      {
        skip:
          loadingDisplayPrefs ||
          !configurationParams ||
          _.isEmpty(statsJobIds) ||
          shouldBlockBecauseNoFilter,
      },
    );

  const statsByMission = useMemo(() => {
    if (!missionsStats || missionsStatsLoading) {
      return {};
    }
    return _.reduce(
      missionsStats.searchPool.jobs,
      (aggregator, currentJobStats) => ({
        ...aggregator,
        [currentJobStats.id]: currentJobStats.stats,
      }),
      {},
    );
  }, [missionsStats, missionsStatsLoading]);

  const { data: sequencesClient, loading: sequencesLoading } =
    useMinimalClientSequences(clientId);

  const jobsCountedForStats = useMemo(
    () =>
      countArchivedInStats
        ? [...displayedActiveJobs, ...displayedArchivedJobs]
        : displayedActiveJobs,
    [countArchivedInStats, displayedActiveJobs, displayedArchivedJobs],
  );

  const updateOwnerFilter = React.useCallback(
    (value) => {
      // info: Remove typename from preferences, otherwise it won't save
      const { __typename, ...displayPrefs } = userTableViewPreferences;
      updateUserTableViewPreferences({
        ...displayPrefs,
        filteringOptions: {
          filters: [
            ..._.filter(
              displayPrefs.filteringOptions?.filters || [],
              (filterOption) => filterOption.key !== 'ownerEmail',
            ),
            {
              key: 'ownerEmail',
              filter: {
                eq: value,
              },
            },
          ],
        },
      }).catch((error) => {
        // info: It does not matter much if this query fails since it has little business value.
        // info: The user will be able to set their filters/views again
        Sentry.captureException(error);
      });
    },
    [updateUserTableViewPreferences, userTableViewPreferences],
  );

  useEffect(() => {
    if (searchPoolJobsLoading || loadingDisplayPrefs) {
      return;
    }

    if (
      _.isEmpty(searchPoolJobsData?.searchPool.jobs || []) &&
      userEmail === userFilterEmail &&
      shouldReloadIfEmptyJobs
    ) {
      setReloadIfEmptyJobs(false);
      updateOwnerFilter('all');
    }
  }, [
    searchPoolJobsData,
    loadingDisplayPrefs,
    searchPoolJobsLoading,
    userEmail,
    userFilterEmail,
    shouldReloadIfEmptyJobs,
    updateOwnerFilter,
  ]);

  const onUpdateColumnsToDisplay = useCallback(
    (newColumnsDisplay) => {
      const { __typename, ...displayPrefs } = userTableViewPreferences;
      updateUserTableViewPreferences({
        ...displayPrefs,
        columnsDisplay: {
          columns: _.map(newColumnsDisplay, (column) => ({
            id: column.id,
            hidden: column.hidden,
            targetOrderIndex: column.targetOrderIndex ?? null,
            // TODO: this should be handled by the backend
            available: column.available ?? true,
          })),
        },
      }).catch((error) => {
        // info: It does not matter much if this query fails since it has little business value.
        // info: The user will be able to set their filters/views again
        Sentry.captureException(error);
      });
    },
    [updateUserTableViewPreferences, userTableViewPreferences],
  );

  const pipelineCountDataPoints = useMemo(() => {
    const result = [];

    _.each(
      statsByMission,
      (
        {
          notContacted,
          interested,
          replied,
          hired,
          contacted,
          archivedFromNotContacted,
          archivedFromInterested,
          archivedFromReplied,
          archivedFromHired,
          archivedFromContacted,
        },
        jobId,
      ) => {
        if (!_.some(jobsCountedForStats, { id: jobId })) {
          return;
        }
        result.push({
          categoryId: jobId,
          data: [
            {
              state: 'pending',
              archived: false,
              count: notContacted,
            },
            {
              state: 'in-progress',
              archived: false,
              count: contacted,
            },
            {
              state: 'replied',
              archived: false,
              count: replied,
            },
            {
              state: 'interested',
              archived: false,
              count: interested,
            },
            {
              state: 'hired',
              archived: false,
              count: hired,
            },
            {
              state: 'pending',
              archived: true,
              count: archivedFromNotContacted,
            },
            {
              state: 'in-progress',
              archived: true,
              count: archivedFromContacted,
            },
            {
              state: 'replied',
              archived: true,
              count: archivedFromReplied,
            },
            {
              state: 'interested',
              archived: true,
              count: archivedFromInterested,
            },
            {
              state: 'hired',
              archived: true,
              count: archivedFromHired,
            },
          ],
        });
      },
    );

    return result;
  }, [statsByMission, jobsCountedForStats]);

  const { globalConversionRates, summaryData, countsByCategory } =
    usePipelineCounts(pipelineCountDataPoints, displayType);

  const sortedActiveJobs = useMemo(() => {
    const sortByOptions =
      userTableViewPreferences?.sortingOptions?.sortBy?.reverse();
    let sortedJobs = [...displayedActiveJobs];
    _.each(sortByOptions, (sortBy) => {
      const columnSortParams = {
        departments: flattenDepartments,
        countsByCategory,
        statsByMission,
      };
      if (sortBy.key.indexOf('customField-') !== -1) {
        const fieldId = sortBy.key.split('customField-')[1];
        columnSortParams.customFieldId = fieldId;
        sortedJobs = COLUMN_SORT_MAP.customField
          ? COLUMN_SORT_MAP.customField(
              sortedJobs,
              sortBy.order,
              columnSortParams,
            )
          : sortedJobs;
      } else if (sortBy.key.indexOf('substep-') !== -1) {
        const fieldId = sortBy.key.split('customField-')[1];
        columnSortParams.customFieldId = fieldId;
        columnSortParams.substepId = fieldId;
        sortedJobs = COLUMN_SORT_MAP.substep
          ? COLUMN_SORT_MAP.substep(sortedJobs, sortBy.order, columnSortParams)
          : sortedJobs;
      } else {
        sortedJobs = COLUMN_SORT_MAP[sortBy.key]
          ? COLUMN_SORT_MAP[sortBy.key](
              sortedJobs,
              sortBy.order,
              columnSortParams,
            )
          : sortedJobs;
      }
    });
    return sortedJobs;
  }, [
    displayedActiveJobs,
    userTableViewPreferences,
    flattenDepartments,
    countsByCategory,
    statsByMission,
  ]);

  const [
    fetchDetails,
    { loading: detailsLoading, volumeByPipeline: detailsVolumeByPipeline },
  ] = usePipelineStatisticsDetails();

  const jobsCountedForStatsIds = _.map(jobsCountedForStats, ({ id }) => id);

  const { filter, applyFilter, profileDetails } = usePipelineDetails(
    detailsVolumeByPipeline,
    displayType,
    ({ stages, archivedStates }) =>
      fetchDetails({
        clientId,
        stagesFilter: !_.isEmpty(stages) ? { in: stages } : undefined,
        archivedStatusesFilter: !_.isEmpty(archivedStates)
          ? { in: archivedStates }
          : undefined,
        missionsFilter: { in: jobsCountedForStatsIds },
      }),
  );

  const updateCustomFieldPreference = useCallback(
    (key, value) => {
      const { __typename, ...displayPrefs } = userTableViewPreferences;
      updateUserTableViewPreferences({
        ...displayPrefs,
        filteringOptions: {
          filters: [
            ..._.filter(
              displayPrefs.filteringOptions?.filters,
              (filterOption) => filterOption.key !== key,
            ),
            {
              key,
              filter: {
                eq: value || 'all',
              },
            },
          ],
        },
      }).catch((error) => {
        // info: It does not matter much if this query fails since it has little business value.
        // info: The user will be able to set their filters/views again
        Sentry.captureException(error);
      });
    },
    [updateUserTableViewPreferences, userTableViewPreferences],
  );

  const handleCustomFieldFilterUpdate = useCallback(
    (customFieldId, value) => {
      updateCustomFieldPreference(customFieldId, value);
    },
    [updateCustomFieldPreference],
  );

  const globalClickListeners = useMemo(() => {
    if (!fetchDetails) {
      return undefined;
    }

    return _.reduce(
      segments,
      (listeners, segment) => ({
        ...listeners,
        [segment]: () => {
          setDetailsModalOpen(true);
          applyFilter({ segment });
        },
      }),
      {},
    );
  }, [fetchDetails, applyFilter]);

  const { loading: loadingAllowedDepartments, ...allowedDepartments } =
    useUserAllowedDepartments();

  const { departmentOptions, enabledSubsections } = useMemo(() => {
    if (loadingAllowedDepartments) {
      return {
        departmentOptions: [],
        enabledSubsections: [],
      };
    }

    const options = [
      {
        key: 'all',
        text: t('reveal.missions.allDepartments'),
        value: 'all',
        type: 'tier1',
      },
    ];

    const innerEnabledSubsections = [];

    _.each(_.sortBy(allowedDepartments.departments, 'text'), (department) => {
      options.push({
        key: department.id,
        text: department.title,
        value: department.id,
        type: 'tier1',
      });

      _.each(_.sortBy(department.sections, 'title'), (section) => {
        options.push({
          key: section.id,
          text: section.title,
          value: section.id,
          type: 'tier2',
        });

        _.each(_.sortBy(section.subsections, 'title'), (subsection) => {
          options.push({
            key: subsection.id,
            text: subsection.title,
            value: subsection.id,
            type: 'tier3',
          });
          innerEnabledSubsections.push(subsection);
        });
      });
    });

    return {
      departmentOptions: options,
      enabledSubsections: innerEnabledSubsections,
    };
  }, [allowedDepartments, t, loadingAllowedDepartments]);

  const [departmentSearch, setDepartmentSearch] = useState('');

  const filteredDepartmentOptions = useMemo(
    () =>
      _.filter(departmentOptions, ({ text }) =>
        caseInsensitiveIncludes(text, departmentSearch),
      ),
    [departmentOptions, departmentSearch],
  );

  const selectedDepartmentValue = useMemo(
    () =>
      _.find(
        [departmentFilter, sectionFilter, subsectionFilter],
        (f) => f !== 'all',
      ) || 'all',
    [departmentFilter, sectionFilter, subsectionFilter],
  );

  const selectedDepartmentOption = useMemo(
    () => _.findWhere(departmentOptions, { value: selectedDepartmentValue }),
    [departmentOptions, selectedDepartmentValue],
  );

  const onArchiveMission = async (job) => {
    try {
      const variables = { searchPoolId, input: { jobId: job?.id } };
      await archiveMission({ variables });
      publishSubscriptionEvent('onMissionsListUpdated', {});

      onShowNotification({
        message: t('reveal.missions.mission.modals.archiveSuccess'),
        level: 'success',
      });
    } catch (e) {
      console.error(e);

      onShowNotification({
        message: t('reveal.missions.mission.archiveError'),
        level: 'error',
      });
    }
  };

  const onUnarchiveMission = async (job) => {
    try {
      const variables = { searchPoolId, input: { jobId: job?.id } };
      await unArchiveMission({ variables });
      publishSubscriptionEvent('onMissionsListUpdated', {});

      onShowNotification({
        message: t('reveal.missions.mission.modals.unarchiveSuccess'),
        level: 'success',
      });
    } catch (e) {
      console.error(e);
      const error = e?.graphQLErrors?.[0]?.data?.details;
      Sentry.captureException(e);
      onShowNotification({
        message: error || t('reveal.missions.mission.restoreError'),
        level: 'error',
      });
    }
  };

  const customFieldsDropdownsWithOptions = _.map(
    customFieldsFilters || [],
    (customFieldFilter) => {
      const translatedCustomFieldOptions = _.map(
        customFieldFilter?.options || [],
        (option) => ({
          key: option.id,
          text: getTranslatedText(option.title),
          value: option.id,
        }),
      );
      return {
        id: customFieldFilter.id,
        options: [
          {
            key: 'all',
            text: `${t('reveal.missions.all')} - ${getTranslatedText(
              customFieldFilter.title,
            )}`,
            value: 'all',
          },
          ..._.sortBy(translatedCustomFieldOptions, 'text'),
        ],
      };
    },
  );

  const handleScopeSelect = (value) => {
    const type = _.findWhere(flattenDepartments, { id: value })?.type;
    const { __typename, ...displayPrefs } = userTableViewPreferences;
    updateUserTableViewPreferences({
      ...displayPrefs,
      filteringOptions: {
        filters: [
          ..._.filter(
            displayPrefs.filteringOptions?.filters,
            (filterOption) =>
              filterOption.key !== 'departmentId' &&
              filterOption.key !== 'sectionId' &&
              filterOption.key !== 'subsectionId',
          ),
          {
            key: 'departmentId',
            filter: {
              eq: type === 'department' ? value : 'all',
            },
          },
          {
            key: 'sectionId',
            filter: {
              eq: type === 'section' ? value : 'all',
            },
          },
          {
            key: 'subsectionId',
            filter: {
              eq: type === 'subsection' ? value : 'all',
            },
          },
        ],
      },
    }).catch((error) => {
      // info: It does not matter much if this query fails since it has little business value.
      // info: The user will be able to set their filters/views again
      Sentry.captureException(error);
    });
  };

  const jobsUsers = useMemo(() => {
    return _.compact(_.uniq(_.map(activeJobs, (job) => job?.owner?.email)));
  }, [activeJobs]);

  const pageTitleKey = useMemo(() => {
    if (!salesProjects) {
      return 'header.parameters.projects';
    }
    if (missionCategoryType === 'sales') {
      return 'header.parameters.salesProjects';
    }
    return 'header.parameters.hiringProjects';
  }, [salesProjects, missionCategoryType]);

  const newMissionKey = useMemo(() => {
    if (!salesProjects) {
      return 'reveal.missions.newMission';
    }
    if (missionCategoryType === 'sales') {
      return 'reveal.missions.newSalesMission';
    }
    return 'reveal.missions.newHiringMission';
  }, [salesProjects, missionCategoryType]);

  if (searchPoolJobsLoading || loadingDisplayPrefs) {
    return (
      <div className='jobs-view'>
        <Loader active />
      </div>
    );
  }

  return (
    <div className='jobs-view'>
      {configurationParams?.shouldHideProjectsViewTopHeader !== 'true' ? (
        <div className='jobs-header'>
          <h2 className='jobs-title'>{t(pageTitleKey)}</h2>
          {configurationParams?.shouldHideProjectsViewNewProjectButton !==
            'true' &&
            configurationParams?.shouldHideAllNewProjectButtons !== 'true' && (
              <div className='new-project-container'>
                <GenericButton
                  onClick={() => setNewJobModalOpen(true)}
                  size='big'
                >
                  <Plus />
                  {t(newMissionKey)}
                </GenericButton>
              </div>
            )}
        </div>
      ) : (
        <br />
      )}
      <div className='jobs-content'>
        <div className='jobs-section'>
          {configurationParams?.shouldHideProjectsViewStatsHeader !==
            'true' && (
            <>
              <div className='jobs-section-top'>
                <h3>
                  {t('reveal.reports.missions.pipelineStats')}
                  <InfoTooltip rich hoverable position='right center'>
                    <h1>
                      {t('reveal.reports.tooltips.missions.pipeline.title')}
                    </h1>
                    <h2>
                      {t(
                        'reveal.reports.tooltips.common.pipeline.passthroughRates',
                      )}
                    </h2>
                    <p>
                      {t(
                        'reveal.reports.tooltips.common.pipeline.passthroughRatesParagraph',
                      )}
                    </p>
                    <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
                    <p>
                      {t(`reveal.missions.pipeline.${displayType}.paragraph`)}
                    </p>
                    <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
                    <p>
                      {t(
                        'reveal.reports.tooltips.common.pipeline.manualMoveParagraph',
                      )}
                    </p>
                    <p>
                      {t(
                        'reveal.reports.tooltips.common.pipeline.deletedParagraph',
                      )}
                    </p>
                    <h2>
                      {t(
                        `reveal.missions.pipeline.${displayType}.alternativeTitle`,
                      )}
                    </h2>
                    <p>
                      {t(
                        `reveal.missions.pipeline.${displayType}.alternativeParagraph`,
                      )}
                    </p>
                  </InfoTooltip>
                </h3>
                <div className='jobs-section-top-settings'>
                  <LabeledCheckbox
                    checked={countArchivedInStats}
                    onClick={() =>
                      updateExtraParameter(
                        'countArchivedInStats',
                        !countArchivedInStats,
                      )
                    }
                    label={t('reveal.missions.pipeline.countArchived')}
                  />
                  <Dropdown
                    selection
                    selectOnBlur={false}
                    options={displayTypeSwitchOptions}
                    value={displayType}
                    onChange={(_e, { value }) =>
                      updateExtraParameter('displayType', value)
                    }
                    className='hiresweet-rounded'
                  />
                </div>
              </div>
              <div className='jobs-section-pipeline'>
                <PipelineFunnelSummary
                  data={summaryData}
                  conversionRates={globalConversionRates}
                  clickListeners={globalClickListeners}
                />
                {detailsLoading ? (
                  <Dimmer active>
                    <Loader size='large' />
                  </Dimmer>
                ) : (
                  <Modal
                    open={detailsModalOpen}
                    onClose={() => setDetailsModalOpen(false)}
                  >
                    <Modal.Content>
                      <DetailsModalHeader>
                        <h1>
                          {filter?.segment
                            ? t(
                                `reveal.pipelineSegmentations.${filter.segment}`,
                              )
                            : ''}
                        </h1>
                      </DetailsModalHeader>
                      <StatsProfileAndMissionTable
                        profileDetails={profileDetails}
                      />
                    </Modal.Content>
                  </Modal>
                )}
              </div>
            </>
          )}
          <div className='jobs-section-filters'>
            <GenericTextInput
              small
              value={nameFilter}
              onValue={setNameFilter}
              icon={<Search />}
              placeholder={t('reveal.missions.filterByName')}
              data-form-type='other'
            />
            <div className='jobs-section-filters-right'>
              {_.map(
                customFieldsDropdownsWithOptions,
                (customFieldDropdown, index) => (
                  <Dropdown
                    key={index}
                    search
                    size='tiny'
                    id={`project-${customFieldDropdown.id}`}
                    selection
                    className='hiresweet-rounded'
                    onChange={(_e, { value }) => {
                      handleCustomFieldFilterUpdate(
                        customFieldDropdown.id,
                        value,
                      );
                    }}
                    options={
                      customFieldDropdown.id === 'profil-vivier'
                        ? _.sortBy(customFieldDropdown.options, (option) =>
                            getTranslatedText(option.title),
                          )
                        : customFieldDropdown.options
                    }
                    value={
                      _.findWhere(
                        userTableViewPreferences?.filteringOptions?.filters ||
                          [],
                        { key: customFieldDropdown.id },
                      )?.filter.eq || 'all'
                    }
                  />
                ),
              )}
              {filteredDepartmentOptions.length > 1 && (
                <Dropdown
                  size='tiny'
                  id='execution-type'
                  search
                  onSearchChange={(_e, { searchQuery }) =>
                    setDepartmentSearch(searchQuery)
                  }
                  className={classNames(
                    'hiresweet-rounded',
                    'department-search',
                  )}
                  text={selectedDepartmentOption?.text}
                >
                  <Dropdown.Menu>
                    {_.map(
                      filteredDepartmentOptions,
                      ({ value, text, type }) => (
                        <Dropdown.Item
                          key={value}
                          onClick={() => {
                            handleScopeSelect(value);
                            setDepartmentSearch('');
                          }}
                          className={`option-${type}`}
                        >
                          {text}
                        </Dropdown.Item>
                      ),
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <UserFilter
                className='hiresweet-rounded'
                selectedUser={userFilterEmail}
                sortOptions
                onChange={(_e, { value }) => {
                  updateOwnerFilter(value);
                }}
                jobsUsers={
                  configurationParams?.shouldDisplayOnlyCurrentJobsUsers ===
                  'true'
                    ? jobsUsers
                    : []
                }
                shouldLimitOnScopedMissionOwners={[
                  'adequat-test1-demo',
                  'adequat-production',
                ].includes(clientId)}
              />
            </div>
          </div>
          {_.isEmpty(activeJobs) && (
            <EmptyState
              title={
                shouldBlockBecauseNoFilter
                  ? t('reveal.missions.emptyState.askForFilter')
                  : t('reveal.missions.emptyState.title')
              }
              innerContent={
                shouldBlockBecauseNoFilter ? (
                  <div>
                    <br />
                    <br />
                  </div>
                ) : (
                  t('reveal.missions.emptyState.innerContent')
                )
              }
              illustrationPath='/images/placeholders/projectsEmptyState.svg'
              cta={
                shouldBlockBecauseNoFilter ? null : (
                  <GenericButton
                    size='big'
                    onClick={() => {
                      setNewJobModalOpen(true);
                    }}
                  >
                    {t('reveal.missions.emptyState.cta')}
                  </GenericButton>
                )
              }
            />
          )}
          {!_.isEmpty(activeJobs) && (
            <div className='table-container'>
              <Table basic className='jobs-table'>
                <Table.Header className='job-table-headers'>
                  <ProjectsHeader
                    columnsDisplay={currentDisplayColumns}
                    missionCustomFields={missionCustomFields}
                    showColumnsSelector
                    displayableColumnGroups={displayableColumnGroups}
                    onUpdateColumnsToDisplay={onUpdateColumnsToDisplay}
                    columnSorting
                    displayPrefs={userTableViewPreferences}
                    updateDisplayPrefs={updateUserTableViewPreferences}
                  />
                </Table.Header>
                <Table.Body>
                  {_.map(sortedActiveJobs, (job) => {
                    // We use defaultJobStats when we created a new job, since the stats request is cache-first
                    const detailedCounts =
                      countsByCategory[job.id] || defaultJobStats;
                    const counts = getCurrentCountsBySegment(detailedCounts);

                    const missionStats = statsByMission[job.id] || {};
                    const stats = {
                      notContacted: counts.pending,
                      contacted: counts['in-progress'],
                      replied: counts.replied,
                      interested: counts.interested,
                      hired: counts.hired,
                      archived: counts.archived,
                      ...missionStats,
                      tasks: statsByMission[job.id]?.tasks || 0,
                      interestedSubSteps:
                        statsByMission[job.id]?.interestedSubSteps,
                    };

                    return (
                      <ProjectRow
                        key={job.id}
                        clientId={clientId}
                        linkedAts={linkedAts}
                        job={job}
                        sequences={
                          sequencesLoading
                            ? null
                            : sequencesClient?.client.sequences || []
                        }
                        setActionJob={setActionJob}
                        setOpenModal={setOpenModal}
                        onArchiveMission={onArchiveMission}
                        onUnarchiveMission={onUnarchiveMission}
                        stats={stats}
                        missionsStatsLoading={missionsStatsLoading}
                        columnsDisplay={currentDisplayColumns}
                        departments={flattenDepartments}
                        shouldHideProjectEditionButtons={
                          configurationParams?.shouldHideProjectEditionButtons ===
                          'true'
                        }
                        shoulPreventFromEnteringProject={
                          configurationParams?.shoulPreventFromEnteringProjectFromProjectsView ===
                          'true'
                        }
                        shouldHideSequence={
                          configurationParams?.shouldHideProjectsSequenceIcon ===
                          'true'
                        }
                        shouldHideATS={
                          configurationParams?.shouldHideProjectsATSIcon ===
                          'true'
                        }
                        shouldHideArchiveOption={
                          configurationParams?.shouldHideArchiveProjectOption ===
                          'true'
                        }
                      />
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          )}
        </div>

        {!_.isEmpty(archivedJobs) && (
          <HideAndShowArchivedButton
            t={t}
            displayArchived={displayArchived}
            setDisplayArchived={setDisplayArchived}
          />
        )}

        {!_.isEmpty(archivedJobs) && displayArchived && (
          <div className='archived-jobs'>
            <div className='job-cards-container'>
              <div className='table-section archived-jobs'>
                <div className='table-container'>
                  <Table basic className='jobs-table'>
                    <Table.Header className='job-table-headers'>
                      <ProjectsHeader
                        columnsDisplay={currentDisplayColumns}
                        missionCustomFields={missionCustomFields}
                        columnSorting
                      />
                    </Table.Header>
                    <Table.Body>
                      {_.map(displayedArchivedJobs, (job) => (
                        <ProjectRow
                          archived
                          key={job.id}
                          clientId={clientId}
                          linkedAts={linkedAts}
                          job={job}
                          t={t}
                          setOpenModal={setOpenModal}
                          setActionJob={setActionJob}
                          onArchiveMission={onArchiveMission}
                          onUnarchiveMission={onUnarchiveMission}
                          stats={statsByMission[job.id]}
                          missionsStatsLoading={missionsStatsLoading}
                          columnsDisplay={currentDisplayColumns}
                          departments={flattenDepartments}
                          shouldHideProjectEditionButtons={
                            configurationParams?.shouldHideProjectEditionButtons ===
                            'true'
                          }
                          shouldHideEditOption={
                            configurationParams?.shouldHideEditOnArchivedProjects ===
                            'true'
                          }
                          shoulPreventFromEnteringProject={
                            configurationParams?.shoulPreventFromEnteringProjectFromProjectsView ===
                            'true'
                          }
                          shouldHideATS={
                            configurationParams?.shouldHideProjectsATSIcon ===
                            'true'
                          }
                          shouldHideSequence={
                            configurationParams?.shouldHideProjectsSequenceIcon ===
                            'true'
                          }
                        />
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <NewRevealJobModal
        open={openNewJobModalDefault}
        onClose={() => setNewJobModalOpen(false)}
        searchPoolId={searchPoolId}
        clientId={clientId}
        onSubmit={createNewJobMutation}
        loading={creatingNewJob}
        queryResponse={createJobData}
      />

      <OnboardingModal />

      {openModal === 'archive' && (
        <ConfirmationModal
          open
          size='tiny'
          header='Do you want to archive this projet?'
          onSubmit={onArchiveMission}
          onCancel={() => setOpenModal(null)}
        />
      )}

      {openModal === 'unarchive' && (
        <ConfirmationModal
          open
          size='tiny'
          header='Do you want to unarchive this projet?'
          onSubmit={onUnarchiveMission}
          onCancel={() => setOpenModal(null)}
        />
      )}

      {openModal === 'edit' &&
        (atsId === 'adventure' ? (
          <NewRevealJobModal
            open
            job={actionJob}
            onClose={() => setOpenModal(false)}
            searchPoolId={searchPoolId}
            clientId={clientId}
            onSubmit={async (variables) => {
              await editMission({ variables });
              setOpenModal(false);
            }}
            loading={updatingJob}
            isEdition
            readOnlyCustomFields={['profil-vivier']}
          />
        ) : (
          <EditMissionModal
            clientId={clientId}
            searchPoolId={searchPoolId}
            job={actionJob}
            onClose={() => setOpenModal(null)}
          />
        ))}
    </div>
  );
};

const HideAndShowArchivedButton = ({
  displayArchived,
  setDisplayArchived,
  t,
}) => {
  if (displayArchived) {
    return (
      <div className='archived-link-box'>
        <button
          type='button'
          className='link-button'
          onClick={() => setDisplayArchived(false)}
        >
          {t('reveal.missions.hideArchived')}
        </button>
      </div>
    );
  }

  return (
    <div className='archived-link-box'>
      <button
        type='button'
        className='link-button'
        onClick={() => setDisplayArchived(true)}
      >
        {t('reveal.missions.showArchived')}
      </button>
    </div>
  );
};

export default JobsView;
