import { MutationHookOptions, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const REPUBLISH_JOB_POSTING = gql`
  mutation RepublishRevealJobPosting(
    $input: RepublishJobPostingInput!
    $searchPoolId: ID!
  ) {
    searchPoolJob(searchPoolId: $searchPoolId) {
      republishRevealJobPosting(input: $input) {
        jobPosting {
          id
        }
      }
    }
  }
`;

type Data = {
  searchPoolJob?: {
    republishRevealJobPosting?: {
      jobPosting?: {
        id: string;
      };
    };
  };
};

type Variables = {
  searchPoolId: string;
  input: {
    id: string;
  };
};

type Input = {
  searchPoolId?: string;
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

function useRepublishJobPosting({
  searchPoolId = 'reveal',
  mutationOptions = {},
}: Input = {}) {
  const [apply, results] = useMutation<Data, Variables>(REPUBLISH_JOB_POSTING, {
    ...mutationOptions,
  });
  const mutation = useCallback(
    ({ input }: { input: Variables['input'] }) =>
      apply({
        variables: {
          searchPoolId,
          input: {
            ...input,
          },
        },
      }),
    [apply, searchPoolId],
  );
  return [mutation, results] as const;
}

export default useRepublishJobPosting;
