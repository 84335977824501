import React from 'react';
import _ from 'underscore';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import TaskLink from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/TaskLink';
import RevealCandidateViewPendingActions from '@/revealComponents/ProfileContactFlow/Sequences/RevealPendingActions/RevealCandidateViewPendingActions';
import useMinimizedView from '@/hooks/ui/useMinimizedView';
import EnrichmentProvider from '@/components/Reveal/Profile/contactout/enrichmentProvider';
import { isLinkedinProfileUrl } from '@/common/validators';
import ATSData from '@/containers/Profile/Resume/Card/ATSData';
import { PluginAddToAtsCard } from '@/revealComponents/ATSStatusBanner';
import styles from './PluginProfileOverview.module.less';
import PluginExperiences from '../components/PluginExperiences';
import PluginEducations from '../components/PluginEducations';

interface PluginProfileOverviewProps {
  clientId: string;
  searchPoolId: string;
  activeProjectState: any;
  onSnoozeAction?: (id: string) => void;
  onDeleteAction?: (id: string) => void;
}

const PluginProfileOverview: React.FC<PluginProfileOverviewProps> = ({
  clientId,
  searchPoolId,
  activeProjectState,
  onSnoozeAction,
  onDeleteAction,
}) => {
  const {
    profile,
    loadingProfile,
    currentSequenceTask,
  } = useCandidateViewContext();
  const { isMinimized } = useMinimizedView();
  const { t } = useTranslation();

  if (loadingProfile) {
    return <></>;
  }
  const { education, experiences } = profile?.resumeData ?? {};
  const explicitTasks = profile?.explicitTasks?.filter(
    (explicitT: any) => explicitT.state !== 'completed',
  );

  const currentSequence = _.findWhere(profile?.contactFlow?.sequences || [], {
    isCurrent: true,
  });

  const sequenceIsTriggerable =
    currentSequence?.trigger?.type === 'manual-trigger';

  const currentSequenceIsCompleted = currentSequence?.completion?.isCompleted;

  const notHaveRevealPendingActions =
    _.isEmpty(profile?.contactFlow) ||
    _.isEmpty(profile?.currentSequenceInfo) ||
    sequenceIsTriggerable ||
    currentSequenceIsCompleted;

  return (
    <div className={`${styles.root} ${isMinimized ? styles.minimized : ''}`}>
      <div className={classNames(styles.block)}>
        <PluginAddToAtsCard clientId={clientId} profile={profile} />
        <ATSData clientId={clientId} profile={profile} />
      </div>
      <div className={classNames(styles.block)}>
        {(
          isLinkedinProfileUrl({ url: activeProjectState?.currentUrl }) || 
          isLinkedinProfileUrl({ url: profile?.resumeData?.sources?.linkedin })
        ) && (
          <>
            <EnrichmentProvider
              title='Contactout'
              provider='contactout'
              icon='/images/logos/contactout.svg'
              profile={profile}
            />
            <EnrichmentProvider
              title='Kaspr'
              provider='kaspr'
              icon='/images/logos/kaspr.png'
              profile={profile}
            />
            <EnrichmentProvider
              title='RocketReach'
              provider='rocketreach'
              icon='/images/logos/rocketreach.svg'
              profile={profile}
            />
          </>
        )}

        {!notHaveRevealPendingActions && (
          <RevealCandidateViewPendingActions
            searchPoolId={searchPoolId}
            clientId={clientId}
            profileId={profile?.id}
            task={currentSequenceTask}
            currentSequenceInfo={profile?.currentSequenceInfo}
            skipFirst={false}
            pluginCurrentUrl={activeProjectState?.currentUrl}
            onSnoozeAction={onSnoozeAction}
            onDeleteAction={onDeleteAction}
          />
        )}
        {notHaveRevealPendingActions && isMinimized && (
          <div className={styles.noPendingAction}>
            <h4>{t('reveal.profileModal.emptyStates.pendingActions.title')}</h4>
            <p>
              {t(
                'reveal.profileModal.emptyStates.pendingActions.innerContent',
                {
                  firstname: profile.resumeData?.firstname || '',
                },
              )}
            </p>
          </div>
        )}
        {!_.isEmpty(explicitTasks) && (
          <div
            className={`reveal-pending-actions candidate-explicit-tasks ${
              isMinimized ? 'minimized' : ''
            }`}
          >
            {_.map(explicitTasks, (explicitTask) => {
              return (
                <TaskLink
                  key={explicitTask?.id}
                  clientId={clientId}
                  profileId={profile?.id}
                  task={explicitTask}
                  sequence={null}
                  onSnooze={() => onSnoozeAction?.(explicitTask.id)}
                  onDelete={() => onDeleteAction?.(explicitTask.id)}
                />
              );
            })}
          </div>
        )}
      </div>
      {!isMinimized && (
        <div className={styles.block}>
          <PluginExperiences experiences={experiences} dense />
        </div>
      )}
      {!isMinimized && (
        <div className={styles.block}>
          <PluginEducations educations={education} dense />
        </div>
      )}
    </div>
  );
};

export default PluginProfileOverview;
