import gql from 'graphql-tag';

export default gql`
  fragment SearchPoolMiniProfile on SearchPoolProfile {
    resumeData {
      firstname
      lastname
      headline {
        content {
          text
        }
      }
      photoLink
    }
    id
    missionsInfo {
      mission {
        id
        data {
          title
        }
      }
    }
  }
`;
