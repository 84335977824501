import React from 'react';
import _ from 'underscore';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import GenericTooltip from '@/components/Common/GenericTooltip';
import {
  MANUAL_TRIGGER as MANUAL_TRIGGER_TYPE,
  AND_CONDITION_TYPE,
  DELAY_AFTER_ACTION,
  LINKEDIN_INVITATION_ACCEPTED,
  OR_CONDITION_TYPE,
  TASK_TYPES,
} from '@/common/constants/taskTypes';
import LinkedinInvitationAcceptedCondition from '@/components/contactFlow/LinkedinInvitationAcceptedCondition';
import useClientId from '@/hooks/router/useClientId';
import TriggerDelayEditor from './TriggerDelayEditor/TriggerDelayEditor';

import './TriggerEditor.css';

export const MANUAL_TRIGGER = {
  type: MANUAL_TRIGGER_TYPE,
};

export const DEFAULT_DELAY_TRIGGER = {
  type: DELAY_AFTER_ACTION,
  delay: {
    value: 7,
    unit: 'day',
  },
};

const TriggerEditor = ({
  action,
  onUpdate,
  permissionsAndNudges,
  t,
  displayExtraDescription = false,
  isNested = false,
  disabled = false,
}) => {
  const clientId = useClientId();
  const canDisplayLinkedinInvitationAcceptedCondition =
    !isNested &&
    action?.trigger?.type === DELAY_AFTER_ACTION &&
    permissionsAndNudges.linkedinInvitationAcceptedTrigger.isAllowed &&
    action.type !== TASK_TYPES.LINKEDIN_CHECK_REQUEST;

  if (
    action?.trigger?.type === MANUAL_TRIGGER_TYPE &&
    !permissionsAndNudges.nonManualTrigger.isAllowed
  ) {
    return (
      <div className='full-trigger-editor'>
        <div className='delay-or-manual-editor'>
          <TriggerPopup
            reason={permissionsAndNudges.nonManualTrigger.reason}
            label={t('contactFlow.triggers.createdImmediately')}
          />
        </div>
      </div>
    );
  }

  if (
    action?.trigger?.type === DELAY_AFTER_ACTION &&
    !permissionsAndNudges.manualTrigger.isAllowed
  ) {
    return (
      <div className='full-trigger-editor'>
        <div className='delay-or-manual-editor'>
          <TriggerPopup
            reason={permissionsAndNudges.manualTrigger.reason}
            label={
              action?.type === TASK_TYPES.SEND_EMAIL
                ? t('contactFlow.triggers.triggeredAutomatically')
                : t('contactFlow.triggers.createdAutomatically')
            }
          />
          <TriggerDelayEditor
            action={action}
            onUpdate={onUpdate}
            t={t}
            displayExtraDescription={displayExtraDescription}
            disabled={disabled}
          />
        </div>
        {canDisplayLinkedinInvitationAcceptedCondition && (
          <LinkedinInvitationAcceptedCondition
            clientId={clientId}
            checked={false}
            action={action}
            onUpdate={onUpdate}
            disabled={disabled}
          />
        )}
      </div>
    );
  }

  if (
    action?.trigger?.type === OR_CONDITION_TYPE ||
    action?.trigger?.type === AND_CONDITION_TYPE
  ) {
    const subActionUpdate =
      (index) =>
      ({ action: newSubAction }) => {
        const newConditions = _.compact(
          _.map(action.trigger.conditions, (newCondition, updateIndex) => {
            if (updateIndex === index) {
              return newSubAction.trigger;
            }
            return newCondition;
          }),
        );
        const newTrigger = getCanonicalTrigger({
          actionType: action.type,
          trigger: {
            ...action.trigger,
            conditions: newConditions,
          },
        });
        const newAction = {
          ...action,
          trigger: newTrigger,
        };
        return onUpdate({
          action: newAction,
        });
      };
    return (
      <div className='full-trigger-editor'>
        {_.map(action?.trigger?.conditions, (condition, index) => {
          const subAction = { ...action, trigger: condition };
          return (
            <TriggerEditor
              key={`${condition?.type}-${index}`}
              action={subAction}
              permissionsAndNudges={permissionsAndNudges}
              onUpdate={subActionUpdate(index)}
              t={t}
              displayExtraDescription={displayExtraDescription}
              isNested
              disabled={disabled}
            />
          );
        })}
      </div>
    );
  }

  if (action?.trigger?.type === LINKEDIN_INVITATION_ACCEPTED) {
    return (
      <LinkedinInvitationAcceptedCondition
        clientId={clientId}
        checked
        action={action}
        onUpdate={onUpdate}
        disabled={disabled}
      />
    );
  }

  return (
    <div className='full-trigger-editor'>
      <div className='delay-or-manual-editor'>
        <TriggerTypeDropdown
          action={action}
          onUpdate={onUpdate}
          t={t}
          disabled={disabled}
        />
        {action?.trigger?.type === DELAY_AFTER_ACTION && (
          <TriggerDelayEditor
            action={action}
            onUpdate={onUpdate}
            t={t}
            displayExtraDescription={displayExtraDescription}
            disabled={disabled}
          />
        )}
      </div>
      {canDisplayLinkedinInvitationAcceptedCondition && (
        <LinkedinInvitationAcceptedCondition
          clientId={clientId}
          checked={false}
          action={action}
          onUpdate={onUpdate}
          disabled={disabled}
        />
      )}
    </div>
  );
};

const TriggerTypeDropdown = ({ action, onUpdate, disabled, t }) => {
  const delayAfterActionText =
    action?.type === TASK_TYPES.SEND_EMAIL
      ? `${t('contactFlow.triggers.triggeredAutomatically')} `
      : `${t('contactFlow.triggers.createdAutomatically')} `;

  const options = [
    {
      value: MANUAL_TRIGGER_TYPE,
      text: t('contactFlow.triggers.createdImmediately'),
    },
    { value: DELAY_AFTER_ACTION, text: delayAfterActionText },
  ];

  const handleChange = (e, { value }) => {
    if (value === action?.trigger?.type) {
      return;
    }
    if (value === DELAY_AFTER_ACTION) {
      onUpdate({ action: { ...action, trigger: DEFAULT_DELAY_TRIGGER } });
      return;
    }
    if (value === MANUAL_TRIGGER_TYPE) {
      onUpdate({ action: { ...action, trigger: MANUAL_TRIGGER } });
    }
  };

  return (
    <Dropdown
      className='trigger-type-dropdown trigger-type-label hs-inline-dropdown'
      value={action?.trigger?.type}
      onChange={handleChange}
      options={options}
      disabled={disabled}
    />
  );
};

const TriggerPopup = ({ label, reason }) => {
  const popupTrigger = <span className='trigger-type-label'>{label}</span>;
  return <GenericTooltip trigger={popupTrigger} content={reason} />;
};

export const getCanonicalTrigger = ({ trigger, actionType }) => {
  if (!_.isArray(trigger?.conditions)) {
    return trigger;
  }
  const { conditions } = trigger;
  if (conditions?.length === 1) {
    return conditions[0];
  }
  if (trigger?.type === OR_CONDITION_TYPE) {
    const manualTrigger = _.findWhere(conditions, {
      type: MANUAL_TRIGGER.type,
    });
    const linkedinInvitationAcceptedTrigger = _.findWhere(conditions, {
      type: LINKEDIN_INVITATION_ACCEPTED,
    });
    if (linkedinInvitationAcceptedTrigger) {
      if (manualTrigger) {
        // linkedinInvitationAcceptedTrigger is only possible with a delay trigger
        return manualTrigger;
      }
      const linkedinActions = [
        TASK_TYPES.LINKEDIN_SEND_INMAIL,
        TASK_TYPES.LINKEDIN_SEND_MESSAGE,
        TASK_TYPES.LINKEDIN_CHECK_REQUEST,
      ];
      if (!_.contains(linkedinActions, actionType)) {
        // linkedinInvitationAcceptedTrigger is only possible with a linkedin actions
        const newConditions = _.filter(
          conditions,
          ({ type }) => type !== LINKEDIN_INVITATION_ACCEPTED,
        );
        if (newConditions.length === 1) {
          return newConditions[0];
        }
        return {
          ...trigger,
          conditions: newConditions,
        };
      }
    }
  }
  return trigger;
};

export default withTranslation('translations')(TriggerEditor);
