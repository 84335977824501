import React from 'react';

export const ashbyLogo = (
  <img alt='ashby logo' src='/images/logos/ashby-square-logo.png' />
);

export const greenhouseLogo = (
  <img alt='greenhouse logo' src='/images/logos/greenhouse-square-logo.png' />
);

export const leverLogo = (
  <img alt='lever logo' src='/images/logos/lever-square-logo.png' />
);

export const recruiteeLogo = (
  <img alt='recruitee logo' src='/images/logos/recruitee-square-logo.png' />
);

export const recruitcrmLogo = (
  <img alt='recruitcrm logo' src='/images/logos/recruitcrm-logo.jpg' />
);

export const workableLogo = (
  <img alt='workable logo' src='/images/logos/workable-square-logo.png' />
);

export const smartrecruitersLogo = (
  <img
    alt='smartrecruiters logo'
    src='/images/logos/smartrecruiters-square-logo.png'
  />
);

export const zohoLogo = (
  <img alt='zoho logo' src='/images/logos/zoho-square-logo.png' />
);

export const successfactorsLogo = (
  <img
    alt='successfactorsLogo logo'
    src='/images/logos/successfactors-logo.png'
  />
);

export const wttjLogo = (
  <img alt='wttj logo' src='/images/logos/wttj-square-logo.png' />
);

export const welcomekitLogo = (
  <img alt='wttj logo' src='/images/logos/welcomekit-square-logo.png' />
);

export const hubspotLogo = (
  <img alt='hubspot logo' src='/images/logos/hubspot-square-logo.svg' />
);

export const teamtailorLogo = (
  <img alt='teamtailor logo' src='/images/logos/team-tailor-logo.svg' />
);

export const boondmanagerLogo = (
  <img
    alt='boondmanager logo'
    src='/images/logos/boondmanager-square-logo.png'
  />
);

export const adventureLogo = (
  <img alt='adventure logo' src='/images/logos/adventure-square-logo.png' />
);
