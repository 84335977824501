import React, { useMemo } from 'react';
import Select, { GroupBase, Props } from 'react-select';
import { useTranslation } from 'react-i18next';

import styles from './styles';

const GenericSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  isClearable,
  noOptionsMessage,
  placeholder,
  ...props
}: Omit<Props<Option, IsMulti, Group>, 'styles'>) => {
  const { t } = useTranslation();

  const actualNoOptionsMessage = useMemo(
    () => noOptionsMessage || (() => t('common.noOptions')),
    [noOptionsMessage, t],
  );

  const actualPlaceholder = useMemo(
    () => placeholder || `${t('common.select')}...`,
    [placeholder, t],
  );

  return (
    <Select
      {...props}
      isClearable={isClearable || false}
      styles={styles}
      noOptionsMessage={actualNoOptionsMessage}
      placeholder={actualPlaceholder}
    />
  );
};

export default GenericSelect;
