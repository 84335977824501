import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import _ from 'underscore';
import { TranslatableText } from '@/types/text';
import styles from './MetaTaskForm.module.less';
import {
  MetaTaskFormat,
  MetaTaskFormatForm,
  MetaTaskFormatFormField,
} from './types';
import MetaTaskFormFieldAnswer from './FormAnswers';
import { HelpText } from './HelpText';

const shouldDisableField = (
  field: MetaTaskFormatFormField,
  formFlagsDict: any,
) => {
  if (field.condition?.type === 'form-flag') {
    if (field.condition?.effect?.type === 'remove') {
      const { key, targetValues } = field.condition;
      return _.contains(targetValues, formFlagsDict[key]);
    }
    if (field.condition?.effect?.type === 'enable') {
      const { key, targetValues } = field.condition;
      return !_.contains(targetValues, formFlagsDict[key]);
    }
  }

  return false;
};

interface MetaTaskFormProps {
  format?: MetaTaskFormat;
  disabled?: boolean;
  readMode?: boolean;
  helpTextCTA?: string | JSX.Element | null;
  onUpdate?: ({
    newForm,
    helpText,
  }: {
    newForm: MetaTaskFormatForm;
    helpText?: TranslatableText;
  }) => void;
}

const MetaTaskForm: React.FC<MetaTaskFormProps> = ({
  format,
  helpTextCTA,
  disabled,
  readMode,
  onUpdate,
}) => {
  const [formFlagsDict, setFormFlagsDict] = useState(
    getInitialFormFlagsDict(format),
  );
  const [innerFormat, setInnerFormat] = useState(format);

  useEffect(() => {
    if (!format) {
      return;
    }
    setInnerFormat(format);
    setFormFlagsDict(getInitialFormFlagsDict(format));
  }, [format]);

  const selectAnswer = ({
    fieldId,
    answerId,
  }: {
    fieldId: string;
    answerId: string;
  }) => {
    if (!innerFormat?.form?.fields) {
      return;
    }
    const matchingField = _.findWhere(innerFormat.form.fields, { id: fieldId });
    _.each(matchingField?.onEvents || [], (event) => {
      if (event.rule?.type !== 'on-selected-value') {
        return;
      }
      if (event.effect?.type === 'set-form-flag-from-selected-value') {
        setFormFlagsDict((currentFormFlagsDict) => ({
          ...currentFormFlagsDict,
          [event.effect?.key]: answerId,
        }));
      }
    });
    const newState = {
      ...innerFormat?.form,
      fields: _.map(innerFormat?.form.fields, (field) => ({
        ...field,
        ...((field.type === 'select' ||
          field.type === 'checkbox' ||
          field.subtype === 'enum') &&
          field.id === fieldId && {
            options: _.map(field?.options || [], (option) => {
              return {
                ...option,
                ...(!field.isMultiselect && { selected: false }),
                ...(option.id === answerId && {
                  selected:
                    option.selected === undefined ? true : !option.selected,
                }),
              };
            }),
          }),
      })),
    };
    setInnerFormat((current) => ({
      ...current,
      form: newState,
    }));
    if (onUpdate) {
      onUpdate({
        ...(innerFormat?.helpText && { helpText: innerFormat.helpText }),
        newForm: newState,
      });
    }
  };

  const inputAnswer = ({
    fieldId,
    value,
  }: {
    fieldId: string;
    value: string;
  }) => {
    if (!innerFormat?.form?.fields) {
      return;
    }
    const newState = {
      ...innerFormat?.form,
      fields: _.map(innerFormat?.form.fields, (field) => ({
        ...field,
        ...(field.type !== 'select' &&
          field.subtype !== 'enum' &&
          field.type !== 'checkbox' &&
          field.id === fieldId && {
            value,
          }),
      })),
    };
    setInnerFormat((current) => ({
      ...current,
      form: newState,
    }));
    if (onUpdate) {
      onUpdate({
        ...(innerFormat?.helpText && { helpText: innerFormat.helpText }),
        newForm: newState,
      });
    }
  };

  useEffect(() => {
    if (!onUpdate) {
      setInnerFormat(format);
    }
  }, [format, onUpdate]);

  if (!format && !helpTextCTA) {
    return null;
  }

  return (
    <div className={styles.metaTaskFormContainer}>
      <HelpText text={innerFormat?.helpText} cta={helpTextCTA} />
      {_.map(
        innerFormat?.form?.fields || [],
        (field) =>
          !shouldDisableField(field, formFlagsDict) && (
            <div
              className={classnames(
                styles.metaTaskFieldContainer,
                styles.classicField,
              )}
              key={field.id}
            >
              {field.type !== 'help-text' ? (
                <>
                  <div className={styles.metaTaskTitle}>
                    {field.title.default}
                  </div>
                  {(field.type === 'select' ||
                    field.type === 'checkbox' ||
                    field.subtype === 'enum') && (
                    <div className={styles.metaTaskAnswersContainer}>
                      {_.map(
                        _.filter(
                          field.options || [],
                          (x: any) => !readMode || x?.selected,
                        ),
                        (option) => (
                          <div
                            className={styles.metaTaskAnswerContainer}
                            key={option.id}
                          >
                            <MetaTaskFormFieldAnswer
                              field={field}
                              option={option}
                              disabled={disabled}
                              readMode={readMode}
                              onSelect={disabled ? undefined : selectAnswer}
                            />
                          </div>
                        ),
                      )}
                      {readMode &&
                      !_.findWhere(field.options || [], { selected: true }) ? (
                        <div>-</div>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                  <MetaTaskFormFieldAnswer
                    field={field}
                    disabled={disabled}
                    readMode={readMode}
                    onChange={
                      disabled ? undefined : _.debounce(inputAnswer, 200)
                    }
                  />
                </>
              ) : (
                <HelpText text={field.title} />
              )}
            </div>
          ),
      )}
    </div>
  );
};

function getInitialFormFlagsDict(format?: MetaTaskFormat) {
  const result = {} as Record<string, string>;

  _.each(format?.form?.fields || [], (field) => {
    _.each(field.onEvents || [], (event) => {
      if (event.rule?.type !== 'on-selected-value') {
        return;
      }
      const value =
        _.findWhere(field.options || [], { selected: true })?.id || field.value;
      if (value && event.effect?.type === 'set-form-flag-from-selected-value') {
        result[event.effect.key] = value;
      }
    });
  });

  return result;
}

export default MetaTaskForm;
