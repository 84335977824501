import gql from 'graphql-tag';
import TranslatableText from './TranslatableText';
import SweetDate from './SweetDate';
import TextWithEnrichments from './TextWithEnrichments';
import HiresweetData from './HiresweetData';
import SmartrecruitersData from './SmartrecruitersData';
import GreenhouseData from './GreenhouseData';
import LeverData from './LeverData';
import RecruiteeData from './RecruiteeData';
import RecruitcrmData from './RecruitcrmData';
import TeamtailorData from './TeamtailorData';
import AshbyData from './AshbyData';
import AdventureData from './AdventureData';
import WelcomekitData from './WelcomekitData';
import WorkableData from './WorkableData';
import BoondmanagerData from './BoondmanagerData';
import GenericSourceData from './GenericSourceData';
import ProfileEnrichmentResultData from './ProfileEnrichmentResultData';
import ConnectData from './ConnectData';
import SuccessfactorsData from './SuccessfactorsData';

export const ATSSourceData = gql`
  fragment ATSSourceData on SourceData {
    adventure {
      ...AdventureData
    }
    ashby {
      ...AshbyData
    }
    smartrecruiters {
      ...SmartrecruitersData
    }
    greenhouse {
      ...GreenhouseData
    }
    lever {
      ...LeverData
    }
    recruitee {
      ...RecruiteeData
    }
    recruitcrm {
      ...RecruitcrmData
    }
    teamtailor {
      ...TeamtailorData
    }
    workable {
      ...WorkableData
    }
    welcomekit {
      ...WelcomekitData
    }
    boondmanager {
      ...BoondmanagerData
    }
    successfactors {
      ...SuccessfactorsData
    }
    generic {
      ...GenericSourceData
    }
  }
  ${AdventureData}
  ${AshbyData}
  ${SmartrecruitersData}
  ${GreenhouseData}
  ${LeverData}
  ${RecruiteeData}
  ${RecruitcrmData}
  ${TeamtailorData}
  ${WelcomekitData}
  ${WorkableData}
  ${BoondmanagerData}
  ${SuccessfactorsData}
  ${GenericSourceData}
`;

export default gql`
  fragment SourceData on SourceData {
    github {
      relevantFacts {
        title {
          ...TranslatableText
        }
        description {
          ...TranslatableText
        }
      }
      repositories {
        name {
          ...TextWithEnrichments
        }
        fullname {
          ...TextWithEnrichments
        }
        description {
          ...TextWithEnrichments
        }
        fork
        createdAt {
          ...SweetDate
        }
        updatedAt {
          ...SweetDate
        }
        stargazersCount
        watchersCounts
        forksCount
        language
      }
    }
    stackoverflow {
      relevantFacts {
        title {
          ...TranslatableText
        }
        description {
          ...TranslatableText
        }
      }
    }
    linkedin {
      skills {
        label {
          ...TranslatableText
        }
        endorsementsCount
        relevance
      }
    }
    linkedinRecruiter {
      id
    }
    linkedinCommon {
      id
    }
    linkedinSalesNavigator {
      id
    }
    hiresweet {
      ...HiresweetData
    }
    ...ATSSourceData
    contactout {
      ...ProfileEnrichmentResultData
    }
    kaspr {
      ...ProfileEnrichmentResultData
    }
    rocketreach {
      ...ProfileEnrichmentResultData
    }
    csvImported {
      rawValues {
        key
        value
        mapping {
          id
          type
        }
      }
    }
    connect {
      ...ConnectData
    }
    # TODO: migrate greenhouse field to more generic ATSData
    # ATSData {
    #   ... on GreenhouseData {
    #     ...GreenhouseData
    #   }
    # }
  }

  ${TranslatableText}
  ${SweetDate}
  ${TextWithEnrichments}
  ${HiresweetData}
  ${ProfileEnrichmentResultData}
  ${ConnectData}
  ${ATSSourceData}
`;
