import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import './CustomFileInput.css';
import GenericButton from '../Common/GenericButton';

const CustomFileInput = ({
  onChange,
  description,
  buttonText = '',
  fileTypes,
  imageSrc,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: fileTypes || 'application/pdf',
    maxFiles: 1,
    onDrop: onChange,
  });
  return (
    <div
      className={`dropzone-container centered${isDragActive ? ' drag-active' : ''
        }`}
    >
      <div {...getRootProps({ className: 'dropzone' })}>
        {imageSrc && (
          <>
            <img src={imageSrc} alt='Upload' />
            <div className='spacer' />
          </>
        )}
        <div className='dropzone-content'>
          <input disabled={disabled} {...getInputProps()} />
          <p>{description || t('fileUpload.dragFile')}</p>
          <GenericButton onClick={open} size='big' disabled={disabled}>
            {buttonText || t('fileUpload.selectFile')}
          </GenericButton>
        </div>
      </div>
    </div>
  );
};

export default CustomFileInput;
