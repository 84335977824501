import React, { useState, useRef } from 'react';
import _ from 'underscore';
import classNames from 'classnames';
import { Form, Input, Popup, Image, Checkbox } from 'semantic-ui-react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { getSecureLink, getRandomDefaultAvatarLink } from '@/common';
import NameAndHeadline from '@/revealComponents/RevealListRow/NameAndHeadline';
import { ExperienceAndEducationIcon } from '@/assets/icons';
import useClickOutside from '@/hooks/common/useClickOutside';
import useClientId from '@/hooks/router/useClientId';
import GenericButton from '@/components/Common/GenericButton';

import SequenceDropdown from '@/components/Reveal/Sequences/SequenceDropdown/SequenceDropdown';
import { CLIENT_SEQUENCES } from '@/containers/Parameters/Sequences/queries';
import { GET_JOB_DEFAULT_SEQUENCES } from '@/graphql/searchPoolJob';
import styles from './ProfileRow.module.less';

type Profile = {
  creationTimestamp?: number;
  resumeData?: { firstname?: string; lastname?: string; photoLink?: string };
};
type Props = {
  selected?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

export const ProfileRow: React.FC<Props> = ({
  selected,
  onClick,
  children,
}) => {
  return (
    <div
      className={classNames('profile-row', selected && 'selected')}
      onClick={onClick}
      role='button'
    >
      {children}
    </div>
  );
};

const Check: React.FC<{
  selected: boolean;
  onCheck: () => void;
}> = ({ selected, onCheck }) => (
  <div className='row-checkbox-container' onClick={onCheck}>
    <Checkbox checked={Boolean(selected)} />
  </div>
);

export const ProfileDetails: React.FC<{
  clientId: string;
  searchPoolId: string;
  profile: Profile;
}> = ({ clientId, searchPoolId, profile }) => {
  const { firstname, lastname, photoLink } = profile?.resumeData || {};
  const name = `${firstname || ''}${lastname || ''}`;
  const avatarImageUrl = photoLink || getRandomDefaultAvatarLink(name);
  return (
    <>
      <div className={classNames('avatar-container', styles.rowAvatar)}>
        <Image
          src={getSecureLink(avatarImageUrl)}
          circular
          onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
            (e.target as HTMLImageElement).src = getRandomDefaultAvatarLink(
              name,
            );
          }}
        />
      </div>

      <div
        className={classNames(
          'name-and-headline-container',
          styles.rowProfileDetails,
        )}
      >
        <NameAndHeadline
          clientId={clientId}
          searchPoolId={searchPoolId}
          profile={profile}
        />
      </div>
    </>
  );
};

const Actions: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className={styles.rowActions}>{children}</div>;
};

interface SkipActionProps {
  onSkip: (reason?: string) => void;
  isApplicationAction?: boolean;
}

const SkipAction: React.FC<SkipActionProps> = ({
  onSkip,
  isApplicationAction = false,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [otherReasonInputDisplayed, setOtherReasonInputDisplayed] = useState(
    false,
  );
  const [otherReason, setOtherReason] = useState('');
  const handleSkip = async (reason?: string) => {
    setOpen(false);
    await onSkip(reason);
  };
  return (
    <Popup
        on='click'
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        size='huge'
        trigger={
          <GenericButton
            primacy='secondary'
            color={isApplicationAction ? 'red' : 'blue'}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <i className='ri-thumb-down-line' />
            {t(
              `reveal.${
                isApplicationAction
                  ? 'applicantProfiles'
                  : 'recommendedProfiles'
              }.skip.trigger`,
            )}
          </GenericButton>
        }
        popperModifiers={[
          {
            // Fix a positioning problem
            name: 'preventOverflow',
            options: {
              boundariesElement: 'window',
            },
          }
        ]}
        className={styles.skipPopup}
      >
        <Popup.Content>
          <div className={styles.skipReasonsContainer}>
            <div className={styles.column}>
              {_.map(_.first(SKIP_REASONS, 3), (reason, index) => (
                <SkipReasonButton
                  key={index}
                  handleSkip={handleSkip}
                  reason={reason}
                  t={t}
                />
              ))}
            </div>
            <div className={styles.column}>
              {_.map(
                SKIP_REASONS.slice(3, SKIP_REASONS.length),
                (reason, index) => (
                  <SkipReasonButton
                    key={index}
                    handleSkip={handleSkip}
                    reason={reason}
                    t={t}
                  />
                ),
              )}
            </div>
          </div>
          <GenericButton
            primacy='secondary'
            onClick={() => {
              setOtherReasonInputDisplayed(true);
            }}
            className={styles.other}
          >
            {t('reveal.recommendedProfiles.skip.reasons.other')}
          </GenericButton>
          {otherReasonInputDisplayed && (
            <Form
              onSubmit={() => {
                handleSkip(`#${otherReason}`);
              }}
            >
              <Input
                size='small'
                className={styles.otherReasonInput}
                placeholder={t(
                  'reveal.recommendedProfiles.skip.reasons.otherPlaceholder',
                )}
                onChange={(e, data) => setOtherReason(data?.value)}
                onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
                value={otherReason}
              />
            </Form>
          )}
        </Popup.Content>
      </Popup>
  );
};

const SKIP_REASONS_ICONS: { [index: string]: any } = {
  'unsuitable-current-position': 'ri-creative-commons-by-line',
  'not-enough-xp': 'ri-suitcase-line',
  'insufficient-education': (
    <ExperienceAndEducationIcon className='education-icon' />
  ),
  'insufficient-targeted-skills': 'ri-tools-fill',
  'too-much-xp': 'ri-skip-forward-fill',
  'friend-company': 'ri-open-arm-line',
};

const SKIP_REASONS = [
  'unsuitable-current-position',
  'not-enough-xp',
  'insufficient-education',
  'insufficient-targeted-skills',
  'too-much-xp',
  'friend-company',
];

const SkipReasonButton: React.FC<{
  handleSkip: any;
  reason: string;
  t: TFunction;
}> = ({ handleSkip, reason, t }) => {
  return (
    <GenericButton
      primacy='secondary'
      onClick={(e) => {
        e.stopPropagation();
        handleSkip(reason);
      }}
    >
      {typeof SKIP_REASONS_ICONS[reason] === 'string' ? (
        <i className={SKIP_REASONS_ICONS[reason]} />
      ) : (
        SKIP_REASONS_ICONS[reason]
      )}
      {t(`reveal.recommendedProfiles.skip.reasons.${reason}`)}
    </GenericButton>
  );
};

interface AddToSequenceModalProps {
  jobId?: string | undefined;
  onSubmit: (selectedSequenceId: string | null) => void;
  onClose: () => void;
  absolutePositionStyles: any;
}

export const AddToSequenceModal: React.FC<AddToSequenceModalProps> = ({
  jobId,
  onSubmit,
  onClose,
  absolutePositionStyles,
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [checked, setChecked] = useState(true);
  const [sequencePopupOpen, setSequencePopupOpen] = useState(false);
  const clientId = useClientId();
  const [selectedSequenceId, setSelectedSequenceId] = useState<string | null>(
    null,
  );
  const { data: jobData } = useQuery(GET_JOB_DEFAULT_SEQUENCES, {
    variables: { searchPoolId: 'reveal', jobId },
    skip: !jobId,
  });
  const attachedSequences = jobData?.searchPool?.job?.attachedSequences;
  const defaultSequenceId = _.findWhere(attachedSequences, { isDefault: true })
    ?.sequenceId;
  const secondarySequenceIds = _.pluck(
    _.filter(
      attachedSequences,
      ({ sequenceId }) => sequenceId !== defaultSequenceId,
    ),
    'sequenceId',
  );
  const { data } = useQuery(CLIENT_SEQUENCES, {
    variables: { clientId },
  });

  const clientSequencesList = _.filter(
    data?.client?.sequences,
    ({ isArchived }) => !isArchived,
  );

  useClickOutside(ref, () => {
    if (!ref.current || sequencePopupOpen) return;
    onClose();
  });

  return (
    <div
      ref={ref}
      className={styles.profileAddToSequenceModal}
      onClick={(event) => event.stopPropagation()}
      style={{ ...absolutePositionStyles }}
    >
      <Checkbox
        defaultChecked={checked}
        label={t('reveal.recommendedProfiles.sequence.add')}
        onChange={(): void => setChecked((prev) => !prev)}
      />
      <div className={styles.modalBottom}>
        {checked && (
          <SequenceDropdown
            clientSequencesList={clientSequencesList}
            defaultSequenceId={defaultSequenceId}
            secondarySequenceIds={secondarySequenceIds}
            currentSequence={{
              sequenceId: selectedSequenceId || defaultSequenceId,
            }}
            onSequenceSelected={(id) => {
              setSelectedSequenceId(id);
              setTimeout(() => {
                setSequencePopupOpen(false);
              }, 0);
            }}
            clientId={clientId}
            style={{
              maxHeight: '400px',
            }}
          />
          // <SequencesDropdown
          //   clientId={clientId}
          //   selectedSequenceId={selectedSequenceId}
          //   setSelectedSequenceId={setSelectedSequenceId}
          //   jobId={jobId}
          //   onChangeSequence={(e: any, { value }: any) => {
          //     setSelectedSequenceId(value);
          //   }}
          // />
        )}

        <GenericButton
          disabled={checked && !(selectedSequenceId || defaultSequenceId)}
          onClick={(): void =>
            onSubmit(selectedSequenceId || defaultSequenceId)
          }
        >
          {t('reveal.recommendedProfiles.confirm.trigger')}
        </GenericButton>
      </div>
    </div>
  );
};

export default Object.assign(ProfileRow, {
  ProfileDetails,
  Check,
  Actions,
  Action: GenericButton,
  AddToSequenceModal,
  SkipAction,
});
