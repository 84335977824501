import React, { VFC } from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useCompanyTargetContacts from '@/graphql/hooks/searchPoolProfile/useCompanyTargetContacts';
import useClientId from '@/hooks/router/useClientId';
import { ProfileDetails } from '@/routes/RevealView/JobsView/JobView/ProjectProfilesTab/ProfileRow';
import useSearchPoolMiniProfileById from '@/graphql/hooks/profiles/useSearchPoolMiniProfileById';
import styles from './LinkedContacts.module.less';

type Props = {
  profileId: string;
};

const LinkedContacts: VFC<Props> = ({ profileId }) => {
  const { t } = useTranslation();
  const { companyTargetContacts } = useCompanyTargetContacts({
    profileId,
  });

  return (
    <>
      <h4 className={styles.title}>
        {t('reveal.profileModal.companyOverview.linkedContacts.title')}
      </h4>
      {_.isEmpty(companyTargetContacts) && (
        <div className={styles.emptyState}>
          {t('reveal.profileModal.companyOverview.linkedContacts.emptyState')}
        </div>
      )}
      {companyTargetContacts?.map(({ id }) => (
        <div key={id}>
          <CompanyLinkedContact profileId={id || ''} />
        </div>
      ))}
    </>
  );
};

interface CompanyLinkedContactProps {
  profileId: string;
}

const CompanyLinkedContact: React.FC<CompanyLinkedContactProps> = ({
  profileId,
}) => {
  const clientId = useClientId();
  const { profile, loading } = useSearchPoolMiniProfileById(
    {
      searchPoolId: 'reveal',
      profileId,
    },
    {
      fetchPolicy: 'network-only',
    },
  );

  if (loading || !profile) {
    return null;
  }

  return (
    <Link
      key={profileId}
      to={`/client/${clientId}/reveal/search?profileId=${profileId}`}
      className={styles.card}
    >
      <div className={styles.cardTitle}>
        <ProfileDetails
          clientId={clientId}
          searchPoolId='reveal'
          profile={profile}
        />
      </div>
    </Link>
  );
};

export default LinkedContacts;
