import React, { FC, useContext, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import GenericButton from '@/components/Common/GenericButton';
import ProfilesViewSettingsContext from '../../../ProfilesViewSettingsContext';

import styles from './WorkingDaysFilterPanel.module.less';

type WorkingDaysFilterPanelProps = {
  onSubmit?: () => void;
};

const WorkingDaysFilterPanel: FC<WorkingDaysFilterPanelProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [state, dispatch] = useContext(ProfilesViewSettingsContext);
  const [filter, setFilter] = useState<string[] | undefined>(
    state?.atsTagFilters?.adventure?.workslot?.tagIds,
  );
  const workingDays = useMemo(
    () => ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    [],
  );

  const periods: { value: string; label: string }[] = useMemo(
    () => [
      {
        value: 'morning',
        label: t(
          'reveal.searchView.filters.ats.adventure.workingHours.morning',
        ),
      },
      {
        value: 'afternoon',
        label: t(
          'reveal.searchView.filters.ats.adventure.workingHours.afternoon',
        ),
      },
      {
        value: 'day',
        label: t('reveal.searchView.filters.ats.adventure.workingHours.day'),
      },
      {
        value: 'night',
        label: t('reveal.searchView.filters.ats.adventure.workingHours.night'),
      },
    ],
    [t],
  );

  const days = useMemo(
    () => [
      {
        value: 'workslot__monday',
        label: t('reveal.searchView.filters.ats.adventure.workingHours.monday'),
      },
      {
        value: 'workslot__tuesday',
        label: t(
          'reveal.searchView.filters.ats.adventure.workingHours.tuesday',
        ),
      },
      {
        value: 'workslot__wednesday',
        label: t(
          'reveal.searchView.filters.ats.adventure.workingHours.wednesday',
        ),
      },
      {
        value: 'workslot__thursday',
        label: t(
          'reveal.searchView.filters.ats.adventure.workingHours.thursday',
        ),
      },
      {
        value: 'workslot__friday',
        label: t('reveal.searchView.filters.ats.adventure.workingHours.friday'),
      },
      {
        value: 'workslot__saturday',
        label: t(
          'reveal.searchView.filters.ats.adventure.workingHours.saturday',
        ),
      },
      {
        value: 'workslot__sunday',
        label: t('reveal.searchView.filters.ats.adventure.workingHours.sunday'),
      },
    ],
    [t],
  );

  const onDayToggle = useCallback(
    (dayAndperiod: string) => {
      const filterIndex = filter?.indexOf(dayAndperiod) ?? -1;
      if (filterIndex === -1) {
        setFilter([...(filter || []), dayAndperiod]);
      } else {
        setFilter([
          ...(filter || []).slice(0, filterIndex),
          ...(filter || []).slice(filterIndex + 1),
        ]);
      }
    },
    [filter],
  );

  const isPeriodSelected = useCallback(
    (period: string) => {
      const periodDays = _.map(
        workingDays,
        (workingDay) => `workslot__${workingDay}-${period}`,
      );
      const offDays = [
        `workslot__saturday-${period}`,
        `workslot__sunday-${period}`,
      ];
      const daysIntersection = _.intersection(filter || [], periodDays);
      const offdaysIntersection = _.intersection(filter || [], offDays);
      return (
        daysIntersection.length === workingDays.length &&
        _.isEmpty(offdaysIntersection)
      );
    },
    [filter, workingDays],
  );

  const onPeriodToggle = useCallback(
    (period: string) => {
      const periodDays = _.map(
        workingDays,
        (workingDay) => `workslot__${workingDay}-${period}`,
      );
      const filteredPeriod = _.filter(
        filter || [],
        (filterOption) => !periodDays.includes(filterOption),
      );
      if (isPeriodSelected(period)) {
        setFilter(filteredPeriod);
      } else {
        setFilter([...filteredPeriod, ...periodDays]);
      }
    },
    [workingDays, filter, isPeriodSelected],
  );

  const selectedPeriods = useMemo(() => {
    const periodValues = _.pluck(periods, 'value');
    return _.filter(periodValues, (periodValue) =>
      isPeriodSelected(periodValue),
    );
  }, [isPeriodSelected, periods]);

  return (
    <div className={styles.panel}>
      <table className={styles.table}>
        <tbody>
          <tr className={styles.hourRow}>
            <td />
            {_.map(days, (day, index) => (
              <td key={index}>{day.label}</td>
            ))}
          </tr>
          {_.map(periods, (period, index) => (
            <tr key={index} className={styles.hourRow}>
              <td className={styles.hourRange}>
                {period.label}{' '}
                <input
                  type='checkbox'
                  checked={selectedPeriods.includes(period.value)}
                  onChange={() => onPeriodToggle(period.value)}
                />
              </td>
              {_.map(days, (day) => (
                <td key={day.value}>
                  <div className={styles.dayCell}>
                    <input
                      type='checkbox'
                      checked={filter?.includes(`${day.value}-${period.value}`)}
                      onClick={() =>
                        onDayToggle(`${day.value}-${period.value}`)
                      }
                    />
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <GenericButton
        onClick={() => {
          dispatch({
            type: 'setAtsTagFilter',
            ats: 'adventure',
            tagType: 'workslot',
            value: {
              tagIds: filter || [],
              operator: 'and',
            },
          });
          if (onSubmit) {
            onSubmit();
          }
        }}
        disabled={filter === undefined}
        className={styles.button}
        size='big'
      >
        {t('reveal.missions.mission.profiles.filter.apply')}
      </GenericButton>
    </div>
  );
};

export default WorkingDaysFilterPanel;
