import React, { FC, useState, useMemo, useContext } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';

import ButtonDropdown from '@/components/Common/ButtonDropdown';
import DropdownPanel from '@/components/Common/DropdownPanel';
import { typesafeMap } from '@/common/utils/types';
import useClientId from '@/hooks/router/useClientId';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import Counter from '@/components/Common/Counter/Counter';

import ProfilesViewSettingsContext from '../../ProfilesViewSettingsContext';
import { getActiveFilterCount } from '../../ProfilesViewSettingsContext/helpers';
import { sectionDefinitions, FilterId } from './helpers/filters';

import FilterTypeMenu from './FilterTypeMenu';
import FilterTitle from './FilterTitle';

import styles from './ProfilesViewFilterDropdown.module.less';

const ProfilesViewFilterDropdown: FC = () => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { data } = useAtsFilterOptions(clientId);
  const [section, setSection] = useState<FilterId | undefined>(undefined);
  const [state] = useContext(ProfilesViewSettingsContext);

  const activeFilterCount = useMemo(() => getActiveFilterCount(state), [state]);

  const menuOptions = useMemo(
    () =>
      _.compact(
        typesafeMap(
          sectionDefinitions,
          ({ i18nKey, isAvailable, isActive }, id) =>
            isAvailable({ client: data?.client })
              ? { id, label: t(i18nKey), isActive }
              : null,
        ),
      ),
    [t, data?.client],
  );

  const panelContent = useMemo(() => {
    if (!section) {
      return <FilterTypeMenu options={menuOptions} onSelect={setSection} />;
    }

    const { i18nKey, Component } = sectionDefinitions[section];

    return (
      <>
        <FilterTitle onPrevious={() => setSection(undefined)}>
          {t(i18nKey)}
        </FilterTitle>
        <Component onSubmit={() => setSection(undefined)} />
      </>
    );
  }, [section, menuOptions, t]);

  return (
    <ButtonDropdown
      primacy='tertiary'
      title={
        <>
          {t('reveal.missions.mission.profiles.filter.button')}
          {activeFilterCount !== 0 && (
            <Counter active value={activeFilterCount} />
          )}
        </>
      }
      position='right'
    >
      <DropdownPanel className={styles.profilesViewFilterDropdown}>
        {panelContent}
      </DropdownPanel>
    </ButtonDropdown>
  );
};

export default ProfilesViewFilterDropdown;
