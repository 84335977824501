import {
  PrimaryCobalt,
  PrimaryCobalt40,
  PrimaryCobalt60,
  PrimaryCobaltOp06,
  PrimaryCobaltOp15,
  SecondaryBlue,
  SecondaryBlue05,
  SecondaryBlue120,
  SecondaryBlue20,
  SecondarySkyBlue10,
  SecondarySkyBlue20,
  SecondarySkyBlue40,
} from '@/less/colors';
import { CSSObjectWithLabel } from 'react-select';

const styles = {
  control: (
    base: CSSObjectWithLabel,
    { isDisabled }: { isDisabled: boolean },
  ) => ({
    ...base,
    padding: '0 10px',
    border: `1px solid ${SecondaryBlue20}`,
    ':hover': {
      border: `1px solid ${SecondarySkyBlue40}`,
      boxShadow: `0px 2px 4px 0px ${PrimaryCobaltOp06}, inset 0px 0px 0px 1px ${SecondarySkyBlue40}`,
    },
    borderRadius: '8px',
    boxShadow: isDisabled ? 'none' : `0px 2px 4px 0px ${PrimaryCobaltOp06}`,
    backgroundColor: isDisabled ? SecondaryBlue05 : 'white',
  }),
  input: (base: CSSObjectWithLabel) => ({ ...base, color: PrimaryCobalt }),
  singleValue: (
    base: CSSObjectWithLabel,
    { isDisabled }: { isDisabled: boolean },
  ) => ({
    ...base,
    color: isDisabled ? PrimaryCobalt40 : PrimaryCobalt,
  }),
  option: (
    base: CSSObjectWithLabel,
    { isSelected, isFocused }: { isSelected: boolean; isFocused: boolean },
  ) => ({
    ...base,
    color: PrimaryCobalt,
    // eslint-disable-next-line
    backgroundColor: isSelected
      ? SecondarySkyBlue20
      : isFocused
      ? SecondarySkyBlue10
      : 'white',
  }),
  dropdownIndicator: (
    base: CSSObjectWithLabel,
    { isFocused }: { isFocused: boolean },
  ) => ({
    ...base,
    padding: 0,
    color: isFocused ? PrimaryCobalt60 : PrimaryCobalt40,
    ':hover': {
      color: SecondaryBlue,
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: PrimaryCobalt40,
  }),
  multiValue: (base: CSSObjectWithLabel) => ({
    ...base,
    backgroundColor: SecondarySkyBlue20,
    height: 24,
    borderRadius: 20,
    padding: '4px 8px',
    alignItems: 'center',
    gap: 4,
  }),
  multiValueLabel: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: 0,
    color: SecondaryBlue,
    fontSize: 12,
    fontWeight: 500,
  }),
  multiValueRemove: (base: CSSObjectWithLabel) => ({
    ...base,
    color: SecondaryBlue,
    padding: 0,
    ':hover': {
      backgroundColor: 'transparent',
      color: SecondaryBlue120,
    },
    cursor: 'pointer',
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: '8px 0',
    border: `2px solid ${SecondarySkyBlue40}`,
    'border-radius': 8,
    'background-color': 'white',
    'box-shadow': `0px 2px 16px 0px ${PrimaryCobaltOp15}`,
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

export default styles;
