import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { useLocation, useHistory } from 'react-router-dom';

import Segmentations from '@/components/PipelineSegmentation/data';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useClientId from '@/hooks/router/useClientId';
import useUpdateProfilesPipelineStage from '@/graphql/hooks/searchPoolJob/useUpdateProfilesMissionPipelineStage';
import useSearchPoolJobOptions from '@/graphql/hooks/searchPoolJobs/useSearchPoolJobOptions';
import {
  ArchivedState,
  PipelineState,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import ProfileIdHandler from '@/common/ProfileIdHandler';
import EnrichedRevealProfileModal from '@/revealComponents/EnrichedRevealProfileModal';
import ArchiveReasonsModal from '@/components/modals/ArchivedReasons/ArchiveReasonsModal';
import CandidateReplyModal from '@/components/modals/CandidateReplyModal/CandidateReplyModal';
import { DragContextType } from '../MissionProfilesGenericView/GenericSegmentationView/GenericSegmentationViewContext';
import { SegmentDefinition } from '../MissionProfilesGenericView/types';
import { GenericSegmentationItem } from '../MissionProfilesGenericView/GenericKanban/GenericKanbanColumn';
import GenericSegmentationView from '../MissionProfilesGenericView/GenericSegmentationView/GenericSegmentationView';
import KanbanProfileCard from '../MissionProfilesGenericView/GenericKanban/GenericKanbanColumn/GenericKanbanCard/KanbanProfileCard/index';
import ProfileKanbanContainer from '../WidgetPlayground/ProfileKanbanContainer';

import styles from '../WidgetPlayground/WidgetPlayground.module.less';
import { SEGMENT_COLORS } from '../MissionProfilesGenericView';
import ProjectTreeDropdown from './ProjectTreeDropdown';
import ProjectTreeDropdownProvider from './ProjectTreeDropdownContext';

export const getRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

const Overview = () => {
  const [selectedItemIds, setSelectedItemIds] = useState([]);

  const defaultQueryOptions = useMemo(
    () => ({
      variables: {
        searchPoolId: 'reveal',
        input: {
          missionsFilter: { in: selectedItemIds },
        },
      },
      fetchPolicy: 'cache-first',
    }),
    [selectedItemIds],
  );

  return (
    <ProjectTreeDropdownProvider onSelectedItemsChange={setSelectedItemIds}>
      <ProfileKanbanContainer queryOptions={defaultQueryOptions}>
        <CrossProjectProfilesView />
      </ProfileKanbanContainer>
    </ProjectTreeDropdownProvider>
  );
};

const CrossProjectProfilesView: React.FC = () => {
  const segmentation = Segmentations[0];
  const { t } = useTranslation();
  const [segmentItemIds, setSegmentItemIds] = useState<string[]>([]);
  const [columnItems, setColumnItems] = useState<GenericSegmentationItem[]>([]);
  const [segmentToMoveProfileTo, setSegmentToMoveProfileTo] = useState('');
  const [segmentToMoveProfileFrom, setSegmentToMoveProfileFrom] = useState('');
  const [showMoveToArchivedModal, setShowMoveToArchivedModal] = useState(false);
  const [showMoveToRepliedModal, setShowMoveToRepliedModal] = useState(false);
  const [reply, setReply] = useState('');
  const [archivedEngagement, setArchivedEngagement] = useState('');
  const [archivedReason, setArchivedReason] = useState('');
  const [lastDraggedItem, setLastDraggedItem] = useState<
    GenericSegmentationItem
  >({ id: '' });
  const [clientArchiveReasonId, setClientArchiveReasonId] = useState('');
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const profileIdFromURL = queryParams.get('item');
  const [selectedItem, setSelectedItem] = useState<GenericSegmentationItem>({
    id: profileIdFromURL || '',
  });
  const notifications = useNotificationSystem();
  const history = useHistory();
  const clientId = useClientId();
  const [updateProfilesPipelineStage] = useUpdateProfilesPipelineStage();
  const { jobOptions, jobOptionsLoading } = useSearchPoolJobOptions('reveal');

  const segmentDefinitionWithItems: SegmentDefinition[] = useMemo(() => {
    return _.map(
      segmentation.segments,
      ({ id, name, type, translationKey }) => ({
        segmentId: id,
        ...(type && { type }),
        ...(name && { title: name }),
        translationKey,
        color: SEGMENT_COLORS[id],
      }),
    );
  }, [segmentation]);

  const updateProfiles = useCallback(
    async ({
      newSegment,
      items,
      labels,
      selectedClientArchiveReasonId,
      jobId,
    }: // prevSegment,
    {
      newSegment: string;
      items: GenericSegmentationItem[];
      labels?: string[];
      selectedClientArchiveReasonId?: string;
      jobId: string;
      prevSegment: string;
    }) => {
      try {
        await updateProfilesPipelineStage({
          missionId: jobId,
          segmentationId: segmentation.id,
          profileIds: _.pluck(items || [], 'id'),
          stage: newSegment || '',
          labels,
          clientArchiveReasonId: selectedClientArchiveReasonId,
        });
        notifications.success(
          t('reveal.pipelineSegmentations.profilesStageUpdated'),
        );
        return;
      } catch (e) {
        console.error(e);
        notifications.error(t('Error occurred'));
      }
    },
    [
      updateProfilesPipelineStage,
      t,
      segmentation,
      notifications,
      // updateCacheAfterMutation,
    ],
  );
  const onChangeSegment = useCallback(
    ({ prevSegment, newSegment, items, item }: DragContextType) => {
      if (prevSegment === newSegment) {
        return;
      }
      setSegmentToMoveProfileTo(newSegment);
      setSegmentToMoveProfileFrom(prevSegment);
      setColumnItems(items);
      setLastDraggedItem(item);
      if (newSegment === 'archived') {
        setShowMoveToArchivedModal(true);
      } else if (newSegment === 'replied') {
        setShowMoveToRepliedModal(true);
      } else {
        updateProfiles({
          newSegment,
          items,
          jobId: item.categoryId,
          prevSegment,
        });
      }
    },
    [updateProfiles],
  );

  const allowDrop = () => {
    return true;
  };

  const confirmMove = async ({ moveTo }: { moveTo?: string } = {}) => {
    let labels;

    // The callback can force a new target segment in order
    // to allow the user/modals to change the target if necessary
    // ex: moving to replied + positive => interested
    const newSegment = moveTo || segmentToMoveProfileTo;

    if (newSegment === PipelineState.REPLIED) {
      labels = [reply === 'neutral' ? 'medium' : reply];
    }
    if (newSegment === ArchivedState.ARCHIVED) {
      labels = [archivedEngagement, archivedReason];
    }
    updateProfiles({
      newSegment,
      items: columnItems,
      labels,
      jobId: lastDraggedItem.categoryId,
      selectedClientArchiveReasonId: clientArchiveReasonId,
      prevSegment: segmentToMoveProfileFrom,
    });
    setReply('');
    resetArchivedModalData();
  };

  const resetArchivedModalData = () => {
    setArchivedEngagement('');
    setArchivedReason('');
    setClientArchiveReasonId('');
  };
  const profileIdHandler = ProfileIdHandler({
    profileIds: segmentItemIds,
    selectedProfileId: selectedItem.id,
    onSelectProfileId: (itemId: string) => {
      setSelectedItem({ id: itemId });
      history.replace({ pathname: 'overview', search: `?item=${itemId}` });
    },
  });

  return (
    <div
      style={{
        padding: '24px',
        height: 'calc(100% - 56px)',
      }}
    >
      <div className={styles.filtersContainer}>
        <h2>{t('reveal.overview.title')}</h2>
        <div className={styles.selectedProjects}>
          <ProjectTreeDropdown
            style={{
              minWidth: '350px',
            }}
          />
        </div>
      </div>

      <GenericSegmentationView
        displayType='kanban'
        selectedItem={selectedItem}
        allowDrop={allowDrop}
        onItemSelected={({ item, segmentItems }) => {
          setSelectedItem(item);
          setSegmentItemIds(segmentItems);
        }}
        segmentItemIds={segmentItemIds}
        onSegmentItemIds={(value) => {
          setSegmentItemIds(value);
        }}
        onChangeSegment={onChangeSegment}
        segmentDefinitions={jobOptionsLoading ? [] : segmentDefinitionWithItems}
        disableDragAndDrop={false}
        renderItem={(item: GenericSegmentationItem, itemLoading: boolean) => {
          return (
            <KanbanProfileCard
              profileId={item.id}
              itemLoading={itemLoading}
              pageName='overview'
              missionTitle={
                _.findWhere(jobOptions, { id: item.categoryId })?.data?.title
              }
            />
          );
        }}
        dataLoading={false}
      />
      <EnrichedRevealProfileModal
        clientId={clientId}
        searches={undefined}
        open={!_.isEmpty(selectedItem?.id)}
        onClose={() => {
          history.replace('?', '');
          setSelectedItem({ id: '' });
        }}
        profileIdHandler={profileIdHandler}
        // onChangeStage={updateCacheAfterMutation}
      />
      {showMoveToArchivedModal && (
        <ArchiveReasonsModal
          confirm={confirmMove}
          archivedEngagement={archivedEngagement}
          setArchivedEngagement={setArchivedEngagement}
          archivedReason={archivedReason}
          setArchivedReason={setArchivedReason}
          clientArchiveReasonId={clientArchiveReasonId}
          setClientArchiveReasonId={setClientArchiveReasonId}
          clientId={clientId}
          profiles={columnItems}
          onCancel={resetArchivedModalData}
          onClose={() => setShowMoveToArchivedModal(false)}
        />
      )}
      {showMoveToRepliedModal && (
        <CandidateReplyModal
          confirm={confirmMove}
          reply={reply}
          onReply={setReply}
          onForceStage={(stage) => {
            confirmMove({
              moveTo: stage,
            });
          }}
          candidatesLength={columnItems?.length}
          onCancel={() => setReply('')}
          onClose={() => setShowMoveToRepliedModal(false)}
        />
      )}
    </div>
  );
};

export default Overview;
