import _ from 'underscore';
import {
  invalidEmailFormat,
  isValidBehanceProfileUrl,
  isValidDribbbleProfileUrl,
  isValidGithubProfileUrl,
  isValidLinkedinProfileUrl,
  isValidStackoverflowProfileUrl,
  isValidTwitterProfileUrl,
} from '@/common/validators';
import {
  CustomFieldDefinition,
  CustomFieldDefinitionEnum,
} from '@/graphql/hooks/clients/useClientProfileCustomFields';
import {
  createCustomFieldsPayload,
  CustomFieldRawValue,
} from '@/common/customFields';
import {
  getFloatValue,
  getIntegerValue,
  getDateValue,
  getDateFormat,
} from './analysis.helpers';

export type User = {
  email: string;
  firstname: string;
  lastname: string;
};
export type CSVLine = string[];
export type CSVLines = CSVLine[];
export type ColumnFieldMapping = {
  type: 'classic-field';
  id: string;
  defaultValue?: string | string[] | number;
};

export type ColumnCustomFieldMapping = {
  type: 'custom-field';
  id: string;
  defaultValue?: string | string[] | number;
};

export type ColumnMapping = ColumnFieldMapping | ColumnCustomFieldMapping;
export type ColumnsMapping = (ColumnMapping | null)[];
type CSVFormatGuess = {
  firstLineIsHeaderGuess: boolean;
  columnsMappingGuess: ColumnsMapping;
  duplicateColumnIndexes: number[];
};

type ProfileRowErrorReason = {
  text: string;
  value?: string;
};
export type ProfileRowError = { row: number; reasons: ProfileRowErrorReason[] };

export function getDefaultValue(
  df: string | string[] | number | null | undefined,
): null | string | string[] | number {
  if (!Array.isArray(df)) return df || null;
  if (df.length > 0) return df;
  return null;
}

type FormattedProfile = ReturnType<typeof formatProfileRow>;

/**
 * Map CSV rows to proper basic profile type
 */
export const formatProfiles = (
  dataFromCSV: CSVLines,
  {
    columnsMapping,
    headers,
    customFields,
  }: {
    columnsMapping: ColumnsMapping;
    headers: CSVLine | null;
    customFields: CustomFieldDefinition[];
  },
): { profiles: FormattedProfile[]; errors: ProfileRowError[] } => {
  const errors: ProfileRowError[] = [];
  const validRows = [];

  const columns = columnsMapping.map((__, index) => {
    return dataFromCSV.map((x) => x[index]);
  });

  for (let i = 0; i < dataFromCSV.length; i += 1) {
    const { profileRow, rawValues, customFieldsValues } = mapRowToProfile(
      dataFromCSV[i],
      {
        columns,
        columnsMapping,
        headers,
        customFieldsDefinitions: customFields,
      },
    );
    const { isValid, reasons } = checkProfileRow(profileRow);
    if (!isValid) {
      errors.push({ row: i, reasons });
    } else {
      validRows.push({
        ...profileRow,
        rawValues,
        customFields: customFieldsValues,
      });
    }
  }

  const profiles = _.map(validRows, (profile) =>
    formatProfileRow(profile, { customFieldsDefinitions: customFields }),
  );
  return { profiles, errors };
};

function mapRowToProfile(
  row: CSVLine,
  {
    columns,
    columnsMapping,
    headers,
    customFieldsDefinitions,
  }: {
    columns: CSVLine[];
    columnsMapping: ColumnsMapping;
    headers: CSVLine | null;
    customFieldsDefinitions: CustomFieldDefinition[];
  },
) {
  const profileRow: Record<string, string> = {};
  const rawValues = [];
  const customFieldsValues: Record<string, CustomFieldRawValue> = {};
  for (
    let columnIndex = 0;
    columnIndex < columnsMapping.length;
    columnIndex += 1
  ) {
    const column = columnsMapping[columnIndex];
    if (profileRow && row[columnIndex] && column) {
      if (column.id === 'fullname') {
        const [firstname, ...lastname] = row[columnIndex].split(' ');
        profileRow.firstname = profileRow.firstname ?? firstname;
        profileRow.lastname = profileRow.lastname ?? lastname.join(' ');
      } else if (column.id === 'linkedin') {
        profileRow[column.id] = normalizeLinkedInUrl(row[columnIndex]);
      } else {
        profileRow[column.id] = row[columnIndex] ?? column.defaultValue;
      }
    }
    const header = headers?.length ? headers[columnIndex] : columnIndex;
    rawValues.push({
      key: header,
      value: row[columnIndex],
      mapping: column,
    });
    if (column?.type === 'custom-field') {
      const value = row[columnIndex];
      const customField = customFieldsDefinitions.find(
        (cf) => cf.id === column.id,
      );
      if (customField) {
        const parsedValue = getCustomFieldValue(value, {
          customField,
          column: columns[columnIndex],
          mapping: column,
        });
        if (parsedValue) {
          customFieldsValues[column.id] = parsedValue;
        }
      }
    }
  }
  return { profileRow, rawValues, customFieldsValues };
}

function normalizeLinkedInUrl(url: string): string {
  if (!url) return url;
  if (url.startsWith('http:')) {
    return url.replace('http:', 'https:');
  }
  if (url.startsWith('https:')) {
    return url;
  }
  return `https://${url}`;
}

function getCustomFieldValue(
  value: string | string[],
  {
    customField,
    column,
    mapping,
  }: {
    customField: CustomFieldDefinition;
    column: CSVLine;
    mapping: ColumnCustomFieldMapping;
  },
): number | string | string[] | null {
  const defaultValue = getDefaultValue(mapping.defaultValue);
  if (customField.type === 'enum') {
    return value ? getFieldValueForEnum(value, customField) : defaultValue;
  }
  if (customField.type === 'float') {
    return (
      getFloatValue(Array.isArray(value) ? value[0] : value) || defaultValue
    );
  }
  if (customField.type === 'integer') {
    return (
      getIntegerValue(Array.isArray(value) ? value[0] : value) || defaultValue
    );
  }
  if (customField.type === 'day') {
    const dateFormat = getDateFormat(column);
    return (
      getDateValue(Array.isArray(value) ? value[0] : value, dateFormat) ||
      defaultValue
    );
  }
  return value || defaultValue;
}

function getFieldValueForEnum(
  value: string | string[],
  customField: CustomFieldDefinitionEnum,
): string[] {
  const values = !Array.isArray(value) ? [value] : value;
  const options = _.compact(
    values.map((v) => customField.options.find((o) => o.title.default === v)),
  );

  return _.compact(options).map((o) => o.id);
}

export type ProfileRow = Partial<{
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  linkedin: string;
  github: string;
  behance: string;
  dribbble: string;
  twitter: string;
  stackoverflow: string;
  website: string;
  headline: string;
  company: string;
  position: string;
  photoLink: string;
  location: string;
  rawValues: {
    key: string | number;
    value: string;
    mapping?: ColumnMapping | null;
  }[];
  customFields: Record<string, CustomFieldRawValue>;
}>;

/**
 * Map the CSV row to a profile type
 */
const formatProfileRow = (
  row: ProfileRow | null,
  {
    customFieldsDefinitions,
  }: { customFieldsDefinitions: CustomFieldDefinition[] },
) => {
  const {
    firstname,
    lastname,
    email,
    phoneNumber,
    linkedin,
    github,
    stackoverflow,
    twitter,
    dribbble,
    behance,
    website,
    headline,
    company,
    position,
    photoLink,
    location,
    rawValues,
    customFields,
  } = row || {};

  return {
    rawValues,
    data: {
      firstname,
      lastname,
      email,
      phoneNumber,
      sources: {
        linkedin,
        github,
        stackoverflow,
        twitter,
        dribbble,
        behance,
        website,
      },
      headline: {
        content: {
          text: headline,
        },
      },
      company,
      position,
      photoLink,
      location: {
        name: {
          default: location || '',
        },
      },
      ...(!_.isEmpty(customFields) && {
        customFields: createCustomFieldsPayload(
          customFields,
          customFieldsDefinitions,
        ),
      }),
    },
  };
};

const checkProfileRow = (profile: ProfileRow) => {
  let profileIsValid = true;
  const reasons = [];
  if (!profile.firstname) {
    profileIsValid = false;
    reasons.push({ text: 'missingFirstname' });
  }

  const mandatoryFieldsArePresent =
    (profile.firstname && profile.lastname) ||
    (profile.firstname && profile.email) ||
    profile.linkedin;
  if (!mandatoryFieldsArePresent) {
    profileIsValid = false;
    reasons.push({ text: 'missingMandatoryFields' });
  }

  if (profile.email && invalidEmailFormat({ email: profile.email })) {
    profileIsValid = false;
    reasons.push({ text: 'invalidEmail', value: profile.email });
  }

  if (
    profile.linkedin &&
    !isValidLinkedinProfileUrl({ linkedin: profile.linkedin })
  ) {
    profileIsValid = false;
    reasons.push({ text: 'invalidLinkedin', value: profile.linkedin });
  }

  if (profile.github && !isValidGithubProfileUrl({ github: profile.github })) {
    profileIsValid = false;
    reasons.push({ text: 'invalidGithub', value: profile.github });
  }

  if (
    profile.behance &&
    !isValidBehanceProfileUrl({ behance: profile.behance })
  ) {
    profileIsValid = false;
    reasons.push({ text: 'invalidBehance', value: profile.behance });
  }

  if (
    profile.dribbble &&
    !isValidDribbbleProfileUrl({ dribbble: profile.dribbble })
  ) {
    profileIsValid = false;
    reasons.push({ text: 'invalidDribbble', value: profile.dribbble });
  }

  if (
    profile.twitter &&
    !isValidTwitterProfileUrl({ twitter: profile.twitter })
  ) {
    profileIsValid = false;
    reasons.push({ text: 'invalidTwitter', value: profile.twitter });
  }

  if (
    profile.stackoverflow &&
    !isValidStackoverflowProfileUrl({ stackoverflow: profile.stackoverflow })
  ) {
    profileIsValid = false;
    reasons.push({
      text: 'invalidStackoverflow',
      value: profile.stackoverflow,
    });
  }

  return { isValid: profileIsValid, reasons };
};

/**
 * Guess the columns mapping from the first row of the CSV file
 */
export const guessColumnsFromFirstLine = (
  firstLine: CSVLine,
): CSVFormatGuess => {
  const regexList = [
    { value: 'firstname', regex: /(first[- ]*name)|(pr(e|é)nom)/i },
    { value: 'lastname', regex: /(last[- ]*name)|(sur[- ]*name)/i },
    { value: 'fullname', regex: /full[- ]*name/i },
    { value: 'email', regex: /e[- ]*mail/i },
    { value: 'phoneNumber', regex: /phone/i },
    { value: 'linkedin', regex: /linkedin/i },
    { value: 'linkedin', regex: /^profile ?url/i },
    { value: 'github', regex: /github/i },
    { value: 'stackoverflow', regex: /stackoverflow/i },
    { value: 'behance', regex: /behance/i },
    { value: 'twitter', regex: /twitter/i },
    { value: 'dribbble', regex: /dribbble/i },
    { value: 'website', regex: /[web]*site/i },
    { value: 'company', regex: /company|entreprise/i },
    { value: 'position', regex: /position|poste|job/i },
    { value: 'headline', regex: /headline|titre/i },
    { value: 'photoLink', regex: /image|picture|avatar|photolink/i },
    { value: 'location', regex: /location|localisation/i },
  ];
  const columnsMappingGuess: ColumnsMapping = [];
  const columnsNames: (string | null)[] = [];
  const duplicateColumnIndexes: number[] = [];

  for (let index = 0; index < firstLine.length; index += 1) {
    const cell = firstLine[index];
    const value = testAllRegex(
      cell,
      _.filter(
        regexList,
        (regex) => !_.contains(columnsMappingGuess, regex.value),
      ),
    );
    const mapping: ColumnFieldMapping | null = value
      ? { type: 'classic-field', id: value }
      : null;
    if (_.contains(columnsNames, value)) {
      duplicateColumnIndexes.push(index);
      columnsMappingGuess.push(null);
    } else {
      columnsNames.push(value);
      columnsMappingGuess.push(mapping);
    }
  }

  const isClassicField = (fieldName: string, mapping: ColumnMapping | null) => {
    return mapping?.type === 'classic-field' && mapping.id === fieldName;
  };
  const hasClassicField = (fieldName: string) => {
    return _.some(columnsMappingGuess, (mapping) =>
      isClassicField(fieldName, mapping),
    );
  };
  const hasFirstAndLastName =
    hasClassicField('firstname') && hasClassicField('lastname');
  const columnsMapping = columnsMappingGuess.map((mapping) => {
    if (!mapping) return mapping;
    if (hasFirstAndLastName && mapping.id === 'fullname') return null;
    return mapping;
  });
  if (
    _.filter(
      columnsMappingGuess,
      (value) =>
        isClassicField('fullname', value) ||
        isClassicField('firstname', value) ||
        isClassicField('lastname', value) ||
        isClassicField('email', value),
    ).length >= 2
  ) {
    return {
      firstLineIsHeaderGuess: true,
      columnsMappingGuess: columnsMapping,
      duplicateColumnIndexes,
    };
  }
  return {
    firstLineIsHeaderGuess: false,
    columnsMappingGuess: columnsMapping,
    duplicateColumnIndexes,
  };
};

const tranformShortLinkedInUrl = (row: any) => {
  const transformedRow = _.map(row, (rowColumn) => {
    if (/(\w+)\.linkedin.com\/in/g.test(rowColumn)) {
      return `https://www.${rowColumn.slice(
        rowColumn.indexOf('linkedin.com/in'),
      )}`;
    }
    return rowColumn;
  });
  return transformedRow;
};

const testAllRegex = (
  text: string,
  regexList: { regex: RegExp; value: string }[],
) => {
  for (let index = 0; index < regexList.length; index += 1) {
    const regex = regexList[index];
    if (regex.regex.test(text)) {
      return regex.value;
    }
  }
  return null;
};

type FilteredCSVLines = {
  /**
   * The lines that are invalid
   */
  excludedResults: CSVLines;
  /**
   * The lines that are valid
   */
  filteredResults: CSVLines;
};
/**
 * Filter CSV data between empty rows and valid rows
 */
export const filterCSVData = (baseRows: CSVLines): FilteredCSVLines => {
  const nbRowsByLength: Record<string, number> = {};
  // Trim empty cells
  const rows = _.map(_.map(baseRows, trimRow), tranformShortLinkedInUrl);

  _.each(rows, (row) => {
    if (nbRowsByLength[row.length.toString(10)]) {
      nbRowsByLength[row.length.toString(10)] += 1;
    } else {
      nbRowsByLength[row.length.toString(10)] = 1;
    }
  });

  let firstRowIndex = -1;
  _.each(rows.slice(0, 20), (row, index) => {
    const isHeaderGuess = guessColumnsFromFirstLine(row);
    if (isHeaderGuess?.firstLineIsHeaderGuess && firstRowIndex === -1) {
      firstRowIndex = index;
    }
  });

  const excludedRows = [];
  if (firstRowIndex >= 0) {
    for (let rowIndex = 0; rowIndex < firstRowIndex; rowIndex++) {
      excludedRows.push(rows[rowIndex]);
    }
  } else {
    // Find the most common length
    const lengthsByRow = _.map(nbRowsByLength, (count, length) => ({
      length,
      count,
    }));
    const max = _.max(lengthsByRow, 'count') as typeof lengthsByRow[0];
    const rowSize = parseInt(max.length, 10);
    for (
      let rowIndex = 0;
      rowIndex <= 5 && rows[rowIndex]?.length < rowSize / 2;
      rowIndex++
    ) {
      excludedRows.push(rows[rowIndex]);
    }
  }

  return {
    filteredResults:
      excludedRows.length >= 2
        ? rows.splice(excludedRows.length, rows.length)
        : rows,
    excludedResults: excludedRows,
  };
};

function trimRow(row: CSVLine): CSVLine {
  const lastNonEmptyCellIndex = _.findLastIndex(row, (cell) => cell !== '');
  return row.slice(0, lastNonEmptyCellIndex + 1);
}
