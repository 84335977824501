import _ from 'underscore';
import React, { useState } from 'react';
import { Label } from 'semantic-ui-react';
import { useTranslation, withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useMutation, useQuery } from '@apollo/client';
import { getTranslatedText } from '@/common';
import { ATStype, ATS_NAMES_BY_TYPE } from '@/common/reveal';
import { getATSDataItems } from '@/common/reveal/SourceData';
import { getCandidateAtsURL } from '@/common/reveal/candidateAtsURL';
import {
  // CustomFieldValue,
  combineDataWithCustomFields,
} from '@/common/customFields';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import TranslatableText from '@/components/TranslatableText';
import { useClientRevealConnector } from '@/graphql/hooks/clients/useClientRevealProjects';
import { Source } from '@/types/source';
import { INTERACTION_TYPES } from '@/common/constants/taskTypes';
import { getFullname } from '@/common/helpers/person';
import { AtsData } from '@/common/reveal/SourceData.type';
import {
  MARK_AS_DO_NOT_CONTACT,
  UNMARK_AS_DO_NOT_CONTACT,
} from '@/graphql/searchPoolProfile';
import {
  DoNotContactInput,
  SearchPoolProfile,
} from '@/types/searchPoolProfile';
import DoNotContactPill from '@/components/Reveal/Profile/privacy/DoNotContactPill/DoNotContactPill';
import OkToContactPill from '@/components/Reveal/Profile/privacy/OkToContactPill/OkToContactPill';

import './ProfileDetails.css';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import GenericButton from '@/components/Common/GenericButton';
import { AdventureData } from '@/containers/Profile/Resume/Card/ATSData/Adventure';
import useClientId from '@/hooks/router/useClientId';
import {
  DoNotContactReason,
  GET_DO_NOT_CONTACT_REASONS,
} from '@/graphql/doNotContactReasons';
import classnames from 'classnames';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { sanitizeTypename } from '@/common/utils/apollo';

interface ProfileDetailsProps {
  customFields: CustomFieldDefinition[];
  profileId: string;
  isPlugin?: boolean;
  isWatchProfile?: boolean;
  watchProfile?: any;
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({
  customFields,
  profileId,
  isPlugin = false,
  isWatchProfile,
  watchProfile,
}) => {
  const { profile: searchPoolProfile } = useCandidateViewContext();
  const profile: any = searchPoolProfile || watchProfile || {};

  const resumeData = profile?.resumeData;
  const creationTimestamp = profile?.creationTimestamp;
  const timeline = profile?.contactData?.timeline;
  const sourceData = resumeData?.sourceData || {};
  const rawValues = sourceData?.csvImported?.rawValues || [];
  const atsDataItems = getATSDataItems(sourceData);

  const { source, privacyState } = profile || {};

  return (
    <div className={`profile-details ${isPlugin ? 'plugin-mode' : ''}`}>
      <BasicDetails
        source={source}
        creationTimestamp={creationTimestamp}
        timeline={timeline}
        atsDataItems={atsDataItems}
        profile={profile}
      />
      <ProfileCustomFields
        customFields={customFields}
        resumeData={resumeData}
      />
      {!_.isEmpty(rawValues) && <ProfileRawValues rawValues={rawValues} />}
      {!isWatchProfile && (
        <PersonalData
          privacyState={privacyState}
          profileId={profileId}
          firstname={resumeData?.firstname}
        />
      )}
    </div>
  );
};

export const getSourceNameFromSource = (
  t: TFunction,
  atsDataItems: any[],
  source?: Source,
): string => {
  if (!source) {
    if (!_.isEmpty(atsDataItems)) {
      const atsType = atsDataItems[0].type as ATStype;
      return ATS_NAMES_BY_TYPE[atsType];
    }
    return '/';
  }
  switch (source.type) {
    case 'reveal-plugin-import':
      if (source?.sourceId === 'github') {
        return 'GitHub';
      }
      return 'LinkedIn';
    case 'reveal-app-import':
      return t('reveal.profileModal.detailsContent.manualImport');
    case 'reveal-csv-import':
      return `CSV ${source.importName || ''}`;
    default:
      return '/';
  }
};

interface BasicDetailsProps {
  source?: Source;
  creationTimestamp: string;
  timeline: any[];
  atsDataItems: any[];
}

const BasicDetails: React.FC<BasicDetailsProps> = ({
  source,
  creationTimestamp,
  timeline,
  atsDataItems,
}) => {
  const recruiter = source?.recruiter || atsDataItems?.[0]?.recruiter;
  const { t } = useTranslation();
  const lastModificationTimestamp = timeline[0]?.date || creationTimestamp;

  const interactions = _.filter(timeline, (timelineItem) =>
    _.contains(INTERACTION_TYPES, timelineItem.action.subtype),
  );
  const lastInteraction = interactions[0];

  return (
    <div className='details-section basic-details-section'>
      <h4>{t('reveal.profileModal.detailsContent.title')}</h4>
      <div className='container'>
        <div className='left-column column'>
          <div className='field'>
            <div className='field-name'>
              {t('reveal.profileModal.detailsContent.creationDate')}
            </div>
            <div className='field-value'>
              {t('common.shortDate', {
                date: new Date(+creationTimestamp),
              })}
            </div>
          </div>
          <div className='field'>
            <div className='field-name'>
              {t('reveal.profileModal.detailsContent.source')}
            </div>
            <div className='field-value'>
              {getSourceNameFromSource(t, atsDataItems, source)}
            </div>
          </div>
          <div className='field'>
            <div className='field-name'>
              {t('reveal.profileModal.detailsContent.importedBy')}
            </div>
            <div className='field-value'>
              {recruiter ? `${recruiter.firstname} ${recruiter.lastname}` : '/'}
            </div>
          </div>
        </div>
        <div className='right-column column'>
          <div className='field'>
            <div className='field-name'>
              {t('reveal.profileModal.detailsContent.lastUpdate')}
            </div>
            <div className='field-value'>
              {t('common.shortDate', {
                date: new Date(+lastModificationTimestamp),
              })}
            </div>
          </div>
          <div className='field'>
            <div className='field-name'>
              {t('reveal.profileModal.detailsContent.lastInteraction')}
            </div>
            <div className='field-value'>
              {lastInteraction
                ? t('common.shortDate', {
                    date: new Date(+lastInteraction.date),
                  })
                : '/'}
            </div>
          </div>
          <div className='field'>
            <div className='field-name'>
              {t('reveal.profileModal.detailsContent.lastContactedBy')}
            </div>
            <div className='field-value'>
              {lastInteraction?.author?.firstname
                ? `${lastInteraction.author.firstname} ${
                    lastInteraction.author.lastname || ''
                  }`.trim()
                : '/'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ProfileRawValuesProps {
  rawValues: {
    key: string;
    value: string;
    mapping: { id: string; type: string };
  }[];
}

const ProfileRawValues: React.FC<ProfileRawValuesProps> = ({ rawValues }) => {
  const { t } = useTranslation();
  return (
    <div className='details-section'>
      <h4>{t('reveal.profileModal.rawValues.title')}</h4>
      <div className='additional-details'>
        {_.map(rawValues, (rawValue) => (
          <div key={rawValue.key} className='additional-detail'>
            <span className='additional-detail-label'>{rawValue.key}</span>
            <span className='additional-detail-value'>{rawValue.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

interface PersonalDataProps {
  privacyState: { markedAsDoNotContact: boolean } | undefined;
  profileId: string;
  firstname: string | undefined;
}

const PersonalData: React.FC<PersonalDataProps> = ({
  privacyState,
  profileId,
  firstname,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const [doNotContactReasonId, setDoNotContactReasonId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [markAsDoNotContact] = useMutation<unknown, DoNotContactInput>(
    MARK_AS_DO_NOT_CONTACT,
  );
  const [unmarkAsDoNotContact] = useMutation<unknown, DoNotContactInput>(
    UNMARK_AS_DO_NOT_CONTACT,
  );

  const { data: doNotContactReasonsData } = useQuery(
    GET_DO_NOT_CONTACT_REASONS,
    {
      variables: { clientId },
      fetchPolicy: 'network-only',
      skip: !modalOpen,
    },
  );

  const clientDoNotContactReasons: DoNotContactReason[] =
    doNotContactReasonsData?.client?.customDoNotContactReasons ?? [];

  const isMarkedAsDoNotContact = privacyState?.markedAsDoNotContact;

  const modalAction = async () => {
    const input = {
      variables: {
        searchPoolId: 'reveal',
        profileId,
        ...(doNotContactReasonId && {
          reason: {
            id: doNotContactReasonId,
            title: sanitizeTypename(
              _.findWhere(clientDoNotContactReasons, {
                id: doNotContactReasonId,
              })?.title ?? '???',
            ),
          },
        }),
      },
    };
    if (privacyState?.markedAsDoNotContact) {
      await unmarkAsDoNotContact(input);
    } else {
      await markAsDoNotContact(input);
    }
    setDoNotContactReasonId('');
    setModalOpen(false);
  };

  const translationPrefix = privacyState?.markedAsDoNotContact
    ? 'okToContact'
    : 'doNotContact';

  return (
    <>
      <hr />
      <div className='details-section personal-data-section'>
        <h4>{t('reveal.profileModal.personalData.title')}</h4>
        <div className='do-not-contact-current-status'>
          {t('reveal.profileModal.privacy.contactStatus')}
          <span className='status'>
            {isMarkedAsDoNotContact ? (
              <DoNotContactPill />
            ) : (
              <OkToContactPill />
            )}
          </span>
        </div>
        <div className='actions'>
          <div className='do-not-contact-section'>
            <h5>{t('reveal.profileModal.privacy.contactStatus')}</h5>
            <p>
              {t('reveal.profileModal.privacy.doNotContact.shortWarning', {
                firstname,
              })}
            </p>
            <GenericButton
              primacy='secondary'
              onClick={() => setModalOpen(true)}
            >
              {isMarkedAsDoNotContact
                ? t('reveal.profileModal.privacy.okToContact.button.label')
                : t('reveal.profileModal.privacy.doNotContact.label')}
            </GenericButton>
          </div>
        </div>
      </div>
      <ConfirmationModal
        header={t(
          `reveal.profileModal.privacy.${translationPrefix}.modal.title`,
          { firstname },
        )}
        submit={t(
          `reveal.profileModal.privacy.${translationPrefix}.modal.submit`,
        )}
        content={
          <>
            {t(
              `reveal.profileModal.privacy.${translationPrefix}.modal.content`,
              { firstname },
            )}
            {!privacyState?.markedAsDoNotContact &&
              !_.isEmpty(clientDoNotContactReasons) && (
                <div className='reasonContainer'>
                  <div className='reasonTitle'>
                    {t(
                      `reveal.profileModal.privacy.${translationPrefix}.reason`,
                    )}
                  </div>
                  <div className='buttons'>
                    {_.map(
                      clientDoNotContactReasons,
                      (archiveReason, index) => (
                        <button
                          type='button'
                          key={index}
                          className={classnames(
                            'customButton',
                            doNotContactReasonId === archiveReason.id &&
                              'buttonActive',
                          )}
                          onClick={() => {
                            if (doNotContactReasonId === archiveReason.id) {
                              setDoNotContactReasonId('');
                            } else {
                              setDoNotContactReasonId(archiveReason.id);
                            }
                          }}
                        >
                          {archiveReason.title.default}
                        </button>
                      ),
                    )}
                  </div>
                </div>
              )}
          </>
        }
        onSubmit={modalAction}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
      />
    </>
  );
};

function ProfileCustomFields({
  customFields,
  resumeData,
}: {
  customFields: CustomFieldDefinition[];
  resumeData?: any;
}) {
  const { t } = useTranslation();
  const detailFields = customFields || [];
  const details = combineDataWithCustomFields(detailFields, resumeData);
  const filteredDetails = _.filter(details, (detail) => !!detail.readableValue);
  if (_.isEmpty(filteredDetails)) return null;

  return (
    <>
      <hr />
      <div className='details-section custom-fields-section'>
        <h4>{t('reveal.profileModal.customFields.title')}</h4>
        <div className='additional-details'>
          {_.map(filteredDetails, (detail) => (
            <div key={detail.id} className='additional-detail'>
              <div className='additional-detail-label'>
                <TranslatableText text={detail.title} />
              </div>
              <div className='additional-detail-value'>
                {detail.readableValue}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

interface ProfileAdditionalDetailsProps {
  atsType: string;
  atsData?: any;
  clientId: string;
  profile: SearchPoolProfile;
}

export const ProfileAdditionalDetails: React.FC<
  ProfileAdditionalDetailsProps
> = ({ atsType, atsData, clientId, profile }) => {
  const { t } = useTranslation();

  const connector = useClientRevealConnector(clientId);

  if (!atsData) {
    return null;
  }

  const {
    currentCompany,
    headline,
    emails,
    phoneNumbers,
    addresses,
    recruiter,
    coordinator,
    sourcer,
    followers,
    tags,
    sourcingChannel,
    sources,
  } = atsData;
  const headlineText = headline?.content?.text;

  const atsProfileURL = getCandidateAtsURL({
    atsDataItem: atsData,
    accountId: connector?.accountId,
  });
  const followersText = _.map(followers, getFullname).join(', ');

  const boondmanagerTagAgency = _.filter(tags, (tag) =>
    tag.sourceTagId?.startsWith('agency__'),
  );
  const boondmanagerTagPole = _.filter(tags, (tag) =>
    tag.sourceTagId?.startsWith('pole__'),
  );
  const boondmanagerTagExpertiseArea = _.filter(tags, (tag) =>
    tag.sourceTagId?.startsWith('expertise-area__'),
  );
  const boondmanagerTagActivityArea = _.filter(tags, (tag) =>
    tag.sourceTagId?.startsWith('activity-area__'),
  );
  const successfactorsTagMobility = _.filter(tags, (tag) =>
    tag.sourceTagId?.startsWith('mobility__'),
  );
  const successfactorsTagContractType = _.filter(tags, (tag) =>
    tag.sourceTagId?.startsWith('contract-type__'),
  );

  const isHired = _.some(atsData?.applications || [], isHiredApplication);

  if (atsType === 'adventure') {
    return <AdventureData profile={profile} atsData={atsData} />;
  }

  return (
    <div className='additional-details'>
      <h4>
        Info
        {atsProfileURL && (
          <a href={atsProfileURL} target='__blank' rel='noopener noreferrer'>
            <i className='ri-share-box-line' />
          </a>
        )}
      </h4>
      {atsType === 'boondmanager' && (
        <>
          {!_.isEmpty(boondmanagerTagAgency) && (
            <div className='additional-detail'>
              <div className='label'>{t('common.agency')}</div>
              <div className='value'>
                {_.map(
                  _.filter(tags, (tag) =>
                    tag.sourceTagId.startsWith('agency__'),
                  ),
                  ({ name }, index) => {
                    return (
                      <Label key={index} className='hiresweet-label'>
                        {getTranslatedText(name)}
                      </Label>
                    );
                  },
                )}
              </div>
            </div>
          )}

          {!_.isEmpty(boondmanagerTagPole) && (
            <div className='additional-detail'>
              <div className='label'>{t('common.pole')}</div>
              <div className='value'>
                {_.map(
                  _.filter(tags, (tag) => tag.sourceTagId.startsWith('pole__')),
                  ({ name }, index) => {
                    return (
                      <Label key={index} className='hiresweet-label'>
                        {getTranslatedText(name)}
                      </Label>
                    );
                  },
                )}
              </div>
            </div>
          )}

          {!_.isEmpty(boondmanagerTagActivityArea) && (
            <div className='additional-detail'>
              <div className='label'>{t('common.domains')}</div>
              <div className='value'>
                {_.map(boondmanagerTagActivityArea, ({ name }, index) => {
                  return (
                    <Label key={index} className='hiresweet-label'>
                      {getTranslatedText(name)}
                    </Label>
                  );
                })}
              </div>
            </div>
          )}

          {!_.isEmpty(boondmanagerTagExpertiseArea) && (
            <div className='additional-detail'>
              <div className='label'>{t('common.sectors')}</div>
              <div className='value'>
                {_.map(boondmanagerTagExpertiseArea, ({ name }, index) => {
                  return (
                    <Label key={index} className='hiresweet-label'>
                      {getTranslatedText(name)}
                    </Label>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
      {atsType === 'successfactors' && (
        <>
          {!_.isEmpty(successfactorsTagMobility) && (
            <div className='additional-detail'>
              <div className='label'>{t('common.mobility')}</div>
              <div className='value'>
                {_.map(successfactorsTagMobility, ({ name }, index) => {
                  return (
                    <Label key={index} className='hiresweet-label'>
                      {getTranslatedText(name)}
                    </Label>
                  );
                })}
              </div>
            </div>
          )}
          {!_.isEmpty(successfactorsTagContractType) && (
            <div className='additional-detail'>
              <div className='label'>{t('common.contractType')}</div>
              <div className='value'>
                {_.map(successfactorsTagContractType, ({ name }, index) => {
                  return (
                    <Label key={index} className='hiresweet-label'>
                      {getTranslatedText(name)}
                    </Label>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
      {!['boondmanager', 'successfactors'].includes(atsType) &&
        !isHired &&
        !_.isEmpty(tags) && (
          <div className='additional-detail'>
            <div className='label'>Tags</div>
            <div className='value'>
              {_.map(tags, ({ name }, index) => {
                return (
                  <Label key={index} className='hiresweet-label'>
                    {getTranslatedText(name)}
                  </Label>
                );
              })}
            </div>
          </div>
        )}
      {atsType === 'lever' && headlineText && (
        <div className='additional-detail'>
          <div className='label'>Organization</div>
          <div className='value'>{headlineText}</div>
        </div>
      )}
      {currentCompany?.name && (
        <div className='additional-detail'>
          <div className='label'>Current company</div>
          <div className='value'>{currentCompany?.name}</div>
        </div>
      )}
      {!_.isEmpty(emails) && (
        <div className='additional-detail'>
          <div className='label'>Emails</div>
          <div>
            {_.map(emails, ({ value }, index) => (
              <div key={index} className='value'>
                {value}
              </div>
            ))}
          </div>
        </div>
      )}
      {!_.isEmpty(phoneNumbers) && (
        <div className='additional-detail'>
          <div className='label'>Phone numbers</div>
          <div>
            {_.map(phoneNumbers, ({ value }, index) => (
              <div className='value' key={index}>
                {value}
              </div>
            ))}
          </div>
        </div>
      )}
      {!_.isEmpty(addresses) && (
        <div className='additional-detail'>
          <div className='label'>Addresses</div>
          <div>
            {_.map(addresses, ({ value }, index) => (
              <div className='value' key={index}>
                {value}
              </div>
            ))}
          </div>
        </div>
      )}
      {!_.isEmpty(recruiter) && (
        <div className='additional-detail'>
          <div className='label'>
            {t(`reveal.profileDetails.recruiter`, 'Recruiter')}
          </div>
          <div className='value'>
            {recruiter?.firstname} {recruiter?.lastname}
          </div>
        </div>
      )}
      {!_.isEmpty(coordinator) && (
        <div className='additional-detail'>
          <div className='label'>Coordinator</div>
          <div className='value'>
            {coordinator?.firstname} {coordinator?.lastname}
          </div>
        </div>
      )}
      {!_.isEmpty(followers) && (
        <div className='additional-detail'>
          <div className='label'>
            {t(`reveal.profileDetails.followers`, 'Followers')}
          </div>
          <div className='value'>{followersText}</div>
        </div>
      )}
      {!_.isEmpty(sourcer) && (
        <div className='additional-detail'>
          <div className='label'>
            {t(`reveal.profileDetails.sourcer`, 'Sourced by')}
          </div>
          <div className='value'>
            {sourcer?.firstname} {sourcer?.lastname}
          </div>
        </div>
      )}
      {!_.isEmpty(sources) && (
        <div className='additional-detail'>
          <div className='label'>
            {t(`reveal.profileDetails.sources`, 'Sources')}
          </div>
          <div className='value'>
            {_.map(sources, (source) => source?.name).join(', ')}
          </div>
        </div>
      )}
      {!_.isEmpty(sourcingChannel) && (
        <div className='additional-detail'>
          <div className='label'>
            {t(`reveal.profileDetails.sourcingChannel`, 'Sourcing Channel')}
          </div>
          <div className='value'>{sourcingChannel?.name}</div>
        </div>
      )}
    </div>
  );
};

interface ProfileApplicationsProps {
  atsData: AtsData;
}

export const ProfileApplications: React.FC<ProfileApplicationsProps> = ({
  atsData,
}) => {
  const applications = atsData?.applications;
  if (!applications || _.isEmpty(applications)) {
    return null;
  }

  const isHired = _.some(applications || [], isHiredApplication);
  if (isHired) {
    return null;
  }

  const sortedApplications = [...applications].sort((a, b) => {
    if (!a.applicationDate && !b.applicationDate) {
      return 0;
    }
    if (!a.applicationDate) {
      return 1;
    }
    if (!b.applicationDate) {
      return -1;
    }
    return (
      new Date(b.applicationDate).getTime() -
      new Date(a.applicationDate).getTime()
    );
  });

  return (
    <div>
      <h4>Application{sortedApplications.length > 1 && 's'}</h4>
      {_.map(sortedApplications, (application) => (
        <Application
          key={application?.sourceApplicationId}
          application={application}
          atsType={atsData?.type}
        />
      ))}
    </div>
  );
};

interface ApplicationProps {
  application: any;
  atsType?: string;
}

const Application: React.FC<ApplicationProps> = ({ application, atsType }) => {
  const {
    isProspect,
    jobs,
    status,
    creditedTo,
    sourcingChannel,
    applicationDate,
    lastActivityDate,
    rejectionDate,
  } = application;
  const { t } = useTranslation();
  const jobTitles = _.pluck(jobs, 'title').join(', ');
  const { sourceStatusId, rejection, stage } = status || {};
  const rejectionReason = rejection?.reason?.sourceRejectionReasonName;
  const lastStage = rejection?.lastStage?.sourceStageName;
  const stageName = stage?.sourceStageName;
  const applicationStatusText = getApplicationStatusText({
    sourceStatusId,
    atsType,
  });
  return (
    <div className='application'>
      <div className='application-title'>
        {jobTitles} {isProspect && <span className='label'>(Prospect)</span>}
      </div>
      {applicationDate && (
        <div className='application-info-item'>
          <span className='label'>{'Creation date '}</span>
          <span className='application-date'>
            {t('common.simpleDate', { date: new Date(applicationDate) })}
          </span>
        </div>
      )}

      {atsType !== 'boondmanager' && (
        <div className='application-info-item'>
          <span className='label'>Status </span>
          <span className={`value ${sourceStatusId}`}>
            {applicationStatusText}
          </span>
        </div>
      )}

      {sourceStatusId !== 'rejected' &&
        stageName &&
        stageName !== applicationStatusText && (
          <div className='application-info-item'>
            <span className='label'>Current stage </span>
            <span className='value'>{stageName}</span>
          </div>
        )}
      {sourceStatusId === 'rejected' && rejectionReason && (
        <div className='application-info-item'>
          <span className='label'>Rejection reason </span>
          <span className='value'>{rejectionReason}</span>
        </div>
      )}
      {sourceStatusId === 'rejected' && lastStage && (
        <div className='application-info-item'>
          <span className='label'>Last stage </span>
          <span className='value'>{lastStage}</span>
        </div>
      )}
      {lastActivityDate && (
        <div className='application-info-item'>
          <span className='label'>Last activity date </span>
          <span className='application-date'>
            {t('common.simpleDate', { date: new Date(lastActivityDate) })}
          </span>
        </div>
      )}
      {(rejectionDate || rejection?.date) && (
        <div className='application-info-item'>
          <span className='label'>Rejection date </span>
          <span className='application-date'>
            {t('common.simpleDate', {
              date: new Date(rejectionDate || rejection?.date),
            })}
          </span>
        </div>
      )}
      {creditedTo && (
        <div className='application-info-item'>
          <span className='label'>Credited to </span>
          <span className='value'>
            {creditedTo?.firstname} {creditedTo?.lastname}
          </span>
        </div>
      )}
      {sourcingChannel?.name && (
        <div className='application-info-item'>
          <span className='label'>Sourcing channel </span>
          <span className='value'>{sourcingChannel?.name}</span>
        </div>
      )}
    </div>
  );
};

const getApplicationStatusText = ({
  sourceStatusId,
  atsType,
}: {
  sourceStatusId: string;
  atsType?: string;
}): string => {
  if (atsType === 'lever' && sourceStatusId === 'rejected') {
    return 'Archived';
  }
  const status =
    (sourceStatusId || '').charAt(0).toUpperCase() +
    (sourceStatusId || '').substring(1);
  return status;
};

const isHiredApplication = (application: any) =>
  (application?.status?.sourceStatusId || '').toLowerCase().includes('hired');

export default withTranslation('translations')(ProfileDetails);
