import SEARCH_POOL_MINI_PROFILE_BY_NUMBER_RESULTS from '@/graphql/searchPoolMiniProfileByNumber';
import { useLazyQuery } from '@apollo/client';

interface useMiniSearchPoolProfileByNumberParams {
  searchNumber: string;
  limit: number;
}

const useMiniSearchPoolProfileByNumber = () =>
  useLazyQuery<any, { input: useMiniSearchPoolProfileByNumberParams, searchPoolId: string }>(SEARCH_POOL_MINI_PROFILE_BY_NUMBER_RESULTS, {
    fetchPolicy: 'no-cache'
  });

export default useMiniSearchPoolProfileByNumber;
