import _ from 'underscore';

export type LocationTarget = {
  target: {
    location?: Location;
    remoteWish: string;
  }[];
};

export type Location =
  | ExactLocation
  | RadiusFromPointLocation
  | RadiusFromIdLocation;

type ExactLocation = {
  type?: 'exact-id';
  id: string;
  label?: string;
};

type RadiusFromPointLocation = {
  type: 'radius-from-point';
  pointLatitude: number;
  pointLongitude: number;
  radiusInKm: number;
  label?: string;
};

export type RadiusFromIdLocation = {
  type: 'radius-from-id';
  id: string;
  radiusInKm: number;
  label?: string;
};

export function getRadiusLocations(
  { target }: LocationTarget = { target: [] },
) {
  const result = [] as (RadiusFromIdLocation | RadiusFromPointLocation)[];
  target.forEach(({ location }) => {
    if (location && 'id' in location) {
      result.push({
        type: 'radius-from-id',
        id: location.id,
        radiusInKm: 'radiusInKm' in location ? location.radiusInKm : 0,
        label: location.label,
      });
      return;
    }
    if (
      location &&
      'pointLatitude' in location &&
      'pointLongitude' in location
    ) {
      result.push({
        type: 'radius-from-point',
        label: location.label,
        pointLongitude: location.pointLongitude,
        pointLatitude: location.pointLatitude,
        radiusInKm: 'radiusInKm' in location ? location.radiusInKm : 0,
      });
    }
  });
  return result;
}

const LOC_SEARCH_URL = 'https://data.geopf.fr/geocodage/search';

export async function getLocationSearchResults(input: string) {
  const url = new URL(LOC_SEARCH_URL);
  url.searchParams.set('q', input);
  const res = await fetch(url);
  if (res.status < 200 || res.status > 399) {
    return [];
  }
  const json = (await res.json()) as { features: SearchLocation[] };

  return _.chain(json.features ?? [])
    .map(({ geometry, properties }) =>
      geometry.type === 'Point'
        ? {
            value: properties.id,
            label: properties.label,
            latitude: geometry.coordinates[0],
            longitude: geometry.coordinates[1],
          }
        : undefined,
    )
    .compact()
    .value();
}

type SearchLocation = {
  geometry:
    | {
        type: 'Point';
        coordinates: [number, number];
      }
    | {
        type:
          | 'LineString'
          | 'Polygon'
          | 'MultiPoint'
          | 'MultiLineString'
          | 'MultiPolygon';
      };
  properties: {
    id: string;
    label: string;
  };
};
